import * as React from 'react';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDocumentsDictByType, selectDocumentsDictRequest } from 'common/store/documents-dict/selectors';
import { fetchDocumentsDict } from 'common/store/documents-dict/actions';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { ApiDocumentTypeT, PartnerTypeT } from 'common/utils/api/models';

export type PropsT = {
    className?: string;
    documentType?: ApiDocumentTypeT;
    partnerType: PartnerTypeT | null;
};

const AsyncDictDocumentNameLabel: React.FC<PropsT> = memo((props) => {
    const { className, documentType, partnerType } = props;
    const dispatch = useDispatch();

    const documentsDictRequest = useSelector(selectDocumentsDictRequest(partnerType));
    const documentsDictByType = useSelector(selectDocumentsDictByType(partnerType));

    useEffect(() => {
        dispatch(fetchDocumentsDict(partnerType));
    }, [partnerType]);

    const dictDocument = documentsDictByType[documentType as ApiDocumentTypeT] || null;

    if (documentsDictRequest.loading) {
        return <ControlLoaderIcon fillColor={StyleGuideColorsEnum.gray} size={DEFAULT_ICON_SIZE} />;
    }

    return <span className={className}>{dictDocument?.name || documentType}</span>;
});

export default AsyncDictDocumentNameLabel;

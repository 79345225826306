import * as React from 'react';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { CurrencyEnum } from 'common/constants';
import { Trans, useTranslation } from 'react-i18next';
import TrailerDictIdFilterPill from 'common/components/filter-pills/TrailerDictIdFilterPill/TrailerDictIdFilterPill';
import Money from 'common/components/Money/Money';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { formatDateRangeFilterValue, pillIconRendererMap } from 'common/components/FilterPills/pill-renderers';
import { QueryFiltersT, QueryFiltersKeysEnum } from '../query-models';

type PropsT = {
    isCompact?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, setQueryFilters } = props;

    const { t } = useTranslation();

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const orderType = queryFilters[QueryFiltersKeysEnum.orderType];

                    return t('common:transport-orders-page.filters.pills.order-type', {
                        value: t(`common:transport-orders-page.filters.transport-order-type.${orderType}`),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.orderType],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('common:transport-orders-page.filters.pills.search', {
                        value: queryFilters[QueryFiltersKeysEnum.searchText],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.searchText],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('common:transport-orders-page.filters.pills.plate-number', {
                        value: queryFilters[QueryFiltersKeysEnum.plateNumber],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.plateNumber],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('common:transport-orders-page.filters.pills.origin-address', {
                        value: queryFilters[QueryFiltersKeysEnum.originAddress],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.originAddress],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('common:transport-orders-page.filters.pills.destination-address', {
                        value: queryFilters[QueryFiltersKeysEnum.destinationAddress],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.destinationAddress],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                renderIcon: (queryFilters, isCompact) => {
                    return pillIconRendererMap.avatar(queryFilters[QueryFiltersKeysEnum.driverName], isCompact);
                },
                render: (queryFilters) => {
                    return t('common:transport-orders-page.filters.pills.driver', {
                        value: queryFilters[QueryFiltersKeysEnum.driverName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.driverId, QueryFiltersKeysEnum.driverName],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const selectedTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

                    return (
                        <Trans
                            i18nKey="common:transport-orders-page.filters.pills.trailer"
                            components={{
                                value: <TrailerDictIdFilterPill trailerDictId={selectedTrailerId} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.dictTrailerId],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const minPrice = queryFilters[QueryFiltersKeysEnum.minPrice];

                    return (
                        <Trans
                            i18nKey="common:transport-orders-page.filters.pills.min-price"
                            components={{
                                value: minPrice ? <Money amount={+minPrice} currency={CurrencyEnum.EUR} /> : null,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.minPrice],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const maxPrice = queryFilters[QueryFiltersKeysEnum.maxPrice];

                    return (
                        <Trans
                            i18nKey="common:transport-orders-page.filters.pills.max-price"
                            components={{
                                value: maxPrice ? <Money amount={+maxPrice} currency={CurrencyEnum.EUR} /> : null,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.maxPrice],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const pickupDateFrom = queryFilters[QueryFiltersKeysEnum.pickupDateFrom];
                    const pickupDateTo = queryFilters[QueryFiltersKeysEnum.pickupDateTo];

                    return t('common:transport-orders-page.filters.pills.pickup', {
                        value: formatDateRangeFilterValue(pickupDateFrom, pickupDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.pickupDateFrom, QueryFiltersKeysEnum.pickupDateTo],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const dropOffDateFrom = queryFilters[QueryFiltersKeysEnum.dropOffDateFrom];
                    const dropOffDateTo = queryFilters[QueryFiltersKeysEnum.dropOffDateTo];

                    return t('common:transport-orders-page.filters.pills.drop-off', {
                        value: formatDateRangeFilterValue(dropOffDateFrom, dropOffDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dropOffDateFrom, QueryFiltersKeysEnum.dropOffDateTo],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const createdDateFrom = queryFilters[QueryFiltersKeysEnum.receiveDatesFrom];
                    const createdDateTo = queryFilters[QueryFiltersKeysEnum.receiveDatesTo];

                    return t('common:transport-orders-page.filters.pills.received', {
                        value: formatDateRangeFilterValue(createdDateFrom, createdDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.receiveDatesFrom, QueryFiltersKeysEnum.receiveDatesTo],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('common:transport-orders-page.filters.pills.spot');
                },
                clearKeys: [QueryFiltersKeysEnum.spot],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('common:transport-orders-page.filters.pills.dedicated');
                },
                clearKeys: [QueryFiltersKeysEnum.dedicated],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('common:transport-orders-page.warning-filters.running-out-of-time-to-assign-driver');
                },
                clearKeys: [QueryFiltersKeysEnum.runningOutOfTimeToAssignDriver],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('common:transport-orders-page.warning-filters.ran-out-of-time-to-assign-driver');
                },
                clearKeys: [QueryFiltersKeysEnum.ranOutOfTimeToAssignDriver],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('common:transport-orders-page.warning-filters.running-out-of-time-to-assign-assets');
                },
                clearKeys: [QueryFiltersKeysEnum.runningOutOfTimeToAssignAssets],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('common:transport-orders-page.warning-filters.ran-out-of-time-to-assign-assets');
                },
                clearKeys: [QueryFiltersKeysEnum.ranOutOfTimeToAssignAssets],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('common:transport-orders-page.warning-filters.expired');
                },
                clearKeys: [QueryFiltersKeysEnum.expired],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('common:transport-orders-page.warning-filters.shipment-missed');
                },
                clearKeys: [QueryFiltersKeysEnum.missed],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('common:transport-orders-page.warning-filters.tour-delaying');
                },
                clearKeys: [QueryFiltersKeysEnum.delaying],
            },
        ],
        [t],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
        />
    );
});

export default SelectedFilters;

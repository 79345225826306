import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import moment from 'moment';

import times from 'lodash/times';

import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './Calendar.scss';

import { useTranslation } from 'react-i18next';
import YearMonthForm from './YearMonthForm/YearMonthForm';

/* eslint-disable import/no-unresolved */
import { ClassNames } from 'react-day-picker/types/ClassNames';
import {
    AfterModifier,
    BeforeAfterModifier,
    BeforeModifier,
    Modifier,
    Modifiers,
} from 'react-day-picker/types/Modifiers';
import { clearDateTimezone, getDateFromISO, parseISOString } from 'common/utils/time';
import { concatTestSelectors } from 'common/utils/test-selectors';

const cx = classNames.bind(styles);

const EMPTY_RANGE = { from: null, to: null };

export type CalendarDateValueT = Date | null;
export type CalendarDateRangeValueT = DateRangeT | null;

export type CalendarBasePropsT = {
    minDate?: string | null;
    maxDate?: string | null;
    hasYearMonthForm?: boolean;
    testSelectorPrefix?: string;
    className?: string;
    disabledDates?: string[];
};

export type CalendarDateValuePropsT = {
    isRange?: false;
    value: CalendarDateValueT;
    initialValue?: CalendarDateValueT;
    focusInitialDate?: CalendarDateValueT;
    onChange: (value: CalendarDateValueT, selectedDate: CalendarDateValueT) => void;
};

export type CalendarDateRangeValuePropsT = {
    isRange: true;
    value: CalendarDateRangeValueT;
    initialValue?: CalendarDateRangeValueT;
    focusInitialDate?: CalendarDateValueT;
    onChange: (value: CalendarDateRangeValueT, selectedDate: CalendarDateValueT) => void;
};

export type CalendarPropsT =
    | (CalendarBasePropsT & CalendarDateValuePropsT)
    | (CalendarBasePropsT & CalendarDateRangeValuePropsT);

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

const Calendar: React.FC<CalendarPropsT> = React.memo((props) => {
    const { value, hasYearMonthForm, disabledDates, testSelectorPrefix, className } = props;

    const minDate = props.minDate ? clearDateTimezone(props.minDate) : null;
    const maxDate = props.maxDate ? clearDateTimezone(props.maxDate) : null;

    const { t } = useTranslation();

    const limitMinDate = minDate ? parseISOString(minDate) : undefined;
    const limitMaxDate = maxDate ? parseISOString(maxDate) : undefined;

    const classNames: ClassNames = React.useMemo(
        () => ({
            container: cs(cx('ReactDayPicker'), className),
            wrapper: cx('ReactDayPicker__wrapper'),
            interactionDisabled: cx('ReactDayPicker__interactionDisabled'),
            navBar: cx('ReactDayPicker__NavBar'),
            navButtonPrev: cx('ReactDayPicker__NavButton', 'ReactDayPicker__NavButton--prev'),
            navButtonNext: cx('ReactDayPicker__NavButton', 'ReactDayPicker__NavButton--next'),
            navButtonInteractionDisabled: cx('ReactDayPicker__NavButton--interactionDisabled'),

            months: cx('ReactDayPicker__Months'),
            month: cx('ReactDayPicker__Month'),
            caption: cx('ReactDayPicker__Caption'),
            weekdays: cx('ReactDayPicker__Weekdays'),
            weekdaysRow: cx('ReactDayPicker__WeekdaysRow'),
            weekday: cx('ReactDayPicker__Weekday'),
            weekNumber: cx('ReactDayPicker__WeekNumber'),
            body: cx('ReactDayPicker__Body'),
            week: cx('ReactDayPicker__Week'),
            day: cx('ReactDayPicker__Day'),
            footer: cx('ReactDayPicker__Footer'),
            todayButton: cx('ReactDayPicker__TodayButton'),

            today: cx('ReactDayPicker__Day--today'),
            selected: cx('ReactDayPicker__Day--selected'),
            disabled: cx('ReactDayPicker__Day--disabled'),
            outside: cx('ReactDayPicker__Day--outside'),
        }),
        [],
    );

    const disabledObjDates = React.useMemo(() => {
        if (!disabledDates) {
            return [];
        }

        return disabledDates.map((date) => {
            return new Date(getDateFromISO(date));
        });
    }, [disabledDates]);

    const disabledDays = React.useMemo(() => {
        let limits: BeforeModifier | AfterModifier | BeforeAfterModifier | undefined;

        if (limitMaxDate) {
            limits = {
                after: limitMaxDate,
            };
        }

        if (limitMinDate) {
            limits = {
                ...(limits || {}),
                before: limitMinDate,
            };
        }

        return [limits, ...disabledObjDates];
    }, [limitMaxDate, limitMinDate, disabledObjDates]);

    const selectedDays = React.useMemo((): Modifier | Modifier[] => {
        return [props.value || EMPTY_RANGE];
    }, [value]);

    const containerProps = React.useMemo((): Record<string, string> => {
        return {
            'data-test-selector': concatTestSelectors([testSelectorPrefix, 'calendar']),
        };
    }, [testSelectorPrefix]);

    const weekdaysShort = React.useMemo(() => {
        const arr = times(7).map((index) => t(`common:day-name.${index}`));

        return [arr[arr.length - 1], ...arr.slice(0, -1)];
    }, [t]);

    const modifiers = React.useMemo((): Partial<Modifiers> => {
        if (!props.isRange) {
            return {
                [cx('startAndEnd')]: props.value || undefined,
            };
        }

        const { value } = props;

        if (
            (value?.from && !value?.to) ||
            (!value?.from && value?.to) ||
            value?.from?.valueOf() === value?.to?.valueOf()
        ) {
            return {
                [cx('startAndEnd')]: value?.from || value?.to || undefined,
            };
        }

        return {
            [cx('start')]: value?.from || undefined,
            [cx('end')]: value?.to || undefined,
        };
    }, [props.isRange, props.value]);

    const getFocusDate = (): Date | undefined => {
        let focusDate;

        let limitDate: Date | null = new Date();

        const parsedMinDate = parseISOString(minDate);
        if (parsedMinDate && limitDate <= parsedMinDate) {
            limitDate = parsedMinDate;
        }

        const parsedMaxDate = parseISOString(maxDate);
        if (parsedMaxDate && parsedMaxDate <= limitDate) {
            limitDate = parsedMaxDate;
        }

        if (props.isRange) {
            focusDate = props.focusInitialDate || props.initialValue?.from || limitDate;
        } else {
            focusDate = props.focusInitialDate || props.initialValue || limitDate;
        }

        return focusDate || undefined;
    };

    const [month, setMonth] = React.useState<Date | undefined>(() => {
        const focusDate = getFocusDate();

        return focusDate;
    });

    React.useEffect(() => {
        const focusDate = getFocusDate();

        setMonth(focusDate);
    }, [props.initialValue, props.focusInitialDate]);

    const handleYearMonthChange = (month: Date) => {
        setMonth(month);
    };

    const handleDateChange = (selectedDate: Date): void => {
        if (
            limitMinDate &&
            moment(limitMinDate).format(ISO_DATE_FORMAT) > moment(selectedDate).format(ISO_DATE_FORMAT)
        ) {
            return;
        }

        if (
            limitMaxDate &&
            moment(limitMaxDate).format(ISO_DATE_FORMAT) < moment(selectedDate).format(ISO_DATE_FORMAT)
        ) {
            return;
        }

        const checkIsDisabledDate = (date: Date) => {
            return disabledObjDates.some((disabledObjDate) => {
                const momentDisabledDate = moment(disabledObjDate);
                return momentDisabledDate.isSame(date, 'day');
            });
        };

        if (props.isRange) {
            const range: CalendarDateRangeValueT = DateUtils.addDayToRange(selectedDate, props.value || EMPTY_RANGE);

            const isDisabled =
                (range.to && checkIsDisabledDate(range.to)) || (range.from && checkIsDisabledDate(range.from));
            if (isDisabled) {
                return;
            }

            props.onChange(range, selectedDate);
        } else {
            const isDisabled = checkIsDisabledDate(selectedDate);
            if (isDisabled) {
                return;
            }

            props.onChange(selectedDate, selectedDate);
        }
    };

    return (
        <DayPicker
            firstDayOfWeek={1}
            numberOfMonths={1}
            modifiers={modifiers}
            classNames={classNames}
            disabledDays={disabledDays}
            month={month}
            showOutsideDays
            selectedDays={selectedDays}
            fromMonth={limitMinDate || undefined}
            toMonth={limitMaxDate || undefined}
            onDayClick={handleDateChange}
            weekdaysShort={weekdaysShort}
            containerProps={containerProps}
            captionElement={
                hasYearMonthForm
                    ? ({ date }) => (
                          <YearMonthForm
                              date={date}
                              onChange={handleYearMonthChange}
                              minDate={minDate}
                              maxDate={maxDate}
                          />
                      )
                    : undefined
            }
        />
    );
});

export default Calendar;

import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerPage.scss';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import BackButton from 'common/components/BackButton/BackButton';
import history, { goBackIfHasRePath } from 'common/utils/history';
import { PartnersRoutesEnum, routesEnum } from 'broker-admin/constants';
import NotificationLabel, {
    NotificationLabelThemeEnum,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import { Route, Switch, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import PartnerDetailsPage from './PartnerDetailsPage/PartnerDetailsPage';
import PartnerDocumentsPage from './PartnerDocumentsPage/PartnerDocumentsPage';
import PartnerMembersPage from './PartnerMembersPage/PartnerMembersPage';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { fetchPartnerDetails } from 'broker-admin/store/partner/details/actions';
import {
    selectFetchPartnerDetailsRequest,
    selectPartnerDetailsById,
} from 'broker-admin/store/partner/details/selectors';
import InlineLoader from 'common/components/InlineLoader/InlineLoader';
import NavigationTabs, {
    NavigationTabConfigT,
    NavigationTabsPropsT,
} from 'common/components/NavigationTabs/NavigationTabs';
import PartnerTrucksPage from './PartnerTrucksPage/PartnerTrucksPage';
import RemoteFormActions from 'common/components/forms/RemoteFormActions/RemoteFormActions';
import useContainerScroll from 'common/utils/hooks/useContainerScroll';
import getScrollbarWidth from 'common/utils/get-scroll-bar-width';
import PartnerTrailersPage from './PartnerTrailersPage/PartnerTrailersPage';
import { selectPermissions } from 'common/store/auth/selectors';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import PartnerDriversPage from './PartnerDriversPage/PartnerDriversPage';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import PartnerTransportOrders from './PartnerTransportOrders/PartnerTransportOrders';
import PartnerTransportOrderDetails from './PartnerTransportOrderDetails/PartnerTransportOrderDetails';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import PageTitle from 'common/components/PageTitle/PageTitle';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import PartnerContext from 'common/contexts/partner-context';
import isString from 'lodash/isString';
import useRemoteFormActions from 'common/utils/hooks/useRemoteFormActions';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import { partnerDetailsRefreshChannel } from 'broker-admin/store/partner/details/channels';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import FeaturesContext from 'common/contexts/features';
import { ClientFeatureEnum } from 'common/utils/features/client-features-config';
import PartnerTypePill from 'common/components/pills/PartnerTypePill/PartnerTypePill';

const cx = classNames.bind(styles);

type PropsT = {};

type ParamsT = {
    partnerType: PartnerTypeEnum;
    partnerId: PartnerIdT;
};

const PartnerPage: React.FC<PropsT> = React.memo(() => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const permissions = useSelector(selectPermissions);
    const featuresContext = React.useContext(FeaturesContext);

    const { containerRef, onContainerScroll, scrollTop } = useContainerScroll();

    const remoteFormActions = useRemoteFormActions();

    const params = useParams<ParamsT>();
    const { partnerId, partnerType } = params;

    React.useEffect(() => {
        dispatch(fetchPartnerDetails(partnerId));
    }, [partnerId]);

    const refreshPageHandler = React.useCallback(() => {
        dispatch(fetchPartnerDetails(partnerId));
    }, [partnerId]);
    useChannelSubscribe(partnerDetailsRefreshChannel, refreshPageHandler);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchPartnerDetails(partnerId));
    }, [partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const fetchRequest = useSelector(selectFetchPartnerDetailsRequest);
    const partnerDetailsById = useSelector(selectPartnerDetailsById);
    const partnerDetails = partnerDetailsById[partnerId];

    const goToPartnersPage = (): void => {
        goBackIfHasRePath(() => {
            history.push({
                pathname: routesEnum.partners,
            });
        });
    };

    const handleSelectTab: NavigationTabsPropsT['onSelectTab'] = (to): void => {
        if (isString(to)) {
            const pathname = generatePath(to, params);

            history.push({
                pathname,
            });
        } else {
            history.push({
                ...to,
            });
        }
    };

    const openLeftSidebar = useOpenLeftSidebar();
    const handleOpenUserDetails = (userId: UserIdT | null) => {
        if (!userId) {
            logWarning('failed to open user details in partner details, empty userId');
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const warningBadgeNode = <NotificationLabel isShowWarning theme={NotificationLabelThemeEnum.orange} />;

    const tabsConfig = React.useMemo((): Array<NavigationTabConfigT | null> => {
        const hasDetailsProblem =
            partnerDetails &&
            (!partnerDetails?.addressVerified ||
                (partnerType === PartnerTypeEnum.shipper && !partnerDetails?.billingAddressVerified));
        const hasDocumentsProblem = partnerDetails && !partnerDetails?.documentsVerified;

        return [
            {
                pathname: PartnersRoutesEnum.partnerDetails,
                to: PartnersRoutesEnum.partnerDetails,
                badge: hasDetailsProblem ? warningBadgeNode : null,
                render: () => t('partner.tabs.details'),
                testSelector: 'details',
            },
            {
                pathname: PartnersRoutesEnum.partnerDocuments,
                to: PartnersRoutesEnum.partnerDocuments,
                badge: hasDocumentsProblem ? warningBadgeNode : null,
                render: () => t('partner.tabs.documents'),
                testSelector: 'documents',
            },
            permissions.canViewTeamMembers
                ? {
                      pathname: PartnersRoutesEnum.partnerMembers,
                      to: PartnersRoutesEnum.partnerMembers,
                      render: () => <span>{t('partner.tabs.members')}</span>,
                      testSelector: 'members',
                  }
                : null,
            featuresContext?.checkActiveClientFeature?.(ClientFeatureEnum.partnerOrders)
                ? {
                      pathname: PartnersRoutesEnum.partnerOrders,
                      to: PartnersRoutesEnum.partnerOrders,
                      render: () => <span>{t('partner.tabs.orders')}</span>,
                      testSelector: 'orders',
                  }
                : null,
            featuresContext?.checkActiveClientFeature?.(ClientFeatureEnum.partnerInvoices)
                ? {
                      pathname: PartnersRoutesEnum.partnerInvoices,
                      to: PartnersRoutesEnum.partnerInvoices,
                      render: () => <span>{t('partner.tabs.invoices')}</span>,
                      testSelector: 'invoices',
                  }
                : null,
            partnerType === PartnerTypeEnum.carrier && permissions.canViewTrailers
                ? {
                      pathname: PartnersRoutesEnum.partnerTrailers,
                      to: PartnersRoutesEnum.partnerTrailers,
                      render: () => <span>{t('partner.tabs.trailers')}</span>,
                      testSelector: 'trailers',
                  }
                : null,
            partnerType === PartnerTypeEnum.carrier && permissions.canViewTrucks
                ? {
                      pathname: PartnersRoutesEnum.partnerTrucks,
                      to: PartnersRoutesEnum.partnerTrucks,
                      render: () => <span>{t('partner.tabs.trucks')}</span>,
                      testSelector: 'trucks',
                  }
                : null,
            partnerType === PartnerTypeEnum.carrier &&
            permissions.canViewDrivers &&
            featuresContext?.checkActiveClientFeature?.(ClientFeatureEnum.partnerDrivers)
                ? {
                      pathname: PartnersRoutesEnum.partnerDrivers,
                      to: PartnersRoutesEnum.partnerDrivers,
                      render: () => <span>{t('partner.tabs.drivers')}</span>,
                      testSelector: 'drivers',
                  }
                : null,
            partnerType === PartnerTypeEnum.carrier
                ? {
                      pathname: PartnersRoutesEnum.partnerTransportOrders,
                      to: PartnersRoutesEnum.partnerTransportOrders,
                      render: () => <span>{t('partner.tabs.transport-orders')}</span>,
                      testSelector: 'transport-orders',
                  }
                : null,
        ];
    }, [partnerDetails, featuresContext]);

    const scrollBarWidth = React.useMemo(() => getScrollbarWidth(containerRef?.current), [containerRef?.current]);

    const isShowPartnerNameLoader = !partnerDetails && !fetchRequest.ok && !fetchRequest.error;

    const partnerContextValue = React.useMemo(() => {
        return {
            partnerType: params.partnerType,
            partnerId: params.partnerId,
        };
    }, [params.partnerId, params.partnerType]);

    return (
        <RemoteFormActionsContext.Provider value={remoteFormActions}>
            <PartnerContext.Provider value={partnerContextValue}>
                <PageTitle
                    title={t('page-titles.partner', {
                        name: partnerDetails?.name || '',
                    })}
                />
                <Switch>
                    <Route path={PartnersRoutesEnum.partnerTransportOrderDetails}>
                        <PartnerTransportOrderDetails />
                    </Route>
                    <Route path="*">
                        <ScrollableContent
                            scrollContainerRef={containerRef}
                            onContainerScroll={onContainerScroll}
                            stickyHeaderNode={
                                <TopBar isSticky isWhite={scrollTop > 10} scrollBarWidth={scrollBarWidth}>
                                    <TopBarContent
                                        leftNode={<BackButton onClick={goToPartnersPage} isDisabled={false} />}
                                        title={
                                            isShowPartnerNameLoader ? (
                                                <InlineLoader lineHeight={20} />
                                            ) : (
                                                `${partnerDetails?.name || ''}${
                                                    partnerDetails?.number ? ` (${partnerDetails?.number || ''})` : ''
                                                }`
                                            )
                                        }
                                        afterTitle={<PartnerTypePill type={partnerType} />}
                                        rightNode={
                                            <>
                                                <Switch>
                                                    <Route path={PartnersRoutesEnum.partnerDetails}>
                                                        <RemoteFormActions className={cx('header__controls')} />
                                                    </Route>
                                                </Switch>
                                                <NotificationsBarTrigger />
                                            </>
                                        }
                                    />
                                </TopBar>
                            }
                        >
                            <ContentMargins>
                                <div className={cx('controls')}>
                                    <NavigationTabs tabsConfig={tabsConfig} onSelectTab={handleSelectTab} />
                                    {/* <div className={cx('actions')}> */}
                                    {/*    <TransparentTrigger */}
                                    {/*        label={t('common:actions-trigger')} */}
                                    {/*        onClick={() => { */}
                                    {/*            // TODO */}
                                    {/*        }} */}
                                    {/*        rightIcon={<ArrowsIcon />} */}
                                    {/*    /> */}
                                    {/* </div> */}
                                </div>
                            </ContentMargins>
                            <Switch>
                                <Route path={PartnersRoutesEnum.partnerDetails}>
                                    <PartnerDetailsPage />
                                </Route>
                                <Route path={PartnersRoutesEnum.partnerDocuments}>
                                    <PartnerDocumentsPage />
                                </Route>
                                <Route path={PartnersRoutesEnum.partnerMembers}>
                                    <PartnerMembersPage />
                                </Route>
                                <Route path={PartnersRoutesEnum.partnerOrders}>order</Route>
                                <Route path={PartnersRoutesEnum.partnerInvoices}>invoices</Route>
                                <Route path={PartnersRoutesEnum.partnerTrailers}>
                                    <PartnerTrailersPage onOpenUserDetails={handleOpenUserDetails} />
                                </Route>
                                <Route path={PartnersRoutesEnum.partnerDrivers}>
                                    <PartnerDriversPage />
                                </Route>
                                <Route path={PartnersRoutesEnum.partnerTrucks}>
                                    <PartnerTrucksPage onOpenUserDetails={handleOpenUserDetails} />
                                </Route>
                                <Route path={PartnersRoutesEnum.partnerTransportOrders}>
                                    <PartnerTransportOrders />
                                </Route>
                            </Switch>
                        </ScrollableContent>
                    </Route>
                </Switch>
                <SideBars />
            </PartnerContext.Provider>
        </RemoteFormActionsContext.Provider>
    );
});

export default PartnerPage;

import React from 'react';
import classNames from 'classnames/bind';

import styles from './FiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import Input from 'common/components/Input/Input';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import NumberInput from 'common/components/NumberInput/NumberInput';
import { StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import { getMoneyMask } from 'common/utils/input-masks';
import { TransportOrderTypeEnum } from 'common/utils/api/models';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import CloseIcon from 'common/icons/CloseIcon';
import SelectedFilters from 'common/layouts/TransportOrderListPageLayout/SelectedFilters/SelectedFilters';
import PinIcon, { PinIconProps } from 'common/icons/PinIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { TooltipPositionEnum } from 'design-system/components/Tooltip/Tooltip';
import DriverSuggest from 'common/components/suggests/DriverSuggest/DriverSuggest';
import { mergeQueryFilters } from 'common/utils/query';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { QueryFiltersT } from '../query-models';

const cx = classNames.bind(styles);

type PropsT = {
    onClose: () => void;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

type TransportOrderTypeOptionT = { label: string; value: TransportOrderTypeEnum | null };

const AVAILABLE_ORDER_TYPE = [
    TransportOrderTypeEnum.train,
    TransportOrderTypeEnum.truck,
    TransportOrderTypeEnum.trailer,
];

const FiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose, setQueryFilters, queryFilters } = props;
    const { t } = useTranslation();

    const { partnerId } = usePartnerContext();

    const [initialQueryFilters, setInitialQueryFilters] = React.useState<QueryFiltersT>(queryFilters);

    React.useEffect(() => {
        setInitialQueryFilters(queryFilters);
    }, [queryFilters]);

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const newQueryFilters = getQueryFilters(values);
            const mergedQueryFilters = mergeQueryFilters(initialQueryFilters, newQueryFilters);
            setQueryFilters(mergedQueryFilters);

            formikHelpers.setTouched({});
        },
    });

    const moneyMask = React.useMemo(() => getMoneyMask(), []);

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values) && isDeepEqual(initialQueryFilters, queryFilters);
    }, [initialValues, formik.values, initialQueryFilters, queryFilters]);

    const typeOptions: Array<TransportOrderTypeOptionT> = React.useMemo(
        () =>
            AVAILABLE_ORDER_TYPE.map((value) => ({
                label: t(`common:transport-orders-page.filters.transport-order-type.${value}`),
                value,
            })),
        [],
    );

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('common:transport-orders-page.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FormikField
                    className={cx('field')}
                    name={FieldsEnum.originAddress}
                    error={formik.errors[FieldsEnum.originAddress]}
                    meta={formik.getFieldMeta(FieldsEnum.originAddress)}
                    label={t('common:transport-orders-page.filters.fields.origin-address')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    tooltipPosition={TooltipPositionEnum.topLeft}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                            {t('common:transport-orders-page.filters.tooltip.address')}
                        </TooltipContent>
                    }
                >
                    {(props) => (
                        <Input
                            renderLeftIcon={(iconMeta) => <PinIcon {...PinIconProps.getControlProps(iconMeta)} />}
                            name={FieldsEnum.originAddress}
                            value={formik.values[FieldsEnum.originAddress] || ''}
                            placeholder={t('common:transport-orders-page.filters.placeholders.address')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasClearControl
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field')}
                    name={FieldsEnum.destinationAddress}
                    error={formik.errors[FieldsEnum.destinationAddress]}
                    meta={formik.getFieldMeta(FieldsEnum.destinationAddress)}
                    label={t('common:transport-orders-page.filters.fields.destination-address')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    tooltipPosition={TooltipPositionEnum.topLeft}
                    tooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                            {t('common:transport-orders-page.filters.tooltip.address')}
                        </TooltipContent>
                    }
                >
                    {(props) => (
                        <Input
                            renderLeftIcon={(iconMeta) => <PinIcon {...PinIconProps.getControlProps(iconMeta)} />}
                            name={FieldsEnum.destinationAddress}
                            value={formik.values[FieldsEnum.destinationAddress] || ''}
                            placeholder={t('common:transport-orders-page.filters.placeholders.address')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                            hasClearControl
                        />
                    )}
                </FormikField>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.pickupDates}
                        error={formik.errors[FieldsEnum.pickupDates]}
                        meta={formik.getFieldMeta(FieldsEnum.pickupDates)}
                        label={t('common:transport-orders-page.filters.fields.pickup-dates')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.pickupDates]}
                                placeholder={t('common:transport-orders-page.filters.placeholders.pickup-dates')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.topLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.dropoffDates}
                        error={formik.errors[FieldsEnum.dropoffDates]}
                        meta={formik.getFieldMeta(FieldsEnum.dropoffDates)}
                        label={t('common:transport-orders-page.filters.fields.drop-off-dates')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.dropoffDates]}
                                placeholder={t('common:transport-orders-page.filters.placeholders.drop-off-dates')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.topRight}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--dates')}
                        name={FieldsEnum.receiveDates}
                        error={formik.errors[FieldsEnum.receiveDates]}
                        meta={formik.getFieldMeta(FieldsEnum.receiveDates)}
                        label={t('common:transport-orders-page.filters.fields.received-dates')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.receiveDates]}
                                placeholder={t('common:transport-orders-page.filters.placeholders.received-dates')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--order-type')}
                        name={FieldsEnum.orderType}
                        error={formik.errors[FieldsEnum.orderType]}
                        meta={formik.getFieldMeta(FieldsEnum.orderType)}
                        label={t('common:transport-orders-page.filters.fields.order-type')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DropdownInput<TransportOrderTypeOptionT, TransportOrderTypeOptionT['value']>
                                selectedValue={formik.values[FieldsEnum.orderType]}
                                placeholder={t('common:transport-orders-page.filters.placeholders.order-type')}
                                options={typeOptions}
                                onSelect={props.onChange}
                                renderOption={(option: TransportOrderTypeOptionT | null | undefined, placeholder) => {
                                    if (!option) {
                                        return <span>{placeholder}</span>;
                                    }

                                    return <span>{option.label}</span>;
                                }}
                                onRightClick={() => {
                                    props.onChange(null);
                                }}
                                renderRightIcon={() =>
                                    formik.values[FieldsEnum.orderType] ? (
                                        <CloseIcon fillColor={StyleGuideColorsEnum.gray} />
                                    ) : null
                                }
                                getOptionValue={(option) => option.value}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--trailer-type')}
                        name={FieldsEnum.dictTrailerId}
                        error={formik.errors[FieldsEnum.dictTrailerId]}
                        meta={formik.getFieldMeta(FieldsEnum.dictTrailerId)}
                        label={t('common:transport-orders-page.filters.fields.trailer-type')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DictTrailerDropdown
                                placeholder={t('common:transport-orders-page.filters.placeholders.trailer-type')}
                                value={formik.values[FieldsEnum.dictTrailerId]}
                                onChange={props.onChange}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--plate-number')}
                        name={FieldsEnum.plateNumber}
                        error={formik.errors[FieldsEnum.plateNumber]}
                        meta={formik.getFieldMeta(FieldsEnum.plateNumber)}
                        label={t('common:transport-orders-page.filters.fields.plate-number')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        tooltipPosition={TooltipPositionEnum.topCenter}
                        tooltipNode={
                            <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                                {t('common:transport-orders-page.filters.tooltip.plate-number')}
                            </TooltipContent>
                        }
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.plateNumber}
                                value={formik.values[FieldsEnum.plateNumber] || ''}
                                placeholder={t('common:transport-orders-page.filters.placeholders.plate-number')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--driver')}
                        name={FieldsEnum.driverId}
                        error={formik.errors[FieldsEnum.driverId]}
                        meta={formik.getFieldMeta(FieldsEnum.driverId)}
                        label={t('common:transport-orders-page.filters.fields.driver')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        tooltipPosition={TooltipPositionEnum.topLeft}
                        tooltipNode={
                            <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                                {t('common:transport-orders-page.filters.tooltip.driver')}
                            </TooltipContent>
                        }
                    >
                        {(props) => (
                            <DriverSuggest
                                partnerId={partnerId}
                                value={formik.values[FieldsEnum.driverId]}
                                initialValue={initialValues[FieldsEnum.driverId]}
                                initialLabel={initialValues[FieldsEnum.driverName] || ''}
                                hasClearControl
                                onSelect={(driverId, driverName) => {
                                    formik.setFieldValue(FieldsEnum.driverId, driverId);
                                    formik.setFieldValue(FieldsEnum.driverName, driverName);
                                }}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                placeholder={t('common:transport-orders-page.filters.placeholders.driver')}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                            />
                        )}
                    </FormikField>
                    <div className={cx('field', 'field--price')}>
                        <FieldGroup isFullWidth>
                            <FormikField
                                className={cx('field', 'field--min-price')}
                                name={FieldsEnum.minPrice}
                                error={formik.errors[FieldsEnum.minPrice]}
                                meta={formik.getFieldMeta(FieldsEnum.minPrice)}
                                label={t('common:transport-orders-page.filters.fields.price')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <NumberInput
                                        name={FieldsEnum.minPrice}
                                        unitType={UnitTypeEnum.euroAbbreviation}
                                        value={formik.values[FieldsEnum.minPrice]}
                                        placeholder={t('common:transport-orders-page.filters.placeholders.min-price')}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        mask={moneyMask}
                                        step={1}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field', 'field--max-price')}
                                name={FieldsEnum.maxPrice}
                                meta={formik.getFieldMeta(FieldsEnum.maxPrice)}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <NumberInput
                                        name={FieldsEnum.maxPrice}
                                        unitType={UnitTypeEnum.euroAbbreviation}
                                        value={formik.values[FieldsEnum.maxPrice]}
                                        placeholder={t('common:transport-orders-page.filters.placeholders.max-price')}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        mask={moneyMask}
                                        step={1}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                    </div>
                </FieldGroup>
            </SideBarLayout>
            <FooterSideBarLayout hasPaddings>
                <div className={cx('selected-filters')}>
                    <SelectedFilters queryFilters={initialQueryFilters} setQueryFilters={setInitialQueryFilters} />
                </div>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('common:transport-orders-page.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default FiltersSidebarContent;

import React, { useEffect } from 'react';
import OutsideClickHandler from 'design-system/components/OutsideClickHandler/OutsideClickHandler';
import classNames from 'classnames/bind';

import styles from './GeoSuggest.scss';
import GoogleMapContext from 'common/contexts/google-map-context';
import { prepareAddressComponents } from 'common/utils/google-places';
import CloseIcon from 'common/icons/CloseIcon';
import { GoogleMapsAutocompleteApi } from 'common/utils/google-maps-api/google-maps-autocomplete-api';
import { GoogleMapsPlacesApi } from 'common/utils/google-maps-api/google-maps-places-api';
import { GoogleMapsGeocoderApi } from 'common/utils/google-maps-api/google-maps-geocoder-api';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { AUTO_COMPLETE_OFF_FIX, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import cs from 'classnames';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import isEmpty from 'lodash/isEmpty';

const cx = classNames.bind(styles);

type ValueT = LocationT | null;

export type IconMetaT = {
    isLoading: boolean;
    hasError: boolean;
    hasWarning: boolean;
    isDisabled: boolean;
    hasValue: boolean;
};

type PropsT = {
    className?: string;
    name: string;
    placeholder: string;
    value: ValueT;
    renderLeftIcon: (meta: IconMetaT) => React.ReactNode;
    onChange: (value: ValueT) => void;
    onFocus?: (name: string) => void;
    onBlur?: (name: string) => void;
    hasError?: boolean;
    hasWarning?: boolean;
    testSelector?: string;
    showClearControl?: boolean;
    isRedTriangle?: boolean;
    overlayPosition?: 'top' | 'bottom';
    isLoading?: boolean;
    renderRightNode?: (meta: IconMetaT) => React.ReactNode;
    triangleClassName?: string;
    isDisabled?: boolean;
    hasTriangle?: boolean;
};

type PlacesSearchResultT = {
    address: string;
    placeId: string;
};

const preparePlacesResponse = (item: google.maps.places.QueryAutocompletePrediction): PlacesSearchResultT => {
    return {
        address: item.description,
        placeId: item.place_id,
    };
};

const EUROPE_BOUNDS: MapBoundsT = [
    [35.746178, -12.780298],
    [71.566289, 44.937383],
];

type QueryT = string;

const fetch = async (
    autocomplete: GoogleMapsAutocompleteApi | null,
    query: QueryT,
): Promise<[QueryT | null, PlacesSearchResultT[]]> => {
    if (!autocomplete) {
        return Promise.resolve([query, []]);
    }

    const results = await autocomplete.getQueryPredictions(query, EUROPE_BOUNDS);

    return [results[0], results[1].map(preparePlacesResponse)];
};

const getSuggestionLabel = (suggestion: PlacesSearchResultT): string => suggestion.address;

const GeoSuggest: React.FC<PropsT> = (props) => {
    const {
        className,
        name,
        value,
        placeholder,
        renderLeftIcon,
        onFocus,
        onBlur,
        onChange,
        hasError,
        hasWarning,
        testSelector,
        showClearControl,
        isRedTriangle,
        overlayPosition,
        isLoading,
        renderRightNode,
        triangleClassName,
        isDisabled,
        hasTriangle,
    } = props;

    const isMouseOverlayFocusRef = React.useRef<boolean>(false);

    const [isOpen, toggleOpen] = React.useState<boolean>(false);
    useEffect(() => {
        if (!isOpen) {
            isMouseOverlayFocusRef.current = false;
        }
    }, [isOpen]);

    const [suggestions, setSuggestions] = React.useState<PlacesSearchResultT[]>([]);
    const lastSuggestionsQueryRef = React.useRef<string>('');

    const [text, setText] = React.useState<string>(value?.address || '');
    const [isFocused, setFocus] = React.useState<boolean>(false);

    React.useEffect(() => {
        const newText = value?.address;
        if (newText !== text) {
            setText(newText || '');
            setSuggestions([]);
        }
    }, [value]);

    const handleOuterEvent = () => {
        toggleOpen(false);

        if (onBlur) {
            onBlur(name);
        }
    };

    const googleMapContext = React.useContext(GoogleMapContext);
    const autocomplete = React.useMemo((): GoogleMapsAutocompleteApi | null => {
        const autocompleteApi = new GoogleMapsAutocompleteApi(googleMapContext?.googleMaps?.maps);

        return autocompleteApi;
    }, [googleMapContext.googleMaps?.maps]);

    const handleChange = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
        const eventText = event.currentTarget.value;

        setText(eventText);

        if (!eventText) {
            onChange(null);
        }

        lastSuggestionsQueryRef.current = eventText;

        const result = await fetch(autocomplete, eventText);
        if (!result) {
            return;
        }

        const [resultQuery, resultSuggestions] = result;
        if (lastSuggestionsQueryRef.current !== resultQuery) {
            return;
        }

        toggleOpen(true);
        setSuggestions(resultSuggestions);
    };

    const handleOptionClick = async (suggestion: PlacesSearchResultT): Promise<void> => {
        const placesApi = new GoogleMapsPlacesApi(
            googleMapContext?.googleMaps?.map,
            googleMapContext?.googleMaps?.maps,
        );

        const result = await placesApi.getPlaceDetails(suggestion.placeId);
        if (!result) {
            return;
        }

        const addressComponents = prepareAddressComponents(result.address_components);

        const point: GeoPointT = {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
        };
        const location: LocationT = {
            point,
            address: suggestion.address,
            utcOffsetMinutes: result.utc_offset_minutes,
            addressComponents,
        };

        if (!location?.addressComponents?.zipCode) {
            const geocoderApi = new GoogleMapsGeocoderApi(googleMapContext?.googleMaps?.maps);
            const geocodeResult = await geocoderApi.geocode(point);

            const geocodeAddressComponents = prepareAddressComponents(geocodeResult?.address_components || []);
            location.addressComponents = {
                ...location.addressComponents,
                zipCode: geocodeAddressComponents?.zipCode || null,
            };
        }

        onChange(location);

        const suggestionLabel = getSuggestionLabel(suggestion);
        setText(suggestionLabel);

        toggleOpen(false);

        if (onBlur) {
            onBlur(name);
        }
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        setFocus(false);

        if (!isMouseOverlayFocusRef.current) {
            if (value) {
                setText(value.address || '');
            }

            if (onBlur) {
                onBlur(name);
            }
        }
    };

    const handleFocus = (): void => {
        setFocus(true);

        if (onFocus) {
            onFocus(name);
        }
    };

    const handleReset = (): void => {
        if (isDisabled) {
            return;
        }

        onChange(null);
    };

    const fullTestSelector = `${testSelector}_geo-suggest`;

    return (
        <div className={cs(cx('dropdown'), className)} data-field-name={name}>
            <OutsideClickHandler onOutsideClick={handleOuterEvent} isDisabled={!isOpen}>
                <div className={cx('input')}>
                    <div className={cx('trigger')}>
                        <input
                            name={name}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={text}
                            disabled={isDisabled}
                            data-test-selector={`${fullTestSelector}_input`}
                            className={cx('trigger-input', {
                                'trigger-input--hasIcon': !!renderLeftIcon,
                                'trigger-input--hasClearControl': showClearControl,
                                'trigger-input--isEmpty': !text,
                                'trigger-input--isFocused': isFocused,
                                'trigger-input--hasError': hasError,
                                'trigger-input--hasWarning': hasWarning,
                            })}
                            placeholder={placeholder}
                            autoComplete={AUTO_COMPLETE_OFF_FIX}
                        />
                        {renderLeftIcon && (
                            <div className={cx('trigger-input__icon')}>
                                {renderLeftIcon({
                                    isDisabled: !!isDisabled,
                                    isLoading: !!isLoading,
                                    hasWarning: !!hasWarning,
                                    hasError: !!hasError,
                                    hasValue: !isEmpty(value),
                                })}
                            </div>
                        )}
                        {(showClearControl || renderRightNode) && (
                            <div className={cx('right-node')}>
                                {isLoading && (
                                    <ControlLoaderIcon fillColor={StyleGuideColorsEnum.gray} size={DEFAULT_ICON_SIZE} />
                                )}
                                {showClearControl && (
                                    <ClickInterceptorLabel>
                                        <TransparentTrigger
                                            spaces="xs"
                                            isDisabled={isDisabled}
                                            onClick={handleReset}
                                            leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.gray} />}
                                            reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                                        />
                                    </ClickInterceptorLabel>
                                )}
                                {renderRightNode && (
                                    <ClickInterceptorLabel>
                                        {renderRightNode({
                                            isDisabled: !!isDisabled,
                                            isLoading: !!isLoading,
                                            hasWarning: !!hasWarning,
                                            hasError: !!hasError,
                                            hasValue: !isEmpty(value),
                                        })}
                                    </ClickInterceptorLabel>
                                )}
                            </div>
                        )}
                    </div>
                    {isOpen && !!suggestions.length && (
                        <div
                            className={cx('overlay', {
                                [`overlay--position-${overlayPosition || 'bottom'}`]: true,
                            })}
                            onMouseLeave={() => {
                                isMouseOverlayFocusRef.current = false;
                            }}
                            onMouseEnter={() => {
                                isMouseOverlayFocusRef.current = true;
                            }}
                        >
                            {suggestions.map((suggestion, index) => {
                                const isSelected = value?.address === suggestion.address;
                                const suggestionLabel = getSuggestionLabel(suggestion);

                                return (
                                    <div
                                        key={index}
                                        className={cx('overlay__option', {
                                            'overlay__option--isSelected': isSelected,
                                        })}
                                        data-test-selector={`${fullTestSelector}_option_${index}`}
                                        onClick={(): void => {
                                            handleOptionClick(suggestion);
                                        }}
                                    >
                                        {suggestionLabel}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </OutsideClickHandler>
            {hasTriangle && (
                <div
                    className={cs(
                        cx('triangle', {
                            'triangle--isHide': !isFocused,
                            'triangle--isRed': isRedTriangle,
                        }),
                        triangleClassName,
                    )}
                />
            )}
        </div>
    );
};

export default GeoSuggest;

type ParamsT = {
    shouldBrokerWindowAppointment: boolean | null | undefined;
    originalDateTimeFrom: string | null | undefined;
    originalDateTimeTo: string | null | undefined;
    correctedDateTimeFrom: string | null | undefined;
    correctedDateTimeTo: string | null | undefined;
};

export const checkBrokerAppointmentStatus = (params: ParamsT) => {
    const {
        shouldBrokerWindowAppointment,
        originalDateTimeFrom,
        originalDateTimeTo,
        correctedDateTimeTo,
        correctedDateTimeFrom,
    } = params;

    const isCorrectedDateTime =
        originalDateTimeFrom !== correctedDateTimeFrom || originalDateTimeTo !== correctedDateTimeTo;

    return {
        shouldBrokerWindowAppointment: !!shouldBrokerWindowAppointment,
        shouldShowCorrectedDateTime: !!shouldBrokerWindowAppointment || isCorrectedDateTime,
    };
};

import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerDetailsLayout.scss';
import { useTranslation } from 'react-i18next';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { PartnerDetailsT } from 'broker-admin/store/partner/details/models';
import { AddressTypeEnum, PartnerTypeEnum } from 'common/utils/api/models';
import Widget from 'common/components/Widget/Widget';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import BasicWidgetHeaderActions from '../BasicWidgetHeaderActions/BasicWidgetHeaderActions';
import AddressWidgetHeaderActions from '../AddressWidgetHeaderActions/AddressWidgetHeaderActions';
import RFQStats from 'broker-admin/layouts/PartnerPage/PartnerDetailsPage/RFQStats/RFQStats';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import CreatePartnerInfo from 'common/layouts/BaseProfileLayout/ProfileBasicInformation/CreatePartnerInfo/CreatePartnerInfo';
import { CountryCodeT } from 'common/store/countries-dict/models';
import FieldValue from 'common/components/FieldValue/FieldValue';
import { useLegalFormDict } from 'common/utils/hooks/useLegalFormDict';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { useCountriesByCode } from 'common/utils/hooks/useCountriesByCode';
import FlagIcon from 'common/icons/FlagIcon/FlagIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

type PropsT = {
    partnerDetails: PartnerDetailsT | null;
};

const PartnerDetailsLayout: React.FC<PropsT> = React.memo((props) => {
    const { partnerDetails } = props;

    const { partnerId, partnerType } = usePartnerContext();

    const { t } = useTranslation();

    const countryByCode = useCountriesByCode();

    const shouldShowBillingInfo = partnerType === PartnerTypeEnum.shipper;

    const legalAddressCountry = countryByCode[partnerDetails?.legalAddress?.country as CountryCodeT];
    const legalFullAddress = [
        legalAddressCountry?.userLangDisplayName || '',
        partnerDetails?.legalAddress?.zipCode,
        partnerDetails?.legalAddress?.city,
        partnerDetails?.legalAddress?.street1,
        partnerDetails?.legalAddress?.street2,
    ]
        .join(' ')
        .trim();

    const { legalFormsRequestStatus, legalFormsById } = useLegalFormDict(partnerDetails?.legalAddress?.country || null);

    const postalAddressCountry = countryByCode[partnerDetails?.postalAddress?.country as CountryCodeT];
    const postalFullAddress = [
        postalAddressCountry?.userLangDisplayName || '',
        partnerDetails?.postalAddress?.zipCode,
        partnerDetails?.postalAddress?.city,
        partnerDetails?.postalAddress?.street1,
        partnerDetails?.postalAddress?.street2,
    ]
        .join(' ')
        .trim();

    const billingAddressCountry = countryByCode[partnerDetails?.billingAddress?.country as CountryCodeT];
    const billingFullAddress = [
        billingAddressCountry?.userLangDisplayName || '',
        partnerDetails?.billingAddress?.zipCode,
        partnerDetails?.billingAddress?.city,
        partnerDetails?.billingAddress?.street1,
        partnerDetails?.billingAddress?.street2,
    ]
        .join(' ')
        .trim();

    const hasLegalAddress = !!partnerDetails?.legalAddress;
    const isNeedApproveLegalAddress = !!partnerDetails?.legalAddress && !partnerDetails?.addressVerified;

    const hasPostalAddress = !!partnerDetails?.postalAddress;
    const isNeedApprovePostalAddress = !!partnerDetails?.postalAddress && !partnerDetails?.postalAddressVerified;

    const hasBillingAddress = !!partnerDetails?.billingAddress;
    const isNeedApproveBillingAddress = !!partnerDetails?.billingAddress && !partnerDetails?.billingAddressVerified;

    return (
        <ContentMargins className={cx('wrap')}>
            <Widget
                className={cx('widget')}
                title={t('partner.details-tab.segments.basic')}
                afterTitleNode={
                    <CreatePartnerInfo
                        partnerId={partnerId}
                        partnerType={partnerType}
                        createdByBroker={false}
                        createdDate={partnerDetails?.createdDate || null}
                        userId={partnerDetails?.createdById || null}
                        userName={partnerDetails?.createdByName || null}
                    />
                }
                rightNode={<BasicWidgetHeaderActions partnerDetails={partnerDetails} />}
            >
                <div className={cx('widget__inner')}>
                    <FieldGroup>
                        <FieldValue
                            className={cx('field', 'field--name')}
                            label={t('partner.details-tab.fields.name.label')}
                            value={partnerDetails?.name || ''}
                        />
                        <FieldValue
                            className={cx('field', 'field--legal-form')}
                            label={t('partner.details-tab.fields.legal-form.label')}
                            value={legalFormsById[partnerDetails?.legalFormId || '']?.name}
                            icon={
                                legalFormsRequestStatus?.loading ? (
                                    <ControlLoaderIcon
                                        fillColor={StyleGuideColorsEnum.brandAccent}
                                        size={DEFAULT_ICON_SIZE}
                                    />
                                ) : null
                            }
                        />
                        <FieldValue
                            className={cx('field', 'field--key-manager')}
                            label={t('partner.details-tab.fields.key-manager.label')}
                            tooltipNode={
                                <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                                    {t('partner.details-tab.fields.key-manager.tooltip')}
                                </TooltipContent>
                            }
                            value={partnerDetails?.keyAccountManagerName || ''}
                        />
                        <FieldValue
                            className={cx('field', 'field--dispatcher')}
                            label={t('partner.details-tab.fields.dispatcher.label')}
                            tooltipNode={
                                <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                                    {t('partner.details-tab.fields.dispatcher.tooltip')}
                                </TooltipContent>
                            }
                            value={partnerDetails?.defaultDispatcherName || ''}
                        />
                    </FieldGroup>
                </div>
            </Widget>
            {partnerType === PartnerTypeEnum.shipper && (
                <Widget
                    className={cx('widget')}
                    title={t('partner.details-tab.segments.rfq-stats')}
                    titleTooltipNode={
                        <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                            {t('partner.details-tab.rfq-stats-tooltip')}
                        </TooltipContent>
                    }
                    afterTitleNode={<RFQStats />}
                />
            )}
            <div className={cx('widget-group')}>
                <Widget
                    className={cx('widget', 'widget-group__widget')}
                    title={t('partner.details-tab.segments.legal-address')}
                    rightNode={
                        <AddressWidgetHeaderActions
                            isShowStatus={hasLegalAddress}
                            addressType={AddressTypeEnum.legal}
                            fullAddress={legalFullAddress}
                            needApproved={isNeedApproveLegalAddress}
                            companyId={partnerDetails?.id}
                        />
                    }
                >
                    <div className={cx('widget__inner')}>
                        <FieldGroup spaces={1}>
                            <FieldValue
                                className={cx('field', 'field--country')}
                                label=""
                                value={legalAddressCountry?.userLangDisplayName || ''}
                                icon={<FlagIcon countryCode={legalAddressCountry?.code} />}
                            />
                            <FieldValue
                                className={cx('field', 'field--zip')}
                                label={t('partner.details-tab.fields.zip.label')}
                                value={partnerDetails?.legalAddress?.zipCode || ''}
                            />
                            <FieldValue
                                className={cx('field', 'field--city')}
                                label={t('partner.details-tab.fields.city.label')}
                                value={partnerDetails?.legalAddress?.city || ''}
                            />
                        </FieldGroup>
                        <FieldValue
                            label={t('partner.details-tab.fields.street1.label')}
                            value={partnerDetails?.legalAddress?.street1 || ''}
                        />
                        <FieldValue
                            label={t('partner.details-tab.fields.street2.label')}
                            value={partnerDetails?.legalAddress?.street2 || ''}
                        />
                    </div>
                </Widget>
                <Widget
                    className={cx('widget', 'widget-group__widget')}
                    title={t('partner.details-tab.segments.postal-address')}
                    rightNode={
                        <AddressWidgetHeaderActions
                            isShowStatus={hasPostalAddress}
                            addressType={AddressTypeEnum.postal}
                            fullAddress={postalFullAddress}
                            needApproved={isNeedApprovePostalAddress}
                            companyId={partnerDetails?.id}
                        />
                    }
                >
                    <div className={cx('widget__inner')}>
                        <FieldGroup spaces={1}>
                            <FieldValue
                                className={cx('field', 'field--country')}
                                label=""
                                value={postalAddressCountry?.userLangDisplayName || ''}
                                icon={<FlagIcon countryCode={postalAddressCountry?.code} />}
                            />
                            <FieldValue
                                className={cx('field', 'field--zip')}
                                label={t('partner.details-tab.fields.zip.label')}
                                value={partnerDetails?.postalAddress?.zipCode || ''}
                            />
                            <FieldValue
                                className={cx('field', 'field--city')}
                                label={t('partner.details-tab.fields.city.label')}
                                value={partnerDetails?.postalAddress?.city || ''}
                            />
                        </FieldGroup>
                        <FieldValue
                            label={t('partner.details-tab.fields.street1.label')}
                            value={partnerDetails?.postalAddress?.street1 || ''}
                        />
                        <FieldValue
                            label={t('partner.details-tab.fields.street2.label')}
                            value={partnerDetails?.postalAddress?.street2 || ''}
                        />
                    </div>
                </Widget>
                {shouldShowBillingInfo && (
                    <Widget
                        className={cx('widget', 'widget-group__widget')}
                        title={t('partner.details-tab.segments.billing-address')}
                        rightNode={
                            <AddressWidgetHeaderActions
                                isShowStatus={hasBillingAddress}
                                addressType={AddressTypeEnum.billing}
                                fullAddress={billingFullAddress}
                                needApproved={isNeedApproveBillingAddress}
                                companyId={partnerDetails?.id}
                            />
                        }
                    >
                        <div className={cx('widget__inner')}>
                            <FieldGroup spaces={1}>
                                <FieldValue
                                    className={cx('field', 'field--country')}
                                    label=""
                                    value={billingAddressCountry?.userLangDisplayName || ''}
                                    icon={<FlagIcon countryCode={billingAddressCountry?.code} />}
                                />
                                <FieldValue
                                    className={cx('field', 'field--zip')}
                                    label={t('partner.details-tab.fields.zip.label')}
                                    value={partnerDetails?.billingAddress?.zipCode || ''}
                                />
                                <FieldValue
                                    className={cx('field', 'field--city')}
                                    label={t('partner.details-tab.fields.city.label')}
                                    value={partnerDetails?.billingAddress?.city || ''}
                                />
                            </FieldGroup>
                            <FieldValue
                                label={t('partner.details-tab.fields.street1.label')}
                                value={partnerDetails?.billingAddress?.street1 || ''}
                            />
                            <FieldValue
                                label={t('partner.details-tab.fields.street2.label')}
                                value={partnerDetails?.billingAddress?.street2 || ''}
                            />

                            <FieldValue
                                label={t('partner.details-tab.fields.billing-email.label')}
                                value={partnerDetails?.billingEmail || ''}
                            />
                        </div>
                    </Widget>
                )}
            </div>
        </ContentMargins>
    );
});

export default PartnerDetailsLayout;

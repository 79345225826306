import { put, select, takeEvery } from 'redux-saga/effects';
import {
    fetchStateMachineSteps,
    fetchStateMachineStepsBegin,
    fetchStateMachineStepsError,
    fetchStateMachineStepsSuccess,
    updateEntityStatus,
    updateEntityStatusBegin,
    updateEntityStatusError,
    updateEntityStatusSuccess,
} from './slice';
import { FetchStateMachineStepsActionT, UpdateEntityStatusActionT } from './types';
import { CompanyTypeEnum } from 'common/constants';
import checkNeedRequest from 'common/utils/check-need-request';
import { getStateMachineEntityHash } from 'common/store/state-machine/utils';
import { selectStateMachineItemState } from 'common/store/state-machine/selectors';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';

function getFetchStateMachineStepsSaga(companyType: CompanyTypeEnum) {
    return function* (action: FetchStateMachineStepsActionT): WrapGeneratorT<void> {
        const { descriptor, options } = action.payload;

        const { fetchRequest }: ReReturnT<typeof selectStateMachineItemState> = yield select(
            selectStateMachineItemState(descriptor),
        );
        if (!checkNeedRequest(fetchRequest, options)) {
            return;
        }

        const hash = getStateMachineEntityHash(descriptor);

        yield put(fetchStateMachineStepsBegin({ hash }));

        const [error, data]: ReturnApiT<typeof commonTranziitApi.fetchStateMachineEntityStatus> =
            yield commonTranziitApi.fetchStateMachineEntityStatus(descriptor.entityType, descriptor.entityId);

        if (error) {
            yield put(fetchStateMachineStepsError({ hash, error }));
        } else {
            yield put(fetchStateMachineStepsSuccess({ hash, data }));
        }
    };
}

function getUpdateStateMachineStepsSaga(companyType: CompanyTypeEnum) {
    return function* (action: UpdateEntityStatusActionT): WrapGeneratorT<void> {
        const { descriptor, update } = action.payload;

        const { updateRequest }: ReReturnT<typeof selectStateMachineItemState> = yield select(
            selectStateMachineItemState(descriptor),
        );
        if (updateRequest.loading) {
            return;
        }

        const hash = getStateMachineEntityHash(descriptor);

        yield put(updateEntityStatusBegin({ hash }));

        const result: ReturnApiT<typeof commonTranziitApi.updateStateMachineEntityStatus> =
            yield commonTranziitApi.updateStateMachineEntityStatus(update.entityType, update.entityId, {
                status: update.status,
            });

        const [error] = result;

        if (error) {
            yield put(updateEntityStatusError({ hash, error }));
        } else {
            yield put(updateEntityStatusSuccess({ hash }));
        }

        stateMachineRefreshChannel.emit({});
    };
}

function* stateMachineSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(fetchStateMachineSteps.type, getFetchStateMachineStepsSaga(companyType));
    yield takeEvery(updateEntityStatus.type, getUpdateStateMachineStepsSaga(companyType));
}

export { stateMachineSaga };

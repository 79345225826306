import * as React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './DriversAssignmentFooterContent.scss';
import DriversAssignmentForm from '../../DriversAssignmentForm/DriversAssignmentForm';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { RequestStatusT } from 'common/utils/request-status';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onCancel: () => void;
    unnassignDriversRequestStatus: RequestStatusT;
    onUnnasignDrivers: () => void;
};

const DriversAssignmentFooterContent: React.FC<PropsT> = React.memo((props) => {
    const {
        onCancel,
        transportOrderId,
        transportOrderDetails,
        className,
        unnassignDriversRequestStatus,
        onUnnasignDrivers,
    } = props;

    if (!transportOrderDetails) {
        return null;
    }

    return (
        <div className={cs(cx('content'), className)}>
            <DriversAssignmentForm
                transportOrderId={transportOrderId}
                transportOrderDetails={transportOrderDetails}
                onCancel={onCancel}
                unnassignDriversRequestStatus={unnassignDriversRequestStatus}
                onUnnasignDrivers={onUnnasignDrivers}
            />
        </div>
    );
});

export default DriversAssignmentFooterContent;

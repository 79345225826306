import * as React from 'react';
import { MapPinThemeEnum, MapPointPropsT } from 'common/components/maps/MapPin/MapPin';
import MapStackClusterPin from 'common/components/maps/MapStackClusterPin/MapStackClusterPin';
import { PointGeoJsonPropertiesT } from '../models';
import TrailerIcon from 'common/icons/TrailerIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import TruckIcon from 'common/icons/TruckIcon';
import { ApiShipmentStatusT, ShipmentStatusEnum } from 'common/utils/api/models';
import WaypointNumberPinContent from 'common/components/maps/pins/content/WaypointNumberPinContent/WaypointNumberPinContent';
import { visitedOriginLoadStatusSet } from '../constants';

type PropsT = {
    className?: string;
    lat?: number | undefined;
    lng?: number | undefined;
    loadStatus?: ApiShipmentStatusT;
    clusterId: number;
    onClusterClick: (clusterId: number) => void;
    clusterProperties: Array<PointGeoJsonPropertiesT>;
    withoutArrow?: boolean;
};

const PointStackClusterPin: React.FC<PropsT> = React.memo((props) => {
    const { className, lat, lng, onClusterClick, clusterId, clusterProperties, withoutArrow, loadStatus } = props;

    const pointProps: Array<Partial<MapPointPropsT>> = [];

    clusterProperties.forEach((pointProperties: PointGeoJsonPropertiesT) => {
        if (pointProperties.type === 'trailer-last-point') {
            pointProps.push({
                theme: MapPinThemeEnum.charcoal,
                children: (
                    <TrailerIcon
                        fillColor={StyleGuideColorsEnum.brandDark}
                        strokeColor={StyleGuideColorsEnum.white}
                        size={DEFAULT_ICON_SIZE}
                    />
                ),
            });
        }

        if (pointProperties.type === 'truck-last-point') {
            pointProps.push({
                theme: MapPinThemeEnum.charcoal,
                children: (
                    <TruckIcon
                        fillColor={StyleGuideColorsEnum.brandDark}
                        strokeColor={StyleGuideColorsEnum.white}
                        size={DEFAULT_ICON_SIZE}
                    />
                ),
            });
        }

        if (pointProperties.type === 'point') {
            const { point, pointNumber } = pointProperties;

            let theme = MapPinThemeEnum.charcoal;
            if (point.driveThru) {
                theme = MapPinThemeEnum.gray;
            }

            // TODO change logic
            const isVisited = visitedOriginLoadStatusSet.has(loadStatus as ShipmentStatusEnum);
            if (isVisited) {
                theme = MapPinThemeEnum.brandDark;
            }

            pointProps.push({
                theme,
                hasPaddings: false,
                children: (
                    <WaypointNumberPinContent withPaddings key={`route-point-${pointNumber}`}>
                        {pointNumber}
                    </WaypointNumberPinContent>
                ),
            });
        }
    });

    if (!pointProps?.length) {
        return null;
    }

    return (
        <MapStackClusterPin
            className={className}
            lat={lat}
            lng={lng}
            pointProps={pointProps}
            clusterId={clusterId}
            onClusterClick={onClusterClick}
            withoutArrow={withoutArrow}
        />
    );
});

export default PointStackClusterPin;

import * as React from 'react';
import EuroSymbolIcon from 'common/icons/EuroSymbolIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import { isNonNil } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { useCommonPriceDetails } from 'common/components/PriceDetails/hook';
import PriceDetails, { PriceDetailT } from 'design-system/components/PriceDetails/PriceDetails';
import isNumber from 'lodash/isNumber';
import { checkShouldShowLayover } from 'common/components/PriceDetails/utils';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { ApiTollByRoadTypeT } from 'common/utils/api/models';

type PropsT = {
    className?: string;
    totalPriceLabel: React.ReactNode;
    totalPriceTooltip?: React.ReactNode;
    totalPrice: number | undefined;
    totalPriceDiff?: number | null | undefined;
    lineHaulCost: number | undefined;
    deadheadLineHaulCost?: number | undefined;
    payloadLineHaulCost?: number | undefined;
    tollCost: number | undefined;
    tollByRoadType: ApiTollByRoadTypeT | undefined;
    co2: number | undefined;
    layoverCost: number | undefined;
    layoverSeconds: number | undefined;
    fuelCost: number | undefined;
    teamDriveCost: number | undefined;
    otherCosts: number | undefined;
    testSelector?: string;
    shouldAlwaysRenderExpandTrigger?: boolean;
};

const CarrierCostPriceInfoTable: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        totalPrice,
        totalPriceDiff,
        totalPriceLabel,
        totalPriceTooltip,
        lineHaulCost,
        deadheadLineHaulCost,
        payloadLineHaulCost,
        co2,
        fuelCost,
        tollCost,
        tollByRoadType,
        layoverCost,
        layoverSeconds,
        teamDriveCost,
        otherCosts,
        shouldAlwaysRenderExpandTrigger,
    } = props;

    const { t } = useTranslation();

    const commonPriceDetails = useCommonPriceDetails();

    const list: Array<PriceDetailT | null> = [
        {
            iconNode: <EuroSymbolIcon strokeColor={StyleGuideColorsEnum.gray} />,
            title: totalPriceLabel,
            tooltipNode: totalPriceTooltip ? (
                <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                    {totalPriceTooltip}
                </TooltipContent>
            ) : null,
            price: totalPrice,
            priceDiff: totalPriceDiff,
            placeholder: t('common:info-table.placeholders.carrier-not-assigned'),
            list: isNumber(totalPrice)
                ? [
                      {
                          ...commonPriceDetails.lineHaul,
                          price: lineHaulCost,
                          list: commonPriceDetails.getLineHaulList({
                              deadheadLineHaulCost,
                              payloadLineHaulCost,
                          }),
                      },
                      {
                          ...commonPriceDetails.fuelCost,
                          price: fuelCost,
                      },
                      {
                          ...commonPriceDetails.getRoadTaxes(co2),
                          price: tollCost,
                          list: commonPriceDetails.getRoadTaxesList(tollByRoadType),
                      },
                      checkShouldShowLayover(layoverSeconds, layoverCost)
                          ? {
                                ...commonPriceDetails.getLayoverCost(layoverSeconds),
                                price: layoverCost || 0,
                            }
                          : null,
                      isNumber(teamDriveCost)
                          ? {
                                ...commonPriceDetails.teamDrive,
                                price: teamDriveCost,
                            }
                          : null,
                      {
                          iconNode: null,
                          name: t('common:order-details.columns.other-costs'),
                          price: otherCosts,
                      },
                  ].filter(isNonNil)
                : [],
        },
    ].filter(isNonNil);

    return (
        <PriceDetails
            className={className}
            list={list}
            shouldAlwaysRenderExpandTrigger={shouldAlwaysRenderExpandTrigger}
        />
    );
});

export default CarrierCostPriceInfoTable;

import Keycloak, { KeycloakError, KeycloakInstance, KeycloakProfile } from 'keycloak-js';
import {
    AuthAccountUrlsT,
    AuthStateChangedCallbackT,
    AuthUnsubscribeCallbackT,
    AuthUserClaimsT,
    AuthUserT,
    BaseAuthService,
} from './base-auth-service';
import { AuthApiError, AuthErrorTypeEnum } from 'common/utils/api/auth/errors/auth-api-error';
import { AuthIdTokenT } from 'common/utils/api/auth/base-security-auth-api';
import { logWarning } from 'common/utils/logger';
import { createSelfExternalUrl } from 'common/utils/history';

type KeycloakConfigT = {
    url: string;
    realm: string;
    clientId: string;
};

const INIT_REDIRECT_MODE_QUERY_KEY = 'init-auth-redirect-mode';

type KeycloakInitRedirectModeT = string | null;
enum KeycloakInitRedirectModeEnum {
    signUp = 'sign-up',
    signIn = 'sign-in',
}

const DELAY = 300;

const TOKEN_KEY = 'kc_token';
const REFRESH_TOKEN_KEY = 'kc_refreshToken';

export class KeycloakAuthService extends BaseAuthService {
    private keycloak: KeycloakInstance;

    private isInitialized = false;

    private authStateChangedCallback: AuthStateChangedCallbackT | null = null;

    constructor(config: KeycloakConfigT, initRedirectMode: KeycloakInitRedirectModeT) {
        super();

        this.keycloak = new Keycloak({
            ...config,
        });
        this.keycloak.onAuthError = this.onAuthError;
        this.keycloak.onAuthSuccess = this.onAuthSuccess;
        this.keycloak.onReady = this.onReady;

        const token = localStorage.getItem(TOKEN_KEY);
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

        this.keycloak.init({
            onLoad: !initRedirectMode ? 'check-sso' : undefined,
            token: token || undefined,
            refreshToken: refreshToken || undefined,
            checkLoginIframe: false,
        });

        if (initRedirectMode === KeycloakInitRedirectModeEnum.signUp) {
            this.keycloak.register({
                redirectUri: window.location.href.replace(
                    INIT_REDIRECT_MODE_QUERY_KEY,
                    `deleted-${INIT_REDIRECT_MODE_QUERY_KEY}`,
                ),
            });
        }

        if (initRedirectMode === KeycloakInitRedirectModeEnum.signIn) {
            this.keycloak.login({
                redirectUri: window.location.href.replace(
                    INIT_REDIRECT_MODE_QUERY_KEY,
                    `deleted-${INIT_REDIRECT_MODE_QUERY_KEY}`,
                ),
            });
        }
    }

    checkAllowedAnonymouslyUserCreation = (): boolean => {
        return false;
    };

    private waitInitialization = async (): Promise<void> => {
        while (!this.isInitialized) {
            await new Promise((resolve) => {
                setTimeout(resolve, DELAY);
            });
        }
    };

    getAuthToken = async (): Promise<AuthIdTokenT | null> => {
        await this.waitInitialization();

        const token = this.keycloak.token || null;
        if (!token) {
            return null;
        }

        if (this.keycloak.isTokenExpired()) {
            try {
                await this.keycloak.updateToken(5); // 5 - is default minValidity
            } catch (error) {
                logWarning('failed to updateToken', error);

                // return expired token
            }
        }

        return token;
    };

    getAuthHeaders = async (): Promise<Record<string, AuthIdTokenT> | null> => {
        const token = await this.getAuthToken();

        return {
            Authorization: `Bearer ${token}`,
        };
    };

    doSignOut = async (): Promise<[AuthApiError | null, null]> => {
        try {
            await this.keycloak.logout({
                redirectUri: window.location.href,
            });

            return [null, null];
        } catch (error) {
            // TODO parse
            return [new AuthApiError(AuthErrorTypeEnum.unknown, error.message), null];
        }
    };

    signInWithEmailAndPassword = async (): Promise<[AuthApiError | null, AuthUserT | null]> => {
        return [new AuthApiError(AuthErrorTypeEnum.unknown, 'not implement'), null];
    };

    getAuthUserClaims = async (): Promise<[AuthApiError | null, AuthUserClaimsT | null]> => {
        await this.waitInitialization();

        if (!this.keycloak.tokenParsed?.roles) {
            return [null, null];
        }

        const authUserClaims: AuthUserClaimsT = {
            roles: this.keycloak.tokenParsed?.roles,
        };

        return [null, authUserClaims];
    };

    getAccountUrls = async (): Promise<[AuthApiError | null, AuthAccountUrlsT | null]> => {
        await this.waitInitialization();

        return [
            null,
            {
                changeCreds: `${this.keycloak.tokenParsed?.iss}/protocol/openid-connect/auth?client_id=${this.keycloak.clientId}&redirect_uri=${window.location.href}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`,
            },
        ];
    };

    verificationEmail = async (): Promise<[AuthApiError | null, null]> => {
        return [new AuthApiError(AuthErrorTypeEnum.unknown, 'not implement'), null];
    };

    private onReady = () => {
        this.isInitialized = true;

        if (this.keycloak.authenticated) {
            const userInfo = this.keycloak?.userInfo as KeycloakProfile;
            this.emitStateChange({
                email: userInfo?.email || '',
                displayName: `${userInfo?.lastName || ''} ${userInfo?.firstName || ''}`,
                isAnonymous: false,
            });
        } else {
            this.emitStateChange(null);
        }
    };

    private onAuthError = (errorData: KeycloakError) => {
        logWarning(`keycloak.onAuthError ${JSON.stringify(errorData)}`);
        // nothing

        this.emitStateChange(null);
    };

    private onAuthSuccess = async () => {
        localStorage.setItem(TOKEN_KEY, this.keycloak.token || '');
        localStorage.setItem(REFRESH_TOKEN_KEY, this.keycloak.refreshToken || '');

        const userInfo = (await this.keycloak.loadUserProfile()) as KeycloakProfile;
        this.emitStateChange({
            email: userInfo.email || '',
            displayName: `${userInfo.lastName || ''} ${userInfo.firstName || ''}`,
            isAnonymous: false,
        });
    };

    destroySession = () => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    };

    private emitStateChange: AuthStateChangedCallbackT = (user) => {
        if (!this.authStateChangedCallback) {
            setTimeout(() => {
                this.emitStateChange(user);
            }, DELAY);
        } else {
            this.authStateChangedCallback(user);
        }
    };

    onAuthStateChanged = (authStateChangedCallback: AuthStateChangedCallbackT): AuthUnsubscribeCallbackT => {
        this.authStateChangedCallback = authStateChangedCallback;

        return () => {
            this.authStateChangedCallback = null;
        };
    };

    createAnonymouslyUser = async (): Promise<[AuthApiError | null, AuthUserT | null]> => {
        return [null, null];
    };

    updatePassword = async (): Promise<[AuthApiError | null, null]> => {
        return [new AuthApiError(AuthErrorTypeEnum.unknown, 'not implement in keycloak'), null];
    };

    reauthenticateWithCredential = async (): Promise<[AuthApiError | null, AuthUserT | null]> => {
        return [new AuthApiError(AuthErrorTypeEnum.unknown, 'not implement in keycloak'), null];
    };

    changePassword = async (): Promise<[AuthApiError | null, null]> => {
        return [new AuthApiError(AuthErrorTypeEnum.unknown, 'not implement in keycloak'), null];
    };

    updateProfile = async (): Promise<[AuthApiError | null, null]> => {
        // nothing
        return [null, null];
    };

    checkIsContinueSignInRedirect = () => {
        return !!window.location.search?.includes('session_state=');
    };

    createSignInUrl = (returnUrl?: string): string => {
        return this.keycloak.createLoginUrl({
            redirectUri: returnUrl ? createSelfExternalUrl(returnUrl) : undefined,
        });
    };

    createSignInUrlAfterChangePassword = (): string => {
        return this.keycloak.createLoginUrl();
    };

    forceRefreshToken = async (): Promise<[AuthApiError | null, boolean | null]> => {
        try {
            await this.waitInitialization();

            // force refresh - Infinity minValidity
            const refreshed = await this.keycloak.updateToken(Infinity);
            if (refreshed) {
                localStorage.setItem(TOKEN_KEY, this.keycloak.token || '');
                localStorage.setItem(REFRESH_TOKEN_KEY, this.keycloak.refreshToken || '');
            }

            return [null, refreshed];
        } catch (error) {
            // TODO parse
            return [new AuthApiError(AuthErrorTypeEnum.unknown, error?.message), null];
        }
    };

    createAccountUrl = (): string => {
        return this.keycloak.createAccountUrl({
            redirectUri: window.location.href,
        });
    };

    createSignUpUrl = (): string => {
        return this.keycloak.createRegisterUrl({
            redirectUri: window.location.href,
        });
    };

    createForgotPasswordUrl = (): string => {
        throw new Error('not implement in keycloak');
    };

    createChangePasswordUrl = (): string => {
        throw new Error('not implement in keycloak');
    };
}

export const tryParseKeycloakConfig = (): KeycloakConfigT => {
    try {
        return JSON.parse(window.STRINGIFIED_KEYCLOAK_CONFIG);
    } catch (error) {
        console.error('Parse keycloak config error!!');
        console.error(error);

        return {} as KeycloakConfigT;
    }
};

export const tryParseInitRedirectMode = (): KeycloakInitRedirectModeT => {
    const url = new URL(window.location.href);

    return url.searchParams.get(INIT_REDIRECT_MODE_QUERY_KEY);
};

import * as React from 'react';
import { ApiTrailerDictTypeT } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    trailerDictType?: Pick<ApiTrailerDictTypeT, 'length' | 'model' | 'eur1Pallets' | 'eur2Pallets'> | null;
};

const SimpleTrailerTypeFormatter: React.FC<PropsT> = React.memo((props) => {
    const { trailerDictType } = props;

    const { t } = useTranslation();

    if (!trailerDictType) {
        return null;
    }

    return (
        <span>
            {t('common:trailer-subtype-template', {
                length: trailerDictType?.length,
                model: trailerDictType?.model,
                eur1Pallets: trailerDictType?.eur1Pallets,
                eur2Pallets: trailerDictType?.eur2Pallets,
            })}
        </span>
    );
});

export default SimpleTrailerTypeFormatter;

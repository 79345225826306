import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './OrderDetailsPage.scss';

import { useParams } from 'react-router-dom';
import GoogleMapContext from 'common/contexts/google-map-context';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import { useTranslation } from 'react-i18next';
import HorizontalColumnsLayout from 'common/layouts/HorizontalColumnsLayout/HorizontalColumnsLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import { useDispatch, useSelector } from 'react-redux';
import OrderDetails from './OrderDetails/OrderDetails';
import { routesEnum } from 'broker-admin/constants';
import history, { goBackIfHasRePath } from 'common/utils/history';
import OrderStatusPill from 'common/components/status-pill/OrderStatusPill/OrderStatusPill';
import { selectBrokerOrderDetails, selectBrokerOrderDetailsRequest } from 'broker-admin/store/orders/selectors';
import { fetchBrokerOrderDetails } from 'broker-admin/store/orders/actions';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import TabsLabel from 'common/components/TabsLabel/TabsLabel';
import { TabsThemesEnum } from 'common/components/TabsLabel/constants';
import TabLabel from 'common/components/TabsLabel/TabLabel/TabLabel';
import SideBarLayoutContextWrap from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import HeaderSideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLoader/HeaderSideBarLoader';
import EntitySidebarSkeleton from 'common/components/order-details/EntitySidebarSkeleton/EntitySidebarSkeleton';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import { isNonNil } from 'common/utils';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';

const cx = classNames.bind(styles);

type ParamsT = {
    orderId: string;
};

type PropsT = {};

const OrderDetailsPage: React.FC<PropsT> = React.memo((props) => {
    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const { t } = useTranslation();

    const params = useParams<ParamsT>();
    const { orderId } = params;

    const order = useSelector(selectBrokerOrderDetails(orderId));
    const orderRequestStatus = useSelector(selectBrokerOrderDetailsRequest);

    const polylineIds = useMemo(() => {
        // @ts-expect-error broker order details is deprecated
        return [order?.polylineId].filter(isNonNil);
        // @ts-expect-error broker order details is deprecated
    }, [order?.polylineId]);

    useRouteGeometry(polylineIds);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchBrokerOrderDetails(orderId));
    }, [orderId]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        dispatch(fetchBrokerOrderDetails(orderId));
    }, [orderId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const closeOrderDetails = () => {
        goBackIfHasRePath(() => {
            history.push(routesEnum.orders + history.location.search);
        });
    };

    const firstShipment = order?.shipments?.[0];

    // @ts-expect-error broker order details is deprecated
    const routingGeometryState = useSelector(selectRoutingGeometryState(order?.polylineId));

    const [activeIndexTab, setActiveIndexTab] = React.useState<number>(0);

    const tabs = [
        {
            label: t('common:order-details.tabs.map'),
            node: null,
        },
        // {
        //     label: t('common:order-details.tabs.activity'),
        //     node: <div>activity</div>,
        // },
    ];

    const isShowLineLoader = orderRequestStatus.loading && !!order;
    const isShowOverlayLoader = !orderRequestStatus.error && !order;

    const openLeftSidebar = useOpenLeftSidebar();

    const handleOpenUserDetails = (params: {
        partnerType: PartnerTypeEnum;
        partnerId: PartnerIdT;
        userId: UserIdT | null;
    }) => {
        const { partnerType, partnerId, userId } = params;
        if (!userId) {
            logWarning('failed to open user details in orders, empty userId');
            return;
        }

        openLeftSidebar(
            {
                type: CommonSidebarsTypeEnum.contact,
                partnerId,
                partnerType,
                userId,
            },
            {
                isForceShowBackAction: true,
            },
        );
    };

    return (
        <>
            <GoogleMapContext.Provider value={googleMapsContextValue}>
                <PageTitle
                    title={t('page-titles.order-details', {
                        number: order?.number || '',
                    })}
                />
                <HorizontalColumnsLayout
                    className={cx('wrap')}
                    leftColumnTitle={t('common:order-details.main-tabs.details')}
                    leftColumnNode={
                        <SideBarLayoutContextWrap>
                            <HeaderSideBarLayout hasBottomBorder>
                                <HeaderSideBarContent
                                    title={
                                        order
                                            ? t('common:order-details.title', {
                                                  number: order.number,
                                              })
                                            : null
                                    }
                                    afterTitleNode={
                                        order ? (
                                            <OrderStatusPill status={order.status} isArchived={order.archived} />
                                        ) : null
                                    }
                                    onClose={closeOrderDetails}
                                />
                                <HeaderSideBarLoader isShow={isShowLineLoader} />
                            </HeaderSideBarLayout>
                            <SideBarLayout>
                                {order ? (
                                    <OrderDetails onOpenUserDetails={handleOpenUserDetails} order={order} />
                                ) : (
                                    <EntitySidebarSkeleton />
                                )}
                            </SideBarLayout>
                            {isShowOverlayLoader && <SideBarLoader />}
                        </SideBarLayoutContextWrap>
                    }
                    rightColumnTitle={t('common:order-details.main-tabs.info')}
                    rightColumnNode={
                        <div className={cx('tabs-wrap')}>
                            <TabsLabel>
                                {tabs.map((tab, tabIndex) => (
                                    <TabLabel
                                        key={tabIndex}
                                        theme={TabsThemesEnum.light}
                                        onClick={() => {
                                            setActiveIndexTab(tabIndex);
                                        }}
                                        isActive={tabIndex === activeIndexTab}
                                    >
                                        {tab.label}
                                    </TabLabel>
                                ))}
                            </TabsLabel>
                            <div className={cx('tab')}>{tabs[activeIndexTab].node}</div>
                        </div>
                    }
                />
            </GoogleMapContext.Provider>
            <SideBars />
        </>
    );
});

export default OrderDetailsPage;

import { CompanyContactDetailsT, CompanyContactT, RolesWithSuccessorT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

import { ApiAddCompanyUserRequestT, ApiCompanyContactRequestT, RolesEnum } from 'common/utils/api/models';

export const FETCH_CONTACTS_REQUEST = 'common/company-members/FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_REQUEST_BEGIN = 'common/company-members/FETCH_CONTACTS_REQUEST_BEGIN';
export const FETCH_CONTACTS_REQUEST_SUCCESS = 'common/company-members/FETCH_CONTACTS_REQUEST_SUCCESS';
export const FETCH_CONTACTS_REQUEST_ERROR = 'common/company-members/FETCH_CONTACTS_REQUEST_ERROR';

export const FETCH_CONTACT_DETAILS_BY_USER_ID = 'common/company-members/FETCH_CONTACT_DETAILS_BY_USER_ID';
export const FETCH_CONTACT_DETAILS_REQUEST = 'common/company-members/FETCH_CONTACT_DETAILS_REQUEST';
export const FETCH_CONTACT_DETAILS_REQUEST_BEGIN = 'common/company-members/FETCH_CONTACT_DETAILS_REQUEST_BEGIN';
export const FETCH_CONTACT_DETAILS_REQUEST_SUCCESS = 'common/company-members/FETCH_CONTACT_DETAILS_REQUEST_SUCCESS';
export const FETCH_CONTACT_DETAILS_REQUEST_ERROR = 'common/company-members/FETCH_CONTACT_DETAILS_REQUEST_ERROR';

export const ADD_CONTACT_REQUEST = 'common/company-members/ADD_CONTACT_REQUEST';
export const INVITE_USER_REQUEST = 'common/company-members/INVITE_USER_REQUEST';
export const ADD_MEMBER_REQUEST_BEGIN = 'common/company-members/ADD_MEMBER_REQUEST_BEGIN';
export const ADD_MEMBER_REQUEST_SUCCESS = 'common/company-members/ADD_MEMBER_REQUEST_SUCCESS';
export const ADD_MEMBER_REQUEST_ERROR = 'common/company-members/ADD_MEMBER_REQUEST_ERROR';

export const DELETE_USER = 'common/company-members/DELETE_USER';
export const DELETE_USER_WITH_SUCCESSOR = 'common/company-members/DELETE_USER_WITH_SUCCESSOR';
export const DELETE_CONTACT = 'common/company-members/DELETE_CONTACT';
export const DELETE_MEMBER_REQUEST_BEGIN = 'common/company-members/DELETE_MEMBER_REQUEST_BEGIN';
export const DELETE_MEMBER_REQUEST_SUCCESS = 'common/company-members/DELETE_MEMBER_REQUEST_SUCCESS';
export const DELETE_MEMBER_REQUEST_ERROR = 'common/company-members/DELETE_MEMBER_REQUEST_ERROR';

export const RESTORE_USER = 'common/company-members/RESTORE_USER';
export const CANCEL_INVITE = 'common/company-members/CANCEL_INVITE';
export const CHANGE_ADMIN_ROLE_WITH_SUCCESSORS = 'common/company-members/CHANGE_ADMIN_ROLE_WITH_SUCCESSORS';
export const CHANGE_USER_ROLE_WITH_SUCCESSOR = 'common/company-members/CHANGE_USER_ROLE_WITH_SUCCESSOR';
export const CHANGE_USER_ROLE = 'common/company-members/CHANGE_USER_ROLE';
export const MAKE_MAIN_CONTACT = 'common/company-members/MAKE_MAIN_CONTACT';
export const UPDATE_CONTACT = 'common/company-members/UPDATE_CONTACT';
export const UPDATE_MEMBER_REQUEST_BEGIN = 'common/company-members/UPDATE_MEMBER_REQUEST_BEGIN';
export const UPDATE_MEMBER_REQUEST_SUCCESS = 'common/company-members/UPDATE_MEMBER_REQUEST_SUCCESS';
export const UPDATE_MEMBER_REQUEST_ERROR = 'common/company-members/UPDATE_MEMBER_REQUEST_ERROR';

export type FetchContactsActionT = {
    type: typeof FETCH_CONTACTS_REQUEST;
    companyId: CompanyIdT;
    options?: FetchOptionsT;
};

export type FetchContactsBeginActionT = {
    type: typeof FETCH_CONTACTS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type FetchContactsSuccessActionT = {
    type: typeof FETCH_CONTACTS_REQUEST_SUCCESS;
    companyContacts: CompanyContactT[];
    companyId: CompanyIdT;
};

export type FetchContactsErrorActionT = {
    type: typeof FETCH_CONTACTS_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type AddContactActionT = {
    type: typeof ADD_CONTACT_REQUEST;
    newContact: ApiCompanyContactRequestT;
    companyId: CompanyIdT;
};

export type InviteUserActionT = {
    type: typeof INVITE_USER_REQUEST;
    newUser: ApiAddCompanyUserRequestT;
    companyId: CompanyIdT;
};

export type AddMemberBeginActionT = {
    type: typeof ADD_MEMBER_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type AddMemberSuccessActionT = {
    type: typeof ADD_MEMBER_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type AddMemberErrorActionT = {
    type: typeof ADD_MEMBER_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type DeleteContactActionT = {
    type: typeof DELETE_CONTACT;
    contactId: ContactIdT;
    companyId: CompanyIdT;
};

export type DeleteUserActionT = {
    type: typeof DELETE_USER;
    userId: UserIdT;
    companyId: CompanyIdT;
};

export type DeleteUserWithSuccessorActionT = {
    type: typeof DELETE_USER_WITH_SUCCESSOR;
    userId: UserIdT;
    companyId: CompanyIdT;
    successorUserId: UserIdT;
    successorRole: RolesWithSuccessorT;
};

export type CancelUserActionT = {
    type: typeof CANCEL_INVITE;
    userId: UserIdT;
    companyId: CompanyIdT;
};

export type RestoreUserActionT = {
    type: typeof RESTORE_USER;
    userId: UserIdT;
    companyId: CompanyIdT;
};

export type DeleteMemberBeginActionT = {
    type: typeof DELETE_MEMBER_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type DeleteMemberSuccessActionT = {
    type: typeof DELETE_MEMBER_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type DeleteMemberErrorActionT = {
    type: typeof DELETE_MEMBER_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type ChangeUserRoleActionT = {
    type: typeof CHANGE_USER_ROLE;
    userId: UserIdT;
    companyId: CompanyIdT;
    role: RolesEnum;
};

export type ChangeUserRoleWithSuccessorActionT = {
    type: typeof CHANGE_USER_ROLE_WITH_SUCCESSOR;
    userId: UserIdT;
    role: RolesEnum;
    successorUserId: UserIdT;
    successorRole: RolesWithSuccessorT;
    companyId: CompanyIdT;
};

export type ChangeAdminRoleWithSuccessorsActionT = {
    type: typeof CHANGE_ADMIN_ROLE_WITH_SUCCESSORS;
    userId: UserIdT;
    role: RolesEnum;
    companyId: CompanyIdT;
    newKeyAccountManagerUserId: UserIdT;
    newDispatcherUserId: UserIdT;
};

export type MakeMainContactActionT = {
    type: typeof MAKE_MAIN_CONTACT;
    contactId: ContactIdT;
    companyId: CompanyIdT;
};

export type UpdateContactActionT = {
    type: typeof UPDATE_CONTACT;
    contactId: ContactIdT;
    contactChanges: ApiCompanyContactRequestT;
    companyId: CompanyIdT;
};

export type UpdateMemberBeginActionT = {
    type: typeof UPDATE_MEMBER_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type UpdateMemberSuccessActionT = {
    type: typeof UPDATE_MEMBER_REQUEST_SUCCESS;
    companyId: CompanyIdT;
};

export type UpdateMemberErrorActionT = {
    type: typeof UPDATE_MEMBER_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type FetchContactDetailsActionT = {
    type: typeof FETCH_CONTACT_DETAILS_REQUEST;
    companyId: CompanyIdT;
    contactId: ContactIdT;
};

export type FetchContactDetailsBeginActionT = {
    type: typeof FETCH_CONTACT_DETAILS_REQUEST_BEGIN;
    companyId: CompanyIdT;
};

export type FetchContactDetailsSuccessActionT = {
    type: typeof FETCH_CONTACT_DETAILS_REQUEST_SUCCESS;
    contactId: ContactIdT;
    companyId: CompanyIdT;
    details: CompanyContactDetailsT | null;
};

export type FetchContactDetailsErrorActionT = {
    type: typeof FETCH_CONTACT_DETAILS_REQUEST_ERROR;
    error: Error;
    companyId: CompanyIdT;
};

export type FetchContactDetailsByUserIdActionT = {
    type: typeof FETCH_CONTACT_DETAILS_BY_USER_ID;
    companyId: CompanyIdT;
    userId: UserIdT;
};

export type MembersActionT =
    | FetchContactsActionT
    | FetchContactsBeginActionT
    | FetchContactsSuccessActionT
    | FetchContactsErrorActionT
    | AddContactActionT
    | InviteUserActionT
    | AddMemberBeginActionT
    | AddMemberSuccessActionT
    | AddMemberErrorActionT
    | DeleteContactActionT
    | DeleteMemberBeginActionT
    | DeleteMemberSuccessActionT
    | DeleteMemberErrorActionT
    | ChangeUserRoleActionT
    | ChangeUserRoleWithSuccessorActionT
    | ChangeAdminRoleWithSuccessorsActionT
    | UpdateContactActionT
    | UpdateMemberBeginActionT
    | UpdateMemberSuccessActionT
    | UpdateMemberErrorActionT
    | FetchContactDetailsActionT
    | FetchContactDetailsBeginActionT
    | FetchContactDetailsSuccessActionT
    | FetchContactDetailsErrorActionT
    | FetchContactDetailsByUserIdActionT;

export type ContactDetailsByIdT = Record<ContactIdT, CompanyContactDetailsT | null>;

export type CompanyMembersStateT = {
    contactIds: ContactIdT[] | null;
    constantById: Record<ContactIdT, CompanyContactT>;
    fetchContactsRequest: RequestStatusT;
    addMemberRequest: RequestStatusT;
    deleteMemberRequest: RequestStatusT;
    updateMemberRequest: RequestStatusT;
    fetchContactDetailsRequest: RequestStatusT;
    contactDetailsById: ContactDetailsByIdT;
    contactIdByUserId: Record<UserIdT, ContactIdT>;
};

export type MembersStateT = Record<CompanyIdT, CompanyMembersStateT>;

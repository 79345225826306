import React from 'react';

import { CompanyContactT } from 'common/store/members/models';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import Modal from 'common/components/Modal/Modal';
import { PartnerTypeEnum, RolesEnum } from 'common/utils/api/models';
import BigWarningIcon from 'common/icons/BigWarningIcon';
import { CommonChangeUserRoleFormPropsT } from 'common/layouts/Members/dialogs/ChangeUserRoleModal/models';
import { USER_ROLE_T_MAP } from 'common/utils/users';
import { getFirstNeedSuccessorRole } from 'common/store/members/utils';
import ChangeRoleUserWithSuccessorForm, {
    ChangeUserRoleWithSuccessorDataT,
} from 'broker-admin/layouts/PartnerPage/PartnerMembersPage/ChangeRoleUserWithSuccessorForm/ChangeRoleUserWithSuccessorForm';
import ChangeRoleUserForm, { ChangeUserRoleDataT } from 'common/layouts/Members/ChangeRoleUserForm/ChangeRoleUserForm';
import { useSelector } from 'react-redux';
import { selectUpdateMemberRequest } from 'common/store/members/selectors';
import ChangeRoleAdminWithSuccessorsForm, {
    ChangeAdminRoleDataT,
} from 'broker-admin/layouts/PartnerPage/PartnerMembersPage/ChangeRoleAdminWithSuccessorsForm/ChangeRoleAdminWithSuccessorsForm';

export type ChangeUserRoleModalDataT = {
    contact: {
        roles: CompanyContactT['roles'];
        fullName: CompanyContactT['fullName'];
        userId: CompanyContactT['userId'];
    } | null;
    partnerType: PartnerTypeEnum;
    partnerId: PartnerIdT;
};

export type ChangeUserRoleModalPropsT = {
    data: ChangeUserRoleModalDataT | null;
    onClose: () => void;
    onChangeUserRole: (params: { data: ChangeUserRoleModalDataT | null; changes: ChangeUserRoleDataT }) => void;
    onChangeUserRoleWithSuccessor: (params: {
        data: ChangeUserRoleModalDataT | null;
        changes: ChangeUserRoleWithSuccessorDataT;
    }) => void;
    onChangeAdminRole: (params: { data: ChangeUserRoleModalDataT | null; changes: ChangeAdminRoleDataT }) => void;
};

const TEST_SELECTOR = 'change-user-role';

const MESSAGE_T: Record<PartnerTypeEnum, string> = {
    [PartnerTypeEnum.shipper]: 'common:team-members.dialogs.change-user-role.description',
    [PartnerTypeEnum.carrier]: 'common:team-members.dialogs.change-user-role.description',
    [PartnerTypeEnum.broker]: 'common:team-members.dialogs.change-user-role.partner-description',
};

const ChangeUserRoleModal: React.FC<ChangeUserRoleModalPropsT> = React.memo((props) => {
    const { data, onClose, onChangeUserRole, onChangeUserRoleWithSuccessor, onChangeAdminRole } = props;

    const contact = data?.contact || null;
    const partnerType = data?.partnerType || null;
    const partnerId = data?.partnerId || null;

    const updateMemberRequest = useSelector(selectUpdateMemberRequest(partnerId));

    const oldUserRoleData = getFirstNeedSuccessorRole(contact?.roles);

    const { t } = useTranslation();

    if (!contact || !partnerType || !partnerId) {
        return null;
    }

    const commonFormProps: CommonChangeUserRoleFormPropsT = {
        contact,
        onCancel: onClose,
        updateMemberRequest,
    };

    let formComponentNode: React.ReactNode = (
        <ChangeRoleUserForm
            {...commonFormProps}
            onChangeUserRole={(changes) => {
                onChangeUserRole({ data, changes });
            }}
        />
    );

    const isShouldSelectSuccessor = !!getFirstNeedSuccessorRole(contact?.roles);
    if (isShouldSelectSuccessor) {
        formComponentNode = (
            <ChangeRoleUserWithSuccessorForm
                {...commonFormProps}
                onChangeUserRole={(changes) => {
                    onChangeUserRoleWithSuccessor({ data, changes });
                }}
            />
        );
    }

    const isBrokerAdmin =
        !!contact?.roles.some((roleData) => {
            return roleData.role === RolesEnum.companyAdmin;
        }) && partnerType === PartnerTypeEnum.broker;
    if (isBrokerAdmin) {
        formComponentNode = (
            <ChangeRoleAdminWithSuccessorsForm
                {...commonFormProps}
                onChangeAdminRole={(changes) => {
                    onChangeAdminRole({ data, changes });
                }}
            />
        );
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    icon={<BigWarningIcon />}
                    title={t('common:team-members.dialogs.change-user-role.title', {
                        fullName: contact?.fullName,
                    })}
                    message={t(MESSAGE_T[partnerType], {
                        fullName: contact?.fullName,
                        role: t(USER_ROLE_T_MAP[oldUserRoleData?.role as RolesEnum]),
                    })}
                >
                    {formComponentNode}
                </ModalDialog>
            </ModalContent>
        </Modal>
    );
});

export default ChangeUserRoleModal;

import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './RouteDetailsCard.scss';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Card from 'design-system/components/Card/Card';
import { useTranslation } from 'react-i18next';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { checkIsInDateRange, formatDate, formatTimeInterval } from 'common/utils/time';
import NumberIcon from 'common/icons/NumberIcon';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import TruckPickUpIcon from 'common/icons/TruckPickUpIcon';
import TrailerPickUpIcon from 'common/icons/TrailerPickUpIcon';
import LinkDropOffIcon from 'common/icons/LinkDropOffIcon';
import TruckDropOffIcon from 'common/icons/TruckDropOffIcon';
import TrailerDropOffIcon from 'common/icons/TrailerDropOffIcon';
import WarningTextFormatter from 'design-system/components/InfoTable/formatters/WarningTextFormatter/WarningTextFormatter';
import isNumber from 'lodash/isNumber';
import { StopEnum } from 'common/utils/api/models';
import DriverWrongCheckPointPill from 'common/components/pills/DriverWrongCheckPointPill/DriverWrongCheckPointPill';
import LinkPickUpIcon from 'common/icons/LinkPickUpIcon';

export type PropsT = {
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    className?: string;
};

const cx = classNames.bind(styles);

const RouteDetailsCard: React.FC<PropsT> = React.memo((props) => {
    const { transportOrderDetails, className } = props;

    const { t } = useTranslation();

    const getDriverArrivedLeftRows = (
        waypoint: TransportOrderDetailsT['waypoints'][number],
        { isLast }: { isLast: boolean },
    ): Array<InfoTableRowT> => {
        return [
            {
                icon: null,
                name: t('common:transport-order-details.route-details.columns.driver-arrived-at'),
                value: waypoint?.driverArrivedTimeStamp ? (
                    <WarningTextFormatter
                        isActive={checkIsInDateRange(
                            waypoint.dateTimeFrom,
                            waypoint.dateTimeTo,
                            waypoint?.driverArrivedTimeStamp,
                        )}
                    >
                        {formatDate('DD MMM YYYY, HH:mm', waypoint?.driverArrivedTimeStamp)}
                    </WarningTextFormatter>
                ) : null,
                emptyValue: t('common:info-table.placeholders.driver-not-arrived'),
                isBoldValue: true,
                rightNode: waypoint?.driverArrivedFalse ? <DriverWrongCheckPointPill /> : null,
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:transport-order-details.route-details.columns.driver-left-at'),
                value: waypoint?.driverLeftTimeStamp ? (
                    <WarningTextFormatter
                        isActive={checkIsInDateRange(
                            waypoint.dateTimeFrom,
                            waypoint.dateTimeTo,
                            waypoint?.driverLeftTimeStamp,
                        )}
                    >
                        {formatDate('DD MMM YYYY, HH:mm', waypoint?.driverLeftTimeStamp)}
                    </WarningTextFormatter>
                ) : null,
                emptyValue: waypoint?.driverArrivedTimeStamp
                    ? t('common:info-table.placeholders.driver-not-leave')
                    : t('common:info-table.placeholders.driver-not-arrived'),
                rightNode: waypoint?.driverLeftFalse ? <DriverWrongCheckPointPill /> : null,
                isBoldValue: true,
                hasBottomBorder: !isLast,
            },
        ];
    };

    const getTimeSlotRows = (waypoint: TransportOrderDetailsT['waypoints'][number]): Array<InfoTableRowT> => {
        return [
            {
                icon: null,
                name: t('common:transport-order-details.route-details.columns.time-slot'),
                value: formatTimeInterval(waypoint.dateTimeFrom, waypoint.dateTimeTo),
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                hasBottomBorder: true,
            },
        ];
    };

    const renderPickupTruckWaypoint = (waypoint: TransportOrderDetailsT['waypoints'][number]) => {
        const rows: Array<InfoTableRowT> = [
            {
                icon: (
                    <TruckPickUpIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.gray}
                        fillColor={StyleGuideColorsEnum.light}
                    />
                ),
                name: t('common:transport-order-details.route-details.columns.pickup-truck'),
                value: <LocationLabel format="pickup_asset_s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
            ...getTimeSlotRows(waypoint),
            ...getDriverArrivedLeftRows(waypoint, { isLast: true }),
        ];

        return <InfoTable key={waypoint.id} isCollapsable shouldRenderIcons rows={rows} className={cx('table')} />;
    };

    const renderPickupTrailerWaypoint = (waypoint: TransportOrderDetailsT['waypoints'][number]) => {
        const rows: Array<InfoTableRowT> = [
            {
                icon: (
                    <TrailerPickUpIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.gray}
                        fillColor={StyleGuideColorsEnum.light}
                    />
                ),
                name: t('common:transport-order-details.route-details.columns.pickup-trailer'),
                value: <LocationLabel format="pickup_asset_s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
            ...getTimeSlotRows(waypoint),
            ...getDriverArrivedLeftRows(waypoint, { isLast: true }),
        ];

        return <InfoTable key={waypoint.id} isCollapsable shouldRenderIcons rows={rows} className={cx('table')} />;
    };

    const renderPickupTrainWaypoint = (waypoint: TransportOrderDetailsT['waypoints'][number]) => {
        const rows: Array<InfoTableRowT> = [
            {
                icon: <LinkPickUpIcon strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('common:transport-order-details.route-details.columns.pickup-train'),
                value: <LocationLabel format="pickup_asset_s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
            ...getTimeSlotRows(waypoint),
            ...getDriverArrivedLeftRows(waypoint, { isLast: true }),
        ];

        return <InfoTable key={waypoint.id} isCollapsable shouldRenderIcons rows={rows} className={cx('table')} />;
    };

    const renderDropoffTrainWaypoint = (waypoint: TransportOrderDetailsT['waypoints'][number]) => {
        const rows: Array<InfoTableRowT> = [
            {
                icon: <LinkDropOffIcon strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('common:transport-order-details.route-details.columns.drop-train'),
                value: <LocationLabel format="dropoff_asset_s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
            ...getTimeSlotRows(waypoint),
            ...getDriverArrivedLeftRows(waypoint, { isLast: true }),
        ];

        return <InfoTable key={waypoint.id} isCollapsable shouldRenderIcons rows={rows} className={cx('table')} />;
    };

    const renderDropoffTruckWaypoint = (waypoint: TransportOrderDetailsT['waypoints'][number]) => {
        const rows: Array<InfoTableRowT> = [
            {
                icon: (
                    <TruckDropOffIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.gray}
                        fillColor={StyleGuideColorsEnum.light}
                    />
                ),
                name: t('common:transport-order-details.route-details.columns.drop-truck'),
                value: <LocationLabel format="dropoff_asset_s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
            ...getTimeSlotRows(waypoint),
            ...getDriverArrivedLeftRows(waypoint, { isLast: true }),
        ];

        return <InfoTable key={waypoint.id} isCollapsable shouldRenderIcons rows={rows} className={cx('table')} />;
    };

    const renderDropoffTrailerWaypoint = (waypoint: TransportOrderDetailsT['waypoints'][number]) => {
        const rows: Array<InfoTableRowT> = [
            {
                icon: (
                    <TrailerDropOffIcon
                        size={DEFAULT_ICON_SIZE}
                        strokeColor={StyleGuideColorsEnum.gray}
                        fillColor={StyleGuideColorsEnum.light}
                    />
                ),
                name: t('common:transport-order-details.route-details.columns.drop-trailer'),
                value: <LocationLabel format="dropoff_asset_s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
            ...getTimeSlotRows(waypoint),
            ...getDriverArrivedLeftRows(waypoint, { isLast: true }),
        ];

        return <InfoTable key={waypoint.id} isCollapsable shouldRenderIcons rows={rows} className={cx('table')} />;
    };

    const renderDriveThroughWaypoint = (waypoint: TransportOrderDetailsT['waypoints'][number]) => {
        const waypointNumber = (waypoint?.index || 0) + 1;
        const rows: Array<InfoTableRowT> = [
            {
                icon: <NumberIcon number={waypointNumber} fillColor={StyleGuideColorsEnum.gray} />,
                name: t('common:transport-order-details.route-details.columns.waypoint-number', {
                    number: waypointNumber,
                }),
                value: <LocationLabel format="s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
            ...getTimeSlotRows(waypoint),
            ...getDriverArrivedLeftRows(waypoint, { isLast: true }),
        ];

        return <InfoTable key={waypoint.id} shouldRenderIcons isCollapsable rows={rows} className={cx('table')} />;
    };

    const renderDropAndHookWaypoint = (waypoint: TransportOrderDetailsT['waypoints'][number]) => {
        const waypointNumber = (waypoint?.index || 0) + 1;

        const rows: Array<InfoTableRowT> = [
            {
                icon: <NumberIcon number={waypointNumber} fillColor={StyleGuideColorsEnum.gray} />,
                name: t('common:transport-order-details.route-details.columns.waypoint-number', {
                    number: waypointNumber,
                }),
                value: <LocationLabel format="s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
            ...getTimeSlotRows(waypoint),
            ...getDriverArrivedLeftRows(waypoint, { isLast: true }),
        ];

        return <InfoTable key={waypoint.id} shouldRenderIcons isCollapsable rows={rows} className={cx('table')} />;
    };

    const renderPickupDeliveryWaypoint = (waypoint: TransportOrderDetailsT['waypoints'][number]) => {
        const waypointNumber = (waypoint.index || 0) + 1;

        const rows: Array<InfoTableRowT> = [
            {
                icon: <NumberIcon number={waypointNumber} fillColor={StyleGuideColorsEnum.charcoal} />,
                name: t('common:transport-order-details.route-details.columns.waypoint-number', {
                    number: waypointNumber,
                }),
                value: <LocationLabel format="s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
            ...getTimeSlotRows(waypoint),
            ...getDriverArrivedLeftRows(waypoint, { isLast: false }),
            {
                icon: null,
                name: t('common:transport-order-details.route-details.columns.company-name'),
                value: waypoint?.contact?.companyName,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                testSelector: 'contact-company-name',
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:transport-order-details.route-details.columns.contact-name'),
                value: waypoint?.contact?.fullName,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:transport-order-details.route-details.columns.contact-phone'),
                value: waypoint?.contact?.phone,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:transport-order-details.route-details.columns.contact-email'),
                value: waypoint?.contact?.email,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:transport-order-details.route-details.columns.comments'),
                value: waypoint?.contact?.comment,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
        ];

        return <InfoTable key={waypoint.id} shouldRenderIcons isCollapsable rows={rows} className={cx('table')} />;
    };

    const waypointCount = transportOrderDetails?.waypoints?.length;
    return (
        <>
            <Card
                titleNode={t('common:transport-order-details.route-details.title')}
                rightNode={
                    isNumber(waypointCount) ? (
                        <PillLabel theme={PillLabelThemeEnum.slate} isSymmetrical>
                            {t('common:transport-order-details.route-details.waypoint-counts', {
                                number: waypointCount,
                            })}
                        </PillLabel>
                    ) : null
                }
                className={className}
                hasHeaderBottomBorder
            >
                <div className={cx('content')}>
                    {transportOrderDetails?.waypoints?.map((waypoint) => {
                        switch (waypoint.type) {
                            case StopEnum.pickupTrailer: {
                                return renderPickupTrailerWaypoint(waypoint);
                            }
                            case StopEnum.pickupTruck: {
                                return renderPickupTruckWaypoint(waypoint);
                            }
                            case StopEnum.pickupRoadTrain: {
                                return renderPickupTrainWaypoint(waypoint);
                            }
                            case StopEnum.pickupDeliveryShipment: {
                                return renderPickupDeliveryWaypoint(waypoint);
                            }
                            case StopEnum.driveThrough: {
                                return renderDriveThroughWaypoint(waypoint);
                            }
                            case StopEnum.dropAndHook: {
                                return renderDropAndHookWaypoint(waypoint);
                            }
                            case StopEnum.dropTrailer: {
                                return renderDropoffTrailerWaypoint(waypoint);
                            }
                            case StopEnum.dropTruck: {
                                return renderDropoffTruckWaypoint(waypoint);
                            }
                            case StopEnum.dropRoadTrain: {
                                return renderDropoffTrainWaypoint(waypoint);
                            }
                            default: {
                                return null;
                            }
                        }
                    })}
                </div>
            </Card>
        </>
    );
});

export default RouteDetailsCard;

import React from 'react';

import { PropsT as InputPropsT } from 'common/components/Input/Input';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAllTrailersTypes,
    selectTrailersDict,
    selectTrailersDictRequest,
} from 'common/store/trailers-dict/selectors';

import classNames from 'classnames/bind';
import styles from './TrailerTypeMultipleDropdown.scss';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import DropdownTreeInput from 'design-system/components/dropdowns/DropdownTreeInput/DropdownTreeInput';
import { TreeNodeT } from 'design-system/components/CheckboxTree/CheckboxTree';
import { useTranslation } from 'react-i18next';
import { TrailerTypeEnum } from 'common/constants';
import TrailerIcon, { TrailerIconProps } from 'common/icons/TrailerIcon';

const cx = classNames.bind(styles);

type ValueT = TrailerDictIdT;

export type PropsT = {
    className?: string;
    values: Array<ValueT>;
    onSelect: (values: Array<ValueT>) => void;
    overlayPosition: DropdownOverlayPositionEnum;
    hasError: InputPropsT['hasError'];
    hasWarning: InputPropsT['hasWarning'];
    placeholder: InputPropsT['placeholder'];
    onBlur: InputPropsT['onBlur'];
    onFocus: InputPropsT['onFocus'];
    isDisabled?: InputPropsT['isDisabled'];
    hasClearControl?: InputPropsT['hasClearControl'];
    hasChanges?: boolean;
};

const TRAILER_TYPE_T_MAP: Record<TrailerTypeEnum, string> = {
    [TrailerTypeEnum.tilt]: 'common:trailer-type-switcher.tilt',
    [TrailerTypeEnum.box]: 'common:trailer-type-switcher.box',
};

const TrailerTypeMultipleDropdown: React.FC<PropsT> = (props) => {
    const {
        className,
        values,
        onSelect,
        hasWarning,
        hasError,
        overlayPosition,
        isDisabled,
        placeholder,
        onBlur,
        onFocus,
        hasChanges,
        hasClearControl,
    } = props;

    const { t } = useTranslation();

    const requestStatus = useSelector(selectTrailersDictRequest);
    const trailersDict = useSelector(selectTrailersDict);
    const trailersTypes = useSelector(selectAllTrailersTypes);

    const options = React.useMemo((): Array<TreeNodeT<ValueT>> => {
        return [
            {
                label: t('common:trailer-type-switcher.all'),
                children: trailersTypes.map((trailerType): TreeNodeT<ValueT> => {
                    return {
                        label: t(TRAILER_TYPE_T_MAP[trailerType]),
                        children: (trailersDict?.[trailerType] || []).map((trailer): TreeNodeT<ValueT> => {
                            return {
                                label: t('common:trailer-subtype-template', {
                                    length: trailer.length,
                                    model: trailer.model,
                                    eur1Pallets: trailer.eur1Pallets,
                                    eur2Pallets: trailer.eur2Pallets,
                                }),
                                value: String(trailer.id),
                                children: [],
                            };
                        }),
                    };
                }),
            },
        ];
    }, [t, trailersTypes, trailersDict]);

    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    return (
        <DropdownTreeInput<ValueT>
            className={className}
            options={options}
            renderLeftIcon={(iconMeta) => <TrailerIcon {...TrailerIconProps.getControlProps(iconMeta)} />}
            selectedValues={values}
            placeholder={placeholder}
            isDisabled={isDisabled}
            onSelect={onSelect}
            hasError={hasError}
            hasWarning={hasWarning}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            overlayPosition={overlayPosition}
            hasClearControl={values?.length ? hasClearControl : false}
            isLoading={requestStatus.loading}
        />
    );
};

export default TrailerTypeMultipleDropdown;

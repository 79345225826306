import { eventChannel } from 'redux-saga';
import { actionChannel, call, cancel, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { generatePath } from 'react-router';
import { diff } from 'deep-object-diff';
import { CompatClient, Stomp } from '@stomp/stompjs';
import isNil from 'lodash/isNil';
import {
    CHANGE_RESERVE_QUERY,
    ChangeReserveQueryActionT,
    CREATE_ORDER_REQUEST,
    CREATE_PRICE_OFFER_WITH_LANE_REQUEST,
    CREATE_PRICE_OFFER_WITH_LANE_REQUEST_ERROR,
    CREATE_RFQ_REQUEST,
    CREATE_RFQ_REQUEST_ERROR,
    CreateOrderActionT,
    CreatePriceOfferWithLaneRequestActionT,
    CreateRFQActionT,
    ENSURE_CREATED_RFQ,
    EnsureCreatedRFQActionT,
    FETCH_ROUTE,
    FETCH_TASK_OFFER,
    FetchRouteActionT,
    PREVIEW_RESERVE_ERROR,
    PreviewReserveErrorActionT,
    SELECT_OFFER,
    SelectOfferActionT,
} from './types';
import {
    changeReserveQuery,
    clearOffersTasks,
    correctPreviewReserveQuery,
    correctReserveQuery,
    createOrderBegin,
    createOrderError,
    createOrderSuccess,
    createPriceOfferWithLaneRequestBegin,
    createPriceOfferWithLaneRequestError,
    createPriceOfferWithLaneRequestSuccess,
    createReserveBegin,
    createReserveError,
    createReserveSuccess,
    createRFQBegin,
    createRFQError,
    createRFQSuccess,
    fetchOffers,
    fetchOffersError,
    fetchRoute,
    fetchRouteError,
    fetchRouteSuccess,
    fetchTaskOfferError,
    markOffersExpired,
    previewReserveBegin,
    previewReserveError,
    previewReserveSuccess,
    resetCreateReserveWithQuery,
    resetOrderCreation,
    setAllowCreateRFQ,
    setCreateReserveQuery,
    setExpectedOffersCount,
    setPreviewReserveQuery,
} from './actions';
import history from 'common/utils/history';
import { CommonRoutesEnum, CompanyTypeEnum, OMSRoutesEnum, QueryKeysEnum } from 'common/constants';
import {
    selectCreateRFQRequest,
    selectIsPartialLoadingOffers,
    selectOffersTasks,
    selectPolylines,
    selectPriceOffers,
    selectPriceOffersList,
    selectReserve,
    selectReservePreview,
    selectReservePreviewQuery,
    selectReservePreviewRequest,
    selectReserveQuery,
    selectReserveRequest,
    selectRFQ,
} from './selectors';
import preparePriceOffer from './utils/prepare-price-offer';
import prepareRFQ from './utils/prepare-rfq';
import ValuesStorage from 'common/utils/form-values-storage';
import { CARGO_DETAILS_FORM_NAME } from 'common/layouts/NewOrderPage/CargoDetailsForm/constants';
import { SHIPMENT_DETAIL_FORM_NAME } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/constants';
import { selectCurrentUser } from 'common/store/user/selectors';
import { logDebug, logWarning } from 'common/utils/logger';

import { formatQuery, parseQuery } from 'common/utils/query';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';
import {
    ApiSocketPriceOfferT,
    CreateReserveQueryT,
    ReservePreviewQueryT,
    ReserveQueryModifyEventT,
    ReserveT,
} from './models';
import { EventChannel } from '@redux-saga/core';
import { checkIsPastDate, getDateFromISO, MS_IN_SEC } from 'common/utils/time';
import { byAlphabetASC } from 'common/utils/sort';
import { getApiReverseQuery, getReverseQueryChanges, mergeReverseQueryChanges } from './utils/create-reverse-query';
import { checkIsSameQuery } from 'common/utils/pagination/utils';
import {
    getApiPreviewReverseQuery,
    getPreviewReverseQueryChanges,
    mergePreviewReverseQuery,
    setReversePreviewDefaultQuery,
} from './utils/preview-reverse-query';
import prepareReservePreview from 'common/store/order-creation/utils/prepare-reserve-preview';
import { selectCountriesByCode } from '../countries-dict/selectors';
import { COUNTRIES_DICT_REQUEST_SUCCESS } from 'common/store/countries-dict/types';
import isEmpty from 'lodash/isEmpty';
import preparePreview from 'common/store/order-creation/utils/prepare-reserve';
import uniq from 'lodash/uniq';
import { getDefaultAdditionalServices } from 'common/store/order-creation/utils/get-default-additional-services';
import { DispatchesRoutesEnum } from 'broker-admin/constants';
import { ordersRefreshChannel } from 'common/store/orders/channels';
import { reserveQueryModifyChannel } from 'common/store/order-creation/channels';
import { getPreviewReserveQueryCorrection } from 'common/store/order-creation/utils/get-preview-reserve-query-correction';
import { clientConfig } from 'common/utils/client-config';
import { isNonNil } from 'common/utils';
import { getReserveQueryCorrection } from 'common/store/order-creation/utils/get-reserve-query-correction';
import { getQueryModifyEventAfterReserve } from 'common/store/order-creation/utils/get-query-modify-event-after-reserve';

const BACKEND_PATH = `wss://${window.location.host}${clientConfig.soketBasepath}`;

type EventChannelEventT =
    | Error
    | {
          taskCount: number;
          data: ApiSocketPriceOfferT;
      };

let stompClient: CompatClient | null = null;

function createPriceOffersChannel(token: string, userId: string, rfqId: RFQIdT): EventChannel<TODO> {
    if (stompClient) {
        logWarning('stompClient already exist. disconnect');
        stompClient.disconnect();
    }

    let taskCount = 0;
    return eventChannel((emitter) => {
        const webSocket = new WebSocket(BACKEND_PATH);

        stompClient = Stomp.over(webSocket);

        stompClient.connect(
            {
                login: token,
                passcode: 'passcode',
            },
            () => {
                if (!stompClient) {
                    logWarning('stompClient is null!');
                    return;
                }

                stompClient.subscribe(`/topic/prices/${userId}`, (message) => {
                    try {
                        const parsedBody: ApiSocketPriceOfferT = JSON.parse(message.body);

                        /* start debug logs */
                        logDebug(`[${taskCount}] fetch offer: `, parsedBody);
                        /* end debug logs */

                        if (parsedBody?.rfqId !== rfqId) {
                            /* start debug logs */
                            logDebug(`[${taskCount}] skip offer, wrong rfqId`, {
                                fetchedPriceOfferRFQId: parsedBody?.rfqId,
                                exptextedPriceOfferRFQId: rfqId,
                            });
                            /* end debug logs */
                            return;
                        }

                        taskCount += 1;

                        /* start debug logs */
                        logDebug(JSON.stringify(parsedBody));
                        /* end debug logs */

                        const channelEvent: EventChannelEventT = {
                            taskCount,
                            data: parsedBody,
                        };

                        emitter(channelEvent);
                    } catch (error) {
                        const apiError = new Error(error?.message);
                        const channelEvent: EventChannelEventT = apiError;
                        emitter(channelEvent);
                    }
                });
            },
            (error: TODO) => {
                const message = error?.headers?.message || '';
                if (message.include('Invalid token')) {
                    logWarning('Invalid token');
                    return;
                }

                const apiError = new Error(error?.message);
                const channelEvent: EventChannelEventT = apiError;
                emitter(channelEvent);
            },
            (event: TODO) => {
                // event.reason === "Cannot connect to server"
                if (event?.code === 1002) {
                    const apiError = new Error(event?.message);
                    const channelEvent: EventChannelEventT = apiError;
                    emitter(channelEvent);
                } else {
                    logWarning(`unknown socket event: ${JSON.stringify(event)}`);
                }
            },
        );

        return () => {
            // TODO exit
        };
    });
}

// eslint-disable-next-line require-yield
function* redirectToStartNewOrderSaga() {
    history.push({
        pathname: generatePath(OMSRoutesEnum.newOrder),
        search: history.location.search,
    });
}

let subscribeTaskOffers: TODO = null;
let delayedSyncFetchTaskOffers: TODO = null;

function* unSubscribeTaskOffersSaga() {
    if (stompClient) {
        stompClient.disconnect();
    }

    if (subscribeTaskOffers) {
        yield cancel(subscribeTaskOffers);
        subscribeTaskOffers = null;
    }

    if (delayedSyncFetchTaskOffers) {
        yield cancel(delayedSyncFetchTaskOffers);
        delayedSyncFetchTaskOffers = null;
    }
}

function* subscribeTaskOffersSaga(rfqId: RFQIdT | null) {
    const currentUser: ReturnType<typeof selectCurrentUser> = yield select(selectCurrentUser);
    const token: ReturnApiT<typeof commonTranziitApi.getAuthToken> = yield commonTranziitApi.getAuthToken();

    // @ts-ignore TS7057: 'yield' expression implicitly results in an 'any' type because its containing generator lacks a return-type annotation.
    const priceOffersChannel = yield call(createPriceOffersChannel, token, currentUser?.id, rfqId);
    try {
        while (true) {
            const message: EventChannelEventT = yield take(priceOffersChannel);
            if (message instanceof Error) {
                yield put(fetchTaskOfferError(message));
            } else {
                const { data } = message;

                const priceOffer = preparePriceOffer(data);
                yield put(fetchOffers([priceOffer]));
            }
        }
    } catch (error) {
        let apiError = null;
        if (error instanceof Error) {
            apiError = error;
        } else {
            apiError = new Error(error?.message);
        }

        yield put(fetchOffersError(apiError));
    } finally {
        // TODO cannel terminated
    }
}

const SUBSCRIPTION_TIMEOUT = 30 * MS_IN_SEC;

function* delayedSyncFetchTaskOffersSaga(isBroker: boolean, rfqId: RFQIdT) {
    yield delay(SUBSCRIPTION_TIMEOUT);

    const currentRFQ: ReturnType<typeof selectRFQ> = yield select(selectRFQ);
    if (currentRFQ?.id !== rfqId) {
        return;
    }

    const isPartialLoadingOffers: ReturnType<typeof selectIsPartialLoadingOffers> = yield select(
        selectIsPartialLoadingOffers,
    );
    if (!isPartialLoadingOffers) {
        return;
    }

    const responsePriceOffers: ReturnApiT<typeof commonTranziitApi.getPriceOffers> =
        yield commonTranziitApi.getPriceOffers(rfqId);
    const [apiPriceOffersError, apiPriceOffers] = responsePriceOffers;

    if (apiPriceOffersError) {
        yield put(fetchOffersError(apiPriceOffersError));
        return;
    }

    if (!apiPriceOffers) {
        const error = new Error('empty apiPriceOffers!');
        yield put(fetchOffersError(error));
        return;
    }

    const priceOffers = (apiPriceOffers || []).map(preparePriceOffer);
    yield put(fetchOffers(priceOffers));
}

function getCreateRFQSaga(companyType: CompanyTypeEnum) {
    return function* createRFQSaga(action: CreateRFQActionT): WrapGeneratorT<void> {
        const isBroker = companyType === CompanyTypeEnum.broker;

        const { actualOrderRequest, reserve } = action;

        yield unSubscribeTaskOffersSaga();
        yield put(clearOffersTasks());

        yield put(createRFQBegin());

        subscribeTaskOffers = yield fork(subscribeTaskOffersSaga, reserve?.reserveId || null);

        const reserveId = reserve?.reserveId;

        const result: ReturnApiT<typeof commonTranziitApi.createMultipointOrderQuotation> =
            yield commonTranziitApi.createMultipointOrderQuotation(reserveId, actualOrderRequest);

        if (!result) {
            return;
        }

        const [apiCreateRFQError, apiCreateRFQResult] = result;
        if (apiCreateRFQError) {
            yield put(createRFQError(apiCreateRFQError));
            return;
        }
        if (!apiCreateRFQResult) {
            logWarning('empty apiCreateRFQResult');
            return;
        }

        const rfqId = apiCreateRFQResult?.rfqId;

        if (rfqId) {
            delayedSyncFetchTaskOffers = yield fork(delayedSyncFetchTaskOffersSaga, isBroker, rfqId);
        }

        const hasSelectedBrokerWindowAppointment = !!actualOrderRequest.timeWindows?.some((timeWindow) => {
            return !!timeWindow?.brokerWindow;
        });

        const defaultAdditionalServices = getDefaultAdditionalServices(hasSelectedBrokerWindowAppointment);

        const baseQuery = {
            [QueryKeysEnum.orderCreationAdditionalServices]: defaultAdditionalServices,
            [QueryKeysEnum.orderCreationDropOffDate]: getDateFromISO(apiCreateRFQResult?.selectedDropOffDate),
            [QueryKeysEnum.orderCreationPickUpDate]: getDateFromISO(apiCreateRFQResult?.selectedPickupDate),
            [QueryKeysEnum.orderCreationPickUpDatesRange]: (apiCreateRFQResult.pickupDates || [])
                .map((date) => {
                    return getDateFromISO(date);
                })
                .sort(byAlphabetASC),
            [QueryKeysEnum.orderCreationDropOffDatesRange]: (apiCreateRFQResult?.dropOffDates || [])
                .map((date) => {
                    return getDateFromISO(date);
                })
                .sort(byAlphabetASC),
        };

        history.push({
            pathname: generatePath(OMSRoutesEnum.newOrderOffers, {
                rfqId,
            }),
            search: formatQuery(baseQuery),
        });

        const expectedOffersCount = apiCreateRFQResult?.expectedOffersCount || null;
        yield put(setExpectedOffersCount(expectedOffersCount));

        const response: ReturnApiT<typeof commonTranziitApi.getRFQDetails> = yield commonTranziitApi.getRFQDetails(
            rfqId,
        );
        const [apiRFQError, apiRFQData] = response;

        if (apiRFQError) {
            yield put(createRFQError(apiRFQError));
            return;
        }

        if (!apiRFQData) {
            const error = new Error('empty apiRFQData');
            yield put(createRFQError(error));
            return;
        }

        const preparedRFQ = prepareRFQ(apiRFQData);

        yield put(createRFQSuccess(preparedRFQ));

        if (apiRFQData?.polylineId) {
            yield put(fetchRoute(apiRFQData.polylineId));
        }
    };
}

function getCreatePriceOfferWithLaneSaga(companyType: CompanyTypeEnum) {
    return function* createPriceOfferWithLaneSaga(
        action: CreatePriceOfferWithLaneRequestActionT,
    ): WrapGeneratorT<void> {
        const { actualOrderRequest, reserve, laneId } = action;

        yield put(createPriceOfferWithLaneRequestBegin());

        const reserveId = reserve?.reserveId;

        const result: ReturnApiT<typeof commonTranziitApi.createMultipointOrderQuotation> =
            yield commonTranziitApi.createMultipointOrderQuotation(reserveId, actualOrderRequest, laneId);

        if (!result) {
            return;
        }

        const [apiCreateLaneOrderPriceOfferError, apiCreateLaneOrderPriceOfferResult] = result;

        if (apiCreateLaneOrderPriceOfferError) {
            yield put(createPriceOfferWithLaneRequestError(apiCreateLaneOrderPriceOfferError));
            return;
        }
        if (!apiCreateLaneOrderPriceOfferResult) {
            logWarning('empty apiCreateLaneOrderPriceOfferResult');
            return;
        }

        const apiLanePriceOffers: ReturnApiT<typeof commonTranziitApi.getPriceOffers> =
            yield commonTranziitApi.getPriceOffers(apiCreateLaneOrderPriceOfferResult?.rfqId);
        const [apiLanePriceOffersError, apiLanePriceOffersResult] = apiLanePriceOffers;
        if (apiLanePriceOffersError) {
            yield put(createPriceOfferWithLaneRequestError(apiLanePriceOffersError));
            return;
        }

        const apiLanePriceOffer = apiLanePriceOffersResult?.[0] || null;
        if (!apiLanePriceOffer) {
            logWarning('empty lanePriceOffer');
            return;
        }

        const rfqId = apiLanePriceOffer?.rfqId || null;
        if (!rfqId) {
            logWarning('empty rfqId');
            return;
        }

        history.push({
            pathname: generatePath(OMSRoutesEnum.newOrderDetailsWithLane, {
                rfqId,
                offerId: apiLanePriceOffer.id || '-',
                laneId,
            }),
            search: formatQuery({
                [QueryKeysEnum.orderCreationAdditionalServices]: [],
            }),
        });

        const apiRFQDetailsResponse: ReturnApiT<typeof commonTranziitApi.getRFQDetails> =
            yield commonTranziitApi.getRFQDetails(rfqId);
        const [apiRFQDetailsError, apiRFQDetailsData] = apiRFQDetailsResponse;
        if (apiRFQDetailsError) {
            yield put(createPriceOfferWithLaneRequestError(apiRFQDetailsError));
            return;
        }

        if (!apiRFQDetailsData) {
            const error = new Error('empty apiRFQData');
            yield put(createPriceOfferWithLaneRequestError(error));
            return;
        }

        if (apiRFQDetailsData.polylineId) {
            yield put(fetchRoute(apiRFQDetailsData.polylineId));
        }

        const preparedRFQ = prepareRFQ(apiRFQDetailsData);
        const lanePriceOffer = preparePriceOffer(apiLanePriceOffer);

        yield put(createPriceOfferWithLaneRequestSuccess(preparedRFQ, lanePriceOffer));
    };
}

function* handlePreviewReserveErrorSaga(action: PreviewReserveErrorActionT): WrapGeneratorT<void> {
    if (
        checkIsTranziitApiRequestError(action.error) &&
        action.error.subType === TranziitApiRequestErrorSubTypeEnum.expiredContextPreviewReserve
    ) {
        // try re-reserve
        yield put(changeReserveQuery({}));
    }
}

function* fetchTaskOfferSaga(): WrapGeneratorT<void> {
    const offersTasks: ReturnType<typeof selectOffersTasks> = yield select(selectOffersTasks);
    if (!isNil(offersTasks.total) && offersTasks.count === offersTasks.total) {
        yield unSubscribeTaskOffersSaga();
    }
}

function getEnsureCreatedRFQSaga(companyType: CompanyTypeEnum) {
    return function* ensureCreatedRFQSaga(action: EnsureCreatedRFQActionT): WrapGeneratorT<void> {
        const { rfqId } = action;
        if (!rfqId) {
            yield redirectToStartNewOrderSaga();
            logWarning('empty rfqId');
            return;
        }

        const createRFQStatus: ReturnType<typeof selectCreateRFQRequest> = yield select(selectCreateRFQRequest);
        if (createRFQStatus.loading) {
            return;
        }

        const currentRFQ: ReturnType<typeof selectRFQ> = yield select(selectRFQ);
        const currentRoute: ReturnType<typeof selectPolylines> = yield select(selectPolylines);
        const currentPriceOffers: ReturnType<typeof selectPriceOffers> = yield select(selectPriceOffers);
        if (currentRFQ && currentRoute && currentPriceOffers) {
            logWarning('some empty');
            return;
        }

        yield put(createRFQBegin());

        const response: ReturnApiT<typeof commonTranziitApi.getRFQDetails> = yield commonTranziitApi.getRFQDetails(
            rfqId,
        );
        const [apiRFQError, apiRFQData] = response;

        if (apiRFQError) {
            yield put(createRFQError(apiRFQError));
            return;
        }

        if (!apiRFQData) {
            const error = new Error('empty apiRFQData');
            yield put(createRFQError(error));
            return;
        }

        const responsePriceOffers: ReturnApiT<typeof commonTranziitApi.getPriceOffers> =
            yield commonTranziitApi.getPriceOffers(rfqId);
        const [apiPriceOffersError, apiPriceOffers] = responsePriceOffers;

        if (apiPriceOffersError) {
            yield put(createRFQError(apiPriceOffersError));
            return;
        }

        if (!apiPriceOffers) {
            const error = new Error('empty apiPriceOffers!');
            yield put(createRFQError(error));
            return;
        }

        const priceOffers = (apiPriceOffers || []).map(preparePriceOffer);

        const preparedRFQ = prepareRFQ(apiRFQData);

        if (preparedRFQ?.polylineId) {
            yield put(fetchRoute(preparedRFQ.polylineId));
        }

        yield put(createRFQSuccess(preparedRFQ));
        yield put(fetchOffers(priceOffers));

        const query = parseQuery(history.location.search);
        if (query?.[QueryKeysEnum.orderCreationComplete]) {
            const pickupDatesRange = uniq(priceOffers.map((priceOffer) => priceOffer.pickUpDate)).sort();
            const dropOffDateRange = uniq(priceOffers.map((priceOffer) => priceOffer.dropOffDate)).sort();

            const selectedIsBrokerWindow = preparedRFQ?.points?.some((point) => point?.brokerWindow);
            const defaultAdditionalServices = getDefaultAdditionalServices(selectedIsBrokerWindow);

            const firstRFQPoint = preparedRFQ?.points?.[0];
            const lastRFQPoint = preparedRFQ?.points ? preparedRFQ.points[preparedRFQ.points.length - 1] : null;

            history.push({
                search: formatQuery({
                    ...query,
                    [QueryKeysEnum.orderCreationAdditionalServices]: defaultAdditionalServices,
                    [QueryKeysEnum.orderCreationPickUpDate]: firstRFQPoint?.fromDate,
                    [QueryKeysEnum.orderCreationDropOffDate]: lastRFQPoint?.toDate,
                    [QueryKeysEnum.orderCreationPickUpDatesRange]: pickupDatesRange,
                    [QueryKeysEnum.orderCreationDropOffDatesRange]: dropOffDateRange,
                }),
            });
        }
    };
}

function getCreatedOrderSaga(isBroker: boolean) {
    return function* createdOrderSaga(action: CreateOrderActionT): WrapGeneratorT<void> {
        const { createOrderRequest, isCreateNextOrder, rfqId } = action;
        yield put(createOrderBegin());

        const responseCreateOrder: ReturnApiT<typeof commonTranziitApi.completeRFQ> =
            yield commonTranziitApi.completeRFQ(rfqId, createOrderRequest);
        const [error, data] = responseCreateOrder;
        if (error) {
            yield put(createOrderError(error));

            if (
                checkIsTranziitApiRequestError(error) &&
                (error?.subType === TranziitApiRequestErrorSubTypeEnum.orderCreationExpiredRFQ ||
                    error?.subType === TranziitApiRequestErrorSubTypeEnum.orderCreation_ExpiredPriceOffer ||
                    error?.subType === TranziitApiRequestErrorSubTypeEnum.orderCreation_AlreadyCompletedRFQ)
            ) {
                history.push(OMSRoutesEnum.newOrder);
            }
        } else {
            yield put(createOrderSuccess());

            ordersRefreshChannel.emit({});

            yield put(resetOrderCreation());

            const cargoDetailsStorage = new ValuesStorage(CARGO_DETAILS_FORM_NAME);
            cargoDetailsStorage.reset();

            const shipmentDetailsStorage = new ValuesStorage(SHIPMENT_DETAIL_FORM_NAME);
            shipmentDetailsStorage.reset();

            let pathname = null;
            if (isCreateNextOrder) {
                pathname = OMSRoutesEnum.newOrder;
            }

            if (!isCreateNextOrder && !isBroker && data && 'orderId' in data) {
                pathname = generatePath(OMSRoutesEnum.orderDetails, {
                    orderId: data.orderId,
                });
            }

            if (!isCreateNextOrder && isBroker && data && 'dispatchId' in data) {
                pathname = generatePath(DispatchesRoutesEnum.dispatchDetails, {
                    dispatchId: data.dispatchId,
                });
            }

            history.push({
                pathname: pathname || CommonRoutesEnum.home,
            });
        }
    };
}

function getFetchRouteSaga(isBroker: boolean) {
    return function* fetchRouteSaga(action: FetchRouteActionT): WrapGeneratorT<void> {
        const { polylineId } = action;
        if (!polylineId) {
            logWarning('failed fetch route, empty polylineId');
            return;
        }

        const [apiRouteError, apiRoute]: ReturnApiT<typeof commonTranziitApi.fetchRouteGeometryGoogle> =
            yield commonTranziitApi.fetchRouteGeometryGoogle(polylineId);

        if (apiRouteError) {
            yield put(fetchRouteError(apiRouteError));
            return;
        }

        if (!apiRoute) {
            const error = new Error('empty apiRoute');
            yield put(fetchRouteError(error));
            return;
        }

        const polylines = apiRoute || [];

        yield put(fetchRouteSuccess(polylines));
    };
}

// eslint-disable-next-line require-yield
function* selectOfferSaga(action: SelectOfferActionT): WrapGeneratorT<void> {
    const { offer, rfqId } = action;

    const pathname = generatePath(OMSRoutesEnum.newOrderDetails, {
        rfqId,
        offerId: offer.id,
    });

    history.push(pathname + history.location.search);
}

function* createReserveSaga(
    isBroker: boolean,
    createReserveQuery: CreateReserveQueryT | null,
): WrapGeneratorT<ReserveQueryModifyEventT> {
    let event: ReserveQueryModifyEventT = {};

    let countriesByCode: ReturnType<typeof selectCountriesByCode> = yield select(selectCountriesByCode);
    if (isEmpty(countriesByCode)) {
        yield take([COUNTRIES_DICT_REQUEST_SUCCESS]);
        countriesByCode = yield select(selectCountriesByCode);
    }

    const apiCreateReserveQuery = getApiReverseQuery(isBroker, createReserveQuery);

    const countyCodes =
        createReserveQuery?.addresses
            ?.map((address) => {
                return address?.address?.country;
            })
            .filter(isNonNil) || [];

    const isWrongQuery = countyCodes.some((countyCode) => {
        return !countriesByCode[countyCode] || createReserveQuery?.prohibitedCountries.includes(countyCode);
    });

    if (isWrongQuery || !apiCreateReserveQuery) {
        yield put(resetCreateReserveWithQuery(createReserveQuery));
        return event;
    }

    yield put(createReserveBegin(createReserveQuery));

    /* start debug logs */
    logDebug(`New reserve [request]: ${JSON.stringify(apiCreateReserveQuery, null, 4)}`);
    /* end debug logs */

    let responseCreateReserve: ReturnApiT<typeof commonTranziitApi.createMultipointOrderReservation>;

    const { shipperId, ...restApiCreateReserveQuery } = apiCreateReserveQuery;
    if (isBroker) {
        responseCreateReserve = yield commonTranziitApi.createMultipointOrderReservation(
            restApiCreateReserveQuery,
            shipperId || undefined,
        );
    } else {
        responseCreateReserve = yield commonTranziitApi.createMultipointOrderReservation(restApiCreateReserveQuery);
    }

    /* start debug logs */
    logDebug(`New reserve [response]: ${JSON.stringify(responseCreateReserve, null, 4)}`);
    /* end debug logs */

    const [error, reserve] = responseCreateReserve;
    if (error) {
        yield put(createReserveError(error, createReserveQuery));
    }

    if (reserve) {
        const preparedReserve = preparePreview(reserve);
        yield put(createReserveSuccess(preparedReserve, createReserveQuery));

        event = {
            correctedPoints: preparedReserve?.points?.map((point) => {
                return {
                    defaultTimeWindow: point?.defaultTimeWindow,
                    replacedCoordinate: point?.replacedCoordinate,
                };
            }),
        };
    }

    return event;
}

function* previewReserveSaga(
    isBroker: boolean,
    reservePreviewQuery: ReservePreviewQueryT | null,
    reserve: ReserveT | null,
): WrapGeneratorT<ReserveQueryModifyEventT> {
    let event: ReserveQueryModifyEventT = {};

    const apiReservePreviewQuery = getApiPreviewReverseQuery(reservePreviewQuery, reserve);
    const reserveId = reservePreviewQuery?.reserveId;

    if (!reservePreviewQuery || !apiReservePreviewQuery || !reserveId) {
        return event;
    }

    yield put(previewReserveBegin(reservePreviewQuery));

    /* start debug logs */
    logDebug(`New reserve preview [request]: ${JSON.stringify({ reserveId, apiReservePreviewQuery }, null, 4)}`);
    /* end debug logs */

    const responsePreviewReserve: ReturnApiT<typeof commonTranziitApi.previewReserve> =
        yield commonTranziitApi.previewReserve(
            reserveId,
            apiReservePreviewQuery,
            reservePreviewQuery?.laneId || undefined,
        );

    /* start debug logs */
    logDebug(`New reserve preview [response]: ${JSON.stringify(responsePreviewReserve, null, 4)}`);
    /* end debug logs */

    const [error, apiPreview] = responsePreviewReserve;
    if (error) {
        yield put(previewReserveError(error, reservePreviewQuery));
    }
    if (apiPreview) {
        const preview = prepareReservePreview(apiPreview, reserve);
        yield put(previewReserveSuccess(preview, reservePreviewQuery));

        event = {
            correctedTimeWindows: preview.timeWindows.map((timeWindow) => {
                return timeWindow.correctedTimeWindow || null;
            }),
            correctedBrokerWindow: preview.timeWindows.map((timeWindow) => {
                return timeWindow.correctedBrokerWindow;
            }),
        };
    }

    return event;
}

function* changeReserveQuerySaga(
    companyType: CompanyTypeEnum,
    action: ChangeReserveQueryActionT,
): WrapGeneratorT<ReserveQueryModifyEventT> {
    let queryModifyEvent: ReserveQueryModifyEventT = {};

    const isBroker = companyType === CompanyTypeEnum.broker;

    let reserve: ReturnType<typeof selectReserve> = yield select(selectReserve);

    const prevReserveQuery: ReturnType<typeof selectReserveQuery> = yield select(selectReserveQuery);
    const prevReserveRequest: ReturnType<typeof selectReserveRequest> = yield select(selectReserveRequest);

    const reverseQueryChanges = getReverseQueryChanges(action.changes, prevReserveQuery, reserve);
    const currentReserveQuery = mergeReverseQueryChanges(prevReserveQuery, reverseQueryChanges);

    const isSameReserveRequest = checkIsSameQuery(prevReserveQuery, currentReserveQuery);

    const isExpiredReserve = reserve?.expireTime ? reserve?.expireTime < Date.now() : false;

    const isInitReserveRequest = prevReserveRequest.initial && !reserve;

    if (!isSameReserveRequest || isExpiredReserve || isInitReserveRequest) {
        logDebug('diff reserve request', diff(prevReserveQuery || {}, currentReserveQuery || {}), {
            prevReserveQuery,
            currentReserveQuery,
        });

        yield put(setCreateReserveQuery(currentReserveQuery));

        const reserveQueryModifyEvent = yield* createReserveSaga(isBroker, currentReserveQuery);

        queryModifyEvent = {
            ...queryModifyEvent,
            ...reserveQueryModifyEvent,
        };
    }

    reserve = yield select(selectReserve);

    const reserveQueryCorrection = getReserveQueryCorrection(currentReserveQuery, reserve);
    if (reserveQueryCorrection) {
        yield put(correctReserveQuery(reserveQueryCorrection));
    }

    const prevReservePreviewQuery: ReturnType<typeof selectReservePreviewQuery> = yield select(
        selectReservePreviewQuery,
    );
    const previewReverseQueryChanges = getPreviewReverseQueryChanges(action.changes, reserve);
    let currentReservePreviewQuery = mergePreviewReverseQuery(prevReservePreviewQuery, previewReverseQueryChanges);
    currentReservePreviewQuery = setReversePreviewDefaultQuery(currentReservePreviewQuery, reserve);

    const queryModifyEventAfterReserve = getQueryModifyEventAfterReserve(prevReservePreviewQuery, reserve);
    queryModifyEvent = {
        ...queryModifyEvent,
        ...queryModifyEventAfterReserve,
    };

    const reservePreview: ReturnType<typeof selectReservePreview> = yield select(selectReservePreview);
    const reservePreviewRequest: ReturnType<typeof selectReservePreviewRequest> = yield select(
        selectReservePreviewRequest,
    );
    const isInitReservePreview = reservePreviewRequest.initial && !reservePreview;
    const isSamePreviewRequest = checkIsSameQuery(prevReservePreviewQuery, currentReservePreviewQuery);
    if (!isSamePreviewRequest || isInitReservePreview) {
        logDebug('diff preview request', diff(prevReservePreviewQuery || {}, currentReservePreviewQuery || {}), {
            prevReservePreviewQuery,
            currentReservePreviewQuery,
        });
        yield put(setPreviewReserveQuery(currentReservePreviewQuery));

        const previewReserveQueryModify = yield* previewReserveSaga(isBroker, currentReservePreviewQuery, reserve);

        queryModifyEvent = {
            ...queryModifyEvent,
            ...previewReserveQueryModify,
        };
    }

    const previewResults: ReturnType<typeof selectReservePreview> = yield select(selectReservePreview);
    const previewReserveQueryCorrection = getPreviewReserveQueryCorrection(
        currentReservePreviewQuery,
        previewResults,
        reserve,
    );
    if (previewReserveQueryCorrection) {
        yield put(correctPreviewReserveQuery(previewReserveQueryCorrection));
    }

    return queryModifyEvent;
}

function createMergeActionsBuffer() {
    let changes: ChangeReserveQueryActionT | null = null;

    return {
        isEmpty: (): boolean => {
            return isEmpty(changes);
        },
        put: (action: ChangeReserveQueryActionT): void => {
            if (!changes) {
                changes = action;
                return;
            }

            changes = {
                ...changes,
                changes: {
                    ...changes?.changes,
                    ...action?.changes,
                },
            };
        },
        take: (): ChangeReserveQueryActionT | undefined => {
            const oldChanges = changes;

            changes = null;

            return oldChanges || undefined;
        },
        flush: (): ChangeReserveQueryActionT[] => {
            const oldChanges = changes;

            changes = null;

            return oldChanges ? [oldChanges] : [];
        },
    };
}

class QueryModifyEventsQueue {
    private summaryEvent: ReserveQueryModifyEventT = {};

    addEvent = (event: ReserveQueryModifyEventT) => {
        this.summaryEvent = {
            ...this.summaryEvent,
            ...event,
        };
    };

    flush = (): ReserveQueryModifyEventT => {
        const prevSummaryEvent = this.summaryEvent;

        this.summaryEvent = {};

        return prevSummaryEvent;
    };
}

// eslint-disable-next-line require-yield
function* emitQueryModifyIfNeed(event: ReserveQueryModifyEventT): WrapGeneratorT<void> {
    if (event && !isEmpty(event)) {
        reserveQueryModifyChannel.emit(event);
    }
}

function* watchSyncChangeReserveQuerySaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    const buffer = createMergeActionsBuffer();
    const channel = yield actionChannel(CHANGE_RESERVE_QUERY, buffer);
    const queryModifyEventsQueue = new QueryModifyEventsQueue();

    while (true) {
        const action: ChangeReserveQueryActionT = yield take(channel);

        yield put(setAllowCreateRFQ(false));

        const queryModifyEvent = yield* changeReserveQuerySaga(companyType, action);
        queryModifyEventsQueue.addEvent(queryModifyEvent);

        if (buffer.isEmpty()) {
            yield put(setAllowCreateRFQ(true));
            yield emitQueryModifyIfNeed(queryModifyEventsQueue.flush());
        }
    }
}

function* watchPriceOffersExpirationSaga(): WrapGeneratorT<void> {
    const REFRESH_INTERVAL = 30 * MS_IN_SEC;

    while (true) {
        yield delay(REFRESH_INTERVAL);

        const priceOffers: ReturnType<typeof selectPriceOffersList> = yield select(selectPriceOffersList);

        const expriredOfferIds = priceOffers
            .filter((offer) => checkIsPastDate(offer.expirationMs))
            .map((offer) => offer.id);

        yield put(markOffersExpired(expriredOfferIds));
    }
}

function* orderCreationSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    const isBroker = companyType === CompanyTypeEnum.broker;

    yield takeEvery(CREATE_RFQ_REQUEST, getCreateRFQSaga(companyType));
    yield takeEvery(CREATE_PRICE_OFFER_WITH_LANE_REQUEST, getCreatePriceOfferWithLaneSaga(companyType));
    yield takeEvery(ENSURE_CREATED_RFQ, getEnsureCreatedRFQSaga(companyType));
    yield takeEvery(CREATE_ORDER_REQUEST, getCreatedOrderSaga(isBroker));
    yield takeEvery(FETCH_ROUTE, getFetchRouteSaga(isBroker));
    yield takeEvery(SELECT_OFFER, selectOfferSaga);
    yield takeEvery(FETCH_TASK_OFFER, fetchTaskOfferSaga);
    yield takeEvery(PREVIEW_RESERVE_ERROR, handlePreviewReserveErrorSaga);
    yield takeLatest(
        [CREATE_RFQ_REQUEST_ERROR, CREATE_PRICE_OFFER_WITH_LANE_REQUEST_ERROR],
        redirectToStartNewOrderSaga,
    );

    yield fork(watchSyncChangeReserveQuerySaga, companyType);
    yield fork(watchPriceOffersExpirationSaga);
}

export default orderCreationSaga;

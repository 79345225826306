import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ShipmentDetailsCard.scss';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import Card from 'design-system/components/Card/Card';
import { useTranslation } from 'react-i18next';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';

import { formatTimeInterval } from 'common/utils/time';
import NumberIcon from 'common/icons/NumberIcon';
import CargoUnitIcon from 'common/icons/CargoUnitIcon';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { getLoadUnitType } from 'common/utils/units';
import WeightIcon from 'common/icons/WeightIcon';
import SnowflakeIcon from 'common/icons/SnowflakeIcon';
import TemperatureModeFormatter from 'design-system/components/InfoTable/formatters/TemperatureModeFormatter/TemperatureModeFormatter';
import CommentIcon from 'common/icons/CommentIcon';

import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import { ApiBrokerSpotShipmentT, ApiSpotStopT } from 'broker-admin/utils/api/spot-broker-tranziit/models';

const ADDRESS_SEPARATOR = ', ';

export type PropsT = {
    isReeferTrailer: boolean;
    shipmentNumber: number;
    shipment: ApiBrokerSpotShipmentT | null;
    pickUpStop: ApiSpotStopT | null;
    dropOffStop: ApiSpotStopT | null;
    keyboardShortcut?: string;
    className?: string;
};

const cx = classNames.bind(styles);

const ShipmentDetailsCard: React.FC<PropsT> = React.memo((props) => {
    const { isReeferTrailer, shipmentNumber, shipment, pickUpStop, dropOffStop, keyboardShortcut, className } = props;

    const { t } = useTranslation();

    const pickUpDetails: Array<InfoTableRowT> = [
        {
            icon: <NumberIcon number={(pickUpStop?.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />,
            name: t('spot-request-details.shipment-details.by-type.pickup'),
            value: (
                <LocationLabel
                    format="s1_s2_zip_city_country"
                    location={pickUpStop?.address}
                    separator={ADDRESS_SEPARATOR}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'origin',
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.time-slots'),
            value: formatTimeInterval(pickUpStop?.timeSlotFrom, pickUpStop?.timeSlotTo),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'date-time',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.company-name'),
            value: pickUpStop?.contact?.companyName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-company-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.contact-name'),
            value: pickUpStop?.contact?.fullName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.contact-phone'),
            value: pickUpStop?.contact?.phone,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-phone',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.contact-email'),
            value: pickUpStop?.contact?.email,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-email',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.reference-number'),
            value: shipment?.pickupReferenceNumber,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'loading-reference',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.comment'),
            value: pickUpStop?.contact?.comment,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-comment',
        },
    ];

    const dropOffDetails: Array<InfoTableRowT> = [
        {
            icon: <NumberIcon number={(dropOffStop?.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />,
            name: t('spot-request-details.shipment-details.by-type.delivery'),
            value: (
                <LocationLabel
                    format="s1_s2_zip_city_country"
                    location={dropOffStop?.address}
                    separator={ADDRESS_SEPARATOR}
                />
            ),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'origin',
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.time-slots'),
            value: formatTimeInterval(dropOffStop?.timeSlotFrom, dropOffStop?.timeSlotTo),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'date-time',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.company-name'),
            value: dropOffStop?.contact?.companyName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-company-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.contact-name'),
            value: dropOffStop?.contact?.fullName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.contact-phone'),
            value: dropOffStop?.contact?.phone,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-phone',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.contact-email'),
            value: dropOffStop?.contact?.email,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-email',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.reference-number'),
            value: shipment?.dropOffReferenceNumber,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'loading-reference',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('spot-request-details.shipment-details.columns.comment'),
            value: dropOffStop?.contact?.comment,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-comment',
        },
    ];

    const loadDetails: Array<InfoTableRowT | null> = [
        {
            icon: (
                <CargoUnitIcon
                    size={DEFAULT_ICON_SIZE}
                    fillColor={StyleGuideColorsEnum.light}
                    strokeColor={StyleGuideColorsEnum.gray}
                />
            ),
            name: t('spot-request-details.shipment-details.columns.units'),
            value: <UnitTypeCount type={getLoadUnitType(shipment?.unitsType)} count={shipment?.maxNumberOfUnits} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'load-units',
        },
        {
            icon: <WeightIcon fillColor={StyleGuideColorsEnum.gray} />,
            name: t('spot-request-details.shipment-details.columns.cargo-weight'),
            value: <UnitTypeCount count={shipment?.weight} type={UnitTypeEnum.kilogramsAbbreviation} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'cargo-weight',
        },
        isReeferTrailer
            ? {
                  icon: <SnowflakeIcon fillColor={StyleGuideColorsEnum.gray} />,
                  name: t('spot-request-details.shipment-details.columns.temperature'),
                  value: (
                      <TemperatureModeFormatter
                          lowestTemperature={shipment?.lowestTemperature}
                          highestTemperature={shipment?.highestTemperature}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
                  testSelector: 'temperature',
              }
            : null,
        {
            icon: <CommentIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('spot-request-details.shipment-details.columns.description'),
            value: shipment?.description,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'description',
        },
    ];

    return (
        <>
            <Card
                titleNode={t('spot-request-details.shipment-details.title', {
                    number: shipmentNumber,
                })}
                afterTitleNode={
                    <CargoUnitIcon
                        size={DEFAULT_ICON_SIZE}
                        fillColor={StyleGuideColorsEnum.light}
                        strokeColor={StyleGuideColorsEnum.slate}
                    />
                }
                className={className}
                hasHeaderBottomBorder
                keyboardShortcut={keyboardShortcut}
            >
                <div className={cx('content')}>
                    <InfoTable
                        shouldRenderIcons
                        isCollapsable
                        rows={pickUpDetails}
                        className={cx('table', 'table--point')}
                    />
                    <InfoTable
                        shouldRenderIcons
                        isCollapsable
                        rows={dropOffDetails}
                        className={cx('table', 'table--point')}
                    />
                    <InfoTable
                        shouldRenderIcons
                        className={cx('table', 'table--load')}
                        rows={loadDetails}
                        testSelector="load-details"
                    />
                </div>
            </Card>
        </>
    );
});

export default ShipmentDetailsCard;

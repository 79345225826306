import * as React from 'react';
import { TollTypeEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    type: TollTypeEnum | null | undefined;
};

export const TOLL_MAP_T: Record<TollTypeEnum, string> = {
    [TollTypeEnum.general]: 'common:tolls.general',
    [TollTypeEnum.marketCorrection]: 'common:tolls.market-correction',
    [TollTypeEnum.intermodal]: 'common:tolls.intermodal',
    [TollTypeEnum.mountainPass]: 'common:tolls.mountain-pass',
    [TollTypeEnum.city]: 'common:tolls.city',
    [TollTypeEnum.bridge]: 'common:tolls.bridge',
    [TollTypeEnum.tunnel]: 'common:tolls.tunnel',
    [TollTypeEnum.ferry]: 'common:tolls.ferry',
    [TollTypeEnum.unspecified]: 'common:tolls.unspecified',
};

export const TOLL_TOOLTIP_MAP_T: Partial<Record<TollTypeEnum, string>> = {
    [TollTypeEnum.general]: 'common:tolls-placeholders.general',
    [TollTypeEnum.mountainPass]: 'common:tolls-placeholders.mountain-pass',
    [TollTypeEnum.city]: 'common:tolls-placeholders.city',
    [TollTypeEnum.bridge]: 'common:tolls-placeholders.bridge',
    [TollTypeEnum.tunnel]: 'common:tolls-placeholders.tunnel',
    [TollTypeEnum.ferry]: 'common:tolls-placeholders.ferry',
};

const TollLabel: React.FC<PropsT> = React.memo((props) => {
    const { type } = props;
    const { t } = useTranslation();

    if (!type) {
        return null;
    }

    return <span>{t(TOLL_MAP_T[type as TollTypeEnum])}</span>;
});

export default TollLabel;

import { fetchStateMachineSteps } from 'common/store/state-machine/slice';
import * as React from 'react';
import { useEffect } from 'react';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';
import { useDispatch, useSelector } from 'react-redux';
import { StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import { selectStateMachineItemState } from 'common/store/state-machine/selectors';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';

export const useStateMachine = <T extends string>(descriptor: StateMachineEntityDescriptorT | null) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!descriptor) {
            return;
        }

        dispatch(
            fetchStateMachineSteps({
                descriptor,
            }),
        );
    }, [descriptor]);

    const refreshPageHandler = React.useCallback(() => {
        if (!descriptor) {
            return;
        }

        dispatch(
            fetchStateMachineSteps({
                descriptor,
                options: {
                    isForceUpdate: true,
                },
            }),
        );
    }, [descriptor]);
    useChannelSubscribe(stateMachineRefreshChannel, refreshPageHandler);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        if (!descriptor) {
            return;
        }

        dispatch(
            fetchStateMachineSteps({
                descriptor,
            }),
        );
    }, [descriptor]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    return useSelector(selectStateMachineItemState<T>(descriptor));
};

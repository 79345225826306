import * as React from 'react';

import { useTranslation } from 'react-i18next';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { PartnerTypeEnum, PartnerTypeT } from 'common/utils/api/models';

type PropsT = {
    type: PartnerTypeT | null | undefined;
};

export const PARTNER_TYPE_T_MAP: Record<PartnerTypeEnum, string> = {
    [PartnerTypeEnum.carrier]: 'common:company-type.CARRIER',
    [PartnerTypeEnum.shipper]: 'common:company-type.SHIPPER',
    [PartnerTypeEnum.broker]: 'common:company-type.BROKER',
};

const PartnerTypePill: React.FC<PropsT> = React.memo((props) => {
    const { type } = props;

    const { t } = useTranslation();

    const label = type ? t(PARTNER_TYPE_T_MAP[type]) : null;

    if (!label) {
        return null;
    }

    return (
        <PillLabel theme={PillLabelThemeEnum.charcoal} testSelector="partner-type">
            {label}
        </PillLabel>
    );
});

export default PartnerTypePill;

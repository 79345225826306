import { createSelector } from 'reselect';
import { AuthStateT } from './types';
import { SharableStateT } from '../models';
import { PermissionsT } from './models';
import { AuthUserClaimRoleT, PartnerTypeEnum, RolesEnum } from 'common/utils/api/models';
import { getPermissions } from 'common/utils/permissions';
import { ALL_ROLES_BY_PARTNER_TYPE, AuthProfileTypeEnum } from 'common/store/auth/constants';
import { CompanyTypeEnum } from 'common/constants';
import { authApi } from 'common/utils/api/auth/factory';

export const selectSignInRequestStatus = (state: SharableStateT): AuthStateT['signInRequest'] =>
    state.auth.signInRequest;

export const selectUser = (state: SharableStateT): AuthStateT['user'] | null => state.auth?.user || null;

export const selectAllowedCreateAnonymously = (): boolean => authApi.checkAllowedAnonymouslyUserCreation();

export const selectUserClaims = (state: SharableStateT): AuthStateT['userClaims'] => state.auth?.userClaims;

export const checkRegisteredUser = (state: SharableStateT): boolean =>
    state.auth.user !== null && !state.auth.user.isAnonymous;

export const selectResetPasswordRequestStatus = (state: SharableStateT): AuthStateT['resetPasswordRequest'] =>
    state.auth.resetPasswordRequest;

export const selectUpdatePasswordRequestStatus = (state: SharableStateT): AuthStateT['updatePasswordRequest'] =>
    state.auth.updatePasswordRequest;

export const selectSignUpRequestStatus = (state: SharableStateT): AuthStateT['signUpRequest'] =>
    state.auth.signUpRequest;

export const selectSignOutRequestStatus = (state: SharableStateT): AuthStateT['signOutRequest'] =>
    state.auth.signOutRequest;

export const selectEmailVerificationRequestStatus = (state: SharableStateT): AuthStateT['emailVerificationRequest'] =>
    state.auth.emailVerificationRequest;

export const selectProfileType = (state: SharableStateT): AuthStateT['profileType'] => state.auth.profileType;

export type RolesConfigT = Record<keyof PermissionsT, Array<AuthUserClaimRoleT | AuthUserClaimRoleT[]>>;
export type ProfileTypeRestrictConfigT = Partial<Record<keyof PermissionsT, AuthProfileTypeEnum[]>>;

export const selectPermissions = createSelector(
    selectUserClaims,
    selectProfileType,
    (userClaims, profileType): PermissionsT => {
        // rule: [a or b or [c and d]]
        const config: RolesConfigT = {
            canViewPriceSettings: [RolesEnum.companyAdmin],
            canViewFullStatistic: [RolesEnum.companyAdmin],

            canCancelOrder: [RolesEnum.companyAdmin, ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.broker]],
            canCreateOrder: [RolesEnum.companyAdmin, RolesEnum.keyAccountManager],

            canViewTrailers: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                RolesEnum.companyAdmin,

                RolesEnum.keyAccountManager,
            ],
            canEditTrailers: [RolesEnum.companyAdmin],

            canViewTrucks: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                RolesEnum.companyAdmin,

                RolesEnum.keyAccountManager,
            ],
            canEditTrucks: [RolesEnum.companyAdmin],

            canAddPartner: [RolesEnum.companyAdmin],
            canDeletePartner: [],

            canDownloadDocuments: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.shipper],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.broker],
            ],
            canUploadDocuments: [RolesEnum.companyAdmin],
            canVerifyDocuments: [RolesEnum.companyAdmin],
            canRevokeDocuments: [RolesEnum.companyAdmin],
            canEditDocuments: [RolesEnum.companyAdmin],
            canReviewDocuments: [RolesEnum.companyAdmin],

            canDownloadCarrierContracts: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.shipper],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.broker],
            ],
            canUploadCarrierContracts: [RolesEnum.companyAdmin],
            canRevokeCarrierContracts: [RolesEnum.companyAdmin],
            canEditCarrierContracts: [RolesEnum.companyAdmin],
            canReviewCarrierContracts: [RolesEnum.companyAdmin],

            canUploadShipperContracts: [RolesEnum.companyAdmin],
            canEditShipperContracts: [RolesEnum.companyAdmin],

            canVerifyPartner: [RolesEnum.companyAdmin],

            canEditTeamMembers: [RolesEnum.companyAdmin, RolesEnum.companyAdmin, RolesEnum.keyAccountManager],
            canViewTeamMembers: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.shipper],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.broker],
            ],
            canEditDrivers: [RolesEnum.companyAdmin, RolesEnum.keyAccountManager],
            canViewDrivers: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                RolesEnum.companyAdmin,
                RolesEnum.keyAccountManager,
            ],

            canViewSpot: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier],
                RolesEnum.companyAdmin,
                RolesEnum.dispatcher,
            ],

            canViewInvoices: [
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.shipper],
                ...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.broker],
            ],

            canDeclineTransportOrders: [[RolesEnum.companyAdmin, CompanyTypeEnum.carrier]],

            canEditSpotLanes: [...ALL_ROLES_BY_PARTNER_TYPE[PartnerTypeEnum.carrier]],
            canUseBrokerAdminSuggest: [RolesEnum.companyAdmin],

            canEditPartnerDetails: [RolesEnum.companyAdmin, RolesEnum.keyAccountManager],

            canFilterDispatchesByAccountManagerOrDispatcher: [RolesEnum.companyAdmin],
        };

        const userRolesSet = new Set(userClaims?.roles || []);

        const profileTypeRestrictConfig: ProfileTypeRestrictConfigT = {
            canRevokeDocuments: [AuthProfileTypeEnum.broker],
            canVerifyDocuments: [AuthProfileTypeEnum.broker],
            canEditDocuments: [AuthProfileTypeEnum.broker],
            canReviewDocuments: [AuthProfileTypeEnum.broker],

            canRevokeCarrierContracts: [AuthProfileTypeEnum.broker],
            canEditCarrierContracts: [AuthProfileTypeEnum.broker],
            canReviewCarrierContracts: [AuthProfileTypeEnum.broker],

            canUploadShipperContracts: [AuthProfileTypeEnum.broker],
            canEditShipperContracts: [AuthProfileTypeEnum.broker],

            canEditSpotLanes: [AuthProfileTypeEnum.carrier],

            canUseBrokerAdminSuggest: [AuthProfileTypeEnum.broker],

            canEditPartnerDetails: [AuthProfileTypeEnum.broker],

            canFilterDispatchesByAccountManagerOrDispatcher: [AuthProfileTypeEnum.broker],
        };

        return getPermissions(userRolesSet, config, profileType, profileTypeRestrictConfig);
    },
);

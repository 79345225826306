import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    fillColor: StyleGuideColorsEnum;
    fillOpacity?: number;
    strokeColor: StyleGuideColorsEnum;
    strokeOpacity?: number;
};

const CaseIcon: React.FC<PropsT> = (props) => {
    const { size, fillColor, fillOpacity, strokeColor, strokeOpacity, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    fillOpacity={strokeOpacity || 1}
                    d="M16 6.25h-3v-1.5l-1.5-1.5h-3L7 4.75v1.5H4c-.825 0-1.5.675-1.5 1.5v3.75c0 .563.3 1.035.75 1.297v2.453c0 .832.667 1.5 1.5 1.5h10.5c.832 0 1.5-.668 1.5-1.5v-2.46a1.51 1.51 0 00.75-1.29V7.75c0-.825-.675-1.5-1.5-1.5zM7.75 13v.75h4.5V13h3v2.25H4.75V13h3zm3.75-8.25v1.5h-3v-1.5h3zm-.75 6v1.5h-1.5v-1.5h1.5zm5.25-3v3.75h-3.75V9.25h-4.5v2.25H4V7.75h12z"
                />
                <path
                    fillOpacity={fillOpacity || 1}
                    fill={fillColor}
                    d="M12.25 13.75h-4.5V13h-3v2.25h10.5V13h-3v.75zM4 11.5h3.75V9.25h4.5v2.25H16V7.75H4v3.75z"
                />
            </g>
        </svg>
    );
};

CaseIcon.displayName = 'CaseIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default CaseIcon;

import classNames from 'classnames/bind';
import styles from './MapCluster.scss';
import * as React from 'react';
import cs from 'classnames';
import isNil from 'lodash/isNil';

const cx = classNames.bind(styles);

export enum MapClusterThemeEnum {
    slate = 'slate',
    charcoal = 'charcoal',
    light = 'light',
    brandDark = 'brand-dark',
}

type PropsT = {
    lat: number | undefined;
    lng: number | undefined;
    className?: string;
    theme?: MapClusterThemeEnum;
    children: (isHover: boolean) => React.ReactElement;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onHover?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const MapCluster: React.FC<PropsT> = React.memo((props) => {
    const { onHover, onClick, lat, lng, theme } = props;

    const [isHover, setHover] = React.useState<boolean>(false);

    if (isNil(lat) || isNil(lng)) {
        return null;
    }

    return (
        <div
            className={cs(
                cx('cluster', {
                    [`cluster--theme-${theme}`]: !!theme,
                }),
                props.className,
            )}
            onMouseMove={(event) => {
                if (!isHover && onHover) {
                    onHover(event);
                }
                if (!isHover) {
                    setHover(true);
                }
            }}
            onMouseLeave={() => {
                setHover(false);
            }}
            onClick={onClick}
        >
            {props.children(isHover)}
        </div>
    );
});

export default MapCluster;

import {
    ADD_CONTACT_REQUEST,
    ADD_MEMBER_REQUEST_BEGIN,
    ADD_MEMBER_REQUEST_ERROR,
    ADD_MEMBER_REQUEST_SUCCESS,
    AddContactActionT,
    AddMemberBeginActionT,
    AddMemberErrorActionT,
    AddMemberSuccessActionT,
    CANCEL_INVITE,
    CancelUserActionT,
    CHANGE_ADMIN_ROLE_WITH_SUCCESSORS,
    CHANGE_USER_ROLE,
    CHANGE_USER_ROLE_WITH_SUCCESSOR,
    ChangeAdminRoleWithSuccessorsActionT,
    ChangeUserRoleActionT,
    ChangeUserRoleWithSuccessorActionT,
    DELETE_CONTACT,
    DELETE_MEMBER_REQUEST_BEGIN,
    DELETE_MEMBER_REQUEST_ERROR,
    DELETE_MEMBER_REQUEST_SUCCESS,
    DELETE_USER,
    DELETE_USER_WITH_SUCCESSOR,
    DeleteContactActionT,
    DeleteMemberBeginActionT,
    DeleteMemberErrorActionT,
    DeleteMemberSuccessActionT,
    DeleteUserActionT,
    DeleteUserWithSuccessorActionT,
    FETCH_CONTACT_DETAILS_BY_USER_ID,
    FETCH_CONTACT_DETAILS_REQUEST,
    FETCH_CONTACT_DETAILS_REQUEST_BEGIN,
    FETCH_CONTACT_DETAILS_REQUEST_ERROR,
    FETCH_CONTACT_DETAILS_REQUEST_SUCCESS,
    FETCH_CONTACTS_REQUEST,
    FETCH_CONTACTS_REQUEST_BEGIN,
    FETCH_CONTACTS_REQUEST_ERROR,
    FETCH_CONTACTS_REQUEST_SUCCESS,
    FetchContactDetailsActionT,
    FetchContactDetailsBeginActionT,
    FetchContactDetailsByUserIdActionT,
    FetchContactDetailsErrorActionT,
    FetchContactDetailsSuccessActionT,
    FetchContactsActionT,
    FetchContactsBeginActionT,
    FetchContactsErrorActionT,
    FetchContactsSuccessActionT,
    INVITE_USER_REQUEST,
    InviteUserActionT,
    MAKE_MAIN_CONTACT,
    MakeMainContactActionT,
    RESTORE_USER,
    RestoreUserActionT,
    UPDATE_CONTACT,
    UPDATE_MEMBER_REQUEST_BEGIN,
    UPDATE_MEMBER_REQUEST_ERROR,
    UPDATE_MEMBER_REQUEST_SUCCESS,
    UpdateContactActionT,
    UpdateMemberBeginActionT,
    UpdateMemberErrorActionT,
    UpdateMemberSuccessActionT,
} from './types';
import { CompanyContactDetailsT, CompanyContactT, RolesWithSuccessorT } from './models';

import { ApiAddCompanyUserRequestT, ApiCompanyContactRequestT, RolesEnum } from 'common/utils/api/models';

export const fetchContacts = (companyId: CompanyIdT, options?: FetchOptionsT): FetchContactsActionT => {
    return {
        type: FETCH_CONTACTS_REQUEST,
        companyId,
        options,
    };
};

export const fetchContactsBegin = (companyId: CompanyIdT): FetchContactsBeginActionT => ({
    type: FETCH_CONTACTS_REQUEST_BEGIN,
    companyId,
});

export const fetchContactsSuccess = (
    companyContacts: CompanyContactT[],
    companyId: CompanyIdT,
): FetchContactsSuccessActionT => ({
    type: FETCH_CONTACTS_REQUEST_SUCCESS,
    companyContacts,
    companyId,
});

export const fetchContactsError = (error: Error, companyId: CompanyIdT): FetchContactsErrorActionT => ({
    type: FETCH_CONTACTS_REQUEST_ERROR,
    error,
    companyId,
});

export const addContact = (newContact: ApiCompanyContactRequestT, companyId: CompanyIdT): AddContactActionT => ({
    type: ADD_CONTACT_REQUEST,
    newContact,
    companyId,
});

export const inviteUser = (newUser: ApiAddCompanyUserRequestT, companyId: CompanyIdT): InviteUserActionT => ({
    type: INVITE_USER_REQUEST,
    newUser,
    companyId,
});

export const addMemberBegin = (companyId: CompanyIdT): AddMemberBeginActionT => ({
    type: ADD_MEMBER_REQUEST_BEGIN,
    companyId,
});

export const addMemberSuccess = (companyId: CompanyIdT): AddMemberSuccessActionT => ({
    type: ADD_MEMBER_REQUEST_SUCCESS,
    companyId,
});

export const addMemberError = (error: Error, companyId: CompanyIdT): AddMemberErrorActionT => ({
    type: ADD_MEMBER_REQUEST_ERROR,
    error,
    companyId,
});

export const deleteContact = (contactId: ContactIdT, companyId: CompanyIdT): DeleteContactActionT => ({
    type: DELETE_CONTACT,
    contactId,
    companyId,
});

export const deleteUser = (userId: UserIdT, companyId: CompanyIdT): DeleteUserActionT => ({
    type: DELETE_USER,
    userId,
    companyId,
});

export const deleteUserWithSuccessor = (
    userId: UserIdT,
    successorUserId: UserIdT,
    successorRole: RolesWithSuccessorT,
    companyId: CompanyIdT,
): DeleteUserWithSuccessorActionT => ({
    type: DELETE_USER_WITH_SUCCESSOR,
    userId,
    companyId,
    successorUserId,
    successorRole,
});

export const cancelInvite = (userId: UserIdT, companyId: CompanyIdT): CancelUserActionT => ({
    type: CANCEL_INVITE,
    userId,
    companyId,
});

export const restoreUser = (userId: UserIdT, companyId: CompanyIdT): RestoreUserActionT => ({
    type: RESTORE_USER,
    userId,
    companyId,
});

export const deleteMemberBegin = (companyId: CompanyIdT): DeleteMemberBeginActionT => ({
    type: DELETE_MEMBER_REQUEST_BEGIN,
    companyId,
});

export const deleteMemberSuccess = (companyId: CompanyIdT): DeleteMemberSuccessActionT => ({
    type: DELETE_MEMBER_REQUEST_SUCCESS,
    companyId,
});

export const deleteMemberError = (error: Error, companyId: CompanyIdT): DeleteMemberErrorActionT => ({
    type: DELETE_MEMBER_REQUEST_ERROR,
    error,
    companyId,
});

export const changeUserRole = (role: RolesEnum, userId: UserIdT, companyId: CompanyIdT): ChangeUserRoleActionT => ({
    type: CHANGE_USER_ROLE,
    userId,
    role,
    companyId,
});

export const changeUserRoleWithSuccessor = (
    role: RolesEnum,
    userId: UserIdT,
    successorUserId: UserIdT,
    successorRole: RolesWithSuccessorT,
    companyId: CompanyIdT,
): ChangeUserRoleWithSuccessorActionT => ({
    type: CHANGE_USER_ROLE_WITH_SUCCESSOR,
    userId,
    role,
    companyId,
    successorUserId,
    successorRole,
});

export const changeAdminRoleWithSuccessors = (
    role: RolesEnum,
    userId: UserIdT,
    newDispatcherUserId: UserIdT,
    newKeyAccountManagerUserId: UserIdT,
    companyId: CompanyIdT,
): ChangeAdminRoleWithSuccessorsActionT => ({
    type: CHANGE_ADMIN_ROLE_WITH_SUCCESSORS,
    userId,
    role,
    companyId,
    newDispatcherUserId,
    newKeyAccountManagerUserId,
});

export const makeMainContact = (contactId: ContactIdT, companyId: CompanyIdT): MakeMainContactActionT => ({
    type: MAKE_MAIN_CONTACT,
    contactId,
    companyId,
});

export const updateContact = (
    contactId: ContactIdT,
    contactChanges: ApiCompanyContactRequestT,
    companyId: CompanyIdT,
): UpdateContactActionT => ({
    type: UPDATE_CONTACT,
    contactId,
    contactChanges,
    companyId,
});

export const updateMemberBegin = (companyId: CompanyIdT): UpdateMemberBeginActionT => ({
    type: UPDATE_MEMBER_REQUEST_BEGIN,
    companyId,
});

export const updateMemberSuccess = (companyId: CompanyIdT): UpdateMemberSuccessActionT => ({
    type: UPDATE_MEMBER_REQUEST_SUCCESS,
    companyId,
});

export const updateMemberError = (error: Error, companyId: CompanyIdT): UpdateMemberErrorActionT => ({
    type: UPDATE_MEMBER_REQUEST_ERROR,
    error,
    companyId,
});

export const fetchContactDetails = (contactId: ContactIdT, companyId: CompanyIdT): FetchContactDetailsActionT => ({
    type: FETCH_CONTACT_DETAILS_REQUEST,
    contactId,
    companyId,
});

export const fetchContactDetailsByUserId = (
    userId: UserIdT,
    companyId: CompanyIdT,
): FetchContactDetailsByUserIdActionT => ({
    type: FETCH_CONTACT_DETAILS_BY_USER_ID,
    userId,
    companyId,
});

export const fetchContactDetailsBegin = (companyId: CompanyIdT): FetchContactDetailsBeginActionT => ({
    type: FETCH_CONTACT_DETAILS_REQUEST_BEGIN,
    companyId,
});

export const fetchContactDetailsSuccess = (
    contactId: ContactIdT,
    details: CompanyContactDetailsT | null,
    companyId: CompanyIdT,
): FetchContactDetailsSuccessActionT => ({
    type: FETCH_CONTACT_DETAILS_REQUEST_SUCCESS,
    contactId,
    details,
    companyId,
});

export const fetchContactDetailsError = (error: Error, companyId: CompanyIdT): FetchContactDetailsErrorActionT => ({
    type: FETCH_CONTACT_DETAILS_REQUEST_ERROR,
    error,
    companyId,
});

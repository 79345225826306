import * as React from 'react';
import { useMemo } from 'react';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateEntityStatus } from 'common/store/state-machine/slice';
import { ApiTourStatusT, TourStatusEnum } from 'common/utils/api/models';
import TourStatus from 'common/components/status/TourStatus/TourStatus';
import { AvailableSubjectStatusesResponseT, StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ChangeBlockedStatusControlOptionLabel from 'common/components/dropdowns/options/ChangeBlockedStatusControlOptionLabel/ChangeBlockedStatusControlOptionLabel';
import ChangeBackwardStatusControlOptionLabel from 'common/components/dropdowns/options/ChangeBackwardStatusControlOptionLabel/ChangeBackwardStatusControlOptionLabel';
import ChangeForwardStatusControlOptionLabel from 'common/components/dropdowns/options/ChangeForwardStatusControlOptionLabel/ChangeForwardStatusControlOptionLabel';
import { addSeparatorAfterEachOption } from 'common/components/dropdowns/utils/add-separators';
import { useStateMachine } from 'common/utils/hooks/useStateMachine';

type PropsT = {
    className?: string;
    isShowLoading: boolean;
    tourId: TourIdT | null;
    dispatchId: DispatchIdT | null;
};

const blockedI18nMap: Record<NonNullable<AvailableSubjectStatusesResponseT['description']>, string> = {
    HAS_LOADING_UNLOADING_SHIPMENT: 'change-tour-status.blocked.HAS_LOADING_UNLOADING_SHIPMENT',
    TOUR_NOT_STARTED: 'change-tour-status.blocked.TOUR_NOT_STARTED',
    NOT_FULLY_ASSIGN: 'change-tour-status.blocked.NOT_FULLY_ASSIGN',
    ALL_SHIPMENTS_NOT_DELIVERED: 'change-tour-status.blocked.ALL_SHIPMENTS_NOT_DELIVERED',
};

const positiveStatusSet = new Set<ApiTourStatusT>([TourStatusEnum.finished]);

const negativeStatusSet = new Set<ApiTourStatusT>([TourStatusEnum.canceled]);

const ChangeTourStatusControl: React.FC<PropsT> = React.memo((props) => {
    const { className, tourId, dispatchId, isShowLoading } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const descriptor = useMemo((): StateMachineEntityDescriptorT => {
        return {
            entityType: 'TOUR',
            entityId: tourId || '-',
        };
    }, [tourId]);

    const { availableSubjectStatuses, fetchRequest, updateRequest } = useStateMachine<ApiTourStatusT>(descriptor);

    const options: Array<DropdownControlOptionT | null | undefined> = [
        ...(availableSubjectStatuses?.description
            ? [
                  {
                      label: (
                          <ChangeBlockedStatusControlOptionLabel
                              reason={t(blockedI18nMap[availableSubjectStatuses.description])}
                          />
                      ),
                      isDisabled: true,
                      onSelect: () => {
                          // nothing
                      },
                  },
              ]
            : []),
        ...(availableSubjectStatuses?.backward || []).map((status) => {
            return {
                label: (
                    <ChangeBackwardStatusControlOptionLabel
                        isPositiveIconStatus={positiveStatusSet.has(status)}
                        isNegativeIconStatus={negativeStatusSet.has(status)}
                        statusNode={<TourStatus status={status} />}
                    />
                ),
                onSelect: () => {
                    dispatch(
                        updateEntityStatus({
                            update: {
                                entityType: 'TOUR',
                                entityId: tourId || '-',
                                status,
                            },
                            descriptor,
                        }),
                    );
                },
            };
        }),
        ...(availableSubjectStatuses?.forward || []).map((status) => {
            return {
                label: (
                    <ChangeForwardStatusControlOptionLabel
                        isPositiveIconStatus={positiveStatusSet.has(status)}
                        isNegativeIconStatus={negativeStatusSet.has(status)}
                        statusNode={<TourStatus status={status} />}
                    />
                ),
                onSelect: () => {
                    dispatch(
                        updateEntityStatus({
                            update: {
                                entityType: 'TOUR',
                                entityId: tourId || '-',
                                status,
                            },
                            descriptor,
                        }),
                    );
                },
            };
        }),
    ];

    const optionsWithSeparators = addSeparatorAfterEachOption(options);

    const isLoading = fetchRequest?.loading || updateRequest?.loading;

    if (!isShowLoading) {
        return null;
    }

    return (
        <DropdownControl
            className={className}
            options={optionsWithSeparators}
            isDisabled={isLoading}
            isShowTriggerForEmptyOptions={isLoading}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isDisabled={isLoading}
                    isPressed={isActive}
                    onClick={onClick}
                    label={t('common:change-status-control.trigger')}
                    rightIcon={
                        isLoading ? (
                            <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />
                        ) : (
                            <ArrowsIcon />
                        )
                    }
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            )}
            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
        />
    );
});

export default ChangeTourStatusControl;

import { ApiRequestError, ApiRequestErrorTypeEnum } from '../errors/api-request-error';
import { BaseSecurityAuthApi } from '../auth/base-security-auth-api';
import parseErrors from './errors/parse-errors';
import { TranziitApiRequestError, TranziitApiRequestErrorSubTypeEnum } from './errors/tranziit-api-errors';
import doFetch from 'common/utils/api/do-fetch';
import { NotImplementedError } from 'common/utils/api/errors/not-implemented-error';
import { BaseFetchConfigT, FetchConfigT } from '../do-fetch-models';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import { logWarning } from 'common/utils/logger';

const delay = async (time: number) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(null);
        }, time);
    });
};

const CHECK_USER_TOKEN_TIMEOUT = 500;

export const throwNotImplementedError = (): TranziitApiResultT<null> => {
    return [new NotImplementedError(), null];
};

export type TranziitApiResultT<TSuccessResponseData, TErrorResponseData = any> = [
    TranziitApiRequestError | ApiRequestError<TErrorResponseData> | NotImplementedError | null,
    TSuccessResponseData | null,
];

abstract class BaseTranziitApi {
    private authApi: BaseSecurityAuthApi;

    private baseRequestConfig: BaseFetchConfigT;

    constructor(authApi: BaseSecurityAuthApi, baseRequestConfig: BaseFetchConfigT) {
        this.authApi = authApi;
        this.baseRequestConfig = baseRequestConfig;
    }

    async getHeaders(): Promise<Record<string, string>> {
        const authHeaders = await this.getAuthHeaders();

        return {
            'Content-Type': 'application/json',
            ...authHeaders,
        };
    }

    private checkIsValidConfig = (): boolean => {
        return isString(this.baseRequestConfig.basepath) && isNumber(this.baseRequestConfig.timeout);
    };

    private waitValidConfig = async (): Promise<void> => {
        while (!this.checkIsValidConfig()) {
            await delay(50);
        }
    };

    public setBasePath = (basepath: string): void => {
        this.baseRequestConfig.basepath = basepath;
    };

    async getAuthHeaders(): Promise<Record<string, string> | null> {
        try {
            let headers: Record<string, string> | null = null;

            // wait token
            while (!headers) {
                headers = await this.authApi.getAuthHeaders();
                if (!headers) {
                    await delay(CHECK_USER_TOKEN_TIMEOUT);
                }
            }

            return headers;
        } catch (error) {
            logWarning('Failed to getAuthHeaders', error);
            return {};
        }
    }

    async getAuthToken(): Promise<string | null> {
        try {
            let token: string | null = null;

            // wait token
            while (!token) {
                token = await this.authApi.getAuthToken();
                if (!token) {
                    await delay(CHECK_USER_TOKEN_TIMEOUT);
                }
            }

            return token;
        } catch (error) {
            logWarning('Failed to getAuthHeaders', error);
            return null;
        }
    }

    async doFetch<D, E = any>(requestConfig: FetchConfigT): Promise<TranziitApiResultT<D>> {
        await this.waitValidConfig();

        const headers = await this.getHeaders();

        /*
        console.log("[DEBUG] request: ", JSON.stringify({
            ...this.baseRequestConfig,
            ...requestConfig,
            headers: {
                ...headers,
                ...requestConfig.headers,
            },
        }, null, 4));
        */

        const [error, result] = await doFetch<D, E>({
            ...this.baseRequestConfig,
            ...requestConfig,
            headers: {
                ...headers,
                ...requestConfig.headers,
            },
        });

        // console.log("[DEBUG] request error: ", error);

        if (error) {
            if (error.type === ApiRequestErrorTypeEnum.badRequest) {
                const subTypes = Object.values(TranziitApiRequestErrorSubTypeEnum);
                for (let i = 0; i < subTypes.length; i += 1) {
                    const subType = subTypes[i];

                    const parseError = parseErrors[subType];
                    if (parseError && parseError(error)) {
                        const apiError = new TranziitApiRequestError(error, subType);
                        return [apiError, null];
                    }
                }
            }

            return [error, null];
        }

        return [null, result];
    }
}

export default BaseTranziitApi;

import * as React from 'react';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import { Trans, useTranslation } from 'react-i18next';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { useSelector } from 'react-redux';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import { TruckStatusEnum } from 'common/utils/api/models';
import { LINK_T, LinkValuesEnum } from 'common/components/radio-groups/AssetLinkRadioGroup/constants';
import { GPS_TRACKED_T, GPSTrackedValuesEnum } from 'common/components/radio-groups/GPSTrackerRadioGroup/constants';
import { DEDICATION_T, DedicationValuesEnum } from 'common/components/radio-groups/AssetDedicationRadioGroup/constants';
import { TRUCK_STATUS_T_MAP } from 'common/components/status/TruckStatus/TruckStatus';
import TruckModelFilterPill from 'common/components/filter-pills/TruckModelFilterPill/TruckModelFilterPill';
import { EMISSION_STANDART_MAP } from 'common/components/EmissionStandartLabel/EmissionStandartLabel';
import { EmissionStandartEnum } from 'common/constants';
import { formatDateRangeFilterValue } from 'common/components/FilterPills/pill-renderers';

type PropsT = {
    isCompact?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, setQueryFilters } = props;

    const { t } = useTranslation();

    const dictTrailerById = useSelector(selectTrailersDictById);

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const selectedTruckModel = queryFilters[QueryFiltersKeysEnum.model];

                    return (
                        <Trans
                            i18nKey="common:trucks-page.filters.pills.truck-model"
                            components={{
                                value: <TruckModelFilterPill truckModel={selectedTruckModel} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.model],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('common:trucks-page.filters.pills.plate-number', {
                        value: queryFilters[QueryFiltersKeysEnum.plateNumber],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.plateNumber],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const emissionClass = queryFilters[QueryFiltersKeysEnum.emissionClass];

                    return t('common:trucks-page.filters.pills.emission-class', {
                        value: EMISSION_STANDART_MAP[emissionClass as EmissionStandartEnum],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.emissionClass],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const status = queryFilters[QueryFiltersKeysEnum.status];

                    return t('common:trucks-page.filters.pills.status', {
                        value: t(TRUCK_STATUS_T_MAP[status as TruckStatusEnum]),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.status],
            },
            {
                theme: FilterPillThemeEnum.brandDark,
                render: (queryFilters) => {
                    const link = queryFilters[QueryFiltersKeysEnum.link];

                    return t(LINK_T[link as LinkValuesEnum]);
                },
                clearKeys: [QueryFiltersKeysEnum.link],
            },
            {
                theme: FilterPillThemeEnum.brandDark,
                render: (queryFilters) => {
                    const gpsTracker = queryFilters[QueryFiltersKeysEnum.GPSTracker];

                    return t(GPS_TRACKED_T[gpsTracker as GPSTrackedValuesEnum]);
                },
                clearKeys: [QueryFiltersKeysEnum.GPSTracker],
            },
            {
                theme: FilterPillThemeEnum.brandDark,
                render: (queryFilters) => {
                    const dedication = queryFilters[QueryFiltersKeysEnum.dedication];

                    return t(DEDICATION_T[dedication as DedicationValuesEnum]);
                },
                clearKeys: [QueryFiltersKeysEnum.dedication],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const createdDateFrom = queryFilters[QueryFiltersKeysEnum.createdDateFrom];
                    const createdDateTo = queryFilters[QueryFiltersKeysEnum.createdDateTo];

                    return t('common:trucks-page.filters.pills.added-dates', {
                        value: formatDateRangeFilterValue(createdDateFrom, createdDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdDateFrom, QueryFiltersKeysEnum.createdDateTo],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const lastModifyDateFrom = queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom];
                    const lastModifyDateTo = queryFilters[QueryFiltersKeysEnum.lastModifyDateTo];

                    return t('common:trucks-page.filters.pills.last-modify-dates', {
                        value: formatDateRangeFilterValue(lastModifyDateFrom, lastModifyDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.lastModifyDateFrom, QueryFiltersKeysEnum.lastModifyDateTo],
            },
        ],
        [t, dictTrailerById],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
        />
    );
});

export default SelectedFilters;

import * as React from 'react';
import { useMemo, useRef } from 'react';

import scrollIntoView from 'scroll-into-view';

import MultiPointOrderDraftDetails, {
    MultiPointOrderDraftDetailsPropsT,
} from '../OrderDraftDetails/MultiPointOrderDraftDetails';
import OfferDetails from '../OfferDetails/OfferDetails';

import classNames from 'classnames/bind';
import styles from './OffersForm.scss';
import { Trans, useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { ensureCreatedRFQ, selectOffer } from 'common/store/order-creation/actions';
import {
    selectCreateRFQRequest,
    selectIsPartialLoadingOffers,
    selectPriceOffersByHash,
    selectPriceOffersById,
    selectRFQ,
} from 'common/store/order-creation/selectors';
import { PriceOfferT } from 'common/store/order-creation/models';
import useQuery from 'common/utils/hooks/useQuery';
import { CurrencyEnum, OMSRoutesEnum, QueryKeysEnum } from 'common/constants';
import getDatesHash from 'common/store/order-creation/utils/get-dates-hash';
import history from 'common/utils/history';
import Money from 'common/components/Money/Money';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import AdditionalServices from './AdditionalServices/AdditionalServices';
import ProgressBar from './ProgressBar/ProgressBar';
import QuickActions from './QuickActions/QuickActions';
import { formatQuery } from 'common/utils/query';
import { summaryAdditionalServicesPrice } from 'common/layouts/NewOrderPage/additional-services';
import HeaderSideBarPortal from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarPortal/HeaderSideBarPortal';
import SelectedOfferHeader from 'common/layouts/NewOrderPage/OffersForm/SelectedOfferHeader/SelectedOfferHeader';
import { moneyOperations } from 'common/utils/money';
import { ApiAdditionalServiceEnum } from 'common/utils/api/models';
import values from 'lodash/values';
import { fetchAdditionalServices } from 'common/store/additional-services-dict/actions';
import { selectAdditionalServicesById } from 'common/store/additional-services-dict/selectors';
import { WAIT_RFQ_ID } from 'common/store/order-creation/constants';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import FooterSideBarAlertsLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarAlertsLayout/FooterSideBarAlertsLayout';

const cx = classNames.bind(styles);

type PropsT = {};

type QueryT = {
    [QueryKeysEnum.orderCreationPriceOfferId]: string;
    [QueryKeysEnum.orderCreationDropOffDate]: string;
    [QueryKeysEnum.orderCreationPickUpDate]: string;
    [QueryKeysEnum.orderCreationAdditionalServices]: string[];
};

const OffersForm: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    const ref = React.createRef<HTMLDivElement>();

    const query = useQuery<QueryT>();
    const params = useParams<{ rfqId: string }>();
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(ensureCreatedRFQ(params.rfqId));
        dispatch(fetchAdditionalServices());
        dispatch(fetchTrailersDict());
    }, []);

    const additionalServiceRefs = useRef<Partial<Record<ApiAdditionalServiceEnum, React.RefObject<HTMLDivElement>>>>(
        values(ApiAdditionalServiceEnum).reduce<
            Partial<Record<ApiAdditionalServiceEnum, React.RefObject<HTMLDivElement>>>
        >((acc, additionalService) => {
            return {
                ...acc,
                [additionalService]: React.createRef<HTMLDivElement>(),
            };
        }, {}),
    );

    const createRFQRequestStatus = useSelector(selectCreateRFQRequest);
    const rfq = useSelector(selectRFQ);
    const priceOffersByHash = useSelector(selectPriceOffersByHash);
    const trailersDictById = useSelector(selectTrailersDictById);

    const trailerType = trailersDictById[String(rfq?.trailerTypeId)];

    const pickUpDate = query[QueryKeysEnum.orderCreationPickUpDate];
    const dropOffDate = query[QueryKeysEnum.orderCreationDropOffDate];
    const hash = getDatesHash(pickUpDate, dropOffDate);
    const priceOffers = priceOffersByHash[hash] || [];

    const priceOffersById = useSelector(selectPriceOffersById);
    const selectedOfferId = query[QueryKeysEnum.orderCreationPriceOfferId];
    const selectedOffer = priceOffersById[selectedOfferId || ''] || null;

    const scrollToSelectedOffer = React.useCallback((): void => {
        if (!ref.current) {
            return;
        }

        scrollIntoView(ref.current, {
            time: 300,
        });
    }, [ref.current]);

    React.useEffect((): void => {
        scrollToSelectedOffer();
    }, [pickUpDate, dropOffDate]);

    const handleSelectPriceOffer = (offer: PriceOfferT) => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.orderCreationPriceOfferId]: offer.id,
            }),
        });
    };

    React.useEffect(() => {
        if (priceOffers?.length && !selectedOffer) {
            const firstPriceOffer = priceOffers[0];
            handleSelectPriceOffer(firstPriceOffer);
        }
    }, [priceOffers, selectedOffer]);

    const acceptPriceOffer = () => {
        dispatch(selectOffer(params.rfqId, selectedOffer));
    };

    const selectedServicesIds = React.useMemo(() => {
        return (query[QueryKeysEnum.orderCreationAdditionalServices] || []).map((id) => +id);
    }, [query]);

    const additionalServicesById = useSelector(selectAdditionalServicesById);
    const additionalServicesPrice = summaryAdditionalServicesPrice(
        selectedServicesIds,
        additionalServicesById,
        selectedOffer,
    );

    const setSelectedServices = React.useCallback(
        (selectedServicesIds: number[]) => {
            history.push({
                search: formatQuery({
                    ...query,
                    [QueryKeysEnum.orderCreationAdditionalServices]: selectedServicesIds,
                }),
            });
        },
        [query],
    );

    const isPartialLoadingOffers = useSelector(selectIsPartialLoadingOffers);
    // const isExactRequest =
    //     (isPickUpDateBased && selectedOffer?.pickUpDate === orderDraft?.pickUpDate) ||
    //     (!isPickUpDateBased && selectedOffer?.dropOffDate === orderDraft?.dropOffDate);

    const points = useMemo((): MultiPointOrderDraftDetailsPropsT['points'] => {
        if (rfq) {
            return rfq.points.map((point, index) => {
                return {
                    address: point.address,
                    driveThru: point.driveThru,
                    index,
                };
            });
        }

        return null;
    }, [rfq]);

    const totalAmount = moneyOperations.plusWithFormat(
        selectedOffer?.price || 0,
        additionalServicesPrice,
        CurrencyEnum.EUR,
    );

    const scrollToAdditionalServices = (): void => {
        const additionalServiceBoxNode = additionalServiceRefs?.current?.[ApiAdditionalServiceEnum.box]?.current;
        const additionalServiceTiltNode = additionalServiceRefs?.current?.[ApiAdditionalServiceEnum.tilt]?.current;
        const additionalServiceNode = additionalServiceBoxNode || additionalServiceTiltNode;
        if (!additionalServiceNode) {
            return;
        }

        scrollIntoView(additionalServiceNode, {
            time: 300,
        });
    };

    if (params.rfqId === WAIT_RFQ_ID && createRFQRequestStatus.initial) {
        return <Redirect to={OMSRoutesEnum.newOrder} />;
    }

    return (
        <div className={cx('inner')} data-test-selector="new-order-offers">
            <HeaderSideBarPortal>
                {selectedOffer && (
                    <SelectedOfferHeader
                        amount={totalAmount}
                        currency={CurrencyEnum.EUR}
                        onClickAmount={scrollToSelectedOffer}
                    />
                )}
            </HeaderSideBarPortal>
            <MultiPointOrderDraftDetails offer={selectedOffer} trailerType={trailerType} points={points} />
            {!'WAIT_EXPERIMENT_TZT-6839' && (
                <>
                    <QuickActions isPartialLoadingOffers={isPartialLoadingOffers} className={cx('quick-actions')} />
                    <ProgressBar className={cx('progress-bar')} />
                </>
            )}
            <div ref={ref}>
                <div className={cx('offers')}>
                    {priceOffers.map((priceOffer, priceOfferIndex) => {
                        const basePriceOfferIndex = priceOfferIndex === 0 ? 1 : 0;
                        const basePriceOffer = priceOffers[basePriceOfferIndex] || null;

                        return (
                            <OfferDetails
                                className={cx('offer')}
                                isSelectable
                                isSelected={priceOffer?.id === selectedOfferId}
                                key={priceOffer?.id || priceOfferIndex}
                                offer={priceOffer}
                                onSelect={handleSelectPriceOffer}
                                selectedServicesIds={selectedServicesIds}
                                scrollToAdditionalServices={scrollToAdditionalServices}
                            />
                        );
                    })}
                </div>
            </div>
            {selectedOffer && (
                <FooterSideBarLayout hasPaddings topNode={<FooterSideBarAlertsLayout />}>
                    <div className={cx('actions')}>
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            className={cx('actions__action', {
                                'actions__action--additional-services': true,
                            })}
                            type="button"
                            onClick={scrollToAdditionalServices}
                            testSelector="select-additional-services"
                        >
                            {t('common:new-order-offers.select-additional-services')}
                        </Button>
                        <Tooltip
                            className={cx('actions__action', {
                                'actions__action--submit': true,
                            })}
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={
                                selectedOffer?.isExpired ? (
                                    <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                        {t('common:new-order-offers.selected-offer-expired')}
                                    </TooltipContent>
                                ) : null
                            }
                        >
                            {() => (
                                <Button
                                    className={cx('inner-button')}
                                    theme={ButtonThemeEnum.primary}
                                    isDisabled={selectedOffer?.isExpired}
                                    type="button"
                                    onClick={acceptPriceOffer}
                                    testSelector="next-step"
                                >
                                    <Trans
                                        i18nKey="common:new-order-offers.accept-price"
                                        components={{
                                            amount: <Money amount={totalAmount} currency={CurrencyEnum.EUR} />,
                                        }}
                                    />
                                </Button>
                            )}
                        </Tooltip>
                    </div>
                </FooterSideBarLayout>
            )}
            <AdditionalServices
                serviceGroupRefs={additionalServiceRefs.current}
                trailerType={trailerType?.trailerType}
                selectedServicesIds={selectedServicesIds}
                setSelectedServices={setSelectedServices}
                selectedOffer={selectedOffer}
            />
        </div>
    );
});

export default OffersForm;

import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './MapStackClusterPin.scss';
import MapPin, { MapPinThemeEnum, MapPointPropsT } from 'common/components/maps/MapPin/MapPin';

const cx = classNames.bind(styles);

type PropsT = {
    lat?: number | undefined;
    lng?: number | undefined;
    className?: string;
    pointProps: Array<Partial<MapPointPropsT>>;
    clusterId: number;
    onClusterClick: (clusterId: number) => void;
    withoutArrow?: boolean;
};

const VERTICAL_SHIFT_PX = 10;
const BASE_HEIGHT_PX = 45;

const MapStackClusterPin: React.FC<PropsT> = React.memo((props) => {
    const { className, pointProps, clusterId, onClusterClick, withoutArrow } = props;

    if (!pointProps || !pointProps?.length) {
        return null;
    }

    return (
        <div
            className={cs(cx('wrap'), className)}
            style={{
                top: -1 * (BASE_HEIGHT_PX / 2 + (withoutArrow ? 0 : VERTICAL_SHIFT_PX * (pointProps?.length || 0))),
            }}
            onClick={() => {
                onClusterClick(clusterId);
            }}
        >
            {pointProps.map((props, index) => {
                const verticalIndex = pointProps.length - 1 - index;

                return (
                    <MapPin
                        hasPaddings
                        hasBorder
                        key={index}
                        className={cs(cx('pin'), props.className)}
                        withoutArrow={withoutArrow || verticalIndex !== 0}
                        style={{
                            top: -1 * VERTICAL_SHIFT_PX * verticalIndex,
                        }}
                        theme={MapPinThemeEnum.charcoal}
                        {...props}
                    />
                );
            })}
        </div>
    );
});

export default MapStackClusterPin;

import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './DriverAssignOptionLabel.scss';
import Avatar, { AvatarSizeEnum } from 'common/components/Avatar/Avatar';

const cx = classNames.bind(styles);

type PropsT = {
    driver: {
        name: string;
    };
};

const DriverAssignOptionLabel: React.FC<PropsT> = React.memo((props) => {
    const { driver } = props;

    return (
        <div className={cx('option')}>
            <div className={cx('column')}>
                <Avatar className={cx('avatar')} hash={driver.name || ''} size={AvatarSizeEnum.small} />
            </div>
            <div className={cx('column')}>{driver.name}</div>
        </div>
    );
});

export default DriverAssignOptionLabel;

import * as React from 'react';
import ActionOverlay from 'common/components/ActionOverlay/ActionOverlay';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';

type PropsT = {
    className?: string;
};

const LockStatusOnChangeForm: React.FC<PropsT> = React.memo((props) => {
    const { children, className } = props;

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);
    const hasFormChanges = !!remoteFormActionsContext?.remoteFormState?.hasChanges;

    return (
        <ActionOverlay isActive={hasFormChanges} className={className}>
            {children}
        </ActionOverlay>
    );
});

export default LockStatusOnChangeForm;

import React from 'react';
import classNames from 'classnames/bind';

import styles from './TrailerFiltersSidebarContent.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import DatePicker, { DatePickerOverlayPositionEnum } from 'design-system/components/date-pickers/DatePicker/DatePicker';
import getQueryFilters from './get-query-filters';
import getInitialValues from './get-initial-values';
import { FieldsEnum, FormValuesT } from './constants';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { isDeepEqual } from 'common/utils/deep-equal';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import DictTrailerDropdown from 'common/components/dropdowns/DictTrailerDropdown/DictTrailerDropdown';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import TrailerStatusDropdown from 'common/components/dropdowns/TrailerStatusDropdown/TrailerStatusDropdown';
import TeamMemberSuggest from 'common/components/suggests/TeamMemberSuggest/TeamMemberSuggest';
import AssetDedicationRadioGroup from 'common/components/radio-groups/AssetDedicationRadioGroup/AssetDedicationRadioGroup';
import GPSTrackerRadioGroup from 'common/components/radio-groups/GPSTrackerRadioGroup/GPSTrackerRadioGroup';
import { LinkValuesEnum } from 'common/components/radio-groups/AssetLinkRadioGroup/constants';
import { GPSTrackedValuesEnum } from 'common/components/radio-groups/GPSTrackerRadioGroup/constants';
import { DedicationValuesEnum } from 'common/components/radio-groups/AssetDedicationRadioGroup/constants';
import { clearEmptyValues, createJsonParams } from 'common/utils/query';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { CommonSidebarDataT, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { TrailerFiltersSidebarDataT } from './models';
import { useQueryParam } from 'use-query-params';
import { QueryFiltersT, QueryKeysEnum } from 'common/layouts/TrailersPageLayout/query-models';
import SelectedFilters from 'common/layouts/TrailersPageLayout/SelectedFilters/SelectedFilters';
import { TrailerStatusEnum } from 'common/utils/api/models';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<TrailerFiltersSidebarDataT, CommonSidebarDataT>;

const TRAILER_EXCLUDE_STATUSES: Array<TrailerStatusEnum> = [TrailerStatusEnum.archived];

const TrailerFiltersSidebarContent: React.FC<PropsT> = (props) => {
    const { onClose } = props;
    const { t } = useTranslation();

    const { partnerId } = usePartnerContext();

    const [queryFilters, setQueryFilters] = useQueryParam<QueryFiltersT>(
        QueryKeysEnum.trailersFilters,
        createJsonParams<QueryFiltersT>({}),
    );

    const [initialQueryFilters, setInitialQueryFilters] = React.useState<QueryFiltersT>(queryFilters);
    React.useEffect(() => {
        setInitialQueryFilters(queryFilters);
    }, [queryFilters]);

    const initialValues = React.useMemo(() => getInitialValues(queryFilters), [queryFilters]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialValues,
        onSubmit: (values, formikHelpers): void => {
            const queryFilters = getQueryFilters(values);

            clearEmptyValues(queryFilters);

            setQueryFilters({
                ...initialQueryFilters,
                ...queryFilters,
            });

            formikHelpers.setTouched({});

            if (onClose) {
                onClose();
            }
        },
    });

    const isSameValues = React.useMemo(() => {
        return isDeepEqual(initialValues, formik.values) && isDeepEqual(initialQueryFilters, queryFilters);
    }, [initialValues, formik.values, initialQueryFilters, queryFilters]);

    const handleLinkChange = (value: LinkValuesEnum | null): void => {
        formik.setFieldValue(FieldsEnum.link, value);
    };

    const handleGPSTrackerChange = (value: GPSTrackedValuesEnum | null): void => {
        formik.setFieldValue(FieldsEnum.GPSTracker, value);
    };

    const handleDedicationChange = (value: DedicationValuesEnum | null): void => {
        formik.setFieldValue(FieldsEnum.dedication, value);
    };

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={t('common:trailers-page.filters.title')} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FormikField
                    className={cx('field')}
                    name={FieldsEnum.dictTrailerTypeId}
                    error={formik.errors[FieldsEnum.dictTrailerTypeId]}
                    meta={formik.getFieldMeta(FieldsEnum.dictTrailerTypeId)}
                    label={t('common:trailers-page.filters.fields.trailer-type.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <DictTrailerDropdown
                            placeholder={t('common:trailers-page.filters.fields.trailer-type.placeholder')}
                            value={formik.values[FieldsEnum.dictTrailerTypeId]}
                            onChange={props.onChange}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasClearControl
                        />
                    )}
                </FormikField>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--plate-number')}
                        name={FieldsEnum.plateNumber}
                        label={t('common:trailers-page.filters.fields.plate-number.label')}
                        error={formik.errors[FieldsEnum.plateNumber]}
                        meta={formik.getFieldMeta(FieldsEnum.plateNumber)}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <Input
                                name={FieldsEnum.plateNumber}
                                value={formik.values[FieldsEnum.plateNumber]}
                                placeholder={t('common:trailers-page.filters.fields.plate-number.placeholder')}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--status')}
                        name={FieldsEnum.status}
                        error={formik.errors[FieldsEnum.status]}
                        meta={formik.getFieldMeta(FieldsEnum.status)}
                        label={t('common:trailers-page.filters.fields.status.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <TrailerStatusDropdown
                                excludeStatuses={TRAILER_EXCLUDE_STATUSES}
                                value={formik.values[FieldsEnum.status]}
                                placeholder={t('common:trailers-page.filters.fields.status.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                <FieldGroup>
                    {/* <AssetLinkRadioGroup */}
                    {/*    className={cx('field', 'field--link')} */}
                    {/*    value={formik.values[FieldsEnum.link]} */}
                    {/*    onChange={handleLinkChange} */}
                    {/* /> */}
                    <GPSTrackerRadioGroup
                        className={cx('field', 'field--gps-tracker')}
                        value={formik.values[FieldsEnum.GPSTracker]}
                        onChange={handleGPSTrackerChange}
                    />
                    <AssetDedicationRadioGroup
                        className={cx('field', 'field--dedication')}
                        value={formik.values[FieldsEnum.dedication]}
                        onChange={handleDedicationChange}
                    />
                </FieldGroup>
                <FieldGroup>
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.createDates}
                        error={formik.errors[FieldsEnum.createDates]}
                        meta={formik.getFieldMeta(FieldsEnum.createDates)}
                        label={t('common:trailers-page.filters.fields.creation-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.createDates]}
                                placeholder={t('common:trailers-page.filters.fields.creation-dates.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field', 'field--date')}
                        name={FieldsEnum.lastModifyDates}
                        error={formik.errors[FieldsEnum.lastModifyDates]}
                        meta={formik.getFieldMeta(FieldsEnum.lastModifyDates)}
                        label={t('common:trailers-page.filters.fields.last-modify-dates.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DatePicker
                                isRange
                                value={formik.values[FieldsEnum.lastModifyDates]}
                                placeholder={t('common:trailers-page.filters.fields.last-modify-dates.placeholder')}
                                onChange={props.onChange}
                                overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                hasClearControl
                            />
                        )}
                    </FormikField>
                </FieldGroup>
                {false && (
                    <FieldGroup>
                        <FormikField
                            className={cx('field', 'field--user')}
                            name={FieldsEnum.createdByUserId}
                            error={formik.errors[FieldsEnum.createdByUserId]}
                            meta={formik.getFieldMeta(FieldsEnum.createdByUserId)}
                            label={t('common:trailers-page.filters.fields.creation-user.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <TeamMemberSuggest
                                    companyId={partnerId}
                                    initialValue={formik.values[FieldsEnum.createdByUserId]}
                                    initialLabel={formik.values[FieldsEnum.createdByUserName]}
                                    value={formik.values[FieldsEnum.createdByUserId]}
                                    placeholder={t('common:trailers-page.filters.fields.creation-user.placeholder')}
                                    onSelect={(userId, userName) => {
                                        props.onChange(userId);
                                        formik.setFieldValue(FieldsEnum.createdByUserName, userName);
                                    }}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasClearControl
                                />
                            )}
                        </FormikField>
                        <FormikField
                            className={cx('field', 'field--user')}
                            name={FieldsEnum.lastModifyByUserId}
                            error={formik.errors[FieldsEnum.lastModifyByUserId]}
                            meta={formik.getFieldMeta(FieldsEnum.lastModifyByUserId)}
                            label={t('common:trailers-page.filters.fields.last-modify-user.label')}
                            setFieldValue={formik.setFieldValue}
                            setFieldTouched={formik.setFieldTouched}
                        >
                            {(props) => (
                                <TeamMemberSuggest
                                    companyId={partnerId}
                                    initialValue={formik.values[FieldsEnum.lastModifyByUserId]}
                                    initialLabel={formik.values[FieldsEnum.lastModifyByUserName]}
                                    value={formik.values[FieldsEnum.lastModifyByUserId]}
                                    placeholder={t('common:trailers-page.filters.fields.last-modify-user.placeholder')}
                                    onSelect={(userId, userName) => {
                                        props.onChange(userId);
                                        formik.setFieldValue(FieldsEnum.lastModifyByUserName, userName);
                                    }}
                                    hasError={props.hasError}
                                    hasWarning={props.hasWarning}
                                    onBlur={props.onBlur}
                                    onFocus={props.onFocus}
                                    hasClearControl
                                />
                            )}
                        </FormikField>
                    </FieldGroup>
                )}
            </SideBarLayout>
            <FooterSideBarLayout hasPaddings>
                <div className={cx('selected-filters')}>
                    <SelectedFilters queryFilters={initialQueryFilters} setQueryFilters={setInitialQueryFilters} />
                </div>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={isSameValues}
                    className={cx('button')}
                    type="submit"
                >
                    {t('common:trailers-page.filters.submit')}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
};

export default TrailerFiltersSidebarContent;

import { ApiRequestError } from '../../errors/api-request-error';

export enum TranziitApiRequestErrorSubTypeEnum {
    accessDeniedToApi = 'accessDeniedToApi',
    accessDeniedToEntity = 'accessDeniedToEntity',
    notFoundUser = 'notFoundUser',
    notFoundCompany = 'notFoundCompany',
    emailAlreadyExist = 'emailAlreadyExist',
    phoneAlreadyExist = 'phoneAlreadyExist',
    canNotDowngradeLastAdminInTeam = 'canNotDowngradeLastAdminInTeam',
    canNotDeleteHimself = 'canNotDeleteHimself',
    orderCreationExpiredRFQ = 'orderCreationExpiredRFQ',
    orderCreation_ExpiredPriceOffer = 'orderCreation_ExpiredPriceOffer',
    orderCreation_FailedCompleteRFQ_CapacityExcess = 'orderCreation_FailedCompleteRFQ_CapacityExcess',
    orderCreation_FailedCompleteRFQ_Overweight = 'orderCreation_FailedCompleteRFQ_Overweight',
    orderCreation_FailedPreviewRoute_RouteNotFound = 'orderCreation_FailedPreviewRoute_RouteNotFound',
    orderCreation_FailedPreviewRoute_RouteWaypointNotLinkable = 'orderCreation_FailedPreviewRoute_RouteWaypointNotLinkable',
    orderCreation_AlreadyCompletedRFQ = 'orderCreation_AlreadyCompletedRFQ',
    noRouteFound = 'noRouteFound',
    successorHasNotActiveStatus = 'successorHasNotActiveStatus',
    couldNotUnLockCompany = 'couldNotUnLockCompany',
    notAuthorizedUser = 'notAuthorizedUser',
    alreadyAssignedDrivers = 'already-assigned-drivers',
    firebaseTooManyResendInviteRequests = 'firebaseTooManyResendInviteRequests',
    expiredContextPreviewReserve = 'expiredContextPreviewReserve',
    failedArchiveInTransitDriver = 'failedArchiveInTransitDriver',
    assignmentSelfCostPrediction = 'assignmentSelfCostPrediction',
}

const tranziitApiRequestErrorSubTypeEnum = new Set(Object.values(TranziitApiRequestErrorSubTypeEnum));

export class TranziitApiRequestError<TResponseData = any, TRequestConfig = any> extends ApiRequestError<
    TResponseData,
    TRequestConfig
> {
    subType: TranziitApiRequestErrorSubTypeEnum;

    constructor(
        apiRequestError: ApiRequestError<TResponseData, TRequestConfig>,
        subType: TranziitApiRequestErrorSubTypeEnum,
    ) {
        super(apiRequestError, apiRequestError.type);

        this.subType = subType;

        // fix for instanceof
        // https://github.com/microsoft/TypeScript/issues/13965
        // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, TranziitApiRequestError.prototype);
    }
}

export const checkIsTranziitApiRequestError = (error: Error | null | undefined): error is TranziitApiRequestError => {
    const errorSubType = (error as TranziitApiRequestError)?.subType;

    return tranziitApiRequestErrorSubTypeEnum.has(errorSubType);
};

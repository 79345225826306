import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PriceMatrix.scss';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import Money from 'common/components/Money/Money';
import useQuery from 'common/utils/hooks/useQuery';
import {
    CurrencyEnum,
    DEFAULT_ICON_SIZE,
    EmissionClassEnum,
    QueryKeysEnum,
    StyleGuideColorsEnum,
} from 'common/constants';
import getDatesHash from 'common/store/order-creation/utils/get-dates-hash';
import history from 'common/utils/history';
import { useSelector } from 'react-redux';
import { selectIsPartialLoadingOffers, selectPriceOffersByHash } from 'common/store/order-creation/selectors';
import { PriceOfferT } from 'common/store/order-creation/models';
import { formatQuery } from 'common/utils/query';
import { byPriceAsc } from 'common/store/order-creation/utils/sort-offers';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import UrgentIcon from 'common/icons/UrgentIcon';
import ThumbUpIcon from 'common/icons/ThumbUpIcon';
import TeamDriveIcon from 'common/icons/TeamDriveIcon';
import { isNonNil } from 'common/utils';
import EmissionClassLabel from 'common/components/EmissionClassLabel/EmissionClassLabel';
import groupBy from 'lodash/groupBy';

const cx = classNames.bind(styles);

type PropsT = {};

type QueryT = {
    [QueryKeysEnum.orderCreationPriceOfferId]: string;
    [QueryKeysEnum.orderCreationDropOffDate]: string;
    [QueryKeysEnum.orderCreationPickUpDate]: string;
    [QueryKeysEnum.orderCreationDropOffDatesRange]: Array<string>;
    [QueryKeysEnum.orderCreationPickUpDatesRange]: Array<string>;
};

const EMISSION_CLASS_ORDER = [EmissionClassEnum.euro5, EmissionClassEnum.euro6] as const;

const PriceMatrix: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const query = useQuery<QueryT>();

    const selectedPickUpDate = query[QueryKeysEnum.orderCreationPickUpDate];
    const selectedDropOffDate = query[QueryKeysEnum.orderCreationDropOffDate];

    const selectDates = (pickUpDate: string, dropOffDate: string, priceOffer: PriceOfferT): void => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.orderCreationPickUpDate]: pickUpDate,
                [QueryKeysEnum.orderCreationDropOffDate]: dropOffDate,
                [QueryKeysEnum.orderCreationPriceOfferId]: priceOffer.id,
            }),
        });
    };

    const [activeDropOffDate, setActiveDropOffDate] = React.useState<string | null>(null);
    const [activePickUpDate, setActivePickUpDate] = React.useState<string | null>(null);

    const isPartialLoadingOffers = useSelector(selectIsPartialLoadingOffers);
    const offersByDatesHash = useSelector(selectPriceOffersByHash);

    const pickUpDates = query[QueryKeysEnum.orderCreationPickUpDatesRange] || [];
    const pickUpDatesHead = pickUpDates.map((pickUpDate) => {
        const momentDate = moment(pickUpDate);
        return (
            <div
                key={pickUpDate}
                className={cx('header', 'column', {
                    'column--isActive': pickUpDate === activePickUpDate,
                    'column--isSelected': pickUpDate === selectedPickUpDate,
                    'column--isHeader': true,
                    'header--isHorizontal': true,
                })}
            >
                <div>{momentDate.format('ddd')}</div>
                <div>{momentDate.format('D MMM')}</div>
            </div>
        );
    });

    const pickUpDatesHeadRow = (
        <div className={cx('row', 'row--isHorizontalHeader')}>
            {pickUpDatesHead}
            <div
                className={cx('header', 'column', {
                    'header--isHorizontal': true,
                    'header--isVertical': true,
                })}
            />
        </div>
    );

    const dropOffDates = query[QueryKeysEnum.orderCreationDropOffDatesRange] || [];
    const dropOffDatesHead = dropOffDates.map((dropOffDate) => {
        const momentDate = moment(dropOffDate);

        return (
            <div
                key={dropOffDate}
                className={cx('column', 'header', {
                    'column--isActive': dropOffDate === activeDropOffDate,
                    'column--isSelected': dropOffDate === selectedDropOffDate,
                    'column--isHeader': true,
                    'header--isVertical': true,
                })}
            >
                <div>{momentDate.format('ddd')}</div>
                <div>{momentDate.format('D MMM')}</div>
            </div>
        );
    });

    const isLoadingOffers = isPartialLoadingOffers;

    const isEmptyDates = !dropOffDates?.length || !pickUpDates?.length;

    return (
        <div className={cx('inner')}>
            {isEmptyDates && <LoaderOverlay />}
            <div
                className={cx('matrix')}
                onMouseLeave={() => {
                    setActiveDropOffDate(null);
                    setActivePickUpDate(null);
                }}
            >
                {pickUpDatesHeadRow}
                {dropOffDates.map((dropOffDate, dropOffDateIndex) => {
                    return (
                        <div className={cx('row')} key={dropOffDateIndex}>
                            {pickUpDates.map((pickUpDate, pickUpDateIndex) => {
                                const datesHash = getDatesHash(pickUpDate, dropOffDate);
                                const offers = offersByDatesHash[datesHash] || [];

                                const sortedOffers = [...offers].sort(byPriceAsc);

                                const groupedOffer = groupBy(sortedOffers, (offer) => offer?.emissionClass);

                                const cheapestSortedOffers = EMISSION_CLASS_ORDER.map((emissionClass) => {
                                    return (groupedOffer[emissionClass] || []).sort(byPriceAsc)?.[0];
                                }).filter(isNonNil);
                                const firstCheapestOffer = cheapestSortedOffers[0];

                                const isActive =
                                    (pickUpDate === activePickUpDate &&
                                        activeDropOffDate &&
                                        dropOffDate <= activeDropOffDate) ||
                                    (dropOffDate === activeDropOffDate &&
                                        activePickUpDate &&
                                        pickUpDate >= activePickUpDate);
                                const isActiveCell =
                                    pickUpDate === activePickUpDate && dropOffDate === activeDropOffDate;

                                const isSelected =
                                    (pickUpDate === selectedPickUpDate &&
                                        selectedDropOffDate &&
                                        dropOffDate <= selectedDropOffDate) ||
                                    (dropOffDate === selectedDropOffDate &&
                                        selectedPickUpDate &&
                                        pickUpDate >= selectedPickUpDate);
                                const isSelectedCell =
                                    pickUpDate === selectedPickUpDate && dropOffDate === selectedDropOffDate;

                                const isShowTooltip = !!firstCheapestOffer && isActiveCell;
                                const isFirstColumnDate = pickUpDateIndex === 0;
                                const isLastColumnDate = pickUpDateIndex === pickUpDates.length - 1;

                                const isShowLoader =
                                    isLoadingOffers &&
                                    !firstCheapestOffer &&
                                    pickUpDate <= dropOffDate &&
                                    pickUpDateIndex <= dropOffDateIndex;

                                const isAllOffersExpired = offers?.length && offers.every((offer) => offer?.isExpired);

                                return (
                                    <div
                                        key={datesHash}
                                        className={cx('column', {
                                            'column--isActive': isActive,
                                            'column--isActiveCell': isActiveCell,
                                            'column--isSelected': isSelected,
                                            'column--isSelectedCell': isSelectedCell,
                                            'column--isShowTooltip': isShowTooltip,
                                        })}
                                        onClick={() => {
                                            const firstNotExpiredCheapestOffer = offers.find((offer) => {
                                                return !offer.isExpired;
                                            });

                                            if (!firstNotExpiredCheapestOffer) {
                                                return;
                                            }

                                            selectDates(pickUpDate, dropOffDate, firstNotExpiredCheapestOffer);
                                        }}
                                        onMouseEnter={() => {
                                            setActiveDropOffDate(dropOffDate);
                                            setActivePickUpDate(pickUpDate);
                                        }}
                                    >
                                        {isShowTooltip && (
                                            <div
                                                className={cx('tooltip', {
                                                    'tooltip--isTopLeft': isFirstColumnDate,
                                                    'tooltip--isTopCenter': !isFirstColumnDate && !isLastColumnDate,
                                                    'tooltip--isTopRight': isLastColumnDate,
                                                })}
                                            >
                                                <div className={cx('tooltip__inner')}>
                                                    {isAllOffersExpired && (
                                                        <div key="expired" className={cx('tooltip__text')}>
                                                            {t('common:new-order-offers.price-matrix.tooltip.expired')}
                                                        </div>
                                                    )}
                                                    {cheapestSortedOffers.map((cheapestOffer) => {
                                                        if (cheapestOffer?.isExpired) {
                                                            return null;
                                                        }

                                                        return (
                                                            <div
                                                                key={cheapestOffer?.id}
                                                                className={cx('tooltip__text')}
                                                            >
                                                                <EmissionClassLabel
                                                                    emissionClass={cheapestOffer?.emissionClass}
                                                                />
                                                                {' - '}
                                                                <Money
                                                                    amount={cheapestOffer.price}
                                                                    currency={CurrencyEnum.EUR}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                        {isShowLoader ? (
                                            <div className={cx('loader')}>
                                                <ControlLoaderIcon
                                                    fillColor={StyleGuideColorsEnum.slate}
                                                    size={DEFAULT_ICON_SIZE}
                                                />
                                            </div>
                                        ) : (
                                            <div className={cx('cell')}>
                                                <div className={cx('cell__spacer', 'cell__spacer--2')} />
                                                <div className={cx('cell__content')}>
                                                    <div
                                                        className={cx('offer', {
                                                            'offer--clickable': !isAllOffersExpired,
                                                        })}
                                                    >
                                                        {firstCheapestOffer && (
                                                            <>
                                                                <div className={cx('offer__icons')}>
                                                                    {firstCheapestOffer?.isUrgentShipment && (
                                                                        <UrgentIcon
                                                                            size={DEFAULT_ICON_SIZE}
                                                                            fillColor={
                                                                                firstCheapestOffer?.isExpired
                                                                                    ? StyleGuideColorsEnum.slate
                                                                                    : StyleGuideColorsEnum.squash
                                                                            }
                                                                        />
                                                                    )}
                                                                    {firstCheapestOffer?.isTeamDrive && (
                                                                        <TeamDriveIcon
                                                                            size={DEFAULT_ICON_SIZE}
                                                                            fillColor={
                                                                                firstCheapestOffer?.isExpired
                                                                                    ? StyleGuideColorsEnum.slate
                                                                                    : StyleGuideColorsEnum.white
                                                                            }
                                                                            strokeColor={
                                                                                firstCheapestOffer?.isExpired
                                                                                    ? StyleGuideColorsEnum.slate
                                                                                    : StyleGuideColorsEnum.white
                                                                            }
                                                                        />
                                                                    )}
                                                                    {firstCheapestOffer?.isBestPrice && (
                                                                        <ThumbUpIcon
                                                                            size={DEFAULT_ICON_SIZE}
                                                                            fillOpacity={1}
                                                                            strokeColor={
                                                                                firstCheapestOffer?.isExpired
                                                                                    ? StyleGuideColorsEnum.slate
                                                                                    : StyleGuideColorsEnum.brandAccent
                                                                            }
                                                                            fillColor={
                                                                                firstCheapestOffer?.isExpired
                                                                                    ? StyleGuideColorsEnum.slate
                                                                                    : StyleGuideColorsEnum.brandAccent
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                                {cheapestSortedOffers.map((cheapestOffer, index) => {
                                                                    return (
                                                                        <div
                                                                            key={cheapestOffer?.id}
                                                                            className={cx('offer__price', {
                                                                                'offer__price--expired':
                                                                                    cheapestOffer.isExpired,
                                                                                'offer__price--green':
                                                                                    cheapestOffer.isGreenTruck,
                                                                                'offer__price--secondary': index !== 0,
                                                                            })}
                                                                        >
                                                                            {cheapestOffer && (
                                                                                <Money
                                                                                    amount={cheapestOffer.price}
                                                                                    currency={CurrencyEnum.EUR}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={cx('cell__spacer', 'cell__spacer--3')} />
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                            {dropOffDatesHead[dropOffDateIndex]}
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default PriceMatrix;

import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';
import { CommonChangeUserRoleFormPropsT } from 'common/layouts/Members/dialogs/ChangeUserRoleModal/models';
import { RolesEnum } from 'common/utils/api/models';

const REQUIRED_FIELDS = [
    FieldsEnum.role,
    FieldsEnum.newKeyAccountManagerUserId,
    FieldsEnum.newDispatcherUserId,
] as const;

const validate = (
    t: TFunction,
    values: FormValuesT,
    contact: CommonChangeUserRoleFormPropsT['contact'],
): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
    };

    const newKeyAccountManagerUserId = values[FieldsEnum.newKeyAccountManagerUserId];
    if (newKeyAccountManagerUserId && contact?.userId === newKeyAccountManagerUserId) {
        errors[FieldsEnum.newKeyAccountManagerUserId] = t('team-members.dialogs.change-admin-role.errors.same-user');
    }

    const newDispatcherUserId = values[FieldsEnum.newDispatcherUserId];
    if (newDispatcherUserId && contact?.userId === newDispatcherUserId) {
        errors[FieldsEnum.newDispatcherUserId] = t('team-members.dialogs.change-admin-role.errors.same-user');
    }

    const role = values[FieldsEnum.role];
    if (role && RolesEnum.companyAdmin === role) {
        errors[FieldsEnum.role] = t('team-members.dialogs.change-admin-role.errors.same-role');
    }

    return errors;
};

export default validate;

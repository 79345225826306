import { FormikHelpers, FormikState } from 'formik';
import { FieldsEnum, FormValuesT, RoutePointFieldsEnum } from '../constants';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { reserveQueryModifyChannel } from 'common/store/order-creation/channels';
import { useCallback } from 'react';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { checkIsSameTimezone } from '../utils';
import isBoolean from 'lodash/isBoolean';

type FormikT = FormikState<FormValuesT> & FormikHelpers<FormValuesT>;

export const useSyncReserveChannels = (formik: FormikT) => {
    // correction after reserve
    const reserveQueryModifyChannelHandler = useCallback(
        (event: InferChannelEventT<typeof reserveQueryModifyChannel>) => {
            const formValuesChanges: Partial<FormValuesT> = {};

            const routeFormValues = formik.values[FieldsEnum.route];
            const newRouteFormValues: typeof routeFormValues = [];

            routeFormValues.forEach((prevRouteFormValue, index) => {
                const newRouteFormValue: typeof prevRouteFormValue = {
                    ...prevRouteFormValue,
                };

                const eventCorrectedPoint = event?.correctedPoints?.[index] || null;
                if (eventCorrectedPoint?.replacedCoordinate) {
                    const prevLocation = prevRouteFormValue[RoutePointFieldsEnum.location];
                    const utcOffsetMinutes = prevLocation?.utcOffsetMinutes;

                    newRouteFormValue[RoutePointFieldsEnum.location] = {
                        ...prevLocation,
                        address: prevLocation?.address || null,
                        addressComponents: prevLocation?.addressComponents || null,
                        utcOffsetMinutes: isNumber(utcOffsetMinutes) ? utcOffsetMinutes : null,
                        point: eventCorrectedPoint?.replacedCoordinate
                            ? {
                                  lng: eventCorrectedPoint.replacedCoordinate.longitude,
                                  lat: eventCorrectedPoint.replacedCoordinate.latitude,
                              }
                            : null,
                    };
                }

                const prevTimeWindow = prevRouteFormValue[RoutePointFieldsEnum.timeWindow];
                if (eventCorrectedPoint?.defaultTimeWindow && !prevTimeWindow) {
                    newRouteFormValue[RoutePointFieldsEnum.timeWindow] = eventCorrectedPoint.defaultTimeWindow;
                }

                const anySelectedTime = prevTimeWindow?.start;
                if (
                    eventCorrectedPoint?.defaultTimeWindow?.start &&
                    anySelectedTime &&
                    !checkIsSameTimezone(eventCorrectedPoint.defaultTimeWindow.start, anySelectedTime)
                ) {
                    newRouteFormValue[RoutePointFieldsEnum.timeWindow] = eventCorrectedPoint.defaultTimeWindow;
                }

                const correctedTimeWindow = event?.correctedTimeWindows?.[index] || null;
                if (correctedTimeWindow) {
                    newRouteFormValue[RoutePointFieldsEnum.timeWindow] = correctedTimeWindow;
                }

                const correctedBrokerWindow = event?.correctedBrokerWindow?.[index];
                if (isBoolean(correctedBrokerWindow)) {
                    newRouteFormValue[RoutePointFieldsEnum.isBrokerTimeWindow] = correctedBrokerWindow;
                }

                newRouteFormValues.push(newRouteFormValue);
            });

            formValuesChanges[FieldsEnum.route] = newRouteFormValues;

            if ('correctedLaneId' in event) {
                formValuesChanges[FieldsEnum.laneId] = event.correctedLaneId || null;
            }

            if (!isEmpty(formValuesChanges)) {
                formik.setValues((prevValues) => ({
                    ...prevValues,
                    ...formValuesChanges,
                }));
            }
        },
        [formik.values, formik.setValues],
    );

    useChannelSubscribe(reserveQueryModifyChannel, reserveQueryModifyChannelHandler);
};

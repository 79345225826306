import * as React from 'react';
import { useMemo } from 'react';
import {
    DEFAULT_TIME_WINDOW_PICKER_SETTINGS,
    FieldsEnum,
    FormValuesT,
    RangeIdSetEnum,
    RoutePointFieldsEnum,
} from '../constants';
import { ReservePreviewT, ReserveT } from 'common/store/order-creation/models';
import {
    addToTimeMs,
    convertReserveTimeWindowToRelativeTimeWindow,
    createReserveTimeWindow,
    setDateForReserveTimeWindow,
    setTimeWindowForReserveTimeWindow,
    suggestLastTimeWindowOfDay,
} from 'common/layouts/NewOrderPage/ShipmentDetailsForm/utils';
import { useSelector } from 'react-redux';
import { ALL_DAY_RANGE, DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import {
    OnChangeRoutePointReserveT,
    TimeWindowPickerSettingsT,
} from 'common/layouts/NewOrderPage/ShipmentDetailsForm/models';
import { RangeSelectorMarkT, RangeSelectorRangeT } from 'common/components/RangeSelector/RangeSelector';
import { isNonNil } from 'common/utils';
import { clearDateTimezone, getMaxISODate, MS_IN_HOUR, MS_IN_MIN } from 'common/utils/time';
import { RangeSelectorZoneThemeEnum } from 'common/components/RangeSelector/constants';
import {
    selectMaxWindowSizeMs,
    selectMinWindowSizeMs,
    selectTimeSlotsDelegationLimitMs,
    selectTimeWindowQuantumMs,
} from 'common/store/settings/selectors';
import { useTranslation } from 'react-i18next';
import FormikConnectContextField from 'common/components/forms/FormikConnectContextField/FormikConnectContextField';
import GeoSuggest from 'common/components/GeoSuggest/GeoSuggest';
import noop from 'lodash/noop';
import TimeIntervalDurationPillLabel from 'common/layouts/NewOrderPage/ShipmentDetailsForm/TimeIntervalDurationPillLabel/TimeIntervalDurationPillLabel';
import QuickTimeWindowLink from 'common/layouts/NewOrderPage/ShipmentDetailsForm/QuickTimeWindowLink/QuickTimeWindowLink';
import Switcher, { SwitcherThemeEnum } from 'common/components/Switcher/Switcher';
import { useFormikContext } from 'formik';
import { getFieldFormValue } from 'common/utils/form-values';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './PickupOrDeliveryRoutePointPicker.scss';
import TimeWindowPicker from 'common/components/range-selectors/TimeWindowPicker/TimeWindowPicker';
import DeleteWaypointTrigger from 'common/layouts/NewOrderPage/ShipmentDetailsForm/DeleteWaypointTrigger/DeleteWaypointTrigger';
import WaypointNumberIcon from 'common/layouts/NewOrderPage/ShipmentDetailsForm/WaypointNumberIcon/WaypointNumberIcon';
import { SyncShipmentDetailsFormMapStateContext } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/contexts/sync-map-state';
import LinkDatePicker, {
    DatePickerOverlayPositionEnum,
} from 'design-system/components/date-pickers/LinkDatePicker/LinkDatePicker';
import { CalendarDateValueT } from 'design-system/components/Calendar/Calendar';
import moment from 'moment';
import { useSelectedLane } from 'common/layouts/NewOrderPage/ShipmentDetailsForm/hooks/use-selected-lane';
import isEqual from 'lodash/isEqual';
import CalendarAltIcon from 'common/icons/CalendarAltIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';
import TimeIntervalLimitPillLabel from 'common/layouts/NewOrderPage/ShipmentDetailsForm/TimeIntervalLimitPillLabel/TimeIntervalLimitPillLabel';
import TimeWindowZoneText from './TimeWindowZoneText/TimeWindowZoneText';
import TeamDriveIcon, { TeamDriveIconProps } from 'common/icons/TeamDriveIcon';
import UrgentIcon, { UrgentIconProps } from 'common/icons/UrgentIcon';
import HourglassIcon, { HourglassIconProps } from 'common/icons/HourglassIcon';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    label: React.ReactNode;
    routePointIndex: number;
    previewReserve: ReservePreviewT | null;
    reserve: ReserveT | null;
    onRemove: (index: number) => void;
    isAllowRemove: boolean;
    onManualSelectLocation: (location: LocationT | null, routePointIndex: number) => void;
    onChangeRoutePointReserve: OnChangeRoutePointReserveT;
    isDisabledPreviewReserve?: boolean;
    isShowSuggestLoading?: boolean;
};

const surchargeRangeTypeSet = new Set<string>([
    RangeIdSetEnum.urgentPickUp,
    RangeIdSetEnum.teamDriveDropOff,
    RangeIdSetEnum.layoverDropOff,
    RangeIdSetEnum.broker,
]);

const PickupOrDeliveryRoutePointPicker: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        routePointIndex,
        previewReserve,
        onRemove,
        reserve,
        isAllowRemove,
        label,
        onManualSelectLocation,
        onChangeRoutePointReserve,
        isDisabledPreviewReserve,
        isShowSuggestLoading,
    } = props;

    const { t } = useTranslation();

    const currentPointTimeWindow = previewReserve?.timeWindows?.[routePointIndex] || null;

    const syncShipmentDetailsFormMapStateContext = React.useContext(SyncShipmentDetailsFormMapStateContext);

    const formik = useFormikContext<FormValuesT>();

    const fakeIdFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.fakeId}` as const;
    const locationFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.location}` as const;
    const timeWindowFieldName = `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.timeWindow}` as const;
    const isBrokerTimeWindowFieldName =
        `${FieldsEnum.route}.${routePointIndex}.${RoutePointFieldsEnum.isBrokerTimeWindow}` as const;
    const selectedIsBrokerTimeWindow = getFieldFormValue(formik.values, isBrokerTimeWindowFieldName);
    const selectedTimeWindow = getFieldFormValue(formik.values, timeWindowFieldName);
    const fakeId = getFieldFormValue(formik.values, fakeIdFieldName);

    const timeWindowQuantumMs = useSelector(selectTimeWindowQuantumMs);

    const minWindowSizeMs = useSelector(selectMinWindowSizeMs);
    const maxWindowSizeMs = useSelector(selectMaxWindowSizeMs);

    const minRangeTimeWindow = minWindowSizeMs - MS_IN_MIN;
    const maxRangeTimeWindow = maxWindowSizeMs + MS_IN_MIN;

    const teamDriveMark = useMemo(
        () => ({
            borderTheme: RangeSelectorZoneThemeEnum.orange,
            icon: <TeamDriveIcon {...TeamDriveIconProps.getCreateOrder()} />,
        }),
        [],
    );

    const urgentPickUpMark = useMemo(
        () => ({
            borderTheme: RangeSelectorZoneThemeEnum.orange,
            icon: <UrgentIcon {...UrgentIconProps.getCreateOrder()} />,
        }),
        [],
    );

    const layoverMark = useMemo(
        () => ({
            borderTheme: RangeSelectorZoneThemeEnum.orange,
            icon: <HourglassIcon {...HourglassIconProps.getCreateOrder()} />,
        }),
        [],
    );

    const teamDriveDropOffRange = useMemo(
        () => ({
            id: RangeIdSetEnum.teamDriveDropOff,
            label: t('common:time-windows-types.team-drive'),
            theme: RangeSelectorZoneThemeEnum.orange,
        }),
        [t],
    );

    const regularDropOffRange = useMemo(
        () => ({
            id: RangeIdSetEnum.regularDropOff,
            theme: null,
        }),
        [t],
    );

    const brokerAppointmentDropOffRange = useMemo(
        () => ({
            id: RangeIdSetEnum.broker,
            theme: RangeSelectorZoneThemeEnum.orange,
            label: t('common:time-windows-types.broker-appointment'),
        }),
        [t],
    );

    const urgentPickUpRange = useMemo(
        () => ({
            id: RangeIdSetEnum.urgentPickUp,
            label: t('common:time-windows-types.urgent'),
            theme: RangeSelectorZoneThemeEnum.orange,
        }),
        [t],
    );

    const regularPickUpRange = useMemo(
        () => ({
            id: RangeIdSetEnum.regularPickUp,
            theme: null,
        }),
        [t],
    );

    const layoverDropOffRange = useMemo(
        () => ({
            id: RangeIdSetEnum.layoverDropOff,
            label: t('common:time-windows-types.layover'),
            theme: RangeSelectorZoneThemeEnum.orange,
        }),
        [t],
    );

    const brokerAppointmentPickUpRange = useMemo(
        () => ({
            id: RangeIdSetEnum.broker,
            theme: RangeSelectorZoneThemeEnum.orange,
            label: t('common:time-windows-types.broker-appointment'),
        }),
        [t],
    );

    const renderZoneTextNode = React.useCallback((currentRanges: RangeSelectorRangeT[] | null) => {
        const surchargeCurrentRanges = currentRanges?.filter((range) => {
            return range && surchargeRangeTypeSet.has(range.id);
        });

        if (!surchargeCurrentRanges?.length) {
            return null;
        }

        return <TimeWindowZoneText currentRanges={surchargeCurrentRanges} />;
    }, []);

    const settings = React.useMemo<TimeWindowPickerSettingsT>(() => {
        const isFirstTimeWindow = routePointIndex === 0;

        if (!previewReserve || !selectedTimeWindow) {
            return DEFAULT_TIME_WINDOW_PICKER_SETTINGS;
        }

        const baseDate = selectedTimeWindow.start;

        const availableValuesStart =
            currentPointTimeWindow?.urgentTimeWindow?.start || currentPointTimeWindow?.regularTimeWindow?.start;
        if (!availableValuesStart) {
            return DEFAULT_TIME_WINDOW_PICKER_SETTINGS;
        }

        const availableValuesEnd =
            currentPointTimeWindow?.layoverTimeWindow?.end || currentPointTimeWindow?.regularTimeWindow?.end || null;

        const availableValues = suggestLastTimeWindowOfDay(
            {
                start: availableValuesStart,
                end: availableValuesEnd,
            },
            baseDate,
        );

        const urgentTimeWindow = convertReserveTimeWindowToRelativeTimeWindow(
            suggestLastTimeWindowOfDay(currentPointTimeWindow?.urgentTimeWindow || null, baseDate),
        );

        const regularTimeWindow = convertReserveTimeWindowToRelativeTimeWindow(
            suggestLastTimeWindowOfDay(currentPointTimeWindow?.regularTimeWindow || null, baseDate),
        );

        const layoverTimeWindow = convertReserveTimeWindowToRelativeTimeWindow(
            suggestLastTimeWindowOfDay(currentPointTimeWindow?.layoverTimeWindow || null, baseDate),
        );

        const marks: Array<RangeSelectorMarkT> = [];

        if (layoverTimeWindow) {
            marks.push({
                ...layoverMark,
                value: layoverTimeWindow[0],
            });
        }

        const timeWindowRanges: RangeSelectorRangeT[] = [
            urgentTimeWindow
                ? {
                      ...(isFirstTimeWindow ? urgentPickUpRange : teamDriveDropOffRange),
                      values: urgentTimeWindow,
                      marks: [
                          {
                              ...(isFirstTimeWindow ? urgentPickUpMark : teamDriveMark),
                              value: urgentTimeWindow[0],
                          },
                      ],
                  }
                : null,
            regularTimeWindow
                ? {
                      ...(isFirstTimeWindow ? regularPickUpRange : regularDropOffRange),
                      values: regularTimeWindow,
                  }
                : null,
            layoverTimeWindow
                ? {
                      ...layoverDropOffRange,
                      values: layoverTimeWindow,
                      isHidden: true,
                      marks: [
                          {
                              ...layoverMark,
                              value: layoverTimeWindow[0],
                          },
                      ],
                  }
                : null,
        ].filter(isNonNil);

        const brokerTimeWindowRanges: RangeSelectorRangeT[] = [
            regularTimeWindow
                ? {
                      values: regularTimeWindow,
                      ...(isFirstTimeWindow ? brokerAppointmentPickUpRange : brokerAppointmentDropOffRange),
                  }
                : null,
        ].filter(isNonNil);

        const brokerTimeWindowHoursCount =
            [[regularTimeWindow?.[0] || ALL_DAY_RANGE[0], ALL_DAY_RANGE[1]]].reduce<number>(
                (acc, brokerTimeWindowRange) => {
                    return acc + (brokerTimeWindowRange[1] - brokerTimeWindowRange[0]);
                },
                0,
            ) / MS_IN_HOUR;

        const brokerAvailableValuesStart = currentPointTimeWindow?.regularTimeWindow?.start;
        const brokerAvailableValuesEnd = currentPointTimeWindow?.regularTimeWindow?.end || null;

        const brokerAvailableValues = brokerAvailableValuesStart
            ? suggestLastTimeWindowOfDay(
                  {
                      start: brokerAvailableValuesStart,
                      end: brokerAvailableValuesEnd,
                  },
                  baseDate,
              )
            : null;

        const settings: TimeWindowPickerSettingsT = {
            availableValues: convertReserveTimeWindowToRelativeTimeWindow(availableValues) || ALL_DAY_RANGE,
            brokerAvailableValues: convertReserveTimeWindowToRelativeTimeWindow(brokerAvailableValues) || ALL_DAY_RANGE,
            brokerTimeWindowRanges,
            timeWindowRanges,
            brokerTimeWindowHoursCount,
            marks,
        };

        return settings;
    }, [
        reserve,
        previewReserve,
        selectedTimeWindow,
        selectedIsBrokerTimeWindow,
        routePointIndex,
        teamDriveDropOffRange,
        regularDropOffRange,
        brokerAppointmentDropOffRange,
        urgentPickUpRange,
        regularPickUpRange,
        brokerAppointmentPickUpRange,
    ]);

    const relativeTimeWindow = useMemo((): TimeWindowT | null => {
        return convertReserveTimeWindowToRelativeTimeWindow(selectedTimeWindow);
    }, [selectedTimeWindow]);

    const handleChangeTimeWindow = (value: TimeWindowT) => {
        const newTimeWindow = setTimeWindowForReserveTimeWindow(value, selectedTimeWindow);

        formik.setFieldValue(timeWindowFieldName, newTimeWindow);

        onChangeRoutePointReserve(routePointIndex, {
            [RoutePointFieldsEnum.timeWindow]: newTimeWindow,
        });
    };

    const handlePickTargetTime = (start: ISODateTimeT | null | undefined) => {
        if (!start) {
            return;
        }

        const newTimeWindow = createReserveTimeWindow(start, minWindowSizeMs);

        formik.setFieldValue(timeWindowFieldName, newTimeWindow);

        onChangeRoutePointReserve(routePointIndex, {
            [RoutePointFieldsEnum.timeWindow]: newTimeWindow,
        });
    };

    const hasViolation = reserve?.points?.[routePointIndex]?.violated;

    const selectedDate = React.useMemo(() => {
        if (!selectedTimeWindow) {
            return null;
        }

        return moment(clearDateTimezone(selectedTimeWindow.start)).toDate();
    }, [selectedTimeWindow]);

    const timeSlotsDelegationLimitMs = useSelector(selectTimeSlotsDelegationLimitMs);

    const selectedLaneId = formik.values[FieldsEnum.laneId];
    const selectedLane = useSelectedLane(selectedLaneId);

    const allowedTimeWindowStart = useMemo(() => {
        if (selectedIsBrokerTimeWindow) {
            return addToTimeMs(currentPointTimeWindow?.regularTimeWindow?.start, timeSlotsDelegationLimitMs);
        }

        return currentPointTimeWindow?.urgentTimeWindow?.start || currentPointTimeWindow?.regularTimeWindow?.start;
    }, [timeSlotsDelegationLimitMs, selectedIsBrokerTimeWindow, previewReserve]);

    const minDate = getMaxISODate(selectedLane?.validFrom, allowedTimeWindowStart);
    const maxDate =
        (currentPointTimeWindow?.layoverTimeWindow
            ? currentPointTimeWindow?.layoverTimeWindow?.end
            : currentPointTimeWindow?.regularTimeWindow?.end) || undefined;

    const handleChangeDate = (date: CalendarDateValueT): void => {
        if (!date) {
            formik.setFieldValue(timeWindowFieldName, null);
            return;
        }

        let newTimeWindow = setDateForReserveTimeWindow(date, selectedTimeWindow);

        if (newTimeWindow && currentPointTimeWindow && selectedIsBrokerTimeWindow) {
            newTimeWindow = suggestLastTimeWindowOfDay(
                currentPointTimeWindow.regularTimeWindow
                    ? {
                          start: currentPointTimeWindow.regularTimeWindow.start,
                          end:
                              currentPointTimeWindow.layoverTimeWindow?.end ||
                              currentPointTimeWindow.regularTimeWindow.end,
                      }
                    : null,
                newTimeWindow.start,
            );
        }

        const isSameValue = isEqual(newTimeWindow, selectedTimeWindow);
        if (isSameValue) {
            return;
        }

        formik.setFieldValue(timeWindowFieldName, newTimeWindow);

        onChangeRoutePointReserve(routePointIndex, {
            [RoutePointFieldsEnum.timeWindow]: newTimeWindow,
        });
    };

    const isDisabledTimeSlots = isNumber(timeSlotsDelegationLimitMs)
        ? settings.brokerTimeWindowHoursCount * MS_IN_HOUR < timeSlotsDelegationLimitMs
        : false;

    return (
        <>
            <div className={cs(cx('time-picker'), className)}>
                <FormikConnectContextField<FormValuesT, typeof locationFieldName>
                    name={locationFieldName}
                    label={label}
                    fieldLabelClassName={cx('field', 'field--location')}
                    subLabel={
                        <>
                            <TimeIntervalDurationPillLabel
                                className={cx('time-interval', {
                                    'time-interval--isHalfOpacity': isDisabledPreviewReserve,
                                })}
                                i18nKey={selectedIsBrokerTimeWindow ? 'common:new-order-form.time-interval' : null}
                                isDayRelative
                                timeWindow={relativeTimeWindow}
                            />
                            <TimeIntervalLimitPillLabel
                                className={cx('time-interval', {
                                    'time-interval--isHalfOpacity': isDisabledPreviewReserve,
                                })}
                                timeWindow={relativeTimeWindow}
                            />
                        </>
                    }
                    warning={hasViolation ? t('common:new-order-form.warnings.check-route-points') : null}
                    labelRightNode={
                        <div className={cx('right-node')}>
                            <QuickTimeWindowLink
                                isDisabled={isDisabledPreviewReserve}
                                values={selectedIsBrokerTimeWindow ? null : relativeTimeWindow}
                                routePointIndex={routePointIndex}
                                ranges={settings?.timeWindowRanges || []}
                                reservePreview={previewReserve}
                                onClick={handlePickTargetTime}
                            />
                            {selectedDate && (
                                <LinkDatePicker
                                    leftIcon={
                                        <CalendarAltIcon
                                            size={DEFAULT_ICON_SIZE}
                                            strokeColor={StyleGuideColorsEnum.brandDark}
                                        />
                                    }
                                    isDisabled={isDisabledPreviewReserve}
                                    minDate={minDate || undefined}
                                    maxDate={maxDate}
                                    value={selectedDate}
                                    onChange={handleChangeDate}
                                    overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                />
                            )}
                        </div>
                    }
                >
                    {(props, extendsProps) => (
                        <div className={cx('wrap')} data-fake-id={fakeId}>
                            <GeoSuggest
                                className={cx('geo-suggest')}
                                hasTriangle
                                name={extendsProps.name}
                                value={extendsProps.value}
                                placeholder={t('common:new-order-form.fields.pickup-delivery-point.placeholder')}
                                renderLeftIcon={({ hasValue, hasError, hasWarning, isDisabled }) => (
                                    <WaypointNumberIcon
                                        routePointIndex={routePointIndex}
                                        hasValue={hasValue}
                                        hasError={hasError}
                                        hasWarning={hasWarning}
                                        isDisabled={isDisabled}
                                        isPickupOrDeliveryRoutePointPicker
                                    />
                                )}
                                onChange={(value) => {
                                    props.onChange(value);

                                    onManualSelectLocation(value, routePointIndex);

                                    onChangeRoutePointReserve(routePointIndex, {
                                        [RoutePointFieldsEnum.location]: value,
                                    });
                                }}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                onFocus={() => {
                                    props.onFocus();

                                    if (syncShipmentDetailsFormMapStateContext?.setEditingRoutePointIndex) {
                                        syncShipmentDetailsFormMapStateContext.setEditingRoutePointIndex(
                                            routePointIndex,
                                        );
                                    }
                                }}
                                onBlur={() => {
                                    props.onBlur();

                                    if (syncShipmentDetailsFormMapStateContext?.setEditingRoutePointIndex) {
                                        syncShipmentDetailsFormMapStateContext.setEditingRoutePointIndex(null);
                                    }
                                }}
                                testSelector={`pickup-or-delivery-location-${routePointIndex}`}
                                showClearControl={!!extendsProps.value}
                                isLoading={isShowSuggestLoading}
                                isRedTriangle={
                                    syncShipmentDetailsFormMapStateContext?.editingRoutePointIndex ===
                                        routePointIndex &&
                                    syncShipmentDetailsFormMapStateContext?.hasEditingRoutePointError
                                }
                                triangleClassName={cx('triangle', {
                                    'triangle--has-remove-control': isAllowRemove,
                                })}
                            />
                            {isAllowRemove && (
                                <DeleteWaypointTrigger
                                    onClick={() => {
                                        onRemove(routePointIndex);
                                    }}
                                />
                            )}
                        </div>
                    )}
                </FormikConnectContextField>
                <TimeWindowPicker
                    className={className}
                    range={ALL_DAY_RANGE}
                    isDisabled={isDisabledPreviewReserve}
                    isLocked={selectedIsBrokerTimeWindow}
                    availableValues={
                        selectedIsBrokerTimeWindow ? settings?.brokerAvailableValues : settings?.availableValues
                    }
                    ranges={
                        (selectedIsBrokerTimeWindow ? settings?.brokerTimeWindowRanges : settings?.timeWindowRanges) ||
                        []
                    }
                    marks={selectedIsBrokerTimeWindow ? [] : settings?.marks}
                    values={relativeTimeWindow}
                    step={timeWindowQuantumMs}
                    minRangeWidth={selectedIsBrokerTimeWindow ? undefined : minRangeTimeWindow}
                    maxRangeWidth={selectedIsBrokerTimeWindow ? undefined : maxRangeTimeWindow}
                    renderZoneTextNode={renderZoneTextNode}
                    onChange={selectedIsBrokerTimeWindow ? noop : handleChangeTimeWindow}
                />
                <FormikConnectContextField<FormValuesT, typeof isBrokerTimeWindowFieldName>
                    name={isBrokerTimeWindowFieldName}
                    withoutLabel
                >
                    {(props, extendsProps) => (
                        <Switcher
                            isDisabled={isDisabledTimeSlots || isDisabledPreviewReserve || !selectedTimeWindow}
                            checked={extendsProps.value}
                            theme={SwitcherThemeEnum.danger}
                            label={t('common:new-order-form.fields.is-broker-time-window.label')}
                            rightIcon={
                                isNumber(timeSlotsDelegationLimitMs) ? (
                                    <Tooltip
                                        position={TooltipPositionEnum.topCenter}
                                        theme={TooltipThemeEnum.black}
                                        tooltipNode={
                                            <TooltipContent theme={TooltipContentThemeEnum.black} width={160}>
                                                {t('common:new-order-form.fields.is-broker-time-window.tooltip', {
                                                    postProcess: 'interval',
                                                    count: Math.round(timeSlotsDelegationLimitMs / MS_IN_HOUR),
                                                })}
                                            </TooltipContent>
                                        }
                                    >
                                        {(isShow) => <TooltipIconTrigger isShow={isShow} />}
                                    </Tooltip>
                                ) : null
                            }
                            onToggle={() => {
                                const newValue = !extendsProps.value;

                                props.onChange(newValue);

                                onChangeRoutePointReserve(routePointIndex, {
                                    [RoutePointFieldsEnum.isBrokerTimeWindow]: newValue,
                                });
                            }}
                        />
                    )}
                </FormikConnectContextField>
            </div>
        </>
    );
});

export default PickupOrDeliveryRoutePointPicker;

import { TFunction } from 'i18next';
import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkMaxValue, checkMinValue, checkNotEmpty } from 'common/utils/form-validators';

const REQUIRED_FIELDS = [
    FieldsEnum.expiration,
    FieldsEnum.number,
    FieldsEnum.name,
    FieldsEnum.truckRate,
    FieldsEnum.tiltRate,
    FieldsEnum.boxRate,
    FieldsEnum.tiltExpectedMileage,
    FieldsEnum.boxExpectedMileage,
    FieldsEnum.truckExpectedMileage,
] as const;

const MILEAGE_FIELDS = [
    FieldsEnum.tiltExpectedMileage,
    FieldsEnum.boxExpectedMileage,
    FieldsEnum.truckExpectedMileage,
] as const;

const RATE_FIELDS = [FieldsEnum.truckRate, FieldsEnum.tiltRate, FieldsEnum.boxRate] as const;

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(t, REQUIRED_FIELDS, values),
        ...checkMinValue(t, RATE_FIELDS, values, 0),
        ...checkMaxValue(t, RATE_FIELDS, values, 2),
        ...checkMinValue(t, MILEAGE_FIELDS, values, 0),
        ...checkMaxValue(t, MILEAGE_FIELDS, values, 30000),
    };
    return errors;
};

export default validate;

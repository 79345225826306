import keyBy from 'lodash/keyBy';

import {
    CLEAR_OFFERS_TASKS,
    CORRECT_PREVIEW_RESERVE_QUERY,
    CORRECT_RESERVE_QUERY,
    CREATE_ORDER_REQUEST_BEGIN,
    CREATE_ORDER_REQUEST_ERROR,
    CREATE_ORDER_REQUEST_SUCCESS,
    CREATE_PRICE_OFFER_WITH_LANE_REQUEST_BEGIN,
    CREATE_PRICE_OFFER_WITH_LANE_REQUEST_ERROR,
    CREATE_PRICE_OFFER_WITH_LANE_REQUEST_SUCCESS,
    CREATE_RESERVE_BEGIN,
    CREATE_RESERVE_ERROR,
    CREATE_RESERVE_SUCCESS,
    CREATE_RFQ_REQUEST_BEGIN,
    CREATE_RFQ_REQUEST_ERROR,
    CREATE_RFQ_REQUEST_SUCCESS,
    FETCH_OFFERS,
    FETCH_ROUTE_SUCCESS,
    MARK_OFFERS_EXPIRED,
    OrderCreationActionT,
    OrderCreationStateT,
    PREVIEW_RESERVE_BEGIN,
    PREVIEW_RESERVE_ERROR,
    PREVIEW_RESERVE_SUCCESS,
    RESET,
    RESET_CREATE_RESERVE_WITH_QUERY,
    RESET_RESERVE_AND_RESERVE_PREVIEW,
    SET_ALLOW_CREATE_RFQ,
    SET_CREATE_RESERVE_QUERY,
    SET_EXPECTED_OFFERS_COUNT,
    SET_PREVIEW_RESERVE_QUERY,
} from './types';
import requestStatus from 'common/utils/request-status';
import groupOffersByDatesHash from './utils/group-offers-by-dates-hash';
import { byPriceAsc } from 'common/store/order-creation/utils/sort-offers';
import { PriceOfferT } from 'common/store/order-creation/models';
import uniqBy from 'lodash/uniqBy';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import { checkIsSameQuery } from 'common/utils/pagination/utils';
import { isNonNil } from 'common/utils';

const initialOffersTasks = {
    count: 0,
    total: null,
};

const initialState: OrderCreationStateT = {
    reserve: null,
    reserveRequest: requestStatus.initial,
    reserveQuery: null,

    previewReserve: null,
    previewReserveRequest: requestStatus.initial,
    previewReserveQuery: null,

    isAllowCreateRFQ: true,

    createRFQRequest: requestStatus.initial,
    createPriceOfferWithLaneRequest: requestStatus.initial,
    polylines: null,
    priceOffers: null,
    rfq: null,

    lanePriceOffer: null,

    createOrderRequest: requestStatus.initial,
    offersTasks: {
        ...initialOffersTasks,
    },
};

const markBestPriceOffers = (priceOffers?: Array<PriceOfferT>): void => {
    if (!priceOffers) {
        return;
    }

    const bestOffer = priceOffers.sort(byPriceAsc)[0];
    priceOffers.forEach((priceOffer) => {
        // eslint-disable-next-line no-param-reassign
        priceOffer.isBestPrice = bestOffer?.price === priceOffer?.price;
    });
};

export default (state = initialState, action: OrderCreationActionT | DestroySessionActionT): OrderCreationStateT => {
    switch (action.type) {
        case CREATE_PRICE_OFFER_WITH_LANE_REQUEST_BEGIN: {
            return {
                ...state,
                createPriceOfferWithLaneRequest: requestStatus.loading,
            };
        }

        case CREATE_PRICE_OFFER_WITH_LANE_REQUEST_SUCCESS: {
            return {
                ...state,
                rfq: action.rfq,
                lanePriceOffer: action.lanePriceOffer,
                createPriceOfferWithLaneRequest: requestStatus.ok,
            };
        }

        case CREATE_PRICE_OFFER_WITH_LANE_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                createPriceOfferWithLaneRequest: requestStatus.setError(error),
            };
        }

        case CREATE_RFQ_REQUEST_BEGIN: {
            return {
                ...state,
                createRFQRequest: requestStatus.loading,
            };
        }

        case CREATE_RFQ_REQUEST_SUCCESS: {
            const { rfq } = action;

            return {
                ...state,
                rfq,
                createRFQRequest: requestStatus.ok,
            };
        }

        case CREATE_RFQ_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                createRFQRequest: requestStatus.setError(error),
            };
        }

        case FETCH_ROUTE_SUCCESS: {
            const { polylines } = action;

            return {
                ...state,
                polylines,
            };
        }

        case SET_EXPECTED_OFFERS_COUNT: {
            const { expectedOffersCount } = action;

            return {
                ...state,
                offersTasks: {
                    ...state.offersTasks,
                    total: expectedOffersCount,
                },
            };
        }

        case FETCH_OFFERS: {
            const { priceOffers } = action;

            const currentPriceOffers = (state.priceOffers?.list || [])
                .map((id) => {
                    return state.priceOffers?.byId[id];
                })
                .filter(isNonNil);
            const fullListPriceOffers = uniqBy([...currentPriceOffers, ...priceOffers], (offer) => {
                return offer.id;
            });

            markBestPriceOffers(fullListPriceOffers);

            const priceOffersByHashDates = groupOffersByDatesHash(fullListPriceOffers);

            const newUniqOffersCount = fullListPriceOffers.length - (state.priceOffers?.list?.length || 0);

            return {
                ...state,
                priceOffers: {
                    byHash: {
                        ...(state.priceOffers?.byHash || {}),
                        ...priceOffersByHashDates,
                    },
                    byId: {
                        ...(state.priceOffers?.byId || {}),
                        ...keyBy(fullListPriceOffers, 'id'),
                    },
                    list: fullListPriceOffers.map((offer) => offer.id),
                },
                offersTasks: {
                    ...state.offersTasks,
                    count: state.offersTasks.count + newUniqOffersCount,
                },
            };
        }

        case MARK_OFFERS_EXPIRED: {
            const { ids } = action;

            if (!state.priceOffers) {
                return state;
            }

            const changesById = ids.reduce((acc, id) => {
                const priceOffer = state.priceOffers?.byId[id];

                if (priceOffer) {
                    acc[id] = {
                        ...priceOffer,
                        isExpired: true,
                    };
                }

                return acc;
            }, {} as Record<PriceOfferIdT, PriceOfferT>);

            return {
                ...state,
                priceOffers: {
                    ...state.priceOffers,
                    byId: {
                        ...state.priceOffers.byId,
                        ...changesById,
                    },
                },
            };
        }

        case CREATE_ORDER_REQUEST_BEGIN: {
            return {
                ...state,
                createOrderRequest: requestStatus.loading,
            };
        }

        case CREATE_ORDER_REQUEST_SUCCESS: {
            return {
                ...state,
                createOrderRequest: requestStatus.ok,
            };
        }

        case CREATE_ORDER_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                createOrderRequest: requestStatus.setError(error),
            };
        }

        case CLEAR_OFFERS_TASKS: {
            return {
                ...state,
                priceOffers: initialState.priceOffers,
                offersTasks: {
                    ...initialState.offersTasks,
                },
            };
        }

        case SET_CREATE_RESERVE_QUERY: {
            const { query } = action;

            return {
                ...state,
                reserveQuery: query,
                reserveRequest: requestStatus.initial,
            };
        }

        case CREATE_RESERVE_BEGIN: {
            const { query } = action;

            if (!checkIsSameQuery(query, state.reserveQuery)) {
                return state;
            }

            return {
                ...state,
                reserveRequest: requestStatus.loading,
            };
        }

        case CREATE_RESERVE_SUCCESS: {
            const { reserve, query } = action;

            if (!checkIsSameQuery(query, state.reserveQuery)) {
                return state;
            }

            return {
                ...state,
                reserve,
                reserveRequest: requestStatus.ok,
            };
        }

        case CREATE_RESERVE_ERROR: {
            const { error, query } = action;

            if (!checkIsSameQuery(query, state.reserveQuery)) {
                return state;
            }

            return {
                ...state,
                reserve: null,
                reserveRequest: requestStatus.setError(error),
            };
        }

        case RESET_CREATE_RESERVE_WITH_QUERY: {
            const { query } = action;

            if (!checkIsSameQuery(query, state.reserveQuery)) {
                return state;
            }

            return {
                ...state,
                reserve: null,
                reserveRequest: requestStatus.initial,
            };
        }

        case SET_ALLOW_CREATE_RFQ: {
            const { isAllowCreateRFQ } = action;

            return {
                ...state,
                isAllowCreateRFQ,
            };
        }

        case SET_PREVIEW_RESERVE_QUERY: {
            const { query } = action;

            return {
                ...state,
                previewReserveQuery: query,
                previewReserveRequest: requestStatus.initial,
            };
        }

        case CORRECT_PREVIEW_RESERVE_QUERY: {
            const { query } = action;

            return {
                ...state,
                previewReserveQuery: query,
            };
        }

        case CORRECT_RESERVE_QUERY: {
            const { query } = action;

            return {
                ...state,
                reserveQuery: query,
            };
        }

        case PREVIEW_RESERVE_BEGIN: {
            const { query } = action;

            if (!checkIsSameQuery(query, state.previewReserveQuery)) {
                return state;
            }

            return {
                ...state,
                previewReserveRequest: requestStatus.loading,
            };
        }

        case PREVIEW_RESERVE_SUCCESS: {
            const { preview, query } = action;

            if (!checkIsSameQuery(query, state.previewReserveQuery)) {
                return state;
            }

            return {
                ...state,
                previewReserve: preview,
                previewReserveRequest: requestStatus.ok,
            };
        }

        case PREVIEW_RESERVE_ERROR: {
            const { error, query } = action;

            if (!checkIsSameQuery(query, state.previewReserveQuery)) {
                return state;
            }

            return {
                ...state,
                previewReserve: null,
                previewReserveRequest: requestStatus.setError(error),
            };
        }

        case RESET_RESERVE_AND_RESERVE_PREVIEW: {
            return {
                ...state,
                reserve: null,
                reserveRequest: requestStatus.initial,
                previewReserve: null,
                previewReserveRequest: requestStatus.initial,
            };
        }

        case RESET: {
            return {
                ...state,
                ...initialState,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

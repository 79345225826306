import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './StatusFooterContent.scss';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import ChangeStatusDropdown from 'common/layouts/TransportOrderDetailsPageLayout/footers/StatusFooterContent/ChangeStatusDropdown/ChangeStatusDropdown';
import ManagementControl from 'common/layouts/TransportOrderDetailsPageLayout/footers/StatusFooterContent/ManagementControl/ManagementControl';
import { useTimeMark } from 'common/utils/hooks/useTimeMark';
import { TransportOrderStatusEnum } from 'common/utils/api/models';
import { checkIsCarrierTransportOrderDetails } from 'common/store/transport-order-details/type-guards';
import TimeLeft from 'common/layouts/TimeLeft/TimeLeft';
import isNumber from 'lodash/isNumber';
import { MS_IN_HOUR } from 'common/utils/time';
import { useTranslation } from 'react-i18next';
import AdditionalManagementControl from 'common/layouts/TransportOrderDetailsPageLayout/footers/StatusFooterContent/AdditionalManagementControl/AdditionalManagementControl';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import WarningIcon from 'common/icons/WarningIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    descriptor: StateMachineEntityDescriptorT;
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onUnassignedAssets: () => void;
    onStartAssignAssets: () => void;
    onUnassignedDrivers: () => void;
    onStartAssignDrivers: () => void;
};

const ASSIGNMENT_WARNING = 24 * MS_IN_HOUR;

const StatusFooterContent: React.FC<PropsT> = React.memo((props) => {
    const {
        descriptor,
        transportOrderDetails,
        transportOrderId,
        onUnassignedAssets,
        onStartAssignAssets,
        onUnassignedDrivers,
        onStartAssignDrivers,
    } = props;

    const { t } = useTranslation();

    const isAllowAssignAssets =
        !!transportOrderDetails &&
        checkIsCarrierTransportOrderDetails(transportOrderDetails) &&
        transportOrderDetails.availableToAssignAssets;
    const isAllowAssignDrivers =
        !!transportOrderDetails &&
        checkIsCarrierTransportOrderDetails(transportOrderDetails) &&
        transportOrderDetails.availableToAssignDriver;

    const deadline = transportOrderDetails?.waypoints[0]?.dateTimeFrom;
    const { diff, formattedDiff } = useTimeMark({
        timeMark: deadline,
    });

    const isAssetsUnassigned = transportOrderDetails?.status === TransportOrderStatusEnum.assetsUnassigned;
    const isDriverUnassigned = transportOrderDetails?.status === TransportOrderStatusEnum.driverUnassigned;
    const isDeadline = isNumber(diff) && diff <= 0;
    if (isDeadline && (isAssetsUnassigned || isDriverUnassigned)) {
        return (
            <Alert
                icon={<WarningIcon fillColor={StyleGuideColorsEnum.white} />}
                size={AlertSizeEnum.small}
                theme={AlertThemeEnum.tomatoRed}
            >
                {t('common:transport-order-details.tour-already-started')}
            </Alert>
        );
    }

    if (isAssetsUnassigned) {
        return (
            <div className={cx('actions')}>
                <TimeLeft
                    className={cx('time-left')}
                    isWarning={isNumber(diff) && diff <= ASSIGNMENT_WARNING}
                    isFailed={isNumber(diff) && diff <= 0}
                    failedMessage={t('common:transport-order-details.footer.tour-already-started')}
                    time={formattedDiff}
                    timeDescription={t('common:transport-order-details.footer.to-assign-assets')}
                />
                <Button
                    theme={ButtonThemeEnum.primary}
                    className={cx('action', 'action--assign')}
                    onClick={onStartAssignAssets}
                >
                    {t('common:transport-order-details.actions.assign-assets')}
                </Button>
                <AdditionalManagementControl
                    transportOrderDetails={transportOrderDetails}
                    isShowAssignDrivers
                    onStartAssignDrivers={onStartAssignDrivers}
                />
            </div>
        );
    }

    if (isDriverUnassigned) {
        return (
            <div className={cx('actions')}>
                <TimeLeft
                    className={cx('time-left')}
                    isWarning={isNumber(diff) && diff <= ASSIGNMENT_WARNING}
                    isFailed={isNumber(diff) && diff <= 0}
                    failedMessage={t('common:transport-order-details.footer.tour-already-started')}
                    time={formattedDiff}
                    timeDescription={t('common:transport-order-details.footer.to-assign-driver', {
                        postProcess: 'interval',
                        count: transportOrderDetails?.drivers?.length || 0,
                    })}
                />
                <ManagementControl
                    transportOrderDetails={transportOrderDetails}
                    isShowAssignAssets
                    isAllowAssignAssets={isAllowAssignAssets}
                    onUnassignedAssets={onUnassignedAssets}
                    onStartAssignAssets={onStartAssignAssets}
                    isShowAssignDrivers={false}
                    isAllowAssignDrivers={false}
                    onUnassignedDrivers={onUnassignedDrivers}
                    onStartAssignDrivers={onStartAssignDrivers}
                />
                <Button
                    theme={ButtonThemeEnum.primary}
                    className={cx('action', 'action--assign')}
                    onClick={onStartAssignDrivers}
                >
                    {t('common:transport-order-details.actions.assign-drivers', {
                        postProcess: 'interval',
                        count: transportOrderDetails?.drivers?.length || 0,
                    })}
                </Button>
            </div>
        );
    }

    return (
        <div className={cx('actions')}>
            {transportOrderDetails?.status === TransportOrderStatusEnum.assigned && (
                <TimeLeft
                    className={cx('time-left')}
                    isWarning={isNumber(diff) && diff <= ASSIGNMENT_WARNING}
                    isFailed={isNumber(diff) && diff <= 0}
                    failedMessage={t('common:transport-order-details.footer.tour-already-started')}
                    time={formattedDiff}
                    timeDescription={t('common:transport-order-details.footer.to-start-tour')}
                />
            )}
            <div className={cx('spacer')} />
            <ManagementControl
                transportOrderDetails={transportOrderDetails}
                isShowAssignAssets
                isAllowAssignAssets={isAllowAssignAssets}
                onUnassignedAssets={onUnassignedAssets}
                onStartAssignAssets={onStartAssignAssets}
                isShowAssignDrivers
                isAllowAssignDrivers={isAllowAssignDrivers}
                onUnassignedDrivers={onUnassignedDrivers}
                onStartAssignDrivers={onStartAssignDrivers}
            />
            <ChangeStatusDropdown
                className={cx('action', 'action--status-dropdown')}
                descriptor={descriptor}
                transportOrderId={transportOrderId}
            />
        </div>
    );
});

export default StatusFooterContent;

import React from 'react';

import classNames from 'classnames/bind';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import { PartnerTypeEnum, RolesEnum } from 'common/utils/api/models';

import styles from './UserRoleDropdown.scss';
import { useTranslation } from 'react-i18next';
import { USER_ROLE_T_MAP } from 'common/utils/users';
import { ALL_ROLES_BY_PARTNER_TYPE } from 'common/store/auth/constants';

type RoleOptionT = {
    label: string;
    value: RolesEnum | null;
};

const cx = classNames.bind(styles);

type ValueT = RolesEnum | null;

type PropsT = {
    value: ValueT;
    placeholder?: string;
    isDisabled?: boolean;
    onChange: (value: ValueT) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    availableRoles: Array<RolesEnum>;
    overlayPosition?: DropdownOverlayPositionEnum;
};

export const getAvailableRolesByPartnerType = (partnerType: PartnerTypeEnum): Array<RolesEnum> => {
    return ALL_ROLES_BY_PARTNER_TYPE[partnerType];
};

const UserRoleDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        placeholder,
        value,
        availableRoles,
        overlayPosition,
        onChange,
        isDisabled,
        hasWarning,
        hasError,
        hasChanges,
        onBlur,
        onFocus,
    } = props;

    const { t } = useTranslation();

    const renderOption = React.useCallback((option: RoleOptionT | null | undefined): React.ReactNode => {
        return option?.label;
    }, []);

    const getOptionValue = React.useCallback((option: RoleOptionT) => option?.value, []);

    const options = React.useMemo((): Array<RoleOptionT> => {
        return availableRoles.map((role) => {
            return {
                label: t(USER_ROLE_T_MAP[role]),
                value: role,
            };
        });
    }, [availableRoles, t]);

    const handleSelect = React.useCallback(
        (value: ValueT): void => {
            onChange(value);
        },
        [onChange],
    );

    return (
        <DropdownInput<RoleOptionT, RoleOptionT['value']>
            selectedValue={value}
            placeholder={placeholder}
            options={options}
            isDisabled={isDisabled}
            hasError={hasError}
            hasWarning={hasWarning}
            onSelect={handleSelect}
            renderOption={renderOption}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            onBlur={onBlur}
            onFocus={onFocus}
            hasChanges={hasChanges}
        />
    );
});

export default UserRoleDropdown;

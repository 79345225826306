import { RequestStatusT } from 'common/utils/request-status';
import { CloseCallbackOptionsT } from 'common/layouts/SideBars/models';
import useAfterSuccessRequest from 'common/utils/hooks/useAfterSuccessRequest';

type CloseCallbackT = (options?: CloseCallbackOptionsT) => void;

const useCloseSidebarAfterRequest = (
    requestStatus: RequestStatusT | null | undefined,
    closeCallback: CloseCallbackT | null | undefined,
) => {
    useAfterSuccessRequest(requestStatus, () => {
        if (closeCallback) {
            closeCallback({ needCloseConfirmation: false });
        }
    });
};

export default useCloseSidebarAfterRequest;

import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './WaypointNumberPinContent.scss';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    withPaddings?: boolean;
};

const WaypointNumberPinContent: React.FC<PropsT> = React.memo((props) => {
    const { className, children, withPaddings } = props;

    return (
        <div
            className={cs(
                cx('content', {
                    'content--withPaddings': withPaddings,
                }),
                className,
            )}
        >
            {children}
        </div>
    );
});

export default WaypointNumberPinContent;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import isNumber from 'lodash/isNumber';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './EmissionUnitValue.scss';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
    co2: number | null | undefined;
};

const EmissionUnitValue: React.FC<PropsT> = (props) => {
    const { className, co2 } = props;

    const { t } = useTranslation();

    if (!isNumber(co2)) {
        return null;
    }

    const roundedCO2 = Math.round(co2);

    return (
        <span className={cs(cx('value'), className)}>
            {roundedCO2}{' '}
            {t('common:unit-count.emission-co2', {
                postProcess: 'interval',
                count: roundedCO2,
            })}
        </span>
    );
};

export default EmissionUnitValue;

import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './TimeLeftForStart.scss';
import { MS_IN_HOUR } from 'common/utils/time';
import TimeLeft from 'common/layouts/TimeLeft/TimeLeft';
import isNumber from 'lodash/isNumber';
import { useTimeMark } from 'common/utils/hooks/useTimeMark';

const cx = classNames.bind(styles);

const ASSIGNMENT_WARNING = 24 * MS_IN_HOUR;

type PropsT = {
    className?: string;
    deadline: string | null | undefined;
    alreadyStartedLabel: React.ReactNode;
    deadlineLabel: React.ReactNode;
    renderActions: (isDisabled: boolean) => React.ReactNode;
    shouldDisableAfterDeadline?: boolean;
};

const TimeLeftForStart: React.FC<PropsT> = React.memo((props) => {
    const { deadline, alreadyStartedLabel, deadlineLabel, shouldDisableAfterDeadline, renderActions } = props;

    const { diff, formattedDiff } = useTimeMark({
        timeMark: deadline,
    });

    const isDisabled = !!shouldDisableAfterDeadline && diff !== null && diff <= 0;

    return (
        <div className={cx('content')}>
            <div className={cx('status-wrap')}>
                {diff !== null && (
                    <TimeLeft
                        isWarning={isNumber(diff) && diff <= ASSIGNMENT_WARNING}
                        isFailed={isNumber(diff) && diff <= 0}
                        failedMessage={alreadyStartedLabel}
                        time={formattedDiff}
                        timeDescription={deadlineLabel}
                    />
                )}
            </div>
            <div className={cx('actions')}>{renderActions(isDisabled)}</div>
        </div>
    );
});

export default TimeLeftForStart;

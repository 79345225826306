import * as React from 'react';
import { MapPinThemeEnum, MapPointPropsT } from 'common/components/maps/MapPin/MapPin';
import MapStackClusterPin from 'common/components/maps/MapStackClusterPin/MapStackClusterPin';
import { PointGeoJsonPropertiesT } from '../models';
import TrailerIcon from 'common/icons/TrailerIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import TruckIcon from 'common/icons/TruckIcon';
import { StopEnum } from 'common/utils/api/models';
import LinkDropOffIcon, { getLinkDropOffIconProps } from 'common/icons/LinkDropOffIcon';
import TruckDropOffIcon, { getTruckDropOffIconProps } from 'common/icons/TruckDropOffIcon';
import TrailerDropOffIcon, { getTrailerDropOffIconProps } from 'common/icons/TrailerDropOffIcon';
import LinkPickUpIcon, { getLinkPickUpIconProps } from 'common/icons/LinkPickUpIcon';
import TruckPickUpIcon, { getTruckPickUpIconProps } from 'common/icons/TruckPickUpIcon';
import TrailerPickUpIcon, { getTrailerPickUpIconProps } from 'common/icons/TrailerPickUpIcon';
import WaypointNumberPinContent from 'common/components/maps/pins/content/WaypointNumberPinContent/WaypointNumberPinContent';

type PropsT = {
    className?: string;
    lat?: number | undefined;
    lng?: number | undefined;
    clusterId: number;
    onClusterClick: (clusterId: number) => void;
    clusterProperties: Array<PointGeoJsonPropertiesT>;
    withoutArrow?: boolean;
};

const PointStackClusterPin: React.FC<PropsT> = React.memo((props) => {
    const { className, lat, lng, onClusterClick, clusterId, clusterProperties, withoutArrow } = props;

    const pointProps: Array<Partial<MapPointPropsT>> = [];

    clusterProperties.forEach((pointProperties: PointGeoJsonPropertiesT) => {
        if (pointProperties.type === 'trailer-last-point') {
            pointProps.push({
                theme: MapPinThemeEnum.charcoal,
                children: (
                    <TrailerIcon
                        fillColor={StyleGuideColorsEnum.brandDark}
                        strokeColor={StyleGuideColorsEnum.white}
                        size={DEFAULT_ICON_SIZE}
                    />
                ),
            });
        }

        if (pointProperties.type === 'truck-last-point') {
            pointProps.push({
                theme: MapPinThemeEnum.charcoal,
                children: (
                    <TruckIcon
                        fillColor={StyleGuideColorsEnum.brandDark}
                        strokeColor={StyleGuideColorsEnum.white}
                        size={DEFAULT_ICON_SIZE}
                    />
                ),
            });
        }

        if (pointProperties.type === 'waypoint-point') {
            const { waypoint } = pointProperties;

            switch (waypoint.type) {
                case StopEnum.pickupTruck: {
                    pointProps.push({
                        theme: MapPinThemeEnum.charcoal,
                        children: <TruckPickUpIcon {...getTruckPickUpIconProps('assignment-normal')} />,
                    });
                    break;
                }
                case StopEnum.pickupTrailer: {
                    pointProps.push({
                        theme: MapPinThemeEnum.charcoal,
                        children: <TrailerPickUpIcon {...getTrailerPickUpIconProps('assignment-normal')} />,
                    });
                    break;
                }
                case StopEnum.pickupRoadTrain: {
                    pointProps.push({
                        theme: MapPinThemeEnum.charcoal,
                        children: <LinkPickUpIcon {...getLinkPickUpIconProps('assignment-normal')} />,
                    });
                    break;
                }
                case StopEnum.driveThrough:
                case StopEnum.pickupDeliveryShipment: {
                    let theme = MapPinThemeEnum.charcoal;

                    if (waypoint.type === StopEnum.driveThrough) {
                        theme = MapPinThemeEnum.gray;
                    }

                    const isVisited = !!waypoint?.driverLeftTimeStamp;
                    if (isVisited) {
                        theme = MapPinThemeEnum.brandDark;
                    }

                    pointProps.push({
                        theme,
                        hasPaddings: false,
                        children: (
                            <WaypointNumberPinContent withPaddings key={`route-point-${waypoint.id}`}>
                                {(waypoint.index || 0) + 1}
                            </WaypointNumberPinContent>
                        ),
                    });
                    break;
                }
                case StopEnum.dropTrailer: {
                    pointProps.push({
                        theme: MapPinThemeEnum.charcoal,
                        children: <TrailerDropOffIcon {...getTrailerDropOffIconProps('assignment-normal')} />,
                    });
                    break;
                }
                case StopEnum.dropTruck: {
                    pointProps.push({
                        theme: MapPinThemeEnum.charcoal,
                        children: <TruckDropOffIcon {...getTruckDropOffIconProps('assignment-normal')} />,
                    });
                    break;
                }
                case StopEnum.dropRoadTrain: {
                    pointProps.push({
                        theme: MapPinThemeEnum.charcoal,
                        children: <LinkDropOffIcon {...getLinkDropOffIconProps('assignment-normal')} />,
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        }
    });

    if (!pointProps?.length) {
        return null;
    }

    return (
        <MapStackClusterPin
            className={className}
            lat={lat}
            lng={lng}
            pointProps={pointProps}
            clusterId={clusterId}
            onClusterClick={onClusterClick}
            withoutArrow={withoutArrow}
        />
    );
});

export default PointStackClusterPin;

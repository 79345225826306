import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AdditionalServicesGroup.scss';
import { alwaysDisabledAdditionalServiceIdsSet, CurrencyEnum, StyleGuideColorsEnum } from 'common/constants';
import { ApiAdditionalServiceEnum, ApiDictAdditionalServiceT, TruckTypesEnum } from 'common/utils/api/models';
import { PriceOfferT } from 'common/store/order-creation/models';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import Money from 'common/components/Money/Money';
import ExpandIcon from 'common/icons/ExpandIcon';
import { DictAdditionalServiceT } from 'common/store/additional-services-dict/models';
import AdditionalServiceLabel from 'common/components/AdditionalServiceLabel/AdditionalServiceLabel';

const cx = classNames.bind(styles);

type PropsT = {
    additionalServicesType: ApiAdditionalServiceEnum;
    additionalServices: ApiDictAdditionalServiceT[];
    selectedServicesSet: Set<AdditionalServiceIdT>;
    selectedServicesCount: number;
    selectedOffer?: PriceOfferT;
    onAddServices: (selectedService: AdditionalServiceIdT) => void;
    onRemoveServices: (selectedService: AdditionalServiceIdT) => void;
    serviceGroupRef?: React.RefObject<HTMLDivElement>;
};

const AdditionalServicesGroup: React.FC<PropsT> = React.memo((props) => {
    const {
        additionalServicesType,
        additionalServices,
        selectedServicesSet,
        selectedServicesCount,
        selectedOffer,
        onRemoveServices,
        onAddServices,
        serviceGroupRef,
    } = props;

    const [isExpanded, setIsExpanded] = React.useState(true);
    const toggle = (): void => {
        setIsExpanded(!isExpanded);
    };

    const testSelector = `additional-services_${additionalServicesType}`;

    return (
        <div
            className={cx('additional-services-group__block')}
            key={additionalServicesType}
            data-test-selector={testSelector}
            ref={serviceGroupRef}
        >
            <div className={cx('additional-services-group__head')}>
                <div className={cx('additional-services-group__trigger')} onClick={toggle}>
                    <div className={cx('additional-services-group__title')}>{additionalServicesType}</div>
                    <ExpandIcon
                        className={cx('additional-services-group__expand-icon')}
                        fillColor={StyleGuideColorsEnum.brandAccent}
                        isInvert={isExpanded}
                    />
                </div>
            </div>
            {isExpanded &&
                additionalServices.map((additionalService: DictAdditionalServiceT) => {
                    const isChecked = selectedServicesSet.has(additionalService.id);

                    const isDisabled = alwaysDisabledAdditionalServiceIdsSet.has(additionalService.id);

                    if (selectedOffer?.isGreenTruck && additionalService.truckType === TruckTypesEnum.regular) {
                        return null;
                    }

                    if (!selectedOffer?.isGreenTruck && additionalService.truckType === TruckTypesEnum.green) {
                        return null;
                    }

                    return (
                        <div key={additionalService.id} className={cx('additional-service')}>
                            <Checkbox
                                className={cx('additional-service__label')}
                                checked={isChecked}
                                isDisabled={isDisabled}
                                onChange={() => {
                                    if (isChecked) {
                                        onRemoveServices(additionalService.id);
                                    } else {
                                        onAddServices(additionalService.id);
                                    }
                                }}
                                testSelector={`${testSelector}_${additionalService.id}`}
                                label={
                                    <span className={cx('checkbox-label')}>
                                        <AdditionalServiceLabel enumType={additionalService.enum} />
                                    </span>
                                }
                            />
                            {!!additionalService.cost && (
                                <Money
                                    className={cx('additional-service__cost', {
                                        'additional-service__cost--isActive': isChecked,
                                    })}
                                    amount={additionalService.cost}
                                    currency={CurrencyEnum.EUR}
                                />
                            )}
                        </div>
                    );
                })}
        </div>
    );
});

export default AdditionalServicesGroup;

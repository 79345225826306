import { RequestStatusT } from 'common/utils/request-status';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';

type CallbackT = () => void;

const useAfterSuccessRequest = (
    requestStatus: RequestStatusT | null | undefined,
    callback: CallbackT | null | undefined,
) => {
    useOnlyChangesEffect(() => {
        if (requestStatus?.ok && callback) {
            callback();
        }
    }, [requestStatus]);
};

export default useAfterSuccessRequest;

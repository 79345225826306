import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './RunningOutOfTimeAssignCarrierCard.scss';
import { Trans } from 'react-i18next';
import SubjectLink from 'common/components/notification-cards/entities/SubjectLink/SubjectLink';
import CityName from 'common/components/notification-cards/entities/CityName/CityName';

const cx = classNames.bind(styles);

export type RunningOutOfTimeAssignDriversCardPropsT = {
    subjectNumber: React.ReactNode;
    originCity: React.ReactNode;
    destinationCity: React.ReactNode;
};

const RunningOutOfTimeAssignCarrierCard: React.FC<RunningOutOfTimeAssignDriversCardPropsT> = React.memo((props) => {
    const { subjectNumber, originCity, destinationCity } = props;

    return (
        <div className={cx('wrap')}>
            <Trans
                i18nKey="notifications.running-out-of-time-assign-carrier"
                components={{
                    subjectLink: <SubjectLink number={subjectNumber} />,
                    cityFrom: <CityName cityName={originCity} />,
                    cityTo: <CityName cityName={destinationCity} />,
                }}
            />
        </div>
    );
});

export default RunningOutOfTimeAssignCarrierCard;

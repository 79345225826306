import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillOpacity?: number;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
    size: number;
};

const TrailerIcon: React.FC<PropsT> = (props) => {
    const { size, className, fillColor, fillOpacity, strokeColor } = props;

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd">
                <path fill={fillColor} fillOpacity={fillOpacity || 1} d="M2 3h15v8l-1 1v2h-2l-3-2-3 2H2z" />
                <path
                    fill={strokeColor}
                    d="M11.182 13.462c1.45 0 2.625 1.197 2.625 2.671 0 1.474-1.175 2.67-2.625 2.67s-2.627-1.196-2.627-2.67c0-1.475 1.178-2.67 2.627-2.67zm0 1.5c-.636 0-1.153.527-1.153 1.171 0 .647.518 1.173 1.153 1.173.634 0 1.152-.528 1.152-1.173 0-.646-.518-1.17-1.152-1.17zm-3.212.778a3.196 3.196 0 00-.026.784H2v-.784h5.97zm9.478-.279v.783h-2.93a3.041 3.041 0 00-.028-.783h2.958zM18 2v10.235h-.844v3.004h-2.7l-.13-.084c-.442-1.314-1.669-2.265-3.109-2.265-1.572 0-2.888 1.129-3.21 2.628H2L1.999 14H6.5c.642-1.926 2.057-2.925 4.244-2.996L11 11h5V4H1.999L2 2h16zm-1.585 10.234h-.41v1.662h.41v-1.662z"
                />
            </g>
        </svg>
    );
};

TrailerIcon.displayName = 'TrailerIcon';

export const TrailerIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        isPressed,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        isPressed?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            size: DEFAULT_ICON_SIZE,
            fillColor:
                hasValue || isFocused || isPressed
                    ? StyleGuideColorsEnum.brandAccent
                    : StyleGuideColorsEnum.transparent,
            strokeColor:
                hasValue || isFocused || isPressed ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.light,
        };
    },
};

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default TrailerIcon;

/**
 *  @deprecated create/use ...Props class
 * */
export const getTrailerIconProps = (
    preset:
        | 'default'
        | 'selected'
        | 'assignment-events'
        | 'assignment-normal'
        | 'assignment-selected'
        | 'assignment-not-in-time-normal'
        | 'assignment-not-in-time-selected',
): PropsT => {
    switch (preset) {
        case 'selected': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.brandAccent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'assignment-normal': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.white,
            };
        }
        case 'assignment-events': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.white,
            };
        }
        case 'assignment-selected': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.brandAccent,
            };
        }
        case 'assignment-not-in-time-normal': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }
        case 'assignment-not-in-time-selected': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        default: {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.light,
            };
        }
    }
};

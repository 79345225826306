import * as React from 'react';
import Link, { LinkPropsT, LinkThemeEnum } from 'common/components/Link/Link';

type PropsT = Omit<LinkPropsT, 'theme' | 'to' | 'onClick'> & {
    to?: LinkPropsT['to'] | null;
};

const LinkIfNeedFormatter: React.FC<PropsT> = React.memo((props) => {
    const { children, className, to, ...rest } = props;

    if (!to) {
        return <span className={className}>{children}</span>;
    }

    return (
        <Link to={to} className={className} {...rest} theme={LinkThemeEnum.boldBrandDark}>
            {children}
        </Link>
    );
});

export default LinkIfNeedFormatter;

import React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';

import { TruckStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';
import { TRUCK_STATUS_T_MAP } from 'common/components/status/TruckStatus/TruckStatus';

type OptionValueT = TruckStatusEnum | null;

type PropsT = {
    placeholder?: string;
    value: OptionValueT;
    isDisabled?: boolean;
    onChange: (value: OptionValueT) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
    overlayPosition?: DropdownOverlayPositionEnum;
    excludeStatuses?: Array<TruckStatusEnum>;
};

type TruckStatusDropdownOptionT = {
    value: OptionValueT;
    label: React.ReactNode;
};

const TruckStatusDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        value,
        placeholder,
        onChange,
        isDisabled,
        hasWarning,
        hasError,
        hasChanges,
        onBlur,
        onFocus,
        hasClearControl,
        overlayPosition,
        excludeStatuses,
    } = props;

    const { t } = useTranslation();

    const options = React.useMemo((): TruckStatusDropdownOptionT[] => {
        return [TruckStatusEnum.free, TruckStatusEnum.inTransit, TruckStatusEnum.archived, TruckStatusEnum.unavailable]
            .filter((status) => {
                if (excludeStatuses) {
                    return !excludeStatuses.includes(status);
                }

                return true;
            })
            .map((status: TruckStatusEnum): TruckStatusDropdownOptionT => {
                return {
                    label: t(TRUCK_STATUS_T_MAP[status]),
                    value: status,
                };
            });
    }, [t]);

    const renderTrigger = (
        option: TruckStatusDropdownOptionT | null | undefined,
        placeholder?: string,
    ): React.ReactNode => {
        if (!option) {
            return placeholder || '';
        }

        return option.label;
    };

    const renderOption = (option: TruckStatusDropdownOptionT | null | undefined): React.ReactNode => {
        if (!option) {
            return null;
        }

        return option.label;
    };

    const handleSelect = (value: OptionValueT): void => {
        onChange(value);
    };

    const getOptionValue = (option: TruckStatusDropdownOptionT): OptionValueT => option.value;

    return (
        <DropdownInput<TruckStatusDropdownOptionT, OptionValueT>
            selectedValue={value}
            placeholder={placeholder}
            options={options}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            renderOption={renderOption}
            renderTriggerContent={renderTrigger}
            getOptionValue={getOptionValue}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            testSelector="truck-status"
            hasClearControl={hasClearControl}
            onReset={() => {
                onChange(null);
            }}
        />
    );
});

export default TruckStatusDropdown;

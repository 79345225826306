import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ShipmentDetailsCard.scss';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import Card from 'design-system/components/Card/Card';
import { useTranslation } from 'react-i18next';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import { formatTimeInterval } from 'common/utils/time';
import NumberIcon from 'common/icons/NumberIcon';
import ShipmentStatusPill from 'common/components/status-pill/ShipmentStatusPill/ShipmentStatusPill';
import CargoUnitIcon from 'common/icons/CargoUnitIcon';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import { getLoadUnitType } from 'common/utils/units';
import WeightIcon from 'common/icons/WeightIcon';
import SnowflakeIcon from 'common/icons/SnowflakeIcon';
import TemperatureModeFormatter from 'design-system/components/InfoTable/formatters/TemperatureModeFormatter/TemperatureModeFormatter';
import CommentIcon from 'common/icons/CommentIcon';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import ChangeShipmentStatusControl from './ChangeShipmentStatusControl/ChangeShipmentStatusControl';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { ApiShipmentStatusT, ShipmentStatusEnum } from 'common/utils/api/models';

export type PropsT = {
    shipmentNumber: number;
    isReeferTrailer: boolean;
    isCurrentLastNotCanceledShipment: boolean;
    transportOrderId: TransportOrderIdT | null;
    shipment: TransportOrderDetailsT['shipments'][number] | null;
    pickUpWaypoint: TransportOrderDetailsT['waypoints'][number] | null;
    dropOffWaypoint: TransportOrderDetailsT['waypoints'][number] | null;
    keyboardShortcut?: string;
    className?: string;
    isReadOnly: boolean;
};

const cx = classNames.bind(styles);

const hideLoaderStatusSet = new Set<ApiShipmentStatusT | null | undefined>([
    ShipmentStatusEnum.delivered,
    ShipmentStatusEnum.canceled,
]);

const ShipmentDetailsCard: React.FC<PropsT> = React.memo((props) => {
    const {
        shipmentNumber,
        shipment,
        isCurrentLastNotCanceledShipment,
        pickUpWaypoint,
        dropOffWaypoint,
        transportOrderId,
        isReeferTrailer,
        keyboardShortcut,
        className,
        isReadOnly,
    } = props;

    const { t } = useTranslation();

    const idDetails: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.shipment-id'),
            value: shipment?.number,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const poDetails: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.po-number'),
            value: shipment?.purchaseOrderNumber,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'po-number',
        },
    ];

    const originPointDetails: Array<InfoTableRowT> = [
        {
            icon: <NumberIcon number={(pickUpWaypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />,
            name: t('common:transport-order-details.shipment-details.columns.pickup'),
            value: <LocationLabel format="s1_s2_zip_city_country" location={pickUpWaypoint?.address} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.time-slot'),
            value: formatTimeInterval(pickUpWaypoint?.dateTimeFrom, pickUpWaypoint?.dateTimeTo),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.company-name'),
            value: pickUpWaypoint?.contact?.companyName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-company-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.contact-name'),
            value: pickUpWaypoint?.contact?.fullName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.contact-phone'),
            value: pickUpWaypoint?.contact?.phone,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.contact-email'),
            value: pickUpWaypoint?.contact?.email,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.comments'),
            value: pickUpWaypoint?.contact?.comment,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const destinationPointDetails: Array<InfoTableRowT> = [
        {
            icon: <NumberIcon number={(dropOffWaypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />,
            name: t('common:transport-order-details.shipment-details.columns.delivery'),
            value: <LocationLabel format="s1_s2_zip_city_country" location={dropOffWaypoint?.address} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.time-slot'),
            value: formatTimeInterval(dropOffWaypoint?.dateTimeFrom, dropOffWaypoint?.dateTimeTo),
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.company-name'),
            value: dropOffWaypoint?.contact?.companyName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            testSelector: 'contact-company-name',
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.contact-name'),
            value: dropOffWaypoint?.contact?.fullName,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.contact-phone'),
            value: dropOffWaypoint?.contact?.phone,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.contact-email'),
            value: dropOffWaypoint?.contact?.email,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
            hasBottomBorder: true,
        },
        {
            icon: null,
            name: t('common:transport-order-details.shipment-details.columns.comments'),
            value: dropOffWaypoint?.contact?.comment,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    const shipmentDetails: Array<InfoTableRowT | null> = [
        {
            icon: (
                <CargoUnitIcon
                    size={DEFAULT_ICON_SIZE}
                    fillColor={StyleGuideColorsEnum.light}
                    strokeColor={StyleGuideColorsEnum.gray}
                />
            ),
            name: t('common:transport-order-details.shipment-details.columns.units'),
            value: <UnitTypeCount type={getLoadUnitType(shipment?.unitsType)} count={shipment?.maxNumberOfUnits} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        {
            icon: <WeightIcon fillColor={StyleGuideColorsEnum.gray} />,
            name: t('common:transport-order-details.shipment-details.columns.weight'),
            value: <UnitTypeCount count={shipment?.weight} type={UnitTypeEnum.kilogramsAbbreviation} />,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
        isReeferTrailer
            ? {
                  icon: <SnowflakeIcon fillColor={StyleGuideColorsEnum.gray} />,
                  name: t('common:transport-order-details.shipment-details.columns.temperature-range'),
                  value: (
                      <TemperatureModeFormatter
                          lowestTemperature={shipment?.lowestTemperature}
                          highestTemperature={shipment?.highestTemperature}
                      />
                  ),
                  emptyValue: t('common:info-table.placeholders.not-specified'),
                  isBoldValue: true,
              }
            : null,
        {
            icon: <CommentIcon fillColor={StyleGuideColorsEnum.light} strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('common:transport-order-details.shipment-details.columns.description'),
            value: shipment?.description,
            emptyValue: t('common:info-table.placeholders.not-specified'),
            isBoldValue: true,
        },
    ];

    return (
        <>
            <Card
                titleNode={t('common:transport-order-details.shipment-details.title', {
                    number: shipmentNumber + 1,
                })}
                rightNode={
                    <>
                        <ShipmentStatusPill isSymmetrical status={shipment?.status} />
                        {!isReadOnly && (
                            <ChangeShipmentStatusControl
                                isCurrentLastNotCanceledShipment={isCurrentLastNotCanceledShipment}
                                isShowLoading={!!shipment && !hideLoaderStatusSet.has(shipment?.status)}
                                transportOrderId={transportOrderId}
                                shipmentId={shipment?.id || null}
                            />
                        )}
                    </>
                }
                className={className}
                keyboardShortcut={keyboardShortcut}
                hasHeaderBottomBorder
            >
                <div className={cx('content')}>
                    <InfoTable shouldRenderIcons rows={idDetails} className={cx('table', 'table--id')} />
                    <InfoTable shouldRenderIcons rows={poDetails} className={cx('table', 'table--po')} />
                    <InfoTable
                        shouldRenderIcons
                        isCollapsable
                        rows={originPointDetails}
                        className={cx('table', 'table--point')}
                    />
                    <InfoTable
                        shouldRenderIcons
                        isCollapsable
                        rows={destinationPointDetails}
                        className={cx('table', 'table--point')}
                    />
                    <InfoTable shouldRenderIcons rows={shipmentDetails} className={cx('table', 'table--shipment')} />
                </div>
            </Card>
        </>
    );
});

export default ShipmentDetailsCard;

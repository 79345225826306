import { put, select, takeEvery } from 'redux-saga/effects';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import {
    FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST,
    FetchGroupedNotificationCountsActionT,
} from 'common/store/grouped-notification-counts/types';
import {
    fetchGroupedNotificationCountsBegin,
    fetchGroupedNotificationCountsError,
    fetchGroupedNotificationCountsSuccess,
} from 'common/store/grouped-notification-counts/actions';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectGroupedNotificationCountsRequest } from 'common/store/grouped-notification-counts/selectors';

function* fetchGroupedNotificationCountsSaga(action: FetchGroupedNotificationCountsActionT): WrapGeneratorT<void> {
    const { options } = action;

    const requestStatus: ReturnType<typeof selectGroupedNotificationCountsRequest> = yield select(
        selectGroupedNotificationCountsRequest,
    );
    const isNeedRequest = checkNeedRequest(requestStatus, options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchGroupedNotificationCountsBegin());

    const [error, response]: ReturnApiT<typeof commonTranziitApi.fetchUnreadGroupedNotificationCounts> =
        yield commonTranziitApi.fetchUnreadGroupedNotificationCounts();

    if (error) {
        yield put(fetchGroupedNotificationCountsError(error));
        return;
    }

    yield put(fetchGroupedNotificationCountsSuccess(response?.byAction || {}));
}

function* groupedNotificationCountsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST, fetchGroupedNotificationCountsSaga);
}

export default groupedNotificationCountsSaga;

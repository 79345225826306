import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PartnerDetailsForm.scss';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import getInitialValues from './get-initial-values';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import Input from 'common/components/Input/Input';
import FormikField from 'common/components/forms/FormikField/FormikField';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import { PartnerDetailsT } from 'broker-admin/store/partner/details/models';
import { selectUpdatePartnerDetailsRequest } from 'broker-admin/store/partner/details/selectors';
import { updatePartnerDetails } from 'broker-admin/store/partner/details/actions';
import prepareApiPartnerDetails from './prepare-partner-details';
import { AddressTypeEnum, PartnerTypeEnum } from 'common/utils/api/models';
import LegalFormDropdown from 'common/components/dropdowns/LegalFormDropdown/LegalFormDropdown';
import { selectPermissions } from 'common/store/auth/selectors';
import Widget from 'common/components/Widget/Widget';
import KeyAccountManagerDropdown from 'broker-admin/components/dropdowns/KeyAccountManagerDropdown/KeyAccountManagerDropdown';
import DispatcherDropdown from 'broker-admin/components/dropdowns/DispatcherDropdown/DispatcherDropdown';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import HiddenSubmitButtonForKeyboardEnter from 'common/components/HiddenEnterSubmitButton/HiddenSubmitButtonForKeyboardEnter';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import BasicWidgetHeaderActions from '../BasicWidgetHeaderActions/BasicWidgetHeaderActions';
import AddressWidgetHeaderActions from '../AddressWidgetHeaderActions/AddressWidgetHeaderActions';
import { selectCountriesByCode } from 'common/store/countries-dict/selectors';
import { VerifyPartnerAdressConfirmationDataT } from '../dialogs/VerifyPartnerAdressConfirmation/models';
import { UnlockPartnerConfirmationDataT } from '../dialogs/UnlockPartnerConfirmation/models';
import { LockPartnerConfirmationDataT } from '../dialogs/LockPartnerConfirmation/models';
import { UnVerifyPartnerAdressConfirmationDataT } from '../dialogs/UnVerifyPartnerAdressConfirmation/models';
import RFQStats from 'broker-admin/layouts/PartnerPage/PartnerDetailsPage/RFQStats/RFQStats';
import { useCheckCountryCorrectLegacyForm } from 'common/components/dropdowns/LegalFormDropdown/hooks';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import CreatePartnerInfo from 'common/layouts/BaseProfileLayout/ProfileBasicInformation/CreatePartnerInfo/CreatePartnerInfo';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';
import { CountryCodeT } from 'common/store/countries-dict/models';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

const cx = classNames.bind(styles);

type PropsT = {
    partnerDetails: PartnerDetailsT | null;
    onVerifyPartner: (data: VerifyPartnerAdressConfirmationDataT | null) => void;
    onUnverifyPartner: (data: UnVerifyPartnerAdressConfirmationDataT | null) => void;
    onLockPartner: (data: LockPartnerConfirmationDataT | null) => void;
    onUnlockPartner: (data: UnlockPartnerConfirmationDataT | null) => void;
};

const legalAddressFields = [
    FieldsEnum.legalAddressCountryCode,
    FieldsEnum.legalAddressZipCode,
    FieldsEnum.legalAddressCity,
    FieldsEnum.legalAddressStreet1,
    FieldsEnum.legalAddressStreet2,
];
const useWatchLegalAddressChanges = createUseWatchAnyFieldValueChanges(legalAddressFields);

const postalAddressFields = [
    FieldsEnum.postalAddressCountryCode,
    FieldsEnum.postalAddressZipCode,
    FieldsEnum.postalAddressCity,
    FieldsEnum.postalAddressStreet1,
    FieldsEnum.postalAddressStreet2,
];
const useWatchPostalAddressChanges = createUseWatchAnyFieldValueChanges(postalAddressFields);

const billingAddressFields = [
    FieldsEnum.billingAddressCountryCode,
    FieldsEnum.billingAddressZipCode,
    FieldsEnum.billingAddressCity,
    FieldsEnum.billingAddressStreet1,
    FieldsEnum.billingAddressStreet2,
];
const useWatchBillingAddressChanges = createUseWatchAnyFieldValueChanges(billingAddressFields);

const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(Object.values(FieldsEnum));

const PartnerDetailsForm: React.FC<PropsT> = React.memo((props) => {
    const { partnerDetails, onVerifyPartner, onUnverifyPartner, onLockPartner, onUnlockPartner } = props;

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);

    const { partnerId, partnerType } = usePartnerContext();

    const permissions = useSelector(selectPermissions);

    const updateRequest = useSelector(selectUpdatePartnerDetailsRequest);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const countryByCode = useSelector(selectCountriesByCode);

    const shouldShowBillingInfo = partnerType === PartnerTypeEnum.shipper;

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, { shouldShowBillingInfo });
    }, [t, shouldShowBillingInfo]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(partnerDetails);
        const errors = validateForm(t, values, { shouldShowBillingInfo });

        return [values, errors];
    }, [partnerDetails, shouldShowBillingInfo]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const partnerDetailsChanges = prepareApiPartnerDetails(values);
            if (!partnerDetails?.id) {
                return;
            }

            dispatch(updatePartnerDetails(partnerDetails.id, partnerType, partnerDetailsChanges));
            formikHelpers.setTouched({});
        },
    });

    const handleSelectLegalForm = (legalFormId: number | null | undefined): void => {
        formik.setFieldValue(FieldsEnum.legalFormId, legalFormId);
    };

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormCallbacks) {
            remoteFormActionsContext.setRemoteFormCallbacks({
                submit: formik.submitForm,
                reset: formik.resetForm,
                touchAllFields: () => {
                    const allFields = Object.keys(FieldsEnum) as Array<FieldsEnum>;

                    const touched = allFields.reduce<Partial<Record<FieldsEnum, boolean>>>((acc, field) => {
                        acc[field] = true;
                        return acc;
                    }, {});

                    formik.setTouched({
                        ...formik.touched,
                        ...touched,
                    });
                },
            });
        }
    }, [formik.submitForm, formik.resetForm]);

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);
    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormState) {
            remoteFormActionsContext.setRemoteFormState({
                hasChanges: hasAnyFieldValueChanges,
                hasErrors: hasFormAnyErros,
            });
        }
    }, [hasFormAnyErros, hasAnyFieldValueChanges]);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormRequest) {
            remoteFormActionsContext.setRemoteFormRequest({
                isLoading: updateRequest.loading,
            });
        }
    }, [updateRequest.loading]);

    const hasLegalAddressChanges = useWatchLegalAddressChanges(initialValues, formik.values);
    const legalAddressCountry = countryByCode[formik.values[FieldsEnum.legalAddressCountryCode] as CountryCodeT];
    const legalFullAddress = [
        legalAddressCountry?.userLangDisplayName || '',
        formik.values[FieldsEnum.legalAddressZipCode],
        formik.values[FieldsEnum.legalAddressCity],
        formik.values[FieldsEnum.legalAddressStreet1],
        formik.values[FieldsEnum.legalAddressStreet2],
    ]
        .join(' ')
        .trim();

    const hasPostalAddressChanges = useWatchPostalAddressChanges(initialValues, formik.values);
    const postalAddressCountryCode = countryByCode[formik.values[FieldsEnum.postalAddressCountryCode] as CountryCodeT];
    const postalFullAddress = [
        postalAddressCountryCode?.userLangDisplayName || '',
        formik.values[FieldsEnum.postalAddressZipCode],
        formik.values[FieldsEnum.postalAddressCity],
        formik.values[FieldsEnum.postalAddressStreet1],
        formik.values[FieldsEnum.postalAddressStreet2],
    ]
        .join(' ')
        .trim();

    const hasBillingAddressChanges = useWatchBillingAddressChanges(initialValues, formik.values);
    const billingAddressCountryCode =
        countryByCode[formik.values[FieldsEnum.billingAddressCountryCode] as CountryCodeT];
    const billingFullAddress = [
        billingAddressCountryCode?.userLangDisplayName || '',
        formik.values[FieldsEnum.billingAddressZipCode],
        formik.values[FieldsEnum.billingAddressCity],
        formik.values[FieldsEnum.billingAddressStreet1],
        formik.values[FieldsEnum.billingAddressStreet2],
    ]
        .join(' ')
        .trim();

    const hasLegalAddress = !!partnerDetails?.legalAddress;
    const isNeedApproveLegalAddress = !!partnerDetails?.legalAddress && !partnerDetails?.addressVerified;

    const hasPostalAddress = !!partnerDetails?.postalAddress;
    const isNeedApprovePostalAddress = !!partnerDetails?.postalAddress && !partnerDetails?.postalAddressVerified;

    const hasBillingAddress = !!partnerDetails?.billingAddress;
    const isNeedApproveBillingAddress = !!partnerDetails?.billingAddress && !partnerDetails?.billingAddressVerified;

    const selectedAddressLegalCountry = formik.values[FieldsEnum.legalAddressCountryCode];
    const selectedLegalFormId = formik.values[FieldsEnum.legalFormId];
    useCheckCountryCorrectLegacyForm(
        {
            selectedLegalFormId,
            selectedCountry: selectedAddressLegalCountry,
        },
        () => {
            formik.setTouched({ [FieldsEnum.legalFormId]: false }, false);
            formik.setFieldValue(FieldsEnum.legalFormId, null);
        },
    );

    const handleCopyBillingAddress = () => {
        formik.setValues((values) => {
            return {
                ...values,
                [FieldsEnum.billingAddressCountryCode]: values[FieldsEnum.legalAddressCountryCode],
                [FieldsEnum.billingAddressZipCode]: values[FieldsEnum.legalAddressZipCode],
                [FieldsEnum.billingAddressCity]: values[FieldsEnum.legalAddressCity],
                [FieldsEnum.billingAddressStreet1]: values[FieldsEnum.legalAddressStreet1],
                [FieldsEnum.billingAddressStreet2]: values[FieldsEnum.legalAddressStreet2],
            };
        });

        formik.setTouched({
            ...formik.touched,
            [FieldsEnum.billingAddressCountryCode]: true,
            [FieldsEnum.billingAddressZipCode]: true,
            [FieldsEnum.billingAddressCity]: true,
            [FieldsEnum.billingAddressStreet1]: true,
            [FieldsEnum.billingAddressStreet2]: true,
        });
    };

    const handleCopyPostalAddress = () => {
        formik.setValues((values) => {
            return {
                ...values,
                [FieldsEnum.postalAddressCountryCode]: values[FieldsEnum.legalAddressCountryCode],
                [FieldsEnum.postalAddressZipCode]: values[FieldsEnum.legalAddressZipCode],
                [FieldsEnum.postalAddressCity]: values[FieldsEnum.legalAddressCity],
                [FieldsEnum.postalAddressStreet1]: values[FieldsEnum.legalAddressStreet1],
                [FieldsEnum.postalAddressStreet2]: values[FieldsEnum.legalAddressStreet2],
            };
        });

        formik.setTouched({
            ...formik.touched,
            [FieldsEnum.postalAddressCountryCode]: true,
            [FieldsEnum.postalAddressZipCode]: true,
            [FieldsEnum.postalAddressCity]: true,
            [FieldsEnum.postalAddressStreet1]: true,
            [FieldsEnum.postalAddressStreet2]: true,
        });
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <ContentMargins className={cx('wrap')}>
                <Widget
                    className={cx('widget')}
                    title={t('partner.details-tab.segments.basic')}
                    afterTitleNode={
                        <CreatePartnerInfo
                            partnerId={partnerId}
                            partnerType={partnerType}
                            createdByBroker={false}
                            createdDate={partnerDetails?.createdDate || null}
                            userId={partnerDetails?.createdById || null}
                            userName={partnerDetails?.createdByName || null}
                        />
                    }
                    rightNode={
                        <BasicWidgetHeaderActions
                            onLock={onLockPartner}
                            onUnLock={onUnlockPartner}
                            partnerDetails={partnerDetails}
                        />
                    }
                >
                    <div className={cx('widget__inner')}>
                        <FieldGroup>
                            <FormikField
                                className={cx('field', 'field--name')}
                                name={FieldsEnum.name}
                                error={formik.errors[FieldsEnum.name]}
                                meta={formik.getFieldMeta(FieldsEnum.name)}
                                label={t('partner.details-tab.fields.name.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.name}
                                        value={formik.values[FieldsEnum.name]}
                                        hasChanges={props.hasChanges}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field', 'field--legal-form')}
                                name={FieldsEnum.legalFormId}
                                error={formik.errors[FieldsEnum.legalFormId]}
                                meta={formik.getFieldMeta(FieldsEnum.legalFormId)}
                                label={t('partner.details-tab.fields.legal-form.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <LegalFormDropdown
                                        countryCode={formik.values[FieldsEnum.legalAddressCountryCode]}
                                        value={formik.values[FieldsEnum.legalFormId]}
                                        onChange={handleSelectLegalForm}
                                        isDisabled={!formik.values[FieldsEnum.legalAddressCountryCode]}
                                        overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field', 'field--key-manager')}
                                name={FieldsEnum.keyAccountManagerId}
                                error={formik.errors[FieldsEnum.keyAccountManagerId]}
                                meta={formik.getFieldMeta(FieldsEnum.keyAccountManagerId)}
                                label={t('partner.details-tab.fields.key-manager.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                tooltipNode={
                                    <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                                        {t('partner.details-tab.fields.key-manager.tooltip')}
                                    </TooltipContent>
                                }
                            >
                                {(props) => (
                                    <KeyAccountManagerDropdown
                                        selectedValue={formik.values[FieldsEnum.keyAccountManagerId]}
                                        initialLabel={partnerDetails?.keyAccountManagerName || null}
                                        placeholder={t('partner.details-tab.fields.key-manager.placeholder')}
                                        isDisabled={updateRequest.loading}
                                        onSelect={props.onChange}
                                        hasWarning={props.hasWarning}
                                        hasError={props.hasError}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                className={cx('field', 'field--dispatcher')}
                                name={FieldsEnum.dispatcherId}
                                error={formik.errors[FieldsEnum.dispatcherId]}
                                meta={formik.getFieldMeta(FieldsEnum.dispatcherId)}
                                label={t('partner.details-tab.fields.dispatcher.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                tooltipNode={
                                    <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                                        {t('partner.details-tab.fields.dispatcher.tooltip')}
                                    </TooltipContent>
                                }
                            >
                                {(props) => (
                                    <DispatcherDropdown
                                        selectedValue={formik.values[FieldsEnum.dispatcherId]}
                                        initialLabel={partnerDetails?.defaultDispatcherName || null}
                                        placeholder={t('partner.details-tab.fields.dispatcher.placeholder')}
                                        isDisabled={updateRequest.loading}
                                        onSelect={props.onChange}
                                        hasWarning={props.hasWarning}
                                        hasError={props.hasError}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </FieldGroup>
                    </div>
                </Widget>
                {partnerType === PartnerTypeEnum.shipper && (
                    <Widget
                        className={cx('widget')}
                        title={t('partner.details-tab.segments.rfq-stats')}
                        titleTooltipNode={
                            <TooltipContent theme={TooltipContentThemeEnum.black} width={200}>
                                {t('partner.details-tab.rfq-stats-tooltip')}
                            </TooltipContent>
                        }
                        afterTitleNode={<RFQStats />}
                    />
                )}
                <div className={cx('widget-group')}>
                    <Widget
                        className={cx('widget', 'widget-group__widget')}
                        title={t('partner.details-tab.segments.legal-address')}
                        rightNode={
                            <AddressWidgetHeaderActions
                                isShowStatus={hasLegalAddress}
                                isAllowShowVerifyAction={
                                    hasLegalAddress && permissions.canVerifyPartner && !hasLegalAddressChanges
                                }
                                addressType={AddressTypeEnum.legal}
                                fullAddress={legalFullAddress}
                                needApproved={isNeedApproveLegalAddress}
                                companyId={partnerDetails?.id}
                                onVerify={onVerifyPartner}
                                onUnVerify={onUnverifyPartner}
                            />
                        }
                    >
                        <div className={cx('widget__inner')}>
                            <FieldGroup spaces={1}>
                                <FormikField
                                    className={cx('field', 'field--country')}
                                    name={FieldsEnum.legalAddressCountryCode}
                                    error={formik.errors[FieldsEnum.legalAddressCountryCode]}
                                    label=""
                                    meta={formik.getFieldMeta(FieldsEnum.legalAddressCountryCode)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <CountryDropdown
                                            hasWarning={isNeedApproveLegalAddress}
                                            hasError={props.hasError}
                                            value={formik.values[FieldsEnum.legalAddressCountryCode]}
                                            onChange={(countryCode) => {
                                                props.onChange(countryCode);
                                            }}
                                            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    className={cx('field', 'field--zip')}
                                    name={FieldsEnum.legalAddressZipCode}
                                    error={formik.errors[FieldsEnum.legalAddressZipCode]}
                                    meta={formik.getFieldMeta(FieldsEnum.legalAddressZipCode)}
                                    label={t('partner.details-tab.fields.zip.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.legalAddressZipCode}
                                            value={formik.values[FieldsEnum.legalAddressZipCode]}
                                            placeholder={t('partner.details-tab.fields.zip.placeholder')}
                                            onChange={props.onChange}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning || isNeedApproveLegalAddress}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    className={cx('field', 'field--city')}
                                    name={FieldsEnum.legalAddressCity}
                                    error={formik.errors[FieldsEnum.legalAddressCity]}
                                    meta={formik.getFieldMeta(FieldsEnum.legalAddressCity)}
                                    label={t('partner.details-tab.fields.city.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.legalAddressCity}
                                            value={formik.values[FieldsEnum.legalAddressCity]}
                                            placeholder={t('partner.details-tab.fields.city.placeholder')}
                                            onChange={props.onChange}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning || isNeedApproveLegalAddress}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                            </FieldGroup>
                            <FormikField
                                name={FieldsEnum.legalAddressStreet1}
                                error={formik.errors[FieldsEnum.legalAddressStreet1]}
                                meta={formik.getFieldMeta(FieldsEnum.legalAddressStreet1)}
                                label={t('partner.details-tab.fields.street1.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.legalAddressStreet1}
                                        value={formik.values[FieldsEnum.legalAddressStreet1]}
                                        placeholder={t('partner.details-tab.fields.street1.placeholder')}
                                        onChange={props.onChange}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning || isNeedApproveLegalAddress}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                name={FieldsEnum.legalAddressStreet2}
                                error={formik.errors[FieldsEnum.legalAddressStreet2]}
                                meta={formik.getFieldMeta(FieldsEnum.legalAddressStreet2)}
                                label={t('partner.details-tab.fields.street2.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.legalAddressStreet2}
                                        value={formik.values[FieldsEnum.legalAddressStreet2]}
                                        placeholder={t('partner.details-tab.fields.street2.placeholder')}
                                        onChange={props.onChange}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning || isNeedApproveLegalAddress}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </div>
                    </Widget>
                    <Widget
                        className={cx('widget', 'widget-group__widget')}
                        title={t('partner.details-tab.segments.postal-address')}
                        rightNode={
                            <AddressWidgetHeaderActions
                                isShowStatus={hasPostalAddress}
                                isAllowShowVerifyAction={
                                    hasPostalAddress && permissions.canVerifyPartner && !hasPostalAddressChanges
                                }
                                addressType={AddressTypeEnum.postal}
                                fullAddress={postalFullAddress}
                                onCopy={handleCopyPostalAddress}
                                needApproved={isNeedApprovePostalAddress}
                                companyId={partnerDetails?.id}
                                onVerify={onVerifyPartner}
                                onUnVerify={onUnverifyPartner}
                            />
                        }
                    >
                        <div className={cx('widget__inner')}>
                            <FieldGroup spaces={1}>
                                <FormikField
                                    className={cx('field', 'field--country')}
                                    name={FieldsEnum.postalAddressCountryCode}
                                    error={formik.errors[FieldsEnum.postalAddressCountryCode]}
                                    label=""
                                    meta={formik.getFieldMeta(FieldsEnum.postalAddressCountryCode)}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <CountryDropdown
                                            hasWarning={isNeedApprovePostalAddress}
                                            hasError={props.hasError}
                                            value={formik.values[FieldsEnum.postalAddressCountryCode]}
                                            onChange={(countryCode) => {
                                                props.onChange(countryCode);
                                            }}
                                            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    className={cx('field', 'field--zip')}
                                    name={FieldsEnum.postalAddressZipCode}
                                    error={formik.errors[FieldsEnum.postalAddressZipCode]}
                                    meta={formik.getFieldMeta(FieldsEnum.postalAddressZipCode)}
                                    label={t('partner.details-tab.fields.zip.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.postalAddressZipCode}
                                            value={formik.values[FieldsEnum.postalAddressZipCode]}
                                            placeholder={t('partner.details-tab.fields.zip.placeholder')}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning || isNeedApprovePostalAddress}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    className={cx('field', 'field--city')}
                                    name={FieldsEnum.postalAddressCity}
                                    error={formik.errors[FieldsEnum.postalAddressCity]}
                                    meta={formik.getFieldMeta(FieldsEnum.postalAddressCity)}
                                    label={t('partner.details-tab.fields.city.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.postalAddressCity}
                                            value={formik.values[FieldsEnum.postalAddressCity]}
                                            placeholder={t('partner.details-tab.fields.city.placeholder')}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning || isNeedApprovePostalAddress}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                            </FieldGroup>
                            <FormikField
                                name={FieldsEnum.postalAddressStreet1}
                                error={formik.errors[FieldsEnum.postalAddressStreet1]}
                                meta={formik.getFieldMeta(FieldsEnum.postalAddressStreet1)}
                                label={t('partner.details-tab.fields.street1.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.postalAddressStreet1}
                                        value={formik.values[FieldsEnum.postalAddressStreet1]}
                                        placeholder={t('partner.details-tab.fields.street1.placeholder')}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning || isNeedApprovePostalAddress}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                            <FormikField
                                name={FieldsEnum.postalAddressStreet2}
                                error={formik.errors[FieldsEnum.postalAddressStreet2]}
                                meta={formik.getFieldMeta(FieldsEnum.postalAddressStreet2)}
                                label={t('partner.details-tab.fields.street2.label')}
                                setFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                            >
                                {(props) => (
                                    <Input
                                        name={FieldsEnum.postalAddressStreet2}
                                        value={formik.values[FieldsEnum.postalAddressStreet2]}
                                        placeholder={t('partner.details-tab.fields.street2.placeholder')}
                                        onChange={props.onChange}
                                        onBlur={props.onBlur}
                                        onFocus={props.onFocus}
                                        hasError={props.hasError}
                                        hasWarning={props.hasWarning || isNeedApprovePostalAddress}
                                        hasChanges={props.hasChanges}
                                    />
                                )}
                            </FormikField>
                        </div>
                    </Widget>
                    {shouldShowBillingInfo && (
                        <Widget
                            className={cx('widget', 'widget-group__widget')}
                            title={t('partner.details-tab.segments.billing-address')}
                            rightNode={
                                <AddressWidgetHeaderActions
                                    isShowStatus={hasBillingAddress}
                                    isAllowShowVerifyAction={
                                        hasBillingAddress && permissions.canVerifyPartner && !hasBillingAddressChanges
                                    }
                                    addressType={AddressTypeEnum.billing}
                                    fullAddress={billingFullAddress}
                                    needApproved={isNeedApproveBillingAddress}
                                    onCopy={handleCopyBillingAddress}
                                    companyId={partnerDetails?.id}
                                    onVerify={onVerifyPartner}
                                    onUnVerify={onUnverifyPartner}
                                />
                            }
                        >
                            <div className={cx('widget__inner')}>
                                <FieldGroup spaces={1}>
                                    <FormikField
                                        className={cx('field', 'field--country')}
                                        name={FieldsEnum.billingAddressCountryCode}
                                        error={formik.errors[FieldsEnum.billingAddressCountryCode]}
                                        label=""
                                        meta={formik.getFieldMeta(FieldsEnum.billingAddressCountryCode)}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <CountryDropdown
                                                hasWarning={isNeedApproveBillingAddress}
                                                hasError={props.hasError}
                                                value={formik.values[FieldsEnum.billingAddressCountryCode]}
                                                onChange={(countryCode) => {
                                                    props.onChange(countryCode);
                                                }}
                                                overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                hasChanges={props.hasChanges}
                                            />
                                        )}
                                    </FormikField>
                                    <FormikField
                                        className={cx('field', 'field--zip')}
                                        name={FieldsEnum.billingAddressZipCode}
                                        error={formik.errors[FieldsEnum.billingAddressZipCode]}
                                        meta={formik.getFieldMeta(FieldsEnum.billingAddressZipCode)}
                                        label={t('partner.details-tab.fields.zip.label')}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <Input
                                                name={FieldsEnum.billingAddressZipCode}
                                                value={formik.values[FieldsEnum.billingAddressZipCode]}
                                                placeholder={t('partner.details-tab.fields.zip.placeholder')}
                                                onChange={props.onChange}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                hasError={props.hasError}
                                                hasWarning={props.hasWarning || isNeedApproveBillingAddress}
                                                hasChanges={props.hasChanges}
                                            />
                                        )}
                                    </FormikField>
                                    <FormikField
                                        className={cx('field', 'field--city')}
                                        name={FieldsEnum.billingAddressCity}
                                        error={formik.errors[FieldsEnum.billingAddressCity]}
                                        meta={formik.getFieldMeta(FieldsEnum.billingAddressCity)}
                                        label={t('partner.details-tab.fields.city.label')}
                                        setFieldValue={formik.setFieldValue}
                                        setFieldTouched={formik.setFieldTouched}
                                    >
                                        {(props) => (
                                            <Input
                                                name={FieldsEnum.billingAddressCity}
                                                value={formik.values[FieldsEnum.billingAddressCity]}
                                                placeholder={t('partner.details-tab.fields.city.placeholder')}
                                                onChange={props.onChange}
                                                onBlur={props.onBlur}
                                                onFocus={props.onFocus}
                                                hasError={props.hasError}
                                                hasWarning={props.hasWarning || isNeedApproveBillingAddress}
                                                hasChanges={props.hasChanges}
                                            />
                                        )}
                                    </FormikField>
                                </FieldGroup>
                                <FormikField
                                    name={FieldsEnum.billingAddressStreet1}
                                    error={formik.errors[FieldsEnum.billingAddressStreet1]}
                                    meta={formik.getFieldMeta(FieldsEnum.billingAddressStreet1)}
                                    label={t('partner.details-tab.fields.street1.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.billingAddressStreet1}
                                            value={formik.values[FieldsEnum.billingAddressStreet1]}
                                            placeholder={t('partner.details-tab.fields.street1.placeholder')}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning || isNeedApproveBillingAddress}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    name={FieldsEnum.billingAddressStreet2}
                                    error={formik.errors[FieldsEnum.billingAddressStreet2]}
                                    meta={formik.getFieldMeta(FieldsEnum.billingAddressStreet2)}
                                    label={t('partner.details-tab.fields.street2.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.billingAddressStreet2}
                                            value={formik.values[FieldsEnum.billingAddressStreet2]}
                                            placeholder={t('partner.details-tab.fields.street2.placeholder')}
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning || isNeedApproveBillingAddress}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                                <FormikField
                                    name={FieldsEnum.billingEmail}
                                    error={formik.errors[FieldsEnum.billingEmail]}
                                    meta={formik.getFieldMeta(FieldsEnum.billingEmail)}
                                    label={t('partner.details-tab.fields.billing-email.label')}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                >
                                    {(props) => (
                                        <Input
                                            name={FieldsEnum.billingEmail}
                                            value={formik.values[FieldsEnum.billingEmail]}
                                            placeholder=""
                                            onChange={props.onChange}
                                            onBlur={props.onBlur}
                                            onFocus={props.onFocus}
                                            hasError={props.hasError}
                                            hasWarning={props.hasWarning || isNeedApproveBillingAddress}
                                            hasChanges={props.hasChanges}
                                        />
                                    )}
                                </FormikField>
                            </div>
                        </Widget>
                    )}
                </div>
            </ContentMargins>
            <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            <HiddenSubmitButtonForKeyboardEnter />
        </form>
    );
});

export default PartnerDetailsForm;

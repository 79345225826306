import { FetchDispatchesPageQueryT } from 'broker-admin/store/dispatches/models';
import { DispatchesQuickFilterEnum, DispatchesSortEnum } from 'common/utils/api/models';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { PageSortT, SortDirectionEnum } from 'common/utils/query';

const getQueryFromQuickFilters = (quickFilter?: DispatchesQuickFilterEnum): FetchDispatchesPageQueryT => {
    switch (quickFilter) {
        case DispatchesQuickFilterEnum.new: {
            return {
                statusBar: 'NEW',
            };
        }
        case DispatchesQuickFilterEnum.carrierUnassigned: {
            return {
                statusBar: 'UNASSIGNED',
            };
        }
        case DispatchesQuickFilterEnum.carrierAssigned: {
            return {
                statusBar: 'ASSIGNED',
            };
        }
        case DispatchesQuickFilterEnum.inTransit: {
            return {
                statusBar: 'IN_TRANSIT',
            };
        }
        case DispatchesQuickFilterEnum.delivered: {
            return {
                statusBar: 'DELIVERED',
            };
        }
        case DispatchesQuickFilterEnum.canceled: {
            return {
                statusBar: 'CANCELED',
            };
        }
        default: {
            return {};
        }
    }
};

const formatSort = (sort: PageSortT<DispatchesSortEnum> | null): FetchDispatchesPageQueryT['sorts'] | null => {
    switch (sort?.value) {
        case DispatchesSortEnum.dropOffDate: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'DESTINATION_DATE_ASC';
            }
            return 'DESTINATION_DATE_DESC';
        }
        case DispatchesSortEnum.pickupDate: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'ORIGIN_DATE_ASC';
            }
            return 'ORIGIN_DATE_DESC';
        }
        case DispatchesSortEnum.createdDate: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'CREATED_DATE_ASC';
            }
            return 'CREATED_DATE_DESC';
        }
        default: {
            return null;
        }
    }
};

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<DispatchesSortEnum> | null,
): FetchDispatchesPageQueryT => {
    const dictTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

    let hasSpotTransportationOrder: boolean | undefined =
        queryFilters[QueryFiltersKeysEnum.spotTransportOrder] || undefined;
    // WTF logic ?!?!?
    if (queryFilters[QueryFiltersKeysEnum.contractedTransportOrder]) {
        hasSpotTransportationOrder = false;
    }

    let hasSpotOrder: boolean | undefined = queryFilters[QueryFiltersKeysEnum.spotOrder] || undefined;
    // WTF logic ?!?!?
    if (queryFilters[QueryFiltersKeysEnum.contractedOrder]) {
        hasSpotOrder = false;
    }

    return {
        originAddress: queryFilters[QueryFiltersKeysEnum.originAddress] || undefined,
        destinationAddress: queryFilters[QueryFiltersKeysEnum.dropoffAddress] || undefined,

        destinationDateFrom: queryFilters[QueryFiltersKeysEnum.dropOffDateFrom] || undefined,
        destinationDateTo: queryFilters[QueryFiltersKeysEnum.dropOffDateTo] || undefined,

        originDateFrom: queryFilters[QueryFiltersKeysEnum.pickupDateFrom] || undefined,
        originDateTo: queryFilters[QueryFiltersKeysEnum.pickupDateTo] || undefined,

        createdDateFrom: queryFilters[QueryFiltersKeysEnum.createdDateFrom] || undefined,
        createdDateTo: queryFilters[QueryFiltersKeysEnum.createdDateTo] || undefined,

        paid: queryFilters[QueryFiltersKeysEnum.hasUnpaidOrders] ? false : undefined,

        dictTrailerId: typeof dictTrailerId === 'string' ? +dictTrailerId : undefined,

        carrierId: queryFilters[QueryFiltersKeysEnum.carrierId] || undefined,
        shipperId: queryFilters[QueryFiltersKeysEnum.shipperId] || undefined,

        dispatcherId: queryFilters[QueryFiltersKeysEnum.dispatcherId] || undefined,
        keyAccountManagerId: queryFilters[QueryFiltersKeysEnum.keyAccountManagerId] || undefined,

        hasSpotTransportationOrder,
        hasSpotOrder,

        textSearch: queryFilters[QueryFiltersKeysEnum.searchText] || undefined,

        runningOutOfTimeToAssignCarrier:
            queryFilters[QueryFiltersKeysEnum.runningOutOfTimeToAssignCarrier] || undefined,
        runOutOfTimeToAssignCarrier: queryFilters[QueryFiltersKeysEnum.runOutOfTimeToAssignCarrier] || undefined,
        runningOutOfTimeToAssignAssets: queryFilters[QueryFiltersKeysEnum.runningOutOfTimeToAssignAssets] || undefined,
        runOutOfTimeToAssignAssets: queryFilters[QueryFiltersKeysEnum.runOutOfTimeToAssignAssets] || undefined,
        runningOutOfTimeToAssignDriver: queryFilters[QueryFiltersKeysEnum.runningOutOfTimeToAssignDriver] || undefined,
        runOutOfTimeToAssignDriver: queryFilters[QueryFiltersKeysEnum.runOutOfTimeToAssignDriver] || undefined,
        delaying: queryFilters[QueryFiltersKeysEnum.tourDelaying] || undefined,
        noShow: queryFilters[QueryFiltersKeysEnum.noShow] || undefined,
        missed: queryFilters[QueryFiltersKeysEnum.shipmentMissed] || undefined,

        ...getQueryFromQuickFilters(queryFilters[QueryFiltersKeysEnum.quickFilter]),

        sorts: formatSort(sort) || undefined,
    };
};

export { prepareFetchPageQuery };

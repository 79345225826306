import * as React from 'react';
import { formatDiff, getTimeLeft, MS_IN_SEC, parseDiff } from 'common/utils/time';
import { useTranslation } from 'react-i18next';

type ResultT = {
    diff: number | null;
    formattedDiff: string | null;
};

const useTimeMark = ({ timeMark }: { timeMark: string | null | undefined }): ResultT => {
    const { t } = useTranslation();

    const [timeLeft, setTimeLeft] = React.useState<number | null>(getTimeLeft(timeMark));

    React.useEffect(() => {
        const handler = () => {
            setTimeLeft(getTimeLeft(timeMark));
        };
        handler();

        const intervalId = setInterval(handler, MS_IN_SEC);

        return () => {
            clearInterval(intervalId);
        };
    }, [timeMark]);

    return React.useMemo((): ResultT => {
        const diff = timeLeft !== null ? timeLeft : null;
        if (diff === null) {
            return {
                diff: null,
                formattedDiff: null,
            };
        }

        const parsedDiff = parseDiff(diff);
        const formattedDiff = formatDiff(t, parsedDiff);

        return {
            diff,
            formattedDiff,
        };
    }, [timeLeft]);
};

export { useTimeMark };

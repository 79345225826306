import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './AssetPin.scss';
import { CurrencyEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import TrailerIcon, { getTrailerIconProps } from 'common/icons/TrailerIcon';
import TruckIcon, { getTruckIconProps } from 'common/icons/TruckIcon';
import MapTooltip from 'common/components/maps/MapTooltip/MapTooltip';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import getUtilizationColor from 'common/utils/get-utilization-color';
import AssetLinkIcon, { getAssetLinkIconProps } from 'common/icons/AssetLinkIcon';
import { getAssignmentIconPreset } from '../utils/get-assignment-icon-preset';
import { PartnerT } from 'common/store/carrier-suggest/models';
import CarrierIcon from 'common/icons/CarrierIcon';
import UnitTypeCount from 'common/components/units/UnitTypeCount/UnitTypeCount';
import Money from 'common/components/Money/Money';
import isNumber from 'lodash/isNumber';
import { formatDateWithTimeZone, formatTimeZone } from 'common/utils/time';
import { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';

const cx = classNames.bind(styles);

type PropsT = {
    lat: number;
    lng: number;
    isSelected: boolean;
    className?: string;
    utcOffset?: number | null;
    arrivalDate?: string | null;
    plateNumber?: string | null;
    truckId?: string | null;
    trailerId?: string | null;
    onClick: (truckId: string | null | undefined, trailerId: string | null | undefined) => void;
    onHover: (truckId: string | null | undefined, trailerId: string | null | undefined) => void;
    isLoadingAssetUtilization: boolean;
    utilization: number | null | undefined;
    isLoadingCarrierUtilization: boolean;
    carrierUtilizationPercent: number | null;
    canInTime: boolean;
    carrier: PartnerT | null;
    ratePerKm?: number;
};

type UtilizationPropsT = {
    isLoading: boolean;
    utilization: number | null | undefined;
};

const Utilization: React.FC<UtilizationPropsT> = (props) => {
    const { isLoading, utilization } = props;

    if (isNil(utilization) && isLoading) {
        return <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandAccent} size={DEFAULT_ICON_SIZE} />;
    }

    if (!isNumber(utilization)) {
        return null;
    }

    return (
        <div
            className={cx('utilization')}
            style={{
                color: getUtilizationColor(utilization || 0),
            }}
        >
            {utilization ? `${utilization}%` : ''}
        </div>
    );
};

const getMapPinTheme = ({ canInTime, isSelected }: { canInTime: boolean; isSelected: boolean }): MapPinThemeEnum => {
    if (isSelected) {
        return MapPinThemeEnum.charcoal;
    }

    if (!canInTime) {
        return MapPinThemeEnum.light;
    }

    return MapPinThemeEnum.slate;
};

const AssetPin: React.FC<PropsT> = React.memo((props) => {
    const {
        lat,
        lng,
        isSelected,
        className,
        truckId,
        trailerId,
        utcOffset,
        arrivalDate,
        plateNumber,
        onClick,
        onHover,
        carrier,
        isLoadingAssetUtilization,
        utilization,
        isLoadingCarrierUtilization,
        carrierUtilizationPercent,
        ratePerKm,
        canInTime,
    } = props;

    const { t } = useTranslation();

    let formattedArrivalDate: React.ReactNode;
    if (arrivalDate) {
        formattedArrivalDate = t('assignment.asset-arrival', {
            date: formatDateWithTimeZone(arrivalDate, utcOffset, 'D MMM'),
            plateNumber,
        });
    } else if (arrivalDate === null) {
        formattedArrivalDate = t('assignment.asset-current-position', {
            plateNumber,
        });
    }

    const handleClick = () => {
        onClick(truckId, trailerId);
    };

    const handleHover = () => {
        onHover(truckId, trailerId);
    };

    const iconPreset = getAssignmentIconPreset(isSelected, canInTime);

    return (
        <MapTooltip
            hasPaddings
            className={cs(cx('asset-tooltip'), className)}
            lat={lat}
            lng={lng}
            onClick={handleClick}
            onHover={handleHover}
            theme={getMapPinTheme({
                canInTime,
                isSelected,
            })}
        >
            {(isHover) => (
                <>
                    {isHover && !!formattedArrivalDate && (
                        <div className={cx('row')}>
                            <div className={cx('arrival')} title={formatTimeZone(utcOffset)}>
                                {formattedArrivalDate}
                            </div>
                        </div>
                    )}
                    {isHover && (
                        <div className={cx('row')}>
                            <span className={cx('carrier')}>
                                <CarrierIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.white} />
                                <span className={cx('carrier__name')}>{carrier?.name}</span>
                                {isLoadingCarrierUtilization && (
                                    <ControlLoaderIcon
                                        fillColor={StyleGuideColorsEnum.brandAccent}
                                        size={DEFAULT_ICON_SIZE}
                                    />
                                )}
                                {!isLoadingCarrierUtilization && !isNil(carrierUtilizationPercent) && (
                                    <span
                                        style={{
                                            color: getUtilizationColor(carrierUtilizationPercent),
                                        }}
                                    >
                                        <UnitTypeCount
                                            type={UnitTypeEnum.percentsAbbreviation}
                                            count={carrierUtilizationPercent}
                                        />
                                    </span>
                                )}
                            </span>
                        </div>
                    )}
                    <div className={cx('row')}>
                        {truckId && !trailerId && (
                            <>
                                <TruckIcon className={cx('icon')} {...getTruckIconProps(iconPreset)} />
                                {isHover && (
                                    <Utilization isLoading={isLoadingAssetUtilization} utilization={utilization} />
                                )}
                            </>
                        )}
                        {!truckId && trailerId && (
                            <>
                                <TrailerIcon className={cx('icon')} {...getTrailerIconProps(iconPreset)} />
                                {isHover && (
                                    <Utilization isLoading={isLoadingAssetUtilization} utilization={utilization} />
                                )}
                            </>
                        )}
                        {truckId && trailerId && (
                            <>
                                <AssetLinkIcon className={cx('icon')} {...getAssetLinkIconProps(iconPreset)} />
                                {isHover && (
                                    <Utilization isLoading={isLoadingAssetUtilization} utilization={utilization} />
                                )}
                            </>
                        )}
                        {isHover && isNumber(ratePerKm) && (
                            <Money amount={ratePerKm} currency={CurrencyEnum.EUR} className={cx('price')} />
                        )}
                    </div>
                </>
            )}
        </MapTooltip>
    );
});

export default AssetPin;

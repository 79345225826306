import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ActionOverlay from 'common/components/ActionOverlay/ActionOverlay';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';

type PropsT = {};

const LockActionOnChangeForm: React.FC<PropsT> = React.memo((props) => {
    const { children } = props;

    const { t } = useTranslation();

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);

    let tooltipMessage: React.ReactNode = null;

    const hasFormChanges = !!remoteFormActionsContext?.remoteFormState?.hasChanges;
    if (hasFormChanges) {
        tooltipMessage = t('partner.actions.save-changes-first');
    }

    return (
        <ActionOverlay
            isActive={hasFormChanges}
            tooltipNode={
                <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                    {tooltipMessage}
                </TooltipContent>
            }
        >
            {children}
        </ActionOverlay>
    );
});

export default LockActionOnChangeForm;

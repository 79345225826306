import { RolesEnum } from 'common/utils/api/models';

export enum FieldsEnum {
    role = 'role',
    newKeyAccountManagerUserId = 'new-key-account-manager-user-id',
    newDispatcherUserId = 'new-dispatcher-user-id',
}

export type FormValuesT = {
    [FieldsEnum.role]: RolesEnum | null;
    [FieldsEnum.newKeyAccountManagerUserId]: UserIdT | null;
    [FieldsEnum.newDispatcherUserId]: UserIdT | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;

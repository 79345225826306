import * as React from 'react';
import { NotificationToastRendererT } from 'common/components/toasts/NotificationToastsManager/models';
import {
    NotificationLinkFactoryT,
    NotificationRendererT,
} from 'common/components/notifications/NotificationsBarContent/models';
import StatusChangedCard from 'common/components/notification-cards/StatusChangedCard/StatusChangedCard';
import {
    NotificationActionEnum,
    NotificationEmotionEnum,
    NotificationIconEnum,
} from 'common/store/notifications/models';
import OrderColoredStatus from 'common/components/status-colored/OrderColoredStatus/OrderColoredStatus';
import { PartnerTypeEnum, SubjectTypeEnum } from 'common/utils/api/models';
import { urlFactory } from 'broker-admin/utils/urls';
import TransportOrderColoredStatus from 'common/components/status-colored/TransportOrderColoredStatus/TransportOrderColoredStatus';
import ShipmentColoredStatus from 'common/components/status-colored/ShipmentColoredStatus/ShipmentColoredStatus';
import {
    ALERT_ICON_LOAD_STATUS_MAP,
    ALERT_ICON_ORDER_STATUS_MAP,
    ALERT_ICON_TOUR_STATUS_MAP,
    ALERT_ICON_TRANSPORTATION_STATUS_MAP,
    EMOTION_LOAD_STATUS_MAP,
    EMOTION_ORDER_STATUS_MAP,
    EMOTION_TOUR_STATUS_MAP,
    EMOTION_TRANSPORTATION_STATUS_MAP,
} from 'common/components/toasts/utils';
import TourColoredStatus from 'common/components/status-colored/TourColoredStatus/TourColoredStatus';
import { AlertToastRendererT } from 'common/components/toasts/AlertToastsManager/models';
import { AnyAlert } from 'common/store/alerts/models';
import { BrokerAlertTypeEnum, BrokerAnyAlert } from './models';
import { AlertThemeEnum } from 'common/components/Alert/Alert';
import DoneIcon from 'common/icons/DoneIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import SpotRequestStatusCard from 'common/components/notification-cards/SpotRequestStatusCard/SpotRequestStatusCard';
import SpotBidStatusCard from 'common/components/notification-cards/SpotBidStatusCard/SpotBidStatusCard';
import ExpiredPartnerDocumentCard from 'broker-admin/components/notification-cards/ExpiredPartnerDocumentCard/ExpiredPartnerDocumentCard';
import ExpireSoonPartnerDocumentCard from 'broker-admin/components/notification-cards/ExpireSoonPartnerDocumentCard/ExpireSoonPartnerDocumentCard';
import UpdatedPartnerDocumentCard from 'broker-admin/components/notification-cards/UpdatedPartnerDocumentCard/UpdatedPartnerDocumentCard';
import RevokedPartnerDocumentCard from 'broker-admin/components/notification-cards/RevokedPartnerDocumentCard/RevokedPartnerDocumentCard';
import RevokedPartnerShipperContractCard from 'broker-admin/components/notification-cards/RevokedPartnerShipperContractCard/RevokedPartnerShipperContractCard';
import ExpiredPartnerShipperContractCard from 'broker-admin/components/notification-cards/ExpiredPartnerShipperContractCard/ExpiredPartnerShipperContractCard';
import ExpireSoonPartnerShipperContractCard from 'broker-admin/components/notification-cards/ExpireSoonPartnerShipperContractCard/ExpireSoonPartnerShipperContractCard';
import UpdatedPartnerCarrierContractCard from 'broker-admin/components/notification-cards/UpdatedPartnerCarrierContractCard/UpdatedPartnerCarrierContractCard';
import RevokedPartnerCarrierContractCard from 'broker-admin/components/notification-cards/RevokedPartnerCarrierContractCard/RevokedPartnerCarrierContractCard';
import ExpiredPartnerCarrierContractCard from 'broker-admin/components/notification-cards/ExpiredPartnerCarrierContractCard/ExpiredPartnerCarrierContractCard';
import ExpireSoonPartnerCarrierContractCard from 'broker-admin/components/notification-cards/ExpireSoonPartnerCarrierContractCard/ExpireSoonPartnerCarrierContractCard';
import UpdatedPartnerShipperContractCard from 'broker-admin/components/notification-cards/UpdatedPartnerShipperContractCard/UpdatedPartnerShipperContractCard';
import RunningOutOfTimeAssignAssetsCard from 'common/components/notification-cards/RunningOutOfTimeAssignAssetsCard/RunningOutOfTimeAssignAssetsCard';
import RunOutOfTimeAssignAssetsCard from 'common/components/notification-cards/RunOutOfTimeAssignAssetsCard/RunOutOfTimeAssignAssetsCard';
import RunningOutOfTimeAssignDriversCard from 'common/components/notification-cards/RunningOutOfTimeAssignDriversCard/RunningOutOfTimeAssignDriversCard';
import RunOutOfTimeAssignDriversCard from 'common/components/notification-cards/RunOutOfTimeAssignDriversCard/RunOutOfTimeAssignDriversCard';
import CompletedPartnerDocumentCard from 'broker-admin/components/notification-cards/CompletedPartnerDocumentCard/CompletedPartnerDocumentCard';
import CompletedPartnerShipperContractCard from 'broker-admin/components/notification-cards/CompletedPartnerShipperContractCard/CompletedPartnerShipperContractCard';
import CompletedPartnerCarrierContractCard from '../notification-cards/CompletedPartnerCarrierContractCard/CompletedPartnerCarrierContractCard';
import UpdatedPartnerShipperLaneContractCard from '../notification-cards/UpdatedPartnerShipperLaneContractCard/UpdatedPartnerShipperLaneContractCard';
import RevokedPartnerShipperLaneContractCard from '../notification-cards/RevokedPartnerShipperLaneContractCard/RevokedPartnerShipperLaneContractCard';
import ExpiredPartnerShipperLaneContractCard from '../notification-cards/ExpiredPartnerShipperLaneContractCard/ExpiredPartnerShipperLaneContractCard';
import CompletedPartnerShipperLaneContractCard from '../notification-cards/CompletedPartnerShipperLaneContractCard/CompletedPartnerShipperLaneContractCard';
import ExpireSoonPartnerShipperLaneContractCard from '../notification-cards/ExpireSoonPartnerShipperLaneContractCard/ExpireSoonPartnerShipperLaneContractCard';
import TransportationOrderMileagAndPaymentChangedCard from 'broker-admin/components/notification-cards/TransportationOrderMileagAndPaymentChangedCard/TransportationOrderMileagAndPaymentChangedCard';
import RunningOutOfTimeAssignCarrierCard from 'broker-admin/components/notification-cards/RunningOutOfTimeAssignCarrierCard/RunningOutOfTimeAssignCarrierCard';
import RunOutOfTimeAssignCarrierCard from '../notification-cards/RunOutOfTimeAssignCarrierCard/RunOutOfTimeAssignCarrierCard';

export const renderNotificationToast: NotificationToastRendererT = (notification) => {
    switch (notification.action) {
        case NotificationActionEnum.SHIPMENT_MISSED:
        case NotificationActionEnum.SHIPMENT_NO_SHOW:
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_LOAD_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={data.dispatchNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.shipment}
                        actionStatus={<ShipmentColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_ORDER_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.order}
                        actionStatus={<OrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TOUR_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.tour}
                        actionStatus={<TourColoredStatus status={data.status} />}
                    />
                ),
            };
        }
        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <RunningOutOfTimeAssignAssetsCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <RunOutOfTimeAssignAssetsCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <RunningOutOfTimeAssignDriversCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TOUR_STATUS_MAP[data.status] : null,
                text: (
                    <RunOutOfTimeAssignDriversCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                icon: data.status ? ALERT_ICON_TRANSPORTATION_STATUS_MAP[data.status] : null,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.transportationOrder}
                        actionStatus={<TransportOrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: (
                    <TransportationOrderMileagAndPaymentChangedCard
                        number={data.dispatchNumber || ''}
                        cityA={data.cityFrom}
                        cityB={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.TOUR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: (
                    <RunningOutOfTimeAssignCarrierCard
                        subjectNumber={data.tourNumber || ''}
                        originCity={data.cityFrom || ''}
                        destinationCity={data.cityTo || ''}
                    />
                ),
            };
        }

        case NotificationActionEnum.TOUR_RUN_OUT_OF_TIME_TO_ASSIGN_CARRIER: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: (
                    <RunOutOfTimeAssignCarrierCard
                        subjectNumber={data.tourNumber || ''}
                        originCity={data.cityFrom || ''}
                        destinationCity={data.cityTo || ''}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_PLACED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.new,
                text: (
                    <SpotRequestStatusCard
                        type="new"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: (
                    <SpotRequestStatusCard
                        type="expired"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_BID_ACCEPTED:
        case NotificationActionEnum.SPOT_BID_PLACED: {
            const { data } = notification;

            return {
                icon: null,
                text: (
                    <SpotBidStatusCard
                        type="new"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_BID_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: (
                    <SpotBidStatusCard
                        type="expired"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.DOCUMENT_UPDATED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: (
                    <UpdatedPartnerDocumentCard
                        partnerName={data.companyName || ''}
                        partnerType={data.companyType || null}
                        documentType={data.documentType}
                    />
                ),
            };
        }

        case NotificationActionEnum.DOCUMENT_REVOKED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: (
                    <RevokedPartnerDocumentCard
                        partnerName={data.companyName || ''}
                        partnerType={data.companyType || null}
                        documentType={data.documentType}
                    />
                ),
            };
        }

        case NotificationActionEnum.DOCUMENT_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: (
                    <ExpiredPartnerDocumentCard
                        partnerName={data.companyName || ''}
                        partnerType={data.companyType || null}
                        documentType={data.documentType}
                    />
                ),
            };
        }
        case NotificationActionEnum.DOCUMENT_COMPLETED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: (
                    <CompletedPartnerDocumentCard
                        partnerName={data.companyName || ''}
                        partnerType={data.companyType || null}
                        documentType={data.documentType}
                    />
                ),
            };
        }

        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: (
                    <ExpireSoonPartnerDocumentCard
                        partnerName={data.companyName || ''}
                        partnerType={data.companyType || null}
                        documentType={data.documentType}
                    />
                ),
            };
        }

        case NotificationActionEnum.SHIPPER_CONTRACT_UPDATED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: <UpdatedPartnerShipperContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_REVOKED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <RevokedPartnerShipperContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpiredPartnerShipperContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <CompletedPartnerShipperContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpireSoonPartnerShipperContractCard contractName={data.contractName || ''} />,
            };
        }

        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: <UpdatedPartnerShipperLaneContractCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <RevokedPartnerShipperLaneContractCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpiredPartnerShipperLaneContractCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <CompletedPartnerShipperLaneContractCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpireSoonPartnerShipperLaneContractCard id={data.tztLaneId || '-'} />,
            };
        }

        case NotificationActionEnum.CARRIER_CONTRACT_UPDATED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.success,
                text: <UpdatedPartnerCarrierContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_REVOKED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <RevokedPartnerCarrierContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpiredPartnerCarrierContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.expired,
                text: <ExpireSoonPartnerCarrierContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_COMPLETED: {
            const { data } = notification;

            return {
                icon: NotificationIconEnum.attention,
                text: <CompletedPartnerCarrierContractCard contractName={data.contractName || ''} />,
            };
        }

        default: {
            return null;
        }
    }
};

export const renderNotification: NotificationRendererT = (notification) => {
    switch (notification.action) {
        case NotificationActionEnum.SHIPMENT_MISSED:
        case NotificationActionEnum.SHIPMENT_NO_SHOW:
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_LOAD_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={data.dispatchNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.shipment}
                        actionStatus={<ShipmentColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_ORDER_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.order}
                        actionStatus={<OrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TOUR_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.tour}
                        actionStatus={<TourColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <RunningOutOfTimeAssignAssetsCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <RunOutOfTimeAssignAssetsCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <RunningOutOfTimeAssignDriversCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TOUR_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <RunOutOfTimeAssignDriversCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.TOUR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: (
                    <RunningOutOfTimeAssignCarrierCard
                        subjectNumber={data.tourNumber || ''}
                        originCity={data.cityFrom || ''}
                        destinationCity={data.cityTo || ''}
                    />
                ),
            };
        }

        case NotificationActionEnum.TOUR_RUN_OUT_OF_TIME_TO_ASSIGN_CARRIER: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: (
                    <RunOutOfTimeAssignCarrierCard
                        subjectNumber={data.tourNumber || ''}
                        originCity={data.cityFrom || ''}
                        destinationCity={data.cityTo || ''}
                    />
                ),
            };
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED: {
            const { data } = notification;

            return {
                emotion: data.status ? EMOTION_TRANSPORTATION_STATUS_MAP[data.status] : NotificationEmotionEnum.neutral,
                text: (
                    <StatusChangedCard
                        subjectType={SubjectTypeEnum.dispatch}
                        subjectNumber={notification.subjectNumber}
                        originCity={data.cityFrom}
                        destinationCity={data.cityTo}
                        actionType={SubjectTypeEnum.transportationOrder}
                        actionStatus={<TransportOrderColoredStatus status={data.status} />}
                    />
                ),
            };
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: (
                    <TransportationOrderMileagAndPaymentChangedCard
                        number={data.dispatchNumber || ''}
                        cityA={data.cityFrom}
                        cityB={data.cityTo}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_PLACED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.neutral,
                text: (
                    <SpotRequestStatusCard
                        type="new"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: (
                    <SpotRequestStatusCard
                        type="expired"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_BID_ACCEPTED:
        case NotificationActionEnum.SPOT_BID_PLACED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.neutral,
                text: (
                    <SpotBidStatusCard
                        type="new"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.SPOT_BID_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: (
                    <SpotBidStatusCard
                        type="expired"
                        fromLocation={data.cityFrom}
                        toLocation={data.cityTo}
                        spotRequestNumber={data.spotNumber}
                    />
                ),
            };
        }

        case NotificationActionEnum.DOCUMENT_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: (
                    <ExpiredPartnerDocumentCard
                        partnerName={data.companyName || ''}
                        partnerType={data.companyType || null}
                        documentType={data.documentType}
                    />
                ),
            };
        }
        case NotificationActionEnum.DOCUMENT_UPDATED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: (
                    <UpdatedPartnerDocumentCard
                        partnerName={data.companyName || ''}
                        partnerType={data.companyType || null}
                        documentType={data.documentType}
                    />
                ),
            };
        }
        case NotificationActionEnum.DOCUMENT_REVOKED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: (
                    <RevokedPartnerDocumentCard
                        partnerName={data.companyName || ''}
                        partnerType={data.companyType || null}
                        documentType={data.documentType}
                    />
                ),
            };
        }
        case NotificationActionEnum.DOCUMENT_COMPLETED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: (
                    <CompletedPartnerDocumentCard
                        partnerName={data.companyName || ''}
                        partnerType={data.companyType || null}
                        documentType={data.documentType}
                    />
                ),
            };
        }
        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: (
                    <ExpireSoonPartnerDocumentCard
                        partnerName={data.companyName || ''}
                        partnerType={data.companyType || null}
                        documentType={data.documentType}
                    />
                ),
            };
        }

        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpiredPartnerShipperContractCard contractName={data.contractName || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_UPDATED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: <UpdatedPartnerShipperContractCard contractName={data.contractName || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_REVOKED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <RevokedPartnerShipperContractCard contractName={data.contractName || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpireSoonPartnerShipperContractCard contractName={data.contractName || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <CompletedPartnerShipperContractCard contractName={data.contractName || '-'} />,
            };
        }

        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpiredPartnerShipperLaneContractCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: <UpdatedPartnerShipperLaneContractCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <RevokedPartnerShipperLaneContractCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpireSoonPartnerShipperLaneContractCard id={data.tztLaneId || '-'} />,
            };
        }
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <CompletedPartnerShipperLaneContractCard id={data.tztLaneId || '-'} />,
            };
        }

        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpiredPartnerCarrierContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_UPDATED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.positive,
                text: <UpdatedPartnerCarrierContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_REVOKED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <RevokedPartnerCarrierContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.negative,
                text: <ExpireSoonPartnerCarrierContractCard contractName={data.contractName || ''} />,
            };
        }
        case NotificationActionEnum.CARRIER_CONTRACT_COMPLETED: {
            const { data } = notification;

            return {
                emotion: NotificationEmotionEnum.attention,
                text: <CompletedPartnerCarrierContractCard contractName={data.contractName || ''} />,
            };
        }

        default: {
            return null;
        }
    }
};

export const getNotificationLink: NotificationLinkFactoryT = (notification) => {
    switch (notification.action) {
        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_DRIVER:
        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_ASSETS:
        case NotificationActionEnum.RUN_OUT_OF_TIME_ASSIGN_ASSETS:
        case NotificationActionEnum.RUNNING_OUT_OF_TIME_ASSIGN_DRIVER:
        case NotificationActionEnum.TOUR_STATUS_CHANGED:
        case NotificationActionEnum.SHIPMENT_MISSED:
        case NotificationActionEnum.SHIPMENT_NO_SHOW:
        case NotificationActionEnum.SHIPMENT_STATUS_CHANGED: {
            const { data } = notification;

            return urlFactory.dispatchDetails(data.dispatchId || 'null');
        }

        case NotificationActionEnum.ORDER_STATUS_CHANGED:
        case NotificationActionEnum.TRANSPORTATION_ORDER_STATUS_CHANGED: {
            return urlFactory.dispatchDetails(notification.data.dispatchId || 'null');
        }

        case NotificationActionEnum.TRANSPORTATION_ORDER_MILEAGE_AND_PAYMENT_CHANGED: {
            return urlFactory.dispatchDetails(notification.data.dispatchId || 'null');
        }

        case NotificationActionEnum.SPOT_PLACED:
        case NotificationActionEnum.SPOT_EXPIRED:
        case NotificationActionEnum.SPOT_BID_PLACED:
        case NotificationActionEnum.SPOT_BID_ACCEPTED:
        case NotificationActionEnum.SPOT_BID_EXPIRED: {
            return urlFactory.spotRequestDetails(notification.data.spotId || 'null');
        }
        case NotificationActionEnum.DOCUMENT_UPLOAD:
        case NotificationActionEnum.DOCUMENT_EXPIRED:
        case NotificationActionEnum.DOCUMENT_REVOKED:
        case NotificationActionEnum.DOCUMENT_UPDATED:
        case NotificationActionEnum.DOCUMENT_COMPLETED:
        case NotificationActionEnum.DOCUMENT_EXPIRATION_SOON: {
            const { documentType, companyType, companyId } = notification.data;

            return urlFactory.partnerDocumentDetails(companyId || '', companyType as PartnerTypeEnum, documentType);
        }

        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRED:
        case NotificationActionEnum.SHIPPER_CONTRACT_REVOKED:
        case NotificationActionEnum.SHIPPER_CONTRACT_UPDATED:
        case NotificationActionEnum.SHIPPER_CONTRACT_COMPLETED:
        case NotificationActionEnum.SHIPPER_CONTRACT_EXPIRATION_SOON: {
            const { companyType, companyId } = notification.data;

            return urlFactory.partnerShipperContactDetails({
                partnerId: companyId || '-',
                partnerType: companyType as PartnerTypeEnum,
                shipperContractId: notification.subjectId || '-',
            });
        }

        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPLOAD:
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRED:
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_REVOKED:
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_UPDATED:
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_COMPLETED:
        case NotificationActionEnum.SHIPPER_CONTRACT_LANE_EXPIRATION_SOON: {
            const { companyType, companyId, contractId, laneId } = notification.data;

            return urlFactory.partnerShipperContractLane({
                partnerId: companyId || '-',
                partnerType: companyType as PartnerTypeEnum,
                contractId: contractId || '-',
                laneId: laneId || '-',
            });
        }

        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRED:
        case NotificationActionEnum.CARRIER_CONTRACT_REVOKED:
        case NotificationActionEnum.CARRIER_CONTRACT_UPDATED:
        case NotificationActionEnum.CARRIER_CONTRACT_COMPLETED:
        case NotificationActionEnum.CARRIER_CONTRACT_EXPIRATION_SOON: {
            const { companyType, companyId, contractId } = notification.data;

            return urlFactory.partnerCarrierContactDetails(
                companyId || '-',
                companyType as PartnerTypeEnum,
                contractId || '-',
            );
        }

        case NotificationActionEnum.TOUR_RUNNING_OUT_OF_TIME_TO_ASSIGN_CARRIER:
        case NotificationActionEnum.TOUR_RUN_OUT_OF_TIME_TO_ASSIGN_CARRIER: {
            const { dispatchId } = notification.data;

            return urlFactory.dispatchDetails(dispatchId || '-');
        }

        default: {
            return null;
        }
    }
};

const checkIsBrokerAnyAlert = (anyAlert: AnyAlert | null | undefined): anyAlert is BrokerAnyAlert => {
    return anyAlert instanceof BrokerAnyAlert;
};

export const renderAlertToast: AlertToastRendererT = (t, anyAlert) => {
    if (!checkIsBrokerAnyAlert(anyAlert)) {
        return null;
    }

    switch (anyAlert.payload.type) {
        case BrokerAlertTypeEnum.spotRequestCancelled: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.tomatoRed,
                icon: <DoneIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />,
                text: t('alerts.spot-request-cancelled'),
            };
        }
        case BrokerAlertTypeEnum.transportOrderAssigned: {
            return {
                id: anyAlert.id,
                theme: AlertThemeEnum.brandAccent,
                icon: <DoneIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />,
                text: t('alerts.transport-order-assigned'),
            };
        }
        default: {
            return null;
        }
    }

    // TODO
    return null;
};

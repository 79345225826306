import * as React from 'react';
import { useMemo } from 'react';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateEntityStatus } from 'common/store/state-machine/slice';
import { ApiShipmentStatusT, ShipmentStatusEnum } from 'common/utils/api/models';
import { AvailableSubjectStatusesResponseT, StateMachineEntityDescriptorT } from 'common/store/state-machine/models';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import LoadStatus from 'common/components/status/LoadStatus/LoadStatus';
import CancelLastShipmentConfirmation, {
    CancelLastShipmentConfirmationDataT,
} from 'common/layouts/dialogs/CancelLastShipmentConfirmation/CancelLastShipmentConfirmation';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import ChangeForwardStatusControlOptionLabel from 'common/components/dropdowns/options/ChangeForwardStatusControlOptionLabel/ChangeForwardStatusControlOptionLabel';
import ChangeBackwardStatusControlOptionLabel from 'common/components/dropdowns/options/ChangeBackwardStatusControlOptionLabel/ChangeBackwardStatusControlOptionLabel';
import ChangeBlockedStatusControlOptionLabel from 'common/components/dropdowns/options/ChangeBlockedStatusControlOptionLabel/ChangeBlockedStatusControlOptionLabel';
import { addSeparatorAfterEachOption } from 'common/components/dropdowns/utils/add-separators';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { useStateMachine } from 'common/utils/hooks/useStateMachine';

type PropsT = {
    className?: string;
    isShowLoading: boolean;
    dispatchId: DispatchIdT | null;
    shipmentId: ShipmentIdT | null;
    isCurrentLastNotCanceledShipment: boolean;
};

const blockedI18nMap: Record<NonNullable<AvailableSubjectStatusesResponseT['description']>, string> = {
    HAS_LOADING_UNLOADING_SHIPMENT: 'change-shipment-status.blocked.HAS_LOADING_UNLOADING_SHIPMENT',
    TOUR_NOT_STARTED: 'change-shipment-status.blocked.TOUR_NOT_STARTED',
    NOT_FULLY_ASSIGN: 'change-shipment-status.blocked.NOT_FULLY_ASSIGN',
    ALL_SHIPMENTS_NOT_DELIVERED: 'change-shipment-status.blocked.ALL_SHIPMENTS_NOT_DELIVERED',
};

const positiveStatusSet = new Set<ApiShipmentStatusT>([ShipmentStatusEnum.delivered]);

const negativeStatusSet = new Set<ApiShipmentStatusT>([ShipmentStatusEnum.canceled]);

const ChangeShipmentStatusControl: React.FC<PropsT> = React.memo((props) => {
    const { className, isShowLoading, shipmentId, isCurrentLastNotCanceledShipment } = props;

    const cancelLastShipmentConfirmation = useModalDialog<CancelLastShipmentConfirmationDataT>();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const descriptor = useMemo((): StateMachineEntityDescriptorT => {
        return {
            entityType: 'SHIPMENT',
            entityId: shipmentId || '-',
        };
    }, [shipmentId]);

    const { availableSubjectStatuses, fetchRequest, updateRequest } = useStateMachine<ApiShipmentStatusT>(descriptor);

    useCloseModalDialogAfterRequest(updateRequest, [cancelLastShipmentConfirmation]);

    const options: Array<DropdownControlOptionT | null | undefined> = [
        ...(availableSubjectStatuses?.description
            ? [
                  {
                      label: (
                          <ChangeBlockedStatusControlOptionLabel
                              reason={t(blockedI18nMap[availableSubjectStatuses.description])}
                          />
                      ),
                      isDisabled: true,
                      onSelect: () => {
                          // nothing
                      },
                  },
              ]
            : []),
        ...(availableSubjectStatuses?.backward || []).map((status) => {
            return {
                label: (
                    <ChangeBackwardStatusControlOptionLabel
                        isPositiveIconStatus={positiveStatusSet.has(status)}
                        isNegativeIconStatus={negativeStatusSet.has(status)}
                        statusNode={<LoadStatus status={status} />}
                    />
                ),
                onSelect: () => {
                    const update = {
                        entityType: 'SHIPMENT',
                        entityId: shipmentId || '-',
                        status,
                    } as const;

                    if (isCurrentLastNotCanceledShipment && status === ShipmentStatusEnum.canceled) {
                        cancelLastShipmentConfirmation.setData({
                            update,
                            descriptor,
                        });
                        return;
                    }

                    dispatch(
                        updateEntityStatus({
                            update,
                            descriptor,
                        }),
                    );
                },
            };
        }),
        ...(availableSubjectStatuses?.forward || []).map((status) => {
            return {
                label: (
                    <ChangeForwardStatusControlOptionLabel
                        isPositiveIconStatus={positiveStatusSet.has(status)}
                        isNegativeIconStatus={negativeStatusSet.has(status)}
                        statusNode={<LoadStatus status={status} />}
                    />
                ),
                onSelect: () => {
                    const update = {
                        entityType: 'SHIPMENT',
                        entityId: shipmentId || '-',
                        status,
                    } as const;

                    if (isCurrentLastNotCanceledShipment && status === ShipmentStatusEnum.canceled) {
                        cancelLastShipmentConfirmation.setData({
                            update,
                            descriptor,
                        });
                        return;
                    }

                    dispatch(
                        updateEntityStatus({
                            update: {
                                entityType: 'SHIPMENT',
                                entityId: shipmentId || '-',
                                status,
                            },
                            descriptor,
                        }),
                    );
                },
            };
        }),
    ];

    const optionsWithSeparators = addSeparatorAfterEachOption(options);

    const isLoading = fetchRequest?.loading || updateRequest?.loading;

    const handleConfirmCancelLastShipment = (data: CancelLastShipmentConfirmationDataT) => {
        dispatch(updateEntityStatus(data));
    };

    if (!isShowLoading) {
        return null;
    }

    return (
        <>
            <DropdownControl
                className={className}
                options={optionsWithSeparators}
                isDisabled={isLoading}
                isShowTriggerForEmptyOptions={isLoading}
                renderTrigger={(isActive, onClick) => (
                    <TransparentTrigger
                        isDisabled={isLoading}
                        isPressed={isActive}
                        onClick={onClick}
                        label={t('common:change-status-control.trigger')}
                        rightIcon={
                            isLoading ? (
                                <ControlLoaderIcon
                                    fillColor={StyleGuideColorsEnum.brandAccent}
                                    size={DEFAULT_ICON_SIZE}
                                />
                            ) : (
                                <ArrowsIcon />
                            )
                        }
                        reflectionTheme={ReflectionThemeEnum.light}
                    />
                )}
                overlayPosition={DropdownOverlayPositionEnum.bottomRight}
            />
            <ClickInterceptorLabel>
                <CancelLastShipmentConfirmation
                    requestStatus={updateRequest}
                    data={cancelLastShipmentConfirmation.data}
                    onClose={cancelLastShipmentConfirmation.onClose}
                    onCancel={cancelLastShipmentConfirmation.onCancel}
                    onConfirm={handleConfirmCancelLastShipment}
                />
            </ClickInterceptorLabel>
        </>
    );
});

export default ChangeShipmentStatusControl;

import { OrderTypeEnum } from './OrderTypeDropdown/OrderTypeDropdown';
import { TransportOrderTypeEnum } from './TransportOrderTypeDropdown/TransportOrderTypeDropdown';

export enum FieldsEnum {
    originAddress = 'originAddress',
    dropOffAddress = 'dropOffAddress',

    pickupDates = 'pickupDates',
    dropoffDates = 'dropoffDates',

    createDates = 'createDates',
    dictTrailerId = 'dictTrailerId',

    shipperId = 'shipperId',
    shipperName = 'shipperName',

    carrierId = 'carrierId',
    carrierName = 'carrierName',

    ordersType = 'ordersType',
    transportOrdersType = 'transportOrdersType',

    dispatcherId = 'dispatcherId',
    dispatcherName = 'dispatcherName',

    keyAccountManagerId = 'keyAccountManagerId',
    keyAccountManagerName = 'keyAccountManagerName',

    hasUnpaidOrders = 'hasUnpaidOrders',
}

export type FormValuesT = {
    [FieldsEnum.originAddress]: string | null;
    [FieldsEnum.dropOffAddress]: string | null;

    [FieldsEnum.pickupDates]: DateRangeT | null;
    [FieldsEnum.dropoffDates]: DateRangeT | null;

    [FieldsEnum.createDates]: DateRangeT | null;
    [FieldsEnum.dictTrailerId]: string | null;

    [FieldsEnum.dispatcherId]: string | null;
    [FieldsEnum.dispatcherName]: string | null;

    [FieldsEnum.keyAccountManagerId]: string | null;
    [FieldsEnum.keyAccountManagerName]: string | null;

    [FieldsEnum.shipperId]: string | null;
    [FieldsEnum.shipperName]: string | null;

    [FieldsEnum.carrierId]: string | null;
    [FieldsEnum.carrierName]: string | null;

    [FieldsEnum.ordersType]: OrderTypeEnum;
    [FieldsEnum.transportOrdersType]: TransportOrderTypeEnum;

    [FieldsEnum.hasUnpaidOrders]: boolean | null;
};

import * as React from 'react';
import { ApiDispatchStatusT, DispatchStatusEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    className?: string;
    status: ApiDispatchStatusT | null | undefined;
};

export const DISPATCH_STATUS_T_MAP: Record<ApiDispatchStatusT, string> = {
    [DispatchStatusEnum.newDispatch]: 'common:dispatch-status.NEW_DISPATCH',
    [DispatchStatusEnum.carrierUnassigned]: 'common:dispatch-status.CARRIER_UNASSIGNED',
    [DispatchStatusEnum.carrierAssigned]: 'common:dispatch-status.CARRIER_ASSIGNED',
    [DispatchStatusEnum.inTransit]: 'common:dispatch-status.IN_TRANSIT',
    [DispatchStatusEnum.canceled]: 'common:dispatch-status.CANCELED',
    [DispatchStatusEnum.delivered]: 'common:dispatch-status.DELIVERED',
    [DispatchStatusEnum.unknown]: 'common:dispatch-status.UNKNOWN',
};

const DispatchStatus: React.FC<PropsT> = React.memo((props) => {
    const { status } = props;

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return t(DISPATCH_STATUS_T_MAP[status]);
});

export default DispatchStatus;

import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './MultiPointOrderRouteMap.scss';

import GoogleMapReact from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import GoogleMapContext from 'common/contexts/google-map-context';
import { ApiShipmentStatusT, ShipmentStatusEnum } from 'common/utils/api/models';
import last from 'lodash/last';
import { AssetTypeEnum } from 'common/constants';
import { RequestStatusT } from 'common/utils/request-status';
import { TrackPointT } from 'common/store/asset-track/models';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import MapBound, { BoundPointT } from 'common/components/maps/MapBound/MapBound';
import MapLoader from '../maps/MapLoader/MapLoader';
import { MapPointT, PointGeoJsonPropertiesT } from './models';
import Supercluster from 'supercluster';
import isNumber from 'lodash/isNumber';
import LastAssetTrackPointPin from 'common/components/maps/pins/LastAssetTrackPointPin/LastAssetTrackPointPin';
import WaypointNumberPin from 'common/components/maps/pins/WaypointNumberPin/WaypointNumberPin';
import { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';
import PointStackClusterPin from './PointStackClusterPin/PointStackClusterPin';
import { visitedOriginLoadStatusSet } from './constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cx = classNames.bind(styles);

export type MultiPointOrderRouteMapPropsT = {
    withRouteTrack?: boolean;
    points: Array<MapPointT | null> | null;
    loadStatus?: ApiShipmentStatusT;
    route: Array<GooglePolylineT> | null;
    routeRequest?: RequestStatusT;
    truckTrack?: Array<TrackPointT>;
    truckTrackRequest?: RequestStatusT;
    truckRoute?: Array<GooglePolylineT> | null;
    truckRouteRequest?: RequestStatusT;
    trailerTrack?: Array<TrackPointT>;
    trailerTrackRequest?: RequestStatusT;
    trailerRoute?: Array<GooglePolylineT> | null;
    trailerRouteRequest?: RequestStatusT;
    isLoading?: boolean;
};

const FEATURES_LIMIT = 99999;
const DEFAULT_OFFSET = 0;

const SUPERCLUSTER_SETTINGS: Supercluster.Options<PointGeoJsonPropertiesT, any> = {
    radius: 100,
    maxZoom: 22,
};

const MultiPointOrderRouteMap: React.FC<MultiPointOrderRouteMapPropsT> = React.memo((props) => {
    const {
        points,
        loadStatus,
        route,
        routeRequest,
        withRouteTrack,
        truckTrack,
        truckTrackRequest,
        truckRoute,
        truckRouteRequest,
        trailerTrack,
        trailerTrackRequest,
        trailerRoute,
        trailerRouteRequest,
        children,
    } = props;

    const googleMapContext = React.useContext(GoogleMapContext);

    const [api, setApi] = React.useState<TODO>(null);
    const [config, setConfig] = React.useState<TODO>(null);

    const allMapPoints = React.useMemo((): Array<BoundPointT> => {
        if (!points) {
            return [];
        }

        return points.reduce<Array<BoundPointT>>((acc, point) => {
            if (!point) {
                return acc;
            }

            acc.push([point.latitude, point.longitude]);

            return acc;
        }, []);
    }, [points]);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        setApi(api);

        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const lastTruckTrackPoint = last(truckTrack);
    const lastTrailerTrackPoint = last(trailerTrack);

    const allGeoJSONs = React.useMemo(() => {
        const geoJSONs: Array<Supercluster.PointFeature<PointGeoJsonPropertiesT>> = [];

        points?.forEach((point, index) => {
            const longitude = point?.longitude;
            const latitude = point?.latitude;
            if (isNumber(latitude) && isNumber(longitude) && point) {
                const geoJson: Supercluster.PointFeature<PointGeoJsonPropertiesT> = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [longitude, latitude],
                    },
                    properties: {
                        type: 'point',
                        point,
                        pointNumber: index + 1,
                    },
                };

                geoJSONs.push(geoJson);
            }
        });

        if (lastTrailerTrackPoint) {
            geoJSONs.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [lastTrailerTrackPoint.lng, lastTrailerTrackPoint.lat],
                },
                properties: {
                    type: 'trailer-last-point',
                    point: lastTrailerTrackPoint,
                },
            });
        }

        if (lastTruckTrackPoint) {
            geoJSONs.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [lastTruckTrackPoint.lng, lastTruckTrackPoint.lat],
                },
                properties: {
                    type: 'truck-last-point',
                    point: lastTruckTrackPoint,
                },
            });
        }

        return geoJSONs;
    }, [points, lastTruckTrackPoint, lastTrailerTrackPoint]);

    const allGeoJSONsClusterator = React.useMemo(() => {
        const clusterator = new Supercluster<PointGeoJsonPropertiesT>(SUPERCLUSTER_SETTINGS);
        clusterator.load(allGeoJSONs || []);
        return clusterator;
    }, [allGeoJSONs]);

    const allGeoJSONsClusters = React.useMemo(() => {
        if (config) {
            const clusters = allGeoJSONsClusterator.getClusters(config.bounds, config.zoom);
            return clusters;
        }

        return [];
    }, [config, allGeoJSONsClusterator]);

    const handleChange = () => {
        if (api) {
            const { zoom } = api.map;

            const bound = api.map.getBounds();

            const northEast = bound.getNorthEast();
            const southWest = bound.getSouthWest();

            setConfig({
                zoom,
                bounds: [southWest.lng(), southWest.lat(), northEast.lng(), northEast.lat()],
            });
        }
    };

    const renderSinglePointFeature = (properties: PointGeoJsonPropertiesT) => {
        switch (properties.type) {
            case 'trailer-last-point': {
                return (
                    <LastAssetTrackPointPin
                        key="trailer-last-point"
                        iconType={AssetTypeEnum.trailer}
                        lat={properties.point.lat}
                        lng={properties.point.lng}
                        timestamp={properties.point.timestamp}
                    />
                );
            }
            case 'truck-last-point': {
                return (
                    <LastAssetTrackPointPin
                        key="truck-last-point"
                        iconType={AssetTypeEnum.truck}
                        lat={properties.point.lat}
                        lng={properties.point.lng}
                        timestamp={properties.point.timestamp}
                    />
                );
            }
            case 'point': {
                const { point, pointNumber } = properties;

                let theme = MapPinThemeEnum.charcoal;
                if (point.driveThru) {
                    theme = MapPinThemeEnum.gray;
                }

                // TODO change logic
                const isVisited = visitedOriginLoadStatusSet.has(loadStatus as ShipmentStatusEnum);
                if (isVisited) {
                    theme = MapPinThemeEnum.brandDark;
                }

                return (
                    <WaypointNumberPin
                        key={`route-point-${point.index}-${pointNumber}`}
                        className={cx('route-point-pin')}
                        theme={theme}
                        lng={point.longitude}
                        lat={point.latitude}
                    >
                        {pointNumber}
                    </WaypointNumberPin>
                );
            }
            default: {
                return null;
            }
        }
    };

    const handleClusterClick = React.useCallback(
        (clusterId: number) => {
            const { map, maps } = api;

            const clusterFeatures = allGeoJSONsClusterator.getLeaves(clusterId, FEATURES_LIMIT, DEFAULT_OFFSET);

            const bounds = new maps.LatLngBounds();

            clusterFeatures.forEach((feature: Supercluster.PointFeature<{}>) => {
                const point = new maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);

                bounds.extend(point);
            });

            map.fitBounds(bounds);
        },
        [api, allGeoJSONsClusterator],
    );

    const isRouteRequestLoading = routeRequest?.loading || truckRouteRequest?.loading || trailerRouteRequest?.loading;

    const isLoading =
        props.isLoading || isRouteRequestLoading || truckTrackRequest?.loading || trailerTrackRequest?.loading;

    return (
        <>
            {isLoading && <MapLoader />}
            <GoogleMapReact
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
                onDragEnd={handleChange}
                onZoomAnimationEnd={handleChange}
                yesIWantToUseGoogleMapApiInternals
            >
                {allGeoJSONsClusters.map((cluster) => {
                    if ('cluster' in cluster.properties) {
                        const pointsFeatures = allGeoJSONsClusterator.getLeaves(
                            cluster.properties.cluster_id,
                            FEATURES_LIMIT,
                            DEFAULT_OFFSET,
                        );

                        const clusterProperties = pointsFeatures.map((pointFeatures) => {
                            return pointFeatures.properties;
                        });

                        return (
                            <PointStackClusterPin
                                key={`cluster-${cluster.properties.cluster_id}`}
                                clusterId={cluster.properties.cluster_id}
                                lng={cluster.geometry.coordinates[0]}
                                lat={cluster.geometry.coordinates[1]}
                                clusterProperties={clusterProperties}
                                onClusterClick={handleClusterClick}
                                withoutArrow={config.zoom < 20}
                                loadStatus={loadStatus}
                            />
                        );
                    }

                    return renderSinglePointFeature(cluster.properties as PointGeoJsonPropertiesT);
                })}
            </GoogleMapReact>
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                polylines={route}
                theme={withRouteTrack ? MapRouteThemeEnum.trackFuture : MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                polylines={truckRoute}
                theme={withRouteTrack ? MapRouteThemeEnum.trackFuture : MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                geometryLibrary={googleMapContext.googleMaps?.libraries?.geometry}
                polylines={trailerRoute}
                theme={withRouteTrack ? MapRouteThemeEnum.trackFuture : MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={trailerTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={truckTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapBound
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                allMapPoints={allMapPoints}
            />
            {children}
        </>
    );
});

export default MultiPointOrderRouteMap;

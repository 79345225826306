import React, { useCallback, useMemo } from 'react';

import { PropsT as InputPropsT } from 'common/components/Input/Input';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';
import {
    AsyncRequestFactoryT,
    useAsyncOptionsRequest,
} from 'design-system/components/dropdowns/SuggestInput/hooks/use-async-options-request';
import DriverAssignOptionLabel from './DriverAssignOptionLabel/DriverAssignOptionLabel';
import ControlLoaderIcon, { ControlLoaderIconProps } from 'common/icons/ControlLoaderIcon';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import { ApiShortDriverT } from 'common/utils/api/models';
import keyBy from 'lodash/keyBy';
import DriverIcon, { DriverIconProps } from 'common/icons/DriverIcon';
import Avatar, { AvatarSizeEnum } from 'common/components/Avatar/Avatar';

type ValueT = DriverIdT;

type OptionT = {
    name: NonNullable<ApiShortDriverT['name']>;
    id: ApiShortDriverT['id'];
};

export type PropsT = {
    transportOrderId: TransportOrderIdT | null;
    initialValue?: ValueT;
    initialLabel?: string;
    onSelect: (value: ValueT | null, name?: string | null) => void;
    value: ValueT | null;
    overlayPosition?: DropdownOverlayPositionEnum;
    hasError: InputPropsT['hasError'];
    hasWarning: InputPropsT['hasWarning'];
    inputPlaceholder: InputPropsT['placeholder'];
    placeholder: InputPropsT['placeholder'];
    onBlur: InputPropsT['onBlur'];
    onFocus: InputPropsT['onFocus'];
    isDisabled?: InputPropsT['isDisabled'];
    hasChanges?: boolean;
};

const DriverSuggestInput: React.FC<PropsT> = (props) => {
    const {
        transportOrderId,
        initialValue,
        initialLabel,
        value,
        onSelect,
        hasWarning,
        hasError,
        overlayPosition,
        isDisabled,
        inputPlaceholder,
        placeholder,
        onBlur,
        onFocus,
        hasChanges,
    } = props;

    const initialOption = useMemo((): OptionT | null => {
        if (!initialValue) {
            return null;
        }

        return {
            name: initialLabel || 'Unknown user',
            id: initialValue,
        };
    }, [initialLabel, initialValue]);

    const requestFactory: AsyncRequestFactoryT<OptionT> = useCallback(
        async (query) => {
            if (!transportOrderId) {
                return [null, null];
            }

            const [error, response] = await carrierTranziitApi.getAvailableDrivers(transportOrderId, query || '');
            if (error) {
                return [error, null];
            }

            const availableDrivers = (response || []).filter((driver) => {
                return value !== driver.id;
            });

            const options = availableDrivers.map((driver): OptionT => {
                return {
                    name: driver.name || '',
                    id: driver.id,
                };
            });

            return [null, options];
        },
        [transportOrderId, value],
    );

    const getOptionText = (option: OptionT): string => {
        return option.name || '';
    };

    const asyncOptionsRequest = useAsyncOptionsRequest<OptionT>({
        initialOption: null,
        requestFactory,
    });

    const handleFocus = useCallback(() => {
        if (onFocus) {
            onFocus();
        }
    }, [onFocus]);

    const renderOption = (option: OptionT) => {
        return <DriverAssignOptionLabel driver={option} />;
    };

    const renderTrigger = (option: OptionT | undefined, placeholder?: string) => {
        if (!option) {
            return placeholder;
        }

        return option.name;
    };

    const options = React.useMemo(() => {
        const hasInitialOption =
            initialOption?.id &&
            asyncOptionsRequest.options?.some((option) => {
                return option?.id === initialOption?.id;
            });

        const result = [...asyncOptionsRequest.options];

        if (!hasInitialOption && initialOption) {
            result.push(initialOption);
        }

        return result;
    }, [asyncOptionsRequest.options, initialOption]);

    const optionByUserId = React.useMemo(() => {
        return keyBy(options, 'id');
    }, [options]);

    const selectedOption = optionByUserId[value as string] || null;

    return (
        <DropdownSearchInput<OptionT, ValueT | null>
            selectedValue={value}
            options={options}
            onSelect={(value) => {
                const option = value ? optionByUserId[value] : null;
                const label = option ? getOptionText(option) : '';

                if (option) {
                    onSelect(value, label);
                } else {
                    onSelect(null, null);
                }
            }}
            renderTrigger={renderTrigger}
            renderOption={renderOption}
            getOptionValue={(option) => option?.id}
            onChangeQuery={asyncOptionsRequest.onChangeQuery}
            renderLeftIcon={(iconMeta) => {
                if (selectedOption) {
                    return <Avatar hash={selectedOption?.name || ''} size={AvatarSizeEnum.small} />;
                }

                return <DriverIcon {...DriverIconProps.getControlProps(iconMeta)} />;
            }}
            renderRightIcon={() =>
                asyncOptionsRequest.requestStatus.loading ? (
                    <ControlLoaderIcon {...ControlLoaderIconProps.getFetchDataProps()} />
                ) : null
            }
            onBlur={onBlur}
            onFocus={handleFocus}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            isDisabled={isDisabled}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
            inputPlaceholder={inputPlaceholder}
            placeholder={placeholder}
            hasOptionsSeparator
            onReset={() => {
                onSelect(null, null);
            }}
        />
    );
};

export default DriverSuggestInput;

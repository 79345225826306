import * as React from 'react';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { Trans, useTranslation } from 'react-i18next';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import ShipperContractLaneStatus from 'common/components/status/ShipperContractLaneStatus/ShipperContractLaneStatus';
import { formatDateRangeFilterValue, pillIconRendererMap } from 'common/components/FilterPills/pill-renderers';
import TrailerDictIdFilterPill from 'common/components/filter-pills/TrailerDictIdFilterPill/TrailerDictIdFilterPill';

type PropsT = {
    isCompact?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, setQueryFilters } = props;

    const { t } = useTranslation();

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('common:shipper-contract-lanes.filters.pills.text', {
                        value: queryFilters[QueryFiltersKeysEnum.text],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.text],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const selectedTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

                    return (
                        <Trans
                            i18nKey="common:shipper-contract-lanes.filters.pills.trailer-type"
                            components={{
                                value: <TrailerDictIdFilterPill trailerDictId={selectedTrailerId} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.dictTrailerId],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('common:shipper-contract-lanes.filters.pills.address', {
                        value: queryFilters[QueryFiltersKeysEnum.address],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.address],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    return (
                        <Trans
                            i18nKey="common:shipper-contract-lanes.filters.pills.status"
                            components={{
                                value: <ShipperContractLaneStatus status={queryFilters[QueryFiltersKeysEnum.status]} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.status],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                renderIcon: (queryFilters, isCompact) => {
                    const name = queryFilters[QueryFiltersKeysEnum.createdByName] || '';

                    return pillIconRendererMap.avatar(name, isCompact);
                },
                render: (queryFilters) => {
                    return t('common:shipper-contract-lanes.filters.pills.created-by', {
                        value: queryFilters[QueryFiltersKeysEnum.createdByName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdById, QueryFiltersKeysEnum.createdByName],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const validFromStart = queryFilters[QueryFiltersKeysEnum.validFromStart];
                    const validFromFinish = queryFilters[QueryFiltersKeysEnum.validFromFinish];

                    return t('common:shipper-contract-lanes.filters.pills.valid-from', {
                        value: formatDateRangeFilterValue(validFromStart, validFromFinish),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.validFromStart, QueryFiltersKeysEnum.validFromFinish],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const validTillStart = queryFilters[QueryFiltersKeysEnum.validTillStart];
                    const validTillFinish = queryFilters[QueryFiltersKeysEnum.validTillFinish];

                    return t('common:shipper-contract-lanes.filters.pills.valid-till', {
                        value: formatDateRangeFilterValue(validTillStart, validTillFinish),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.validTillStart, QueryFiltersKeysEnum.validTillFinish],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const createdDateStart = queryFilters[QueryFiltersKeysEnum.createdDateStart];
                    const createdDateFinish = queryFilters[QueryFiltersKeysEnum.createdDateFinish];

                    return t('common:shipper-contract-lanes.filters.pills.created-dates', {
                        value: formatDateRangeFilterValue(createdDateStart, createdDateFinish),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdDateStart, QueryFiltersKeysEnum.createdDateFinish],
            },
        ],
        [t],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
        />
    );
});

export default SelectedFilters;

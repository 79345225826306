import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './DispatchListPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDispatchesByIds,
    selectDispatchesPages,
    selectDispatchesTotal,
} from 'broker-admin/store/dispatches/selectors';
import { CompanyTypeEnum } from 'common/constants';
import { QueryFiltersKeysEnum, QueryKeysEnum, QueryFiltersT } from './query-models';
import DispatchesTable from './DispatchesTable/DispatchesTable';
import { useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import history from 'common/utils/history';
import omit from 'lodash/omit';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import { DispatchesQuickFilterEnum, DispatchesSortEnum } from 'common/utils/api/models';
import Pagination from 'common/components/Table/Pagination/Pagination';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import { fetchDispatchesPage } from 'broker-admin/store/dispatches/actions';
import { DispatchT } from 'broker-admin/store/dispatches/models';
import FiltersSidebarContent from './FiltersSidebarContent/FiltersSidebarContent';
import SideBar from 'common/layouts/LeftMenuLayout/SideBar/SideBar';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import { QuickFiltersOptionT } from 'common/components/Table/QuickFilters/QuickFilters';
import { colorCodingNotificationLabelTheme } from 'common/components/notifications/NotificationLabel/NotificationLabel';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { selectDispatchStats } from 'broker-admin/store/dispatch-stats/selectors';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import { urlFactory } from 'broker-admin/utils/urls';
import TableError from 'common/components/Table/TableError/TableError';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { prepareFetchPageQuery } from 'broker-admin/layouts/DispatchesPage/DispatchListPage/prepare-fetch-page-query';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ListPageHeaderTabsLayout from 'common/layouts/ListPage/ListPageHeaderTabsLayout/ListPageHeaderTabsLayout';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import { selectGroupedNotificationCounts } from 'common/store/grouped-notification-counts/selectors';
import { fetchGroupedNotificationCounts } from 'common/store/grouped-notification-counts/actions';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import PageTitle from 'common/components/PageTitle/PageTitle';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import { useHistoryPush } from 'common/utils/hooks/useHistoryPush';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import { dispatchesPaginationChannel, dispatchesRefreshChannel } from 'broker-admin/store/dispatches/channels';
import SideBars from 'broker-admin/layouts/SideBars/SideBars';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { useQueryParams } from 'use-query-params';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import { checkFilters } from 'common/components/Table/utils/check-filters';
import QuickFiltersButtonGroups from 'common/components/Table/QuickFiltersButtonGroups/QuickFiltersButtonGroups';
import WarningsFilter from './WarningsFilter/WarningsFilter';

const cx = classNames.bind(styles);

type PropsT = {};

const DispatchListPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { historyPush } = useHistoryPush();
    const total = useSelector(selectDispatchesTotal);
    const dispatchesPages = useSelector(selectDispatchesPages);
    const dispatchById = useSelector(selectDispatchesByIds);

    const dispatchStat = useSelector(selectDispatchStats);

    const openLeftSidebar = useOpenLeftSidebar();

    const unReadNotificationsGroupedCounts = useSelector(selectGroupedNotificationCounts);

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    const [isShowFilters, triggerFilters] = React.useState<boolean>(false);
    const showFilters = (): void => {
        triggerFilters(true);
    };
    const hideFilters = (): void => {
        triggerFilters(false);
    };

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.dispatchesFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.dispatchesPage]: createPageNumberParam(),
        [QueryKeysEnum.dispatchesSort]: createSortParams<DispatchesSortEnum>({
            value: DispatchesSortEnum.createdDate,
            direction: SortDirectionEnum.DESC,
        }),
    });

    const pageNumber = query[QueryKeysEnum.dispatchesPage];
    const selectedSort = query[QueryKeysEnum.dispatchesSort];
    const queryFilters = query[QueryKeysEnum.dispatchesFilters];

    const page = dispatchesPages[pageNumber];
    const { ids, requestStatus } = page || {};

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchDispatchesPage(pageNumber, query, { isForceUpdate: false }));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: false }));
    }, [pageNumber, selectedSort, queryFilters]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchDispatchesPage(pageNumber, query, { isForceUpdate: true }));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: true }));
    }, [pageNumber, selectedSort, queryFilters]);
    useChannelSubscribe(dispatchesRefreshChannel, refreshPageHandler);

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchDispatchesPage(pageNumber, query));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: true }));

        return (): void => {
            // TODO reset
        };
    }, [page, pageNumber, selectedSort, queryFilters]);

    const dispatches = React.useMemo(() => {
        return (ids || []).map((id): DispatchT => dispatchById[id]);
    }, [ids, dispatchById]);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.dispatchesPage]: pageNumber,
            });
        },
        [query],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof dispatchesPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(dispatchesPaginationChannel, setPageHandler);

    const goToDispatchDetails = (event: React.MouseEvent, dispatchId: DispatchIdT) => {
        const url = urlFactory.dispatchDetails(dispatchId);
        historyPush(event, url);
    };

    const goToPartnerDetails = (companyId: PartnerIdT, companyType: CompanyTypeEnum) => {
        const url = urlFactory.partnerDetails(companyType, companyId);
        history.push(url);
    };

    const goToTrailerDetails = (companyId: CarrierIdT, trailerId: TrailerIdT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.trailerDetails,
            partnerId: companyId,
            trailerId,
        });
    };

    const dispatchesQuickFilterOptions: Array<QuickFiltersOptionT<DispatchesQuickFilterEnum>> = [
        {
            id: undefined,
            showPriority: 0,
            hidePriority: 0,
            label: t('dispatches.list.status-filter.all-dispatches'),
            testSelector: 'all',
        },
        {
            id: DispatchesQuickFilterEnum.new,
            showPriority: 1,
            hidePriority: 1,
            label: t('dispatches.list.status-filter.new'),
            testSelector: DispatchesQuickFilterEnum.new,
            notificationProps: {
                count: dispatchStat?.newDispatches,
                theme: colorCodingNotificationLabelTheme.attention,
            },
        },
        {
            id: DispatchesQuickFilterEnum.carrierUnassigned,
            showPriority: 2,
            hidePriority: 2,
            label: t('dispatches.list.status-filter.carrier-unassigned'),
            testSelector: DispatchesQuickFilterEnum.carrierUnassigned,
            notificationProps: {
                count: dispatchStat?.carrierUnassign,
                theme: colorCodingNotificationLabelTheme.attention,
            },
        },
        {
            id: DispatchesQuickFilterEnum.carrierAssigned,
            showPriority: 3,
            hidePriority: 3,
            label: t('dispatches.list.status-filter.carrier-assigned'),
            testSelector: DispatchesQuickFilterEnum.carrierAssigned,
            notificationProps: {
                count: dispatchStat?.assigned,
                theme: colorCodingNotificationLabelTheme.normal,
            },
        },
        {
            id: DispatchesQuickFilterEnum.inTransit,
            showPriority: 4,
            hidePriority: 4,
            label: t('dispatches.list.status-filter.in-transit'),
            testSelector: DispatchesQuickFilterEnum.inTransit,
            notificationProps: {
                count: dispatchStat?.inTransit,
                theme: colorCodingNotificationLabelTheme.normal,
            },
        },
        {
            id: DispatchesQuickFilterEnum.canceled,
            showPriority: 5,
            hidePriority: 5,
            label: t('dispatches.list.status-filter.canceled'),
            testSelector: DispatchesQuickFilterEnum.canceled,
            notificationProps: {
                count: unReadNotificationsGroupedCounts?.DISPATCH_STATUS_CHANGED?.CANCELED,
                theme: colorCodingNotificationLabelTheme.normal,
                isShowPlusSign: true,
            },
        },
        {
            id: DispatchesQuickFilterEnum.delivered,
            showPriority: 6,
            hidePriority: 6,
            label: t('dispatches.list.status-filter.delivered'),
            testSelector: DispatchesQuickFilterEnum.delivered,
            notificationProps: {
                count: unReadNotificationsGroupedCounts?.DISPATCH_STATUS_CHANGED?.DELIVERED,
                theme: colorCodingNotificationLabelTheme.success,
                isShowPlusSign: true,
            },
        },
    ];

    const sortOptions: Array<SortDropdownOptionT<DispatchesSortEnum>> = React.useMemo(
        () => [
            {
                label: t('dispatches.list.table.sorts.triggers.pickupDate'),
                value: DispatchesSortEnum.pickupDate,
            },
            {
                label: t('dispatches.list.table.sorts.triggers.dropOffDate'),
                value: DispatchesSortEnum.dropOffDate,
            },
            {
                label: t('dispatches.list.table.sorts.triggers.createdDate'),
                value: DispatchesSortEnum.createdDate,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<DispatchesSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.dispatchesPage]: 0,
            [QueryKeysEnum.dispatchesSort]: sort,
        });
    };

    const handleSetStatusFilter = (quickFilterId: DispatchesQuickFilterEnum | undefined) => {
        const prevQueryFilters = query[QueryKeysEnum.dispatchesFilters] || {};

        const queryFilters: QueryFiltersT = {
            ...prevQueryFilters,
            [QueryFiltersKeysEnum.quickFilter]: quickFilterId,
        };

        changeQuery({
            [QueryKeysEnum.dispatchesPage]: 0,
            [QueryKeysEnum.dispatchesFilters]: queryFilters,
        });
    };

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.dispatchesFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.dispatchesPage]: 0,
            [QueryKeysEnum.dispatchesFilters]: queryFilters,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.dispatchesFilters] || {};

        const queryFilters = {
            [QueryFiltersKeysEnum.quickFilter]: prevQueryFilters[QueryFiltersKeysEnum.quickFilter],
            ...selectedQueryFilters,
        };

        changeQuery({
            [QueryKeysEnum.dispatchesPage]: 0,
            [QueryKeysEnum.dispatchesFilters]: queryFilters,
        });

        hideFilters();
    };

    const handleSearch = (searchText: string) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.searchText]: searchText || undefined,
        });
    };

    const renderTableMessage = () => {
        if (!checkNeedRenderEmptyState(dispatches, requestStatus)) {
            return null;
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        const { hasPrimaryQueryFilters, hasSecondaryQueryFilters } = checkFilters(
            [queryFilters[QueryFiltersKeysEnum.quickFilter]],
            [omit(queryFilters, [QueryFiltersKeysEnum.quickFilter])],
        );

        if (requestStatus?.ok && hasSecondaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('dispatches.list.table.messages.not-found.title')}
                    description={t('dispatches.list.table.messages.not-found.description')}
                    isShowAction
                    actionTitle={t('dispatches.list.table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => handleSetQueryFilters({})}
                    testSelector="empty-state-has-secondary-filters"
                />
            );
        }

        if (requestStatus?.ok && hasPrimaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('dispatches.list.table.messages.empty-quick-filters.title')}
                    testSelector="empty-state-has-primary-filters"
                    isShowAction={false}
                />
            );
        }

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.empty}
                title={t('dispatches.list.table.messages.empty.action')}
                testSelector="empty"
                isShowAction={false}
            />
        );
    };

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.dispatches')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('dispatches.list.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
                <ListPageLayout>
                    <ListPageHeaderTabsLayout>
                        <QuickFiltersButtonGroups
                            options={dispatchesQuickFilterOptions}
                            selectedId={queryFilters[QueryFiltersKeysEnum.quickFilter]}
                            onSelect={handleSetStatusFilter}
                            moreStatusesLabel={t('dispatches.list.status-filter.more-statuses')}
                        />
                    </ListPageHeaderTabsLayout>
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                <FiltersTrigger
                                    className={cx('filters-trigger')}
                                    title={t('dispatches.list.filters.trigger')}
                                    isActive={isShowFilters}
                                    onClick={showFilters}
                                />
                                <WarningsFilter
                                    className={cx('warnings-filter')}
                                    stat={dispatchStat}
                                    queryFilters={queryFilters}
                                    onQueryFiltersChange={updateQueryFilters}
                                />
                                <SortDropdown
                                    className={cx('sort-trigger')}
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                />
                                <SearchControl
                                    placeholder={t('dispatches.list.search-placeholder')}
                                    searchText={queryFilters[QueryFiltersKeysEnum.searchText]}
                                    onChangeSearchText={handleSearch}
                                />
                            </>
                        }
                        filterTagsNode={
                            <SelectedFilters
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                            />
                        }
                    />
                    {renderTableMessage()}
                    <DispatchesTable
                        dispatches={dispatches}
                        className={cx('dispatches__table')}
                        goToDispatchDetails={goToDispatchDetails}
                        goToPartnerDetails={goToPartnerDetails}
                        goToTrailerDetails={goToTrailerDetails}
                        isLoading={requestStatus?.loading}
                    />
                </ListPageLayout>
                <StickyFooter>
                    <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
                </StickyFooter>
                <SideBar isShow={isShowFilters} onClose={hideFilters}>
                    {(onClose) => (
                        <FiltersSidebarContent
                            onClose={onClose}
                            queryFilters={queryFilters}
                            setQueryFilters={handleSetQueryFilters}
                        />
                    )}
                </SideBar>
            </ContentMargins>
            <SideBars />
        </ScrollableContent>
    );
});

export default DispatchListPage;

import { QueryFiltersT } from '../query-models';
import { FieldsEnum, FormValuesT } from './constants';
import { OrderTypeEnum } from 'broker-admin/layouts/DispatchesPage/DispatchListPage/FiltersSidebarContent/OrderTypeDropdown/OrderTypeDropdown';
import { TransportOrderTypeEnum } from 'broker-admin/layouts/DispatchesPage/DispatchListPage/FiltersSidebarContent/TransportOrderTypeDropdown/TransportOrderTypeDropdown';

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.originAddress]: '',
        [FieldsEnum.dropOffAddress]: '',

        [FieldsEnum.dispatcherId]: null,
        [FieldsEnum.dispatcherName]: null,

        [FieldsEnum.keyAccountManagerId]: null,
        [FieldsEnum.keyAccountManagerName]: null,

        [FieldsEnum.shipperId]: null,
        [FieldsEnum.shipperName]: null,

        [FieldsEnum.carrierId]: null,
        [FieldsEnum.carrierName]: null,

        [FieldsEnum.dictTrailerId]: null,

        [FieldsEnum.dropOffAddress]: null,
        [FieldsEnum.originAddress]: null,

        [FieldsEnum.ordersType]: OrderTypeEnum.all,
        [FieldsEnum.transportOrdersType]: TransportOrderTypeEnum.all,

        [FieldsEnum.createDates]: null,
        [FieldsEnum.pickupDates]: null,
        [FieldsEnum.dropoffDates]: null,

        [FieldsEnum.hasUnpaidOrders]: false,
    };

    return values;
};

export default getInitialValues;

import { CreateReserveQueryT, ReserveT } from 'common/store/order-creation/models';

export const getReserveQueryCorrection = (
    reserveQuery: CreateReserveQueryT | null,
    reserve: ReserveT | null,
): CreateReserveQueryT | null => {
    if (!reserve || !reserveQuery) {
        return null;
    }

    const hasCorrection = reserve?.points?.some((point) => {
        return !!point?.replacedCoordinate;
    });
    if (!hasCorrection) {
        return null;
    }

    const changeQuery: CreateReserveQueryT = {
        ...reserveQuery,
    };

    reserve?.points?.forEach((point, index) => {
        changeQuery.addresses[index] = {
            ...reserveQuery.addresses[index],
            address: {
                ...reserveQuery.addresses[index].address,
                latitude: point?.replacedCoordinate?.latitude || reserveQuery.addresses[index].address?.latitude,
                longitude: point?.replacedCoordinate?.longitude || reserveQuery.addresses[index].address?.longitude,
            },
        };
    });

    return changeQuery;
};

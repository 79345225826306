import * as React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './SpotAssetsAssignmentFooterContent.scss';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import SpotAssetsAssignmentForm from '../../SpotAssetsAssignmentForm/SpotAssetsAssignmentForm';
import { RequestStatusT } from 'common/utils/request-status';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onCancel: () => void;
    assignmentRequestStatus: RequestStatusT;
    onAssignAssets: (params: { transportOrderId: TransportOrderIdT; truckId: AssetIdT; trailerId: AssetIdT }) => void;
    onClearAssets: (params: { transportOrderId: TransportOrderIdT }) => void;
};

const SpotAssetsAssignmentFooterContent: React.FC<PropsT> = React.memo((props) => {
    const {
        onCancel,
        transportOrderId,
        transportOrderDetails,
        className,
        assignmentRequestStatus,
        onAssignAssets,
        onClearAssets,
    } = props;

    if (!transportOrderDetails || !transportOrderDetails?.spotInfo?.id) {
        return null;
    }

    return (
        <div className={cs(cx('content'), className)}>
            <SpotAssetsAssignmentForm
                transportOrderId={transportOrderId}
                transportOrderDetails={transportOrderDetails}
                onCancel={onCancel}
                assignmentRequestStatus={assignmentRequestStatus}
                onAssignAssets={onAssignAssets}
                onClearAssets={onClearAssets}
            />
        </div>
    );
});

export default SpotAssetsAssignmentFooterContent;

import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './TypeCell.scss';
import { useTranslation } from 'react-i18next';
import { PartnerT } from 'broker-admin/store/partners/models';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { PARTNER_TYPE_T_MAP } from 'common/components/pills/PartnerTypePill/PartnerTypePill';

type PropsT = {
    partner: PartnerT;
};

const PILL_LABEL_MAP: Record<PartnerTypeEnum, PillLabelThemeEnum> = {
    [PartnerTypeEnum.carrier]: PillLabelThemeEnum.charcoal,
    [PartnerTypeEnum.shipper]: PillLabelThemeEnum.brandDark,
    [PartnerTypeEnum.broker]: PillLabelThemeEnum.charcoal,
};

const cx = classNames.bind(styles);

const TypeCell: React.FC<PropsT> = React.memo((props) => {
    const { partner } = props;

    const { profileType = [] } = partner;

    const { t } = useTranslation();

    return (
        <div className={cx('type')}>
            {profileType.map((profileType) => (
                <PillLabel key={profileType} theme={PILL_LABEL_MAP[profileType]}>
                    {t(PARTNER_TYPE_T_MAP[profileType])}
                </PillLabel>
            ))}
        </div>
    );
});

export default TypeCell;

import { ContactPositionsEnum, RolesEnum, RoleT } from 'common/utils/api/models';

export const USER_ROLE_T_MAP: Record<RoleT, string> = {
    [RolesEnum.serviceUser]: 'common:team-members.roles.COMPANY_ADMIN',
    [RolesEnum.companyAdmin]: 'common:team-members.roles.COMPANY_ADMIN',
    [RolesEnum.driver]: 'common:team-members.roles.DRIVER',
    [RolesEnum.user]: 'common:team-members.roles.USER',
    [RolesEnum.keyAccountManager]: 'common:team-members.roles.KEY_ACCOUNT_MANAGER',
    [RolesEnum.dispatcher]: 'common:team-members.roles.DISPATCHER',
};

export const USER_POSITIONS_T_MAP: Record<ContactPositionsEnum, string> = {
    [ContactPositionsEnum.operator]: 'common:team-members.position.operator',
    [ContactPositionsEnum.management]: 'common:team-members.position.management',
    [ContactPositionsEnum.sales]: 'common:team-members.position.sales',
    [ContactPositionsEnum.owner]: 'common:team-members.position.owner',
    [ContactPositionsEnum.it]: 'common:team-members.position.it',
};

import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ActionOverlay.scss';
import cs from 'classnames';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';

const cx = classNames.bind(styles);

export type ActionOverlayPropsT = {
    className?: string;
    isActive: boolean;
    tooltipPosition?: TooltipPositionEnum;
    tooltipNode?: React.ReactNode;
};

const ActionOverlay: React.FC<ActionOverlayPropsT> = (props) => {
    const { className, children, tooltipNode, tooltipPosition, isActive } = props;

    return (
        <Tooltip
            position={tooltipPosition || TooltipPositionEnum.topCenter}
            theme={TooltipThemeEnum.black}
            tooltipNode={isActive ? tooltipNode : null}
        >
            {() => (
                <div className={cs(cx('wrap'), className)}>
                    {children}
                    {isActive ? <div className={cx('overlay')} /> : null}
                </div>
            )}
        </Tooltip>
    );
};

export default ActionOverlay;

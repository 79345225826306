import React, { useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './SpotAssetsAssignmentForm.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import AssetOptionLabel from './AssetOptionLabel/AssetOptionLabel';
import { useDispatch, useSelector } from 'react-redux';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { useFormik } from 'formik';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import {
    selectTrailerAssignmentInfoById,
    selectTrailerSuggestIds,
    selectTrailerSuggestRequestStatus,
    selectTruckAssignmentInfoById,
    selectTruckSuggestIds,
    selectTruckSuggestRequestStatus,
    selectVehicleLinks,
} from 'common/store/spot-assets-assigment/selectors';
import ScrollToFirstError from 'common/components/ScrollToFirstError/ScrollToFirstError';
import { findTrailer, findTruck } from 'common/store/spot-assets-assigment/actions';
import { logWarning } from 'common/utils/logger';
import TrailerIcon, { TrailerIconProps } from 'common/icons/TrailerIcon';
import TruckIcon, { TruckIconProps } from 'common/icons/TruckIcon';
import { RequestStatusT } from 'common/utils/request-status';
import DropdownSearchInput from 'design-system/components/dropdowns/DropdownSearchInput/DropdownSearchInput';

import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import mapValues from 'lodash/mapValues';

const cx = classNames.bind(styles);

type PropsT = {
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onCancel: () => void;
    assignmentRequestStatus: RequestStatusT;
    onAssignAssets: (params: { transportOrderId: TransportOrderIdT; truckId: AssetIdT; trailerId: AssetIdT }) => void;
    onClearAssets: (params: { transportOrderId: TransportOrderIdT }) => void;
};

type AssetOptionT = {
    id: AssetIdT;
    plateNumber: string;
    model?: string;
    ratePerKm?: number;
};

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.truckId]: null,
    [FieldsEnum.trailerId]: null,
};

const SpotAssetsAssignmentForm: React.FC<PropsT> = (props) => {
    const {
        onCancel,
        transportOrderId,
        transportOrderDetails,
        onClearAssets,
        onAssignAssets,
        assignmentRequestStatus,
    } = props;

    const { partnerId } = usePartnerContext();

    const openLeftSidebar = useOpenLeftSidebar();

    const trailer = transportOrderDetails?.trailer || null;
    const trailerId = trailer?.id || null;

    const truck = transportOrderDetails?.truck || null;
    const truckId = truck?.id;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
            [FieldsEnum.truckId]: truckId || null,
            [FieldsEnum.trailerId]: trailerId || null,
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, [trailerId, truckId]);

    const hasInitialAssets = trailerId && trailerId;

    const formik = useFormik<FormValuesT>({
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const trailerId = values[FieldsEnum.trailerId];
            const truckId = values[FieldsEnum.truckId];
            if (!trailerId || !truckId || !transportOrderId) {
                logWarning('failed to assign assets, empty any parameter');
                return;
            }

            onAssignAssets({
                transportOrderId,
                truckId,
                trailerId,
            });

            formikHelpers.setTouched({});
        },
    });

    const links = useSelector(selectVehicleLinks);

    const truckSuggestRequestStatus = useSelector(selectTruckSuggestRequestStatus);
    const truckSuggestIds = useSelector(selectTruckSuggestIds);
    const truckInfoById = useSelector(selectTruckAssignmentInfoById);
    const trucksOptions = useMemo(() => {
        const result: AssetOptionT[] = [];

        if (truck && !truckSuggestIds.includes(truck.id)) {
            result.push({
                id: truck?.id,
                plateNumber: truck?.plateNumber || '',
                model: truck?.dictTruckInfo?.model,
            });
        }

        truckSuggestIds.forEach((id) => {
            const info = truckInfoById[id] || null;

            result.push({
                id,
                plateNumber: info?.plateNumber || '',
                model: info?.model,
                ratePerKm: info?.ratePerKm,
            });
        });

        return result;
    }, [truckInfoById, truckSuggestIds, truck]);

    const [trailerQuery, setTrailerQuery] = React.useState<string>('');
    React.useEffect(() => {
        if (!transportOrderId) {
            return;
        }

        dispatch(
            findTrailer({
                transportOrderId,
                plateNumber: trailerQuery,
            }),
        );
    }, [trailerQuery]);

    const trailerSuggestRequestStatus = useSelector(selectTrailerSuggestRequestStatus);
    const trailerSuggestIds = useSelector(selectTrailerSuggestIds);
    const trailerInfoById = useSelector(selectTrailerAssignmentInfoById);
    const trailersOptions: AssetOptionT[] = useMemo(() => {
        const result: AssetOptionT[] = [];

        if (trailer && !trailerSuggestIds.includes(trailer.id)) {
            result.push({
                id: trailer?.id,
                plateNumber: trailer?.plateNumber || '',
                model: trailer?.dictTrailerInfo?.model,
            });
        }

        trailerSuggestIds.forEach((id) => {
            const info = trailerInfoById[id] || null;

            result.push({
                id,
                plateNumber: info?.plateNumber || '',
                model: info?.model,
                ratePerKm: info?.ratePerKm,
            });
        });

        return result;
    }, [trailerInfoById, trailerSuggestIds, trailer]);

    const getOptionValue = (option: AssetOptionT) => option.id;

    const renderOption = (option: AssetOptionT) => {
        return <AssetOptionLabel plateNumber={option.plateNumber} model={option.model} ratePerKm={option.ratePerKm} />;
    };

    const handleChangeTruck = (_: string, truckId: AssetOptionT['id'] | null) => {
        const formValueChanges: Partial<FormValuesT> = {
            [FieldsEnum.truckId]: truckId,
        };

        const linkedTrailerId = truckId ? links.byTruckId[truckId] : null;
        if (linkedTrailerId) {
            formValueChanges[FieldsEnum.trailerId] = linkedTrailerId;
        }

        formik.setValues((prevValues) => {
            return {
                ...prevValues,
                ...formValueChanges,
            };
        });

        // Set meta => touched true
        setTimeout(() => {
            formik.setTouched(mapValues(formValueChanges, () => true));
        }, 0);
    };

    const handleChangeTrailer = (_: string, trailerId: AssetOptionT['id'] | null) => {
        const formValueChanges: Partial<FormValuesT> = {
            [FieldsEnum.trailerId]: trailerId,
        };

        const linkedTruckId = trailerId ? links.byTrailerId[trailerId] : null;
        if (linkedTruckId) {
            formValueChanges[FieldsEnum.truckId] = linkedTruckId;
        }

        formik.setValues((prevValues) => {
            return {
                ...prevValues,
                ...formValueChanges,
            };
        });

        // Set meta => touched true
        setTimeout(() => {
            formik.setTouched(mapValues(formValueChanges, () => true));
        }, 0);
    };

    const handleClearAssets = () => {
        if (!transportOrderId) {
            return;
        }

        onClearAssets({
            transportOrderId,
        });
    };

    const renderTruckTrigger = (option: AssetOptionT | undefined, placeholder?: React.ReactNode) => {
        if (!option) {
            return placeholder;
        }

        return (
            <AssetOptionLabel
                plateNumber={option.plateNumber}
                model={option.model}
                onOpenDetails={() => {
                    openLeftSidebar(
                        {
                            type: CommonSidebarsTypeEnum.truckDetails,
                            truckId: option?.id,
                            partnerId,
                            isReadOnly: true,
                            isDisableShowOnMap: true,
                        },
                        {
                            isForceShowBackAction: true,
                        },
                    );
                }}
            />
        );
    };

    const renderTrailerTrigger = (option: AssetOptionT | undefined, placeholder?: React.ReactNode) => {
        if (!option) {
            return placeholder;
        }

        return (
            <AssetOptionLabel
                plateNumber={option.plateNumber}
                model={option.model}
                onOpenDetails={() => {
                    openLeftSidebar(
                        {
                            type: CommonSidebarsTypeEnum.trailerDetails,
                            trailerId: option?.id,
                            partnerId,
                            isReadOnly: true,
                            isDisableShowOnMap: true,
                        },
                        {
                            isForceShowBackAction: true,
                        },
                    );
                }}
            />
        );
    };

    return (
        <div className={cx('wrap')}>
            <form onSubmit={formik.handleSubmit}>
                <div className={cx('head')}>
                    <div className={cx('head__title')}>{t('common:spot-asset-transport-order-assignment.title')}</div>
                </div>
                <div className={cx('form')}>
                    <FormikField
                        className={cx('field')}
                        name={FieldsEnum.truckId}
                        error={formik.errors[FieldsEnum.truckId]}
                        meta={formik.getFieldMeta(FieldsEnum.truckId)}
                        label={t('common:spot-asset-transport-order-assignment.fields.truck.label')}
                        setFieldValue={handleChangeTruck}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DropdownSearchInput<AssetOptionT, AssetOptionT['id'] | null>
                                selectedValue={formik.values[FieldsEnum.truckId]}
                                options={trucksOptions}
                                onSelect={props.onChange}
                                renderTrigger={renderTruckTrigger}
                                renderOption={renderOption}
                                isLoading={truckSuggestRequestStatus.loading}
                                getOptionValue={getOptionValue}
                                onChangeQuery={(text) => {
                                    dispatch(
                                        findTruck({
                                            transportOrderId,
                                            plateNumber: text.trim(),
                                        }),
                                    );
                                }}
                                renderLeftIcon={(iconMeta) => (
                                    <TruckIcon {...TruckIconProps.getControlProps(iconMeta)} />
                                )}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                inputPlaceholder={t(
                                    'common:spot-asset-transport-order-assignment.fields.truck.input-placeholder',
                                )}
                                placeholder={t('common:spot-asset-transport-order-assignment.fields.truck.placeholder')}
                                hasOptionsSeparator
                                onReset={() => {
                                    props.onChange(null);
                                }}
                            />
                        )}
                    </FormikField>
                    <FormikField
                        className={cx('field')}
                        name={FieldsEnum.trailerId}
                        error={formik.errors[FieldsEnum.trailerId]}
                        meta={formik.getFieldMeta(FieldsEnum.trailerId)}
                        label={t('common:spot-asset-transport-order-assignment.fields.trailer.label')}
                        setFieldValue={handleChangeTrailer}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <DropdownSearchInput<AssetOptionT, AssetOptionT['id'] | null>
                                selectedValue={formik.values[FieldsEnum.trailerId]}
                                options={trailersOptions}
                                onSelect={props.onChange}
                                renderTrigger={renderTrailerTrigger}
                                renderOption={renderOption}
                                isLoading={trailerSuggestRequestStatus.loading}
                                getOptionValue={getOptionValue}
                                onChangeQuery={(text) => {
                                    dispatch(
                                        findTrailer({
                                            transportOrderId,
                                            plateNumber: text.trim(),
                                        }),
                                    );
                                }}
                                renderLeftIcon={(iconMeta) => (
                                    <TrailerIcon {...TrailerIconProps.getControlProps(iconMeta)} />
                                )}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                overlayPosition={DropdownOverlayPositionEnum.topLeft}
                                inputPlaceholder={t(
                                    'common:spot-asset-transport-order-assignment.fields.trailer.input-placeholder',
                                )}
                                placeholder={t(
                                    'common:spot-asset-transport-order-assignment.fields.trailer.placeholder',
                                )}
                                hasOptionsSeparator
                                onReset={() => {
                                    props.onChange(null);
                                }}
                            />
                        )}
                    </FormikField>
                </div>
                {hasInitialAssets ? (
                    <div className={cx('actions')}>
                        <Button
                            theme={ButtonThemeEnum.secondaryDanger}
                            className={cx('action', 'action--clear-assets')}
                            onClick={handleClearAssets}
                            isLoading={assignmentRequestStatus.loading}
                            isDisabled={assignmentRequestStatus.loading}
                        >
                            {t('common:spot-asset-transport-order-assignment.action.clear-assets')}
                        </Button>
                        <div className={cx('sub-actions')}>
                            <Button
                                theme={ButtonThemeEnum.secondary}
                                className={cx('action', 'action--reassign-cancel')}
                                onClick={onCancel}
                            >
                                {t('common:spot-asset-transport-order-assignment.action.cancel')}
                            </Button>
                            <Button
                                theme={ButtonThemeEnum.primary}
                                className={cx('action', 'action--reassign')}
                                isLoading={assignmentRequestStatus.loading}
                                isDisabled={assignmentRequestStatus.loading}
                                type="submit"
                            >
                                {t('common:spot-asset-transport-order-assignment.action.reassign')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={cx('actions')}>
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            className={cx('action', 'action--assign-cancel')}
                            onClick={onCancel}
                        >
                            {t('common:spot-asset-transport-order-assignment.action.cancel')}
                        </Button>
                        <Button
                            theme={ButtonThemeEnum.primary}
                            className={cx('action', 'action--assign')}
                            isLoading={assignmentRequestStatus.loading}
                            type="submit"
                        >
                            {t('common:spot-asset-transport-order-assignment.action.assign')}
                        </Button>
                    </div>
                )}
                <ScrollToFirstError submitCount={formik.submitCount} errors={formik.errors} />
            </form>
        </div>
    );
};

export default SpotAssetsAssignmentForm;

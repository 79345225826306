export enum FieldsEnum {
    expiration = 'expiration',
    number = 'number',
    name = 'name',
    truckRate = 'truckRate',
    tiltRate = 'tiltRate',
    boxRate = 'boxRate',
    boxExpectedMileage = 'boxExpectedMileage',
    tiltExpectedMileage = 'tiltExpectedMileage',
    truckExpectedMileage = 'truckExpectedMileage',
}

export type FormValuesT = {
    [FieldsEnum.expiration]: Date | null;
    [FieldsEnum.number]: string;
    [FieldsEnum.name]: string;
    [FieldsEnum.truckRate]: string;
    [FieldsEnum.tiltRate]: string;
    [FieldsEnum.boxRate]: string;
    [FieldsEnum.boxExpectedMileage]: string;
    [FieldsEnum.tiltExpectedMileage]: string;
    [FieldsEnum.truckExpectedMileage]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;

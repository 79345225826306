import { ReservePreviewQueryT, ReserveQueryModifyEventT, ReserveT } from '../models';

export const getQueryModifyEventAfterReserve = (
    prevPreviewQuery: ReservePreviewQueryT | null,
    reserve: ReserveT | null,
): ReserveQueryModifyEventT => {
    let queryModifyEvent: ReserveQueryModifyEventT = {};

    const hasLane = reserve?.contractLanes?.every((contractLane) => contractLane?.id !== prevPreviewQuery?.laneId);
    if (prevPreviewQuery?.laneId && hasLane) {
        queryModifyEvent = {
            ...queryModifyEvent,
            correctedLaneId: null,
        };
    }

    return queryModifyEvent;
};

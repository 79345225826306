import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './AssetOptionLabel.scss';
import { useTranslation } from 'react-i18next';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import DetailsIcon from 'common/icons/DetailsIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

type PropsT = {
    model?: string;
    plateNumber?: string;
    ratePerKm?: number;
    onOpenDetails?: () => void;
};

const AssetOptionLabel: React.FC<PropsT> = React.memo((props) => {
    const { model, plateNumber, ratePerKm, onOpenDetails } = props;

    const { t } = useTranslation();

    return (
        <div className={cx('option')}>
            <div>
                {plateNumber && <span className={cx('plate-number')}>{plateNumber}</span>}
                {' / '}
                {model && <span className={cx('model')}>{model}</span>}
            </div>
            <div>
                {isNumber(ratePerKm) && (
                    <span className={cx('rate')}>
                        {t('common:spot-asset-transport-order-assignment.rate', {
                            rate: ratePerKm,
                        })}
                    </span>
                )}
            </div>
            {onOpenDetails && (
                <ClickInterceptorLabel className={cx('details-trigger')}>
                    <TransparentTrigger
                        spaces="xs"
                        onClick={onOpenDetails}
                        leftIcon={<DetailsIcon strokeColor={StyleGuideColorsEnum.gray} />}
                        reflectionTheme={ReflectionThemeEnum.halfTransparentLight}
                    />
                </ClickInterceptorLabel>
            )}
        </div>
    );
});

export default AssetOptionLabel;

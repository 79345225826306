import React from 'react';
import classNames from 'classnames/bind';

import styles from './DateLinkTrigger.scss';
import LinkButton, { LinkButtonHeightEnum, LinkButtonThemeEnum } from 'common/components/LinkButton/LinkButton';
import { CalendarDateRangeValueT, CalendarDateValueT } from 'design-system/components/Calendar/Calendar';
import { formatDate } from 'common/utils/time';

const cx = classNames.bind(styles);

type BasePropsT = {
    isDisabled?: boolean;
    placeholder?: React.ReactNode;
    dateFormat?: string;
    onClick?: () => void;
    className?: string;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
};

export type DateLinkTriggerPropsT = {
    isRange?: false;
    value: CalendarDateValueT;
};

export type DateLinkTriggerRangePropsT = {
    isRange: true;
    value: CalendarDateRangeValueT;
};

export type DateInputPropsT = (BasePropsT & DateLinkTriggerPropsT) | (BasePropsT & DateLinkTriggerRangePropsT);

const DATES_SEPARATOR = ' - ';

const formatDateValue = (value: CalendarDateValueT, format: string): string | null => {
    return formatDate(format, value);
};

const formatDateRangeValue = (value: CalendarDateRangeValueT, format: string): string | null => {
    const startDate = formatDate(format, value?.from);
    const endDate = formatDate(format, value?.to);

    if (!startDate && !endDate) {
        return '';
    }

    return [startDate || '', startDate ? DATES_SEPARATOR : '', endDate || ''].join('');
};

const checkIsEmptyDateValue = (value: CalendarDateValueT): boolean => {
    return !value;
};

const checkIsEmptyDateRangeValue = (value: CalendarDateRangeValueT): boolean => {
    return !value?.from && !value?.to;
};

const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';
const DEFAULT_PLACEHOLDER = ' - ';

/* eslint-disable react/destructuring-assignment */
const DateLinkTrigger: React.FC<DateInputPropsT> = (props) => {
    const { className, onClick, placeholder, leftIcon, rightIcon, isDisabled } = props;

    const dateFormat = props.dateFormat || DEFAULT_DATE_FORMAT;

    const isEmpty = props.isRange ? checkIsEmptyDateRangeValue(props.value) : checkIsEmptyDateValue(props.value);
    const formattedValue = props.isRange
        ? formatDateRangeValue(props.value, dateFormat)
        : formatDateValue(props.value, dateFormat);

    const label = isEmpty ? placeholder || DEFAULT_PLACEHOLDER : formattedValue;

    return (
        <LinkButton
            isDisabled={isDisabled}
            className={className}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            theme={LinkButtonThemeEnum.brandDark}
            height={LinkButtonHeightEnum.narrow}
            onClick={onClick}
        >
            {label}
        </LinkButton>
    );
};

export default DateLinkTrigger;

import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TourStatusPill.scss';
import PillLabel, { colorCodingPillLabelTheme, PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { TourStatusEnum, ApiTourStatusT } from 'common/utils/api/models';
import TourStatus from 'common/components/status/TourStatus/TourStatus';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    status?: ApiTourStatusT;
    isSymmetrical?: boolean;
};

const PILL_THEME_BY_STATUS: Record<ApiTourStatusT, PillLabelThemeEnum> = {
    [TourStatusEnum.carrierUnassigned]: colorCodingPillLabelTheme.attention,
    [TourStatusEnum.carrierAssigned]: colorCodingPillLabelTheme.normal,
    [TourStatusEnum.inTransit]: colorCodingPillLabelTheme.normal,
    [TourStatusEnum.finished]: colorCodingPillLabelTheme.success,
    [TourStatusEnum.canceled]: colorCodingPillLabelTheme.normal,
};

const TourStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, className, isSymmetrical } = props;

    if (!status) {
        return null;
    }

    return (
        <PillLabel isNoWrap className={className} isSymmetrical={isSymmetrical} theme={PILL_THEME_BY_STATUS[status]}>
            <TourStatus status={status} />
        </PillLabel>
    );
});

export default TourStatusPill;

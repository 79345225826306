import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './TransportOrderRouteMap.scss';

import GoogleMapReact from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import GoogleMapContext from 'common/contexts/google-map-context';
import last from 'lodash/last';
import { AssetTypeEnum } from 'common/constants';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import MapLoader from 'common/components/maps/MapLoader/MapLoader';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import { fetchTracksByTransportOrder } from 'common/store/asset-track/actions';
import { getAssetTrackHashByTransportOrderId } from 'common/store/asset-track/utils';
import { selectAssetTrackPoints, selectAssetTrackRequest } from 'common/store/asset-track/selectors';
import { isNonNil } from 'common/utils';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import { StopEnum } from 'common/utils/api/models';
import AssetDropOffLocationPin from 'common/components/maps/pins/AssetDropOffLocationPin/AssetDropOffLocationPin';
import AssetPickUpLocationPin from 'common/components/maps/pins/AssetPickUpLocationPin/AssetPickUpLocationPin';
import Supercluster from 'supercluster';
import { PointGeoJsonPropertiesT } from './models';
import isNumber from 'lodash/isNumber';
import WaypointNumberPin from 'common/components/maps/pins/WaypointNumberPin/WaypointNumberPin';
import { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';
import PointStackClusterPin from './PointStackClusterPin/PointStackClusterPin';
import LastAssetTrackPointPin from 'common/components/maps/pins/LastAssetTrackPointPin/LastAssetTrackPointPin';
import MapBound, { BoundPointT } from 'common/components/maps/MapBound/MapBound';

const FEATURES_LIMIT = 99999;
const DEFAULT_OFFSET = 0;

const SUPERCLUSTER_SETTINGS: Supercluster.Options<PointGeoJsonPropertiesT, any> = {
    radius: 100,
    maxZoom: 22,
};

const cx = classNames.bind(styles);

type PropsT = {
    isLoading?: boolean;
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
};

const TransportOrderRouteMap: React.FC<PropsT> = React.memo((props) => {
    const { transportOrderId, transportOrderDetails } = props;

    const dispatch = useDispatch();

    const polylineIds = useMemo(() => {
        return [
            transportOrderDetails?.dropTrailerPolylineId,
            transportOrderDetails?.dropTruckPolylineId,
            transportOrderDetails?.polylineId,
            transportOrderDetails?.trailerToPickUpPolylineId,
            transportOrderDetails?.truckToTrailerPolylineId,
        ].filter(isNonNil);
    }, [
        transportOrderDetails?.dropTrailerPolylineId,
        transportOrderDetails?.dropTruckPolylineId,
        transportOrderDetails?.polylineId,
        transportOrderDetails?.trailerToPickUpPolylineId,
        transportOrderDetails?.truckToTrailerPolylineId,
    ]);

    useRouteGeometry(polylineIds);

    const dropTrailerGeometryState = useSelector(
        selectRoutingGeometryState(transportOrderDetails?.dropTrailerPolylineId),
    );
    const dropTruckGeometryState = useSelector(selectRoutingGeometryState(transportOrderDetails?.dropTruckPolylineId));
    const payloadGeometryState = useSelector(selectRoutingGeometryState(transportOrderDetails?.polylineId));
    const trailerToPickUpGeometryState = useSelector(
        selectRoutingGeometryState(transportOrderDetails?.trailerToPickUpPolylineId),
    );
    const truckToTrailerGeometryState = useSelector(
        selectRoutingGeometryState(transportOrderDetails?.truckToTrailerPolylineId),
    );

    const isLoadingGeometry =
        dropTrailerGeometryState?.requestStatus?.loading ||
        dropTruckGeometryState?.requestStatus?.loading ||
        payloadGeometryState?.requestStatus?.loading ||
        trailerToPickUpGeometryState?.requestStatus?.loading ||
        truckToTrailerGeometryState?.requestStatus?.loading;

    React.useEffect(() => {
        if (transportOrderId) {
            dispatch(fetchTracksByTransportOrder(transportOrderId));
        }
    }, [transportOrderId]);

    const firstTourId = transportOrderDetails?.tourInfo?.id;
    const firstTourTrailerId = transportOrderDetails?.trailer?.id;
    const firstTourTruckId = transportOrderDetails?.truck?.id;

    const trailerHash = getAssetTrackHashByTransportOrderId(firstTourId, firstTourTrailerId);
    const trailerTrack = useSelector(selectAssetTrackPoints(trailerHash));
    const trailerTrackRequest = useSelector(selectAssetTrackRequest(trailerHash));

    const truckHash = getAssetTrackHashByTransportOrderId(firstTourId, firstTourTruckId);
    const truckTrack = useSelector(selectAssetTrackPoints(truckHash));
    const truckTrackRequest = useSelector(selectAssetTrackRequest(truckHash));

    const googleMapContext = React.useContext(GoogleMapContext);

    const [api, setApi] = React.useState<TODO>(null);
    const [config, setConfig] = React.useState<TODO>(null);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        setApi(api);

        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const boundMapPoints = React.useMemo((): Array<BoundPointT> => {
        return (
            transportOrderDetails?.waypoints?.map((waypoint): BoundPointT => {
                return [waypoint?.address?.latitude, waypoint?.address?.longitude];
            }) || []
        );
    }, [transportOrderDetails]);

    const lastTruckTrackPoint = last(truckTrack);
    const lastTrailerTrackPoint = last(trailerTrack);

    const allGeoJSONs = React.useMemo(() => {
        const geoJSONs: Array<Supercluster.PointFeature<PointGeoJsonPropertiesT>> = [];

        transportOrderDetails?.waypoints?.forEach((waypoint) => {
            const longitude = waypoint?.address?.longitude;
            const latitude = waypoint?.address?.latitude;
            if (isNumber(latitude) && isNumber(longitude)) {
                const geoJson: Supercluster.PointFeature<PointGeoJsonPropertiesT> = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [longitude, latitude],
                    },
                    properties: {
                        type: 'waypoint-point',
                        waypoint,
                    },
                };

                geoJSONs.push(geoJson);
            }
        });

        if (lastTrailerTrackPoint) {
            geoJSONs.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [lastTrailerTrackPoint.lng, lastTrailerTrackPoint.lat],
                },
                properties: {
                    type: 'trailer-last-point',
                    point: lastTrailerTrackPoint,
                },
            });
        }

        if (lastTruckTrackPoint) {
            geoJSONs.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [lastTruckTrackPoint.lng, lastTruckTrackPoint.lat],
                },
                properties: {
                    type: 'truck-last-point',
                    point: lastTruckTrackPoint,
                },
            });
        }

        return geoJSONs;
    }, [transportOrderDetails?.waypoints, lastTruckTrackPoint, lastTrailerTrackPoint]);

    const allGeoJSONsClusterator = React.useMemo(() => {
        const clusterator = new Supercluster<PointGeoJsonPropertiesT>(SUPERCLUSTER_SETTINGS);
        clusterator.load(allGeoJSONs || []);
        return clusterator;
    }, [allGeoJSONs]);

    const handleChange = () => {
        if (api) {
            const { zoom } = api.map;

            const bound = api.map.getBounds();

            const northEast = bound.getNorthEast();
            const southWest = bound.getSouthWest();

            setConfig({
                zoom,
                bounds: [southWest.lng(), southWest.lat(), northEast.lng(), northEast.lat()],
            });
        }
    };

    const allGeoJSONsClusters = React.useMemo(() => {
        if (config) {
            const clusters = allGeoJSONsClusterator.getClusters(config.bounds, config.zoom);
            return clusters;
        }

        return [];
    }, [config, allGeoJSONsClusterator]);

    const renderSinglePointFeature = (properties: PointGeoJsonPropertiesT) => {
        switch (properties.type) {
            case 'trailer-last-point': {
                return (
                    <LastAssetTrackPointPin
                        key="trailer-last-point"
                        iconType={AssetTypeEnum.trailer}
                        lat={properties.point.lat}
                        lng={properties.point.lng}
                        timestamp={properties.point.timestamp}
                    />
                );
            }
            case 'truck-last-point': {
                return (
                    <LastAssetTrackPointPin
                        key="truck-last-point"
                        iconType={AssetTypeEnum.truck}
                        lat={properties.point.lat}
                        lng={properties.point.lng}
                        timestamp={properties.point.timestamp}
                    />
                );
            }
            case 'waypoint-point': {
                const { waypoint } = properties;

                const isVisitedWaypoint = !!waypoint.driverLeftTimeStamp;

                const commonProps = {
                    key: `waypoint-${waypoint.index}`,
                    className: cx('location'),
                    lng: waypoint?.address?.longitude,
                    lat: waypoint?.address?.latitude,
                } as const;

                switch (waypoint.type) {
                    case StopEnum.pickupTrailer: {
                        return <AssetPickUpLocationPin assetType={AssetTypeEnum.trailer} {...commonProps} />;
                    }
                    case StopEnum.pickupTruck: {
                        return <AssetPickUpLocationPin assetType={AssetTypeEnum.truck} {...commonProps} />;
                    }
                    case StopEnum.pickupRoadTrain: {
                        return <AssetPickUpLocationPin assetType={null} {...commonProps} />;
                    }
                    case StopEnum.dropTrailer: {
                        return <AssetDropOffLocationPin assetType={AssetTypeEnum.trailer} {...commonProps} />;
                    }
                    case StopEnum.dropTruck: {
                        return <AssetDropOffLocationPin assetType={AssetTypeEnum.truck} {...commonProps} />;
                    }
                    case StopEnum.dropRoadTrain: {
                        return <AssetDropOffLocationPin assetType={null} {...commonProps} />;
                    }
                    case StopEnum.driveThrough: {
                        return (
                            <WaypointNumberPin
                                theme={isVisitedWaypoint ? MapPinThemeEnum.brandDark : MapPinThemeEnum.gray}
                                {...commonProps}
                            >
                                {(waypoint.index || 0) + 1}
                            </WaypointNumberPin>
                        );
                    }
                    case StopEnum.dropAndHook:
                    case StopEnum.pickupDeliveryShipment:
                    default: {
                        return (
                            <WaypointNumberPin
                                theme={isVisitedWaypoint ? MapPinThemeEnum.brandDark : MapPinThemeEnum.charcoal}
                                {...commonProps}
                            >
                                {(waypoint.index || 0) + 1}
                            </WaypointNumberPin>
                        );
                    }
                }
            }
            default: {
                return null;
            }
        }
    };

    const handleClusterClick = React.useCallback(
        (clusterId: number) => {
            const { map, maps } = api;

            const clusterFeatures = allGeoJSONsClusterator.getLeaves(clusterId, FEATURES_LIMIT, DEFAULT_OFFSET);

            const bounds = new maps.LatLngBounds();

            clusterFeatures.forEach((feature: Supercluster.PointFeature<{}>) => {
                const point = new maps.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);

                bounds.extend(point);
            });

            map.fitBounds(bounds);
        },
        [api, allGeoJSONsClusterator],
    );

    const isLoading =
        props.isLoading || isLoadingGeometry || truckTrackRequest?.loading || trailerTrackRequest?.loading;

    return (
        <>
            {isLoading && <MapLoader />}
            <GoogleMapReact
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
                onDragEnd={handleChange}
                onZoomAnimationEnd={handleChange}
                yesIWantToUseGoogleMapApiInternals
            >
                {allGeoJSONsClusters.map((cluster) => {
                    if ('cluster' in cluster.properties) {
                        const pointsFeatures = allGeoJSONsClusterator.getLeaves(
                            cluster.properties.cluster_id,
                            FEATURES_LIMIT,
                            DEFAULT_OFFSET,
                        );

                        const clusterProperties = pointsFeatures.map((pointFeatures) => {
                            return pointFeatures.properties;
                        });

                        return (
                            <PointStackClusterPin
                                key={`cluster-${cluster.properties.cluster_id}`}
                                clusterId={cluster.properties.cluster_id}
                                lng={cluster.geometry.coordinates[0]}
                                lat={cluster.geometry.coordinates[1]}
                                clusterProperties={clusterProperties}
                                onClusterClick={handleClusterClick}
                                withoutArrow={config.zoom < 20}
                            />
                        );
                    }

                    return renderSinglePointFeature(cluster.properties as PointGeoJsonPropertiesT);
                })}
            </GoogleMapReact>
            {dropTrailerGeometryState && (
                <MapRoute
                    key="dropTrailerGeometryState"
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={dropTrailerGeometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            )}
            {dropTruckGeometryState && (
                <MapRoute
                    key="dropTruckGeometryState"
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={dropTruckGeometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            )}
            {payloadGeometryState && (
                <MapRoute
                    key="payloadGeometryState"
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={payloadGeometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            )}
            {trailerToPickUpGeometryState && (
                <MapRoute
                    key="trailerToPickUpGeometryState"
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={trailerToPickUpGeometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            )}
            {truckToTrailerGeometryState && (
                <MapRoute
                    key="truckToTrailerGeometryState"
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={truckToTrailerGeometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            )}
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={trailerTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={truckTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapBound
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                allMapPoints={boundMapPoints}
            />
        </>
    );
});

export default TransportOrderRouteMap;

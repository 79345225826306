import React, { useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './DeactivateUserWithSuccessorForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import { RolesEnum } from 'common/utils/api/models';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { logWarning } from 'common/utils/logger';

import ModalDialogActions from 'common/components/Modal/ModalDialogActions/ModalDialogActions';
import DispatcherDropdown from 'broker-admin/components/dropdowns/DispatcherDropdown/DispatcherDropdown';
import KeyAccountManagerDropdown from 'broker-admin/components/dropdowns/KeyAccountManagerDropdown/KeyAccountManagerDropdown';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import { USER_ROLE_T_MAP } from 'common/utils/users';
import { DeactivateUserFormPropsT } from 'common/layouts/Members/dialogs/DeactivateUserModal/models';
import { RolesWithSuccessorT } from 'common/store/members/models';
import { getFirstNeedSuccessorRole } from 'common/store/members/utils';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';
import { isNonNil } from 'common/utils';

const cx = classNames.bind(styles);

type PropsT = DeactivateUserFormPropsT;

export const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.successorUserId]: RolesEnum.driver,
};

const TEST_SELECTOR = 'change-role-user-with-successor-form';

const SuccessorDropdownByOldRole: Record<RolesWithSuccessorT, any> = {
    [RolesEnum.keyAccountManager]: KeyAccountManagerDropdown,
    [RolesEnum.dispatcher]: DispatcherDropdown,
};

const DeactivateUserWithSuccessorForm: React.FC<PropsT> = (props) => {
    const { contact, onDeactivateUser, onCancel, updateMemberRequest } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values, contact);
    }, [t, contact]);

    const oldUserRoleData = getFirstNeedSuccessorRole(contact?.roles);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values: FormValuesT = {
            [FieldsEnum.successorUserId]: null,
        };

        const errors = validateForm(t, values, contact);

        return [values, errors];
    }, [contact]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const successorUserId = values[FieldsEnum.successorUserId];

            if (!successorUserId) {
                logWarning('empty successor user id');
                return;
            }

            if (!contact?.userId) {
                logWarning('empty contact?.userId');
                return;
            }

            onDeactivateUser({
                userId: contact.userId,
                successorUserId,
                successorRole: oldUserRoleData?.role as RolesWithSuccessorT,
            });

            formikHelpers.setTouched({});
        },
    });

    const SuccessorDropdown = SuccessorDropdownByOldRole[oldUserRoleData?.role as RolesWithSuccessorT];

    const excludeUserIds = useMemo(() => {
        return [contact?.userId].filter(isNonNil);
    }, [contact?.userId]);

    const hasFormAnyErrors = useWatchFormAnyErrors(formik.errors);
    const isDisabledSubmit = hasFormAnyErrors;

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('form')}>
                <FormikField
                    name={FieldsEnum.successorUserId}
                    error={formik.errors[FieldsEnum.successorUserId]}
                    meta={formik.getFieldMeta(FieldsEnum.successorUserId)}
                    label={t('team-members.dialogs.deactivate-user.fields.successor.label', {
                        oldUserRole: t(USER_ROLE_T_MAP[oldUserRoleData?.role as RolesEnum]),
                    })}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <SuccessorDropdown
                            selectedValue={formik.values[FieldsEnum.successorUserId]}
                            initialLabel={initialValues[FieldsEnum.successorUserId]}
                            placeholder={t('team-members.dialogs.deactivate-user.fields.successor.placeholder', {
                                oldUserRole: t(USER_ROLE_T_MAP[oldUserRoleData?.role as RolesEnum]),
                            })}
                            onSelect={props.onChange}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            overlayPosition={DropdownOverlayPositionEnum.topCenter}
                            excludeUserIds={excludeUserIds}
                        />
                    )}
                </FormikField>
            </div>
            <ModalDialogActions
                actions={[
                    {
                        children: t('common:error-modal.actions.cancel'),
                        theme: ButtonThemeEnum.secondary,
                        testSelector: `${TEST_SELECTOR}_cancel`,
                        isDisabled: updateMemberRequest.loading,
                        onClick: onCancel,
                    },
                    {
                        children: t('common:error-modal.actions.deactivate'),
                        theme: ButtonThemeEnum.danger,
                        testSelector: `${TEST_SELECTOR}_deactivate`,
                        isLoading: updateMemberRequest.loading,
                        type: isDisabledSubmit ? 'button' : 'submit',
                        isDisabled: isDisabledSubmit,
                    },
                ]}
            />
        </form>
    );
};

export default DeactivateUserWithSuccessorForm;

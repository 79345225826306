import * as React from 'react';
import { DispatchT } from 'broker-admin/store/dispatches/models';
import { formatTimeInterval } from 'common/utils/time';
import LocationCell from 'common/components/Table/cell-renderers/LocationCell/LocationCell';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import { CountryCodeT } from 'common/utils/api/models';

type PropsT = {
    dispatch: DispatchT;
};

const PickUpCell: React.FC<PropsT> = React.memo((props) => {
    const { dispatch } = props;

    const timeWindow = formatTimeInterval(dispatch.pickUpDateFrom, dispatch.pickUpDateTo);

    return (
        <LocationCell
            address={
                <LocationLabel
                    location={{
                        zipCode: dispatch.zipCodeFrom,
                        city: dispatch.cityFrom,
                        country: dispatch.countryCodeFrom as CountryCodeT,
                    }}
                    format="zip_city_country"
                    allowShowCountryCode
                />
            }
            time={timeWindow}
        />
    );
});

export default PickUpCell;

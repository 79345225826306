import * as React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { useTranslation } from 'react-i18next';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';

export enum TransportOrderTypeEnum {
    all = 'all',
    spot = 'spot',
    contracted = 'contracted',
}

type OptionT = {
    id: TransportOrderTypeEnum;
    label: React.ReactNode;
};

export type PropsT = {
    className?: string;
    value: TransportOrderTypeEnum;
    onSelect: (value: TransportOrderTypeEnum) => void;
    testSelector?: string;
};

const i18nTMap: Record<TransportOrderTypeEnum, string> = {
    [TransportOrderTypeEnum.spot]: 'dispatches.list.filters.transport-order-type-filter.spot',
    [TransportOrderTypeEnum.contracted]: 'dispatches.list.filters.transport-order-type-filter.contracted',
    [TransportOrderTypeEnum.all]: 'dispatches.list.filters.transport-order-type-filter.all',
};

const TransportOrderTypeDropdown = (props: PropsT) => {
    const { className, value, onSelect, testSelector } = props;

    const { t } = useTranslation();

    const options = React.useMemo((): Array<OptionT> => {
        return [
            {
                id: TransportOrderTypeEnum.spot,
                label: t(i18nTMap[TransportOrderTypeEnum.spot]),
            },
            {
                id: TransportOrderTypeEnum.contracted,
                label: t(i18nTMap[TransportOrderTypeEnum.contracted]),
            },
        ];
    }, []);

    return (
        <DropdownInput<OptionT, TransportOrderTypeEnum>
            className={className}
            selectedValue={value}
            options={options}
            onSelect={onSelect}
            testSelector={testSelector}
            renderOption={(option) => {
                if (!option) {
                    return null;
                }

                return <DropdownControlOptionLabel withoutPaddings label={option.label} />;
            }}
            renderTriggerContent={(selectedOption, placeholder) => {
                if (!selectedOption) {
                    return placeholder;
                }

                return t(i18nTMap[selectedOption.id]);
            }}
            placeholder={t(i18nTMap[TransportOrderTypeEnum.all])}
            hasClearControl
            onReset={() => {
                onSelect(TransportOrderTypeEnum.all);
            }}
            getOptionValue={(option) => option?.id}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
};

export default TransportOrderTypeDropdown;

import * as React from 'react';

import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, AssetTypeEnum } from 'common/constants';
import InfoIcon from 'common/icons/InfoIcon';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectGPSCheckData, selectGPSIsChecking, selectGPSStatus } from 'common/store/gps-tracking-status/selectors';
import { checkGPSStatusRequest, reset } from 'common/store/gps-tracking-status/actions';
import { GPStatusEnum } from 'common/store/gps-tracking-status/constants';

export type PropsT = {
    className?: string;
    plateNumber: string;
    assetType: AssetTypeEnum;
    companyId: CompanyIdT;
};

const LastGPSPositionAlert: React.FC<PropsT> = React.memo((props) => {
    const { className, plateNumber, assetType, companyId } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    React.useEffect(() => {
        dispatch(checkGPSStatusRequest({ companyId, assetType, plateNumber }));
    }, [companyId, assetType, plateNumber]);

    const infoIconNode = <InfoIcon fillColor={StyleGuideColorsEnum.white} />;
    const loaderIconNode = <ControlLoaderIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />;

    let theme: AlertThemeEnum | null = null;
    let text = '';

    const isChecking = useSelector(selectGPSIsChecking);
    const gpsStatus = useSelector(selectGPSStatus);
    const gpsData = useSelector(selectGPSCheckData);

    const renderTime = (time: string | null) => {
        if (!time) {
            return t('common:trucks-page.add-truck.gps-tracking-status.no-latest-gps-position');
        }

        const momentTime = moment(time);
        const formattedDate = momentTime.format('Do');
        const formattedMonth = momentTime.format('MMM');
        const formattedTime = momentTime.format('H:mm');

        return t('common:trucks-page.add-truck.gps-tracking-status.latest-gps-position', {
            date: formattedDate,
            month: formattedMonth,
            time: formattedTime,
        });
    };

    if (gpsStatus === GPStatusEnum.unable) {
        theme = AlertThemeEnum.tomatoRed;
        text = t('common:trucks-page.add-truck.gps-tracking-status.error');
    }

    if (gpsStatus === GPStatusEnum.enabled) {
        theme = AlertThemeEnum.brandAccent;

        const lastUpdateDate = gpsData?.lastUpdateDate || null;
        text = renderTime(lastUpdateDate);
    }

    if (isChecking && !gpsStatus) {
        theme = AlertThemeEnum.gray;
        text = t('common:trucks-page.add-truck.gps-tracking-status.loading');
    }

    return (
        <Alert
            className={className}
            icon={isChecking ? loaderIconNode : infoIconNode}
            size={AlertSizeEnum.small}
            theme={theme || AlertThemeEnum.gray}
        >
            {text}
        </Alert>
    );
});

export default LastGPSPositionAlert;

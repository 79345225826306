import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './WaypointNumberPin.scss';
import MapPin, { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';
import WaypointNumberPinContent from 'common/components/maps/pins/content/WaypointNumberPinContent/WaypointNumberPinContent';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    lat: number | undefined;
    lng: number | undefined;
    theme?: MapPinThemeEnum;
    children?: React.ReactNode;
};

const WaypointNumberPin: React.FC<PropsT> = React.memo((props) => {
    const { className, lat, lng, theme, children } = props;

    return (
        <MapPin className={cs(cx('content'), className)} lat={lat} lng={lng} theme={theme || MapPinThemeEnum.charcoal}>
            <WaypointNumberPinContent>{children}</WaypointNumberPinContent>
        </MapPin>
    );
});

export default WaypointNumberPin;

import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AddressWidgetHeaderActions.scss';
import ColoredStatusLabel from 'common/components/ColoredStatusLabel/ColoredStatusLabel';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import MoreIcon from 'common/icons/MoreIcon';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControl, {
    checkIsSpecialOption,
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { AddressTypeEnum } from 'common/utils/api/models';
import DoneIcon from 'common/icons/DoneIcon';
import CloseIcon from 'common/icons/CloseIcon';
import MapIcon from 'common/icons/MapIcon';
import { VerifyPartnerAdressConfirmationDataT } from '../dialogs/VerifyPartnerAdressConfirmation/models';
import { UnVerifyPartnerAdressConfirmationDataT } from '../dialogs/UnVerifyPartnerAdressConfirmation/models';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import IconTrigger from 'design-system/components/IconTrigger/IconTrigger';
import CopyIcon from 'common/icons/CopyIcon';
import { useMemo } from 'react';
import LockActionOnChangeForm from '../LockActionOnChangeForm/LockActionOnChangeForm';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import LockStatusOnChangeForm from '../LockStatusOnChangeForm/LockStatusOnChangeForm';
import { createMapLink } from 'common/utils/links';

type PropsT = {
    isShowStatus?: boolean;
    isAllowShowVerifyAction?: boolean;
    addressType: AddressTypeEnum;
    fullAddress: string;
    needApproved: boolean;
    companyId: string | undefined;
    onCopy?: () => void;
    onVerify?: (data: VerifyPartnerAdressConfirmationDataT | null) => void;
    onUnVerify?: (data: UnVerifyPartnerAdressConfirmationDataT | null) => void;
};

const cx = classNames.bind(styles);

type ActionT = {
    icon: React.ReactNode;
    label: React.ReactNode;
    onSelect: () => void;
};
const AddressWidgetHeaderActions: React.FC<PropsT> = React.memo((props) => {
    const {
        isShowStatus,
        isAllowShowVerifyAction,
        needApproved,
        companyId,
        addressType,
        fullAddress,
        onVerify,
        onUnVerify,
        onCopy,
    } = props;

    const { t } = useTranslation();

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);

    const onOpenGoogleMaps = React.useCallback((): void => {
        window.open(createMapLink(fullAddress));
    }, [fullAddress]);

    const actions = React.useMemo((): Array<ActionT | SpecialOptionEnum> => {
        const isShowUnVerifyAction = isAllowShowVerifyAction && !needApproved;

        const hasAddress = !!fullAddress?.trim();

        return [
            ...(hasAddress
                ? [
                      {
                          label: t('partner.actions.open-map'),
                          icon: (
                              <MapIcon
                                  size={DEFAULT_ICON_SIZE}
                                  fillColor={StyleGuideColorsEnum.brandAccent}
                                  strokeColor={StyleGuideColorsEnum.brandDark}
                              />
                          ),
                          onSelect: onOpenGoogleMaps,
                      },
                  ]
                : []),
            ...(isShowUnVerifyAction && onUnVerify
                ? [
                      SpecialOptionEnum.separator,
                      {
                          icon: <CloseIcon fillColor={StyleGuideColorsEnum.tomatoRed} />,
                          label: t('partner.actions.un-verify'),
                          onSelect: () => {
                              if (!companyId) {
                                  return;
                              }

                              if (remoteFormActionsContext.remoteFormState?.hasErrors) {
                                  remoteFormActionsContext.remoteFormCallbacks?.touchAllFields?.();
                                  return;
                              }

                              const data: VerifyPartnerAdressConfirmationDataT = {
                                  partnerId: companyId,
                                  addressType,
                              };

                              onUnVerify(data);
                          },
                      },
                  ]
                : []),
        ];
    }, [onOpenGoogleMaps, t, needApproved, onVerify, onUnVerify, isAllowShowVerifyAction, fullAddress]);

    const dropdownOptions = useMemo((): Array<DropdownControlOptionT | SpecialOptionEnum> => {
        return actions.map((action) => {
            if (checkIsSpecialOption(action)) {
                return action;
            }

            return {
                label: <DropdownControlOptionLabel label={action.label} icon={action.icon} />,
                onSelect: action.onSelect,
            };
        });
    }, [actions]);

    const renderSingleAction = (action: ActionT | SpecialOptionEnum | undefined): React.ReactNode => {
        if (checkIsSpecialOption(action) || !action) {
            return null;
        }

        return (
            <Tooltip
                position={TooltipPositionEnum.topCenter}
                theme={TooltipThemeEnum.black}
                tooltipNode={
                    <TooltipContent isCenter width={115} theme={TooltipContentThemeEnum.black}>
                        {action.label}
                    </TooltipContent>
                }
            >
                {(isShow) => (
                    <TransparentTrigger
                        isPressed={isShow}
                        onClick={action.onSelect}
                        reflectionTheme={ReflectionThemeEnum.light}
                        leftIcon={action.icon}
                    />
                )}
            </Tooltip>
        );
    };

    return (
        <>
            {isShowStatus && (
                <LockStatusOnChangeForm className={cx('status')}>
                    <ColoredStatusLabel
                        label={needApproved ? t('partner.status.not-verified') : t('partner.status.verified')}
                        color={needApproved ? StyleGuideColorsEnum.orange : StyleGuideColorsEnum.mediumGreen}
                    />
                </LockStatusOnChangeForm>
            )}
            {needApproved && isAllowShowVerifyAction && onVerify && (
                <LockActionOnChangeForm>
                    <TransparentTrigger
                        onClick={() => {
                            if (!companyId) {
                                return;
                            }

                            if (remoteFormActionsContext.remoteFormState?.hasErrors) {
                                remoteFormActionsContext.remoteFormCallbacks?.touchAllFields?.();
                                return;
                            }

                            const data: VerifyPartnerAdressConfirmationDataT = {
                                partnerId: companyId,
                                addressType,
                            };

                            onVerify(data);
                        }}
                        label={t('partner.actions.verify')}
                        className={cx('timeline-trigger')}
                        leftIcon={<DoneIcon fillColor={StyleGuideColorsEnum.brandDark} size={DEFAULT_ICON_SIZE} />}
                        reflectionTheme={ReflectionThemeEnum.light}
                    />
                </LockActionOnChangeForm>
            )}
            {onCopy && (
                <Tooltip
                    position={TooltipPositionEnum.topCenter}
                    theme={TooltipThemeEnum.black}
                    tooltipNode={
                        <TooltipContent isCenter width={115} theme={TooltipContentThemeEnum.black}>
                            {t('common:profile-page.basic-information.tooltips.copy-from-legal-address')}
                        </TooltipContent>
                    }
                >
                    {(isShow) => (
                        <IconTrigger isFocused={isShow} onClick={onCopy}>
                            {() => <CopyIcon className={cx('copy')} strokeColor={StyleGuideColorsEnum.light} />}
                        </IconTrigger>
                    )}
                </Tooltip>
            )}
            <LockActionOnChangeForm>
                {actions.length === 1 ? (
                    renderSingleAction(actions[0])
                ) : (
                    <DropdownControl
                        options={dropdownOptions}
                        renderTrigger={(isActive, onClick) => (
                            <TransparentTrigger
                                isPressed={isActive}
                                onClick={onClick}
                                reflectionTheme={ReflectionThemeEnum.light}
                                leftIcon={<MoreIcon />}
                                testSelector="more-actions"
                            />
                        )}
                        overlayPosition={DropdownOverlayPositionEnum.bottomRight}
                    />
                )}
            </LockActionOnChangeForm>
        </>
    );
});

export default AddressWidgetHeaderActions;

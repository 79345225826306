import { RequestStatusT } from 'common/utils/request-status';
import { ApiGroupedNotificationCountsT, GroupedNotificationCountsT } from 'common/utils/api/models';

export const FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST =
    'common/grouped-notification-counts/FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST';
export const FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_BEGIN =
    'common/grouped-notification-counts/FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_BEGIN';
export const FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_SUCCESS =
    'common/grouped-notification-counts/FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_SUCCESS';
export const FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_ERROR =
    'common/grouped-notification-counts/FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_ERROR';

export type FetchGroupedNotificationCountsActionT = {
    type: typeof FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST;
    options?: FetchOptionsT;
};

export type FetchGroupedNotificationCountsBeginActionT = {
    type: typeof FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_BEGIN;
};

export type FetchGroupedNotificationCountsSuccessActionT = {
    type: typeof FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_SUCCESS;
    counts: ApiGroupedNotificationCountsT | null;
};

export type FetchGroupedNotificationCountsErrorActionT = {
    type: typeof FETCH_GROUPED_NOTIFICATION_COUNTS_REQUEST_ERROR;
    error: Error;
};

export type GroupedNotificationCountsActionT =
    | FetchGroupedNotificationCountsBeginActionT
    | FetchGroupedNotificationCountsSuccessActionT
    | FetchGroupedNotificationCountsErrorActionT
    | FetchGroupedNotificationCountsActionT;

export type GroupedNotificationCountsStateT = {
    fetchRequest: RequestStatusT;
    counts: GroupedNotificationCountsT | null;
};

import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './SpotRequestsTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT } from 'common/components/Table/Table';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import { SpotRequestT } from 'broker-admin/store/spot-requests/models';
import LocationCell from 'common/components/Table/cell-renderers/LocationCell/LocationCell';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';
import BidsCountCell from './cell-renderers/BidsCountCell/BidsCountCell';
import CostsCell from './cell-renderers/CostsCell/CostsCell';
import AssetCell from './cell-renderers/AssetCell/AssetCell';
import { PartnerTypeEnum } from 'common/utils/api/models';
import { LinkToT } from 'common/components/Link/Link';
import { formatTimeInterval } from 'common/utils/time';
import DateCell from 'common/components/Table/cell-renderers/DateCell/DateCell';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import SpotRequestStatusPill from 'common/components/status-pill/SpotRequestStatusPill/SpotRequestStatusPill';

const cx = classNames.bind(styles);

const PLACED_DATE_FORMAT = 'DD MMM YYYY';
const EXPIRATION_DATE_FORMAT = 'DD MMM, HH:mm';

type PropsT = {
    spotRequests: Array<SpotRequestT>;
    className: string;
    goToSpotRequestDetails: (spotRequestId: SpotRequestIdT) => void;
    openUserDetails?: (userId: UserIdT) => void;
    getPartnerLink: (partnerType: PartnerTypeEnum, partnerId: CompanyIdT) => LinkToT;
    isLoading: boolean;
};

const SpotRequestsTable: React.FC<PropsT> = React.memo((props) => {
    const { spotRequests, className, goToSpotRequestDetails, openUserDetails, getPartnerLink, isLoading } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<SpotRequestT, void>> = [
        {
            renderHeader: () => <span>{t('spot-requests.list.table.columns.origin')}</span>,
            headerClassName: cx('table__header', 'table__header--origin'),
            render: (spotRequest: SpotRequestT) => {
                const firstStop = spotRequest?.stops?.[0] || null;

                return (
                    <LocationCell
                        address={
                            <LocationLabel
                                format="zip_city_country"
                                allowShowCountryCode
                                location={firstStop?.address}
                            />
                        }
                        time={formatTimeInterval(firstStop?.timeSlotFrom, firstStop?.timeSlotTo)}
                        isSmallTimeFont
                    />
                );
            },
            className: cx('table__column', 'table__column--origin'),
            testSelector: 'origin',
        },
        {
            renderHeader: () => <span>{t('spot-requests.list.table.columns.destination')}</span>,
            headerClassName: cx('table__header'),
            render: (spotRequest: SpotRequestT) => {
                const lastStop = spotRequest?.stops?.[(spotRequest?.stops?.length || 0) - 1] || null;

                return (
                    <LocationCell
                        address={
                            <LocationLabel
                                format="zip_city_country"
                                allowShowCountryCode
                                location={lastStop?.address}
                            />
                        }
                        time={formatTimeInterval(lastStop?.timeSlotFrom, lastStop?.timeSlotTo)}
                        isSmallTimeFont
                    />
                );
            },
            className: cx('table__column', 'table__column--destination'),
            testSelector: 'destination',
        },
        {
            renderHeader: () => <span>{t('spot-requests.list.table.columns.placed')}</span>,
            headerClassName: cx('table__header'),
            render: (spotRequest: SpotRequestT) => (
                <EventCell
                    isHumanReadableDate
                    userFullName={spotRequest.placedByName}
                    userId={spotRequest.placedBy}
                    openUserDetails={openUserDetails}
                    date={spotRequest.placedDate}
                    dateFormat={PLACED_DATE_FORMAT}
                    isSmallFont
                />
            ),
            className: cx('table__column'),
            testSelector: 'placed',
        },
        {
            renderHeader: () => <span>{t('spot-requests.list.table.columns.expiration')}</span>,
            headerClassName: cx('table__header'),
            render: (spotRequest: SpotRequestT) => {
                return <DateCell isBold date={spotRequest.expiration} dateFormat={EXPIRATION_DATE_FORMAT} />;
            },
            className: cx('table__column'),
            testSelector: 'expiration',
        },
        {
            renderHeader: () => <span>{t('spot-requests.list.table.columns.bids')}</span>,
            headerClassName: cx('table__header'),
            render: (spotRequest: SpotRequestT) => {
                return (
                    <BidsCountCell
                        numberOfNewBids={spotRequest.numberOfNewBids}
                        numberOfReadBids={spotRequest.numberOfReadBids}
                        numberOfSentBids={spotRequest.numberOfSentBids}
                    />
                );
            },
            className: cx('table__column'),
            testSelector: 'bids',
        },
        {
            renderHeader: () => <span>{t('spot-requests.list.table.columns.costs')}</span>,
            headerClassName: cx('table__header', 'table__header--costs'),
            render: (spotRequest: SpotRequestT) => {
                return <CostsCell basePrice={spotRequest.estimatePrice} lowestBidsPrice={spotRequest.lowestPrice} />;
            },
            className: cx('table__column'),
            testSelector: 'costs',
        },
        {
            renderHeader: () => <span>{t('spot-requests.list.table.columns.asset')}</span>,
            headerClassName: cx('table__header'),
            render: (spotRequest: SpotRequestT) => {
                return <AssetCell getPartnerLink={getPartnerLink} spotRequest={spotRequest} />;
            },
            className: cx('table__column'),
            testSelector: 'asset',
        },
        {
            renderHeader: () => <span>{t('spot-requests.list.table.columns.status')}</span>,
            headerClassName: cx('table__header', 'table__header--status'),
            render: (spotRequest: SpotRequestT) => <SpotRequestStatusPill isSymmetrical status={spotRequest.status} />,
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
    ];

    const handleSelectRow = (event: React.MouseEvent, spotRequest: SpotRequestT) => {
        if (!spotRequest.id) {
            return;
        }

        goToSpotRequestDetails(spotRequest.id);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getRowMods = (meta: TableRowMetaT, spotRequest: SpotRequestT) => {
        return {};
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<SpotRequestT> tableName="spot-request" isLoading={isLoading} rows={spotRequests}>
                {(rows, isUsedPrevRows) => (
                    <Table<SpotRequestT, void>
                        testSelector="spot-requests"
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        onSelectRow={handleSelectRow}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default SpotRequestsTable;

import * as React from 'react';
import { useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './TourDetailsCard.scss';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Card from 'design-system/components/Card/Card';
import { useTranslation } from 'react-i18next';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import CarrierIcon from 'common/icons/CarrierIcon';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import TruckIcon from 'common/icons/TruckIcon';
import AssetLinkFormatter from 'design-system/components/InfoTable/formatters/AssetLinkFormatter/AssetLinkFormatter';
import AssetLabelFormatter from 'design-system/components/InfoTable/formatters/AssetLabelFormatter/AssetLabelFormatter';
import TrailerIcon from 'common/icons/TrailerIcon';
import { DispatchDetailsT } from 'broker-admin/store/dispatch-details/models';
import { urlFactory } from 'broker-admin/utils/urls';
import {
    ApiDispatchWaypointDetailsT,
    ApiStopTypeT,
    ApiTourStatusT,
    ApiTransportOrderStatusT,
    PartnerTypeEnum,
    StopEnum,
    TourStatusEnum,
    TransportOrderStatusEnum,
} from 'common/utils/api/models';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import DriverIcon from 'common/icons/DriverIcon';
import TeamDrive2Icon from 'common/icons/TeamDrive2Icon';
import DocIcon from 'common/icons/DocIcon';
import TeamDrivePill from 'common/components/status-pill/TeamDrivePill/TeamDrivePill';
import TourStatusPill from 'common/components/status-pill/TourStatusPill/TourStatusPill';
import RadarIcon from 'common/icons/RadarIcon';
import WarningTextFormatter from 'design-system/components/InfoTable/formatters/WarningTextFormatter/WarningTextFormatter';
import { checkIsInDateRange, formatDate, formatTimeInterval } from 'common/utils/time';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import NumberIcon from 'common/icons/NumberIcon';
import LocationLabel, { LocationLabelAddressFormatT } from 'common/components/LocationLabel/LocationLabel';
import SimpleDriverFormatter from 'design-system/components/InfoTable/formatters/SimpleDriverFormatter/SimpleDriverFormatter';
import ChangeTourStatusControl from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchDetails/TourDetailsCard/ChangeTourStatusControl/ChangeTourStatusControl';
import some from 'lodash/some';
import CancelSpotRequest from './CancelSpotRequest/CancelSpotRequest';
import StartAssignment from './StartAssignment/StartAssignment';
import StartEditTimeWindows from './StartEditTimeWindows/StartEditTimeWindows';
import AsyncSpotRequestDetailsLinkFormatter from 'broker-admin/components/InfoTable/formatters/AsyncSpotRequestDetailsLinkFormatter/AsyncSpotRequestDetailsLinkFormatter';
import { BrokerSidebarsTypeEnum } from 'broker-admin/layouts/SideBars/constants';
import AsyncSpotRequestDetailsStatusFormatter from 'broker-admin/components/InfoTable/formatters/AsyncSpotRequestDetailsStatusFormatter/AsyncSpotRequestDetailsStatusFormatter';
import DriverWrongCheckPointPill from 'common/components/pills/DriverWrongCheckPointPill/DriverWrongCheckPointPill';
import TrailerPickUpIcon from 'common/icons/TrailerPickUpIcon';
import LinkDropOffIcon from 'common/icons/LinkDropOffIcon';
import TruckPickUpIcon from 'common/icons/TruckPickUpIcon';
import TruckDropOffIcon from 'common/icons/TruckDropOffIcon';
import TrailerDropOffIcon from 'common/icons/TrailerDropOffIcon';
import { TFunction } from 'i18next';
import LinkPickUpIcon from 'common/icons/LinkPickUpIcon';
import { findActualTransportOrders } from 'broker-admin/store/dispatch-details/utils/find-actual-transport-order';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import AttentionTextFormatter from 'design-system/components/InfoTable/formatters/AttentionTextFormatter/AttentionTextFormatter';
import ChangeTransportOrderStatusControl from 'broker-admin/layouts/DispatchesPage/DispatchDetailsPage/DispatchDetails/TransportOrderDetailsCard/ChangeTransportOrderStatusControl/ChangeTransportOrderStatusControl';
import { checkBrokerAppointmentStatus } from 'common/utils/broker-appointment';

export type PropsT = {
    tourNumber: number;
    tour: DispatchDetailsT['tours'][number] | null | undefined;
    dispatchDetails: DispatchDetailsT | null | undefined;
    className?: string;
    onStartAssignment: () => void;
    onStartSendToSpot: () => void;
    onStartEditTimeWindows: () => void;
    isDisableAnyActions: boolean;
    keyboardShortcut?: string;
};

const cx = classNames.bind(styles);

const hideLoaderStatusSet = new Set<ApiTourStatusT | null | undefined>([
    TourStatusEnum.canceled,
    TourStatusEnum.finished,
]);

const hideLoaderTransportOrderStatusSet = new Set<ApiTransportOrderStatusT | null | undefined>([
    TransportOrderStatusEnum.done,
    TransportOrderStatusEnum.declined,
    TransportOrderStatusEnum.canceled,
]);

const detailedWaypointStopTypeSet = new Set<ApiStopTypeT>([StopEnum.pickupDeliveryShipment]);

const renderWaypointName = (t: TFunction, waypoint: ApiDispatchWaypointDetailsT | null | undefined) => {
    if (!waypoint) {
        return null;
    }

    switch (waypoint.type) {
        case StopEnum.pickupTrailer: {
            return t('common:waypoint-details.by-type.hook-trailer');
        }
        case StopEnum.pickupTruck: {
            return t('common:waypoint-details.by-type.initial-truck');
        }
        case StopEnum.pickupRoadTrain: {
            return t('common:waypoint-details.by-type.initial-truck-and-trailer');
        }
        case StopEnum.dropTrailer: {
            return t('common:waypoint-details.by-type.drop-trailer');
        }
        case StopEnum.dropTruck: {
            return t('common:waypoint-details.by-type.drop-truck');
        }
        case StopEnum.dropRoadTrain: {
            return t('common:waypoint-details.by-type.drop-truck-and-trailer');
        }
        case StopEnum.driveThrough: {
            return t('common:waypoint-details.by-type.drive-through', {
                number: (waypoint?.index || 0) + 1,
            });
        }
        case StopEnum.dropAndHook:
        case StopEnum.pickupDeliveryShipment:
        default: {
            return t('common:waypoint-details.by-type.pickup-delivery', {
                number: (waypoint?.index || 0) + 1,
            });
        }
    }
};

const renderWaypointStopIcon = (waypoint: ApiDispatchWaypointDetailsT | null | undefined) => {
    if (!waypoint) {
        return null;
    }

    switch (waypoint.type) {
        case StopEnum.pickupTrailer: {
            return (
                <TrailerPickUpIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            );
        }
        case StopEnum.pickupTruck: {
            return (
                <TruckPickUpIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            );
        }
        case StopEnum.pickupRoadTrain: {
            return <LinkPickUpIcon strokeColor={StyleGuideColorsEnum.gray} />;
        }
        case StopEnum.dropTrailer: {
            return (
                <TrailerDropOffIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            );
        }
        case StopEnum.dropTruck: {
            return (
                <TruckDropOffIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            );
        }
        case StopEnum.dropRoadTrain: {
            return <LinkDropOffIcon strokeColor={StyleGuideColorsEnum.gray} />;
        }
        case StopEnum.driveThrough: {
            return <NumberIcon number={(waypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.gray} />;
        }
        case StopEnum.dropAndHook: {
            return <NumberIcon number={(waypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.gray} />;
        }
        case StopEnum.pickupDeliveryShipment:
        default: {
            return <NumberIcon number={(waypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />;
        }
    }
};

const renderWaypointLocationAddressFormat = (
    waypoint: ApiDispatchWaypointDetailsT | null | undefined,
): LocationLabelAddressFormatT => {
    if (!waypoint) {
        return 's1_s2_zip_city_country';
    }

    switch (waypoint.type) {
        case StopEnum.pickupTrailer:
        case StopEnum.pickupTruck:
        case StopEnum.pickupRoadTrain: {
            return 'pickup_asset_s1_s2_zip_city_country';
        }
        case StopEnum.dropTrailer:
        case StopEnum.dropTruck:
        case StopEnum.dropRoadTrain: {
            return 'dropoff_asset_s1_s2_zip_city_country';
        }
        default: {
            return 's1_s2_zip_city_country';
        }
    }
};

const TourDetailsCard: React.FC<PropsT> = React.memo((props) => {
    const {
        tourNumber,
        tour,
        dispatchDetails,
        className,
        onStartAssignment,
        onStartEditTimeWindows,
        onStartSendToSpot,
        isDisableAnyActions,
        keyboardShortcut,
    } = props;

    const { t } = useTranslation();

    const { truckTransportOrder, trailerTransportOrder, carrierTransportOrder, trainTransportOrder } =
        findActualTransportOrders(tour?.transportationOrders);

    const truck = truckTransportOrder?.truck || null;
    const trailer = trailerTransportOrder?.trailer || null;
    const carrier = carrierTransportOrder?.carrier || null;
    const waypoints = tour?.waypoints || [];

    const transportOrderForChangeTourStatus = truckTransportOrder || trainTransportOrder;

    const openLeftSidebar = useOpenLeftSidebar();

    const handleOpenTrailerDetails = () => {
        if (!trailer?.id || !carrier?.id) {
            logWarning('failed to open trailer details, empty firstTrailer');
            return;
        }

        openLeftSidebar(
            {
                type: CommonSidebarsTypeEnum.trailerDetails,
                trailerId: trailer.id,
                partnerId: carrier.id,
            },
            {
                isForceShowBackAction: true,
            },
        );
    };

    const handleOpenSpotRequestDetails = (spotRequestId: SpotRequestIdT | null) => {
        if (!spotRequestId) {
            logWarning('failed to open spot details, empty spotRequestId');
            return;
        }

        openLeftSidebar(
            {
                type: BrokerSidebarsTypeEnum.spotRequestDetails,
                id: spotRequestId,
            },
            {
                isForceShowBackAction: true,
            },
        );
    };

    const handleOpenTruckDetails = () => {
        if (!truck?.id || !carrier?.id) {
            logWarning('failed to open trailer details, empty firstTruck');
            return;
        }

        openLeftSidebar(
            {
                type: CommonSidebarsTypeEnum.truckDetails,
                truckId: truck.id,
                partnerId: carrier.id,
            },
            {
                isForceShowBackAction: true,
            },
        );
    };

    const idDetailRows: Array<InfoTableRowT> = [
        {
            icon: null,
            name: t('dispatch-details.tour-details.columns.tour-number'),
            value: tour?.number,
            emptyValue: t('common:info-table.placeholders.carrier-not-assigned'),
            isBoldValue: true,
        },
    ];

    const spotDetails: Array<InfoTableRowT | null> = [
        tour?.spotId
            ? {
                  icon: <RadarIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.slate} />,
                  name: t('dispatch-details.tour-details.columns.spot-request'),
                  value: (
                      <AsyncSpotRequestDetailsLinkFormatter
                          spotRequestId={tour?.spotId || null}
                          onOpenSpotRequestDetails={handleOpenSpotRequestDetails}
                      />
                  ),
                  rightNode: <AsyncSpotRequestDetailsStatusFormatter spotRequestId={tour?.spotId || null} />,
                  emptyValue: t('common:info-table.placeholders.carrier-not-assigned'),
                  isBoldValue: true,
              }
            : null,
    ];

    const brokerWindowAppointmentWarning = (
        <Tooltip
            position={TooltipPositionEnum.topLeft}
            theme={TooltipThemeEnum.black}
            tooltipNode={
                <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                    {t('dispatches.details.broke-window-appointment-warning')}
                </TooltipContent>
            }
        >
            {(isShow, childrenClassName) => (
                <TimeWindowIcon
                    className={childrenClassName}
                    fillColor={StyleGuideColorsEnum.transparent}
                    strokeColor={StyleGuideColorsEnum.orange}
                />
            )}
        </Tooltip>
    );

    const getWaypointDetailRows = (waypoint: ApiDispatchWaypointDetailsT): Array<InfoTableRowT | null> => {
        const driverRows: Array<InfoTableRowT> = [
            {
                icon: null,
                name: t('common:waypoint-details.columns.driver-arriver-at'),
                value: waypoint?.driverArrivedTimeStamp ? (
                    <WarningTextFormatter
                        isActive={checkIsInDateRange(
                            waypoint?.correctedDateTimeFrom || waypoint?.originalDateTimeFrom,
                            waypoint?.correctedDateTimeTo || waypoint?.originalDateTimeTo,
                            waypoint?.driverArrivedTimeStamp,
                        )}
                    >
                        {formatDate('DD MMM YYYY, HH:mm', waypoint?.driverArrivedTimeStamp)}
                    </WarningTextFormatter>
                ) : null,
                emptyValue: t('common:info-table.placeholders.driver-not-arrived'),
                isBoldValue: true,
                testSelector: 'contact-company-name',
                hasBottomBorder: true,
                rightNode: waypoint?.driverArrivedFalse ? <DriverWrongCheckPointPill /> : null,
            },
            {
                icon: null,
                name: t('common:waypoint-details.columns.driver-left-at'),
                value: waypoint?.driverLeftTimeStamp ? (
                    <WarningTextFormatter
                        isActive={checkIsInDateRange(
                            waypoint?.correctedDateTimeFrom || waypoint?.originalDateTimeFrom,
                            waypoint?.correctedDateTimeTo || waypoint?.originalDateTimeTo,
                            waypoint?.driverLeftTimeStamp,
                        )}
                    >
                        {formatDate('DD MMM YYYY, HH:mm', waypoint?.driverLeftTimeStamp)}
                    </WarningTextFormatter>
                ) : null,
                emptyValue: waypoint?.driverArrivedTimeStamp
                    ? t('common:info-table.placeholders.driver-not-leave')
                    : t('common:info-table.placeholders.driver-not-arrived'),
                isBoldValue: true,
                testSelector: 'contact-company-name',
                rightNode: waypoint?.driverLeftFalse ? <DriverWrongCheckPointPill /> : null,
            },
        ];

        const isDetailedWaypoint = detailedWaypointStopTypeSet.has(waypoint?.type);
        if (!isDetailedWaypoint) {
            return [
                {
                    icon: renderWaypointStopIcon(waypoint),
                    name: renderWaypointName(t, waypoint),
                    value: (
                        <LocationLabel
                            format={renderWaypointLocationAddressFormat(waypoint)}
                            location={waypoint?.address}
                        />
                    ),
                    emptyValue: t('common:info-table.placeholders.carrier-not-assigned'),
                    isBoldValue: true,
                },
                {
                    icon: null,
                    name: t('common:waypoint-details.columns.time-slot'),
                    value: formatTimeInterval(
                        waypoint?.correctedDateTimeFrom || waypoint?.originalDateTimeFrom,
                        waypoint?.correctedDateTimeTo || waypoint?.originalDateTimeTo,
                    ),
                    emptyValue: t('common:info-table.placeholders.not-specified'),
                    isBoldValue: true,
                    hasBottomBorder: true,
                },
                ...driverRows,
            ];
        }

        const brokerAppointmentStatus = checkBrokerAppointmentStatus({
            shouldBrokerWindowAppointment: waypoint?.appointmentByBroker,
            originalDateTimeFrom: waypoint?.originalDateTimeFrom,
            originalDateTimeTo: waypoint?.originalDateTimeTo,
            correctedDateTimeFrom: waypoint?.correctedDateTimeFrom,
            correctedDateTimeTo: waypoint?.correctedDateTimeTo,
        });

        return [
            {
                icon: renderWaypointStopIcon(waypoint),
                name: renderWaypointName(t, waypoint),
                value: (
                    <LocationLabel
                        format={renderWaypointLocationAddressFormat(waypoint)}
                        location={waypoint?.address}
                    />
                ),
                emptyValue: t('common:info-table.placeholders.carrier-not-assigned'),
                isBoldValue: true,
            },
            ...(brokerAppointmentStatus.shouldShowCorrectedDateTime
                ? [
                      {
                          icon: null,
                          name: t('common:waypoint-details.columns.original-time-slot'),
                          value: formatTimeInterval(waypoint?.originalDateTimeFrom, waypoint?.originalDateTimeTo),
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          isBoldValue: true,
                          hasBottomBorder: true,
                      },
                      {
                          icon: brokerAppointmentStatus.shouldBrokerWindowAppointment
                              ? brokerWindowAppointmentWarning
                              : null,
                          name: (
                              <AttentionTextFormatter isActive={brokerAppointmentStatus.shouldBrokerWindowAppointment}>
                                  {t('common:waypoint-details.columns.corrected-time-slot')}
                              </AttentionTextFormatter>
                          ),
                          value: (
                              <AttentionTextFormatter isActive={brokerAppointmentStatus.shouldBrokerWindowAppointment}>
                                  {formatTimeInterval(waypoint?.correctedDateTimeFrom, waypoint?.correctedDateTimeTo)}
                              </AttentionTextFormatter>
                          ),
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          isBoldValue: true,
                          hasBottomBorder: true,
                      },
                  ]
                : [
                      {
                          icon: brokerAppointmentStatus.shouldBrokerWindowAppointment
                              ? brokerWindowAppointmentWarning
                              : null,
                          name: (
                              <AttentionTextFormatter isActive={brokerAppointmentStatus.shouldBrokerWindowAppointment}>
                                  {t('common:waypoint-details.columns.time-slot')}
                              </AttentionTextFormatter>
                          ),
                          value: (
                              <AttentionTextFormatter isActive={brokerAppointmentStatus.shouldBrokerWindowAppointment}>
                                  {formatTimeInterval(
                                      waypoint?.correctedDateTimeFrom || waypoint?.originalDateTimeFrom,
                                      waypoint?.correctedDateTimeTo || waypoint?.originalDateTimeTo,
                                  )}
                              </AttentionTextFormatter>
                          ),
                          emptyValue: t('common:info-table.placeholders.not-specified'),
                          isBoldValue: true,
                          hasBottomBorder: true,
                      },
                  ]),
            ...driverRows,
            {
                icon: null,
                name: t('common:waypoint-details.columns.company-name'),
                value: waypoint?.contact?.companyName,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                testSelector: 'contact-company-name',
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:waypoint-details.columns.contact-name'),
                value: waypoint?.contact?.fullName,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:waypoint-details.columns.contact-phone'),
                value: waypoint?.contact?.phone,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:waypoint-details.columns.contact-email'),
                value: waypoint?.contact?.email,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
                hasBottomBorder: true,
            },
            {
                icon: null,
                name: t('common:waypoint-details.columns.comments'),
                value: waypoint?.contact?.comment,
                emptyValue: t('common:info-table.placeholders.not-specified'),
                isBoldValue: true,
            },
        ];
    };

    const carrierLink = urlFactory.partnerDetails(PartnerTypeEnum.carrier, carrier?.id);
    const isShowAssignInfo = tour?.calculatedStatus !== TourStatusEnum.carrierUnassigned;

    const teamDrivers = useMemo(() => {
        const shouldShowTeamDrivers = !!tour?.teamDrive;
        if (!shouldShowTeamDrivers) {
            return [];
        }

        const drivers = carrierTransportOrder?.drivers?.slice(1) || [];
        return drivers?.length ? drivers : [null];
    }, [tour]);

    const carrierDetails: Array<InfoTableRowT | null> = [
        {
            icon: <CarrierIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('dispatch-details.tour-details.columns.carrier'),
            value:
                isShowAssignInfo && carrier?.id ? (
                    <Link to={carrierLink} className={cx('link')} theme={LinkThemeEnum.boldBrandDark}>
                        {carrier?.name}
                    </Link>
                ) : null,
            emptyValue: t('common:info-table.placeholders.carrier-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: (
                <TruckIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('dispatch-details.tour-details.columns.truck'),
            value:
                isShowAssignInfo && truck ? (
                    <AssetLinkFormatter assetId={truck?.id} onOpenAssetDetails={handleOpenTruckDetails}>
                        <AssetLabelFormatter model={truck?.dictTruckInfo?.model} plateNumber={truck?.plateNumber} />
                    </AssetLinkFormatter>
                ) : null,
            emptyValue: t('common:info-table.placeholders.truck-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: (
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('dispatch-details.tour-details.columns.trailer'),
            value:
                isShowAssignInfo && trailer ? (
                    <AssetLinkFormatter assetId={trailer?.id} onOpenAssetDetails={handleOpenTrailerDetails}>
                        <AssetLabelFormatter
                            model={trailer?.dictTrailerInfo?.model}
                            plateNumber={trailer?.plateNumber}
                        />
                    </AssetLinkFormatter>
                ) : null,
            emptyValue: t('common:info-table.placeholders.trailer-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: <DriverIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.gray} />,
            name: t('dispatch-details.tour-details.columns.driver'),
            value:
                isShowAssignInfo && carrierTransportOrder?.drivers?.[0] ? (
                    <SimpleDriverFormatter driver={carrierTransportOrder?.drivers?.[0]} />
                ) : null,
            emptyValue: t('common:info-table.placeholders.driver-not-assigned'),
            isBoldValue: true,
        },
        ...teamDrivers.map((driver) => {
            return {
                icon: <TeamDrive2Icon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.gray} />,
                name: t('dispatch-details.tour-details.columns.team-driver'),
                value: driver ? <SimpleDriverFormatter driver={driver} /> : null,
                emptyValue: t('common:info-table.placeholders.driver-not-assigned'),
                isBoldValue: true,
            };
        }),
        {
            icon: <DocIcon strokeColor={StyleGuideColorsEnum.gray} />,
            name: t('dispatch-details.tour-details.columns.transport-order'),
            value: carrierTransportOrder?.number,
            emptyValue: t('common:info-table.placeholders.not-created'),
            isBoldValue: true,
        },
    ];

    const isNeedAppointmentTimeWindow = waypoints?.some((waypoint) => {
        return waypoint.appointmentByBroker;
    });

    const isNeedAssignAssets = some(dispatchDetails?.tours, (tour) => tour?.availableToAssignAssets);
    const isNeedSendToSpot = some(dispatchDetails?.tours, (tour) => tour?.availableToSendToSpot);

    const isNeedAssignment = !isNeedAppointmentTimeWindow && (isNeedAssignAssets || isNeedSendToSpot);

    const hasSpotRequests = dispatchDetails?.tours?.some((tour) => tour?.spotId);

    const getCardFooterNode = (): React.ReactNode => {
        if (isNeedAssignment && hasSpotRequests) {
            return <CancelSpotRequest dispatchDetails={dispatchDetails} isDisableAnyActions={isDisableAnyActions} />;
        }

        if (isNeedAssignment && !hasSpotRequests) {
            return (
                <StartAssignment
                    dispatchDetails={dispatchDetails}
                    isNeedAssignAssets={isNeedAssignAssets}
                    onStartAssignment={onStartAssignment}
                    isNeedSendToSpot={isNeedSendToSpot}
                    onStartSendToSpot={onStartSendToSpot}
                    isDisableAnyActions={isDisableAnyActions}
                />
            );
        }

        if (isNeedAppointmentTimeWindow) {
            return (
                <StartEditTimeWindows
                    dispatchDetails={dispatchDetails}
                    onStartEditTimeWindows={onStartEditTimeWindows}
                    isDisableAnyActions={isDisableAnyActions}
                />
            );
        }

        return null;
    };

    return (
        <>
            <Card
                titleNode={t('dispatch-details.tour-details.title', {
                    number: tourNumber,
                })}
                rightNode={
                    <>
                        {dispatchDetails?.priceOffer?.teamDrive && <TeamDrivePill isSymmetrical />}
                        <TourStatusPill isSymmetrical status={tour?.calculatedStatus} />
                        <ChangeTransportOrderStatusControl
                            isShowLoading={
                                !!transportOrderForChangeTourStatus &&
                                !hideLoaderTransportOrderStatusSet.has(transportOrderForChangeTourStatus?.status)
                            }
                            transportOrderId={transportOrderForChangeTourStatus?.id || null}
                        />
                        {false && (
                            <ChangeTourStatusControl
                                isShowLoading={!!tour && !hideLoaderStatusSet.has(tour?.calculatedStatus)}
                                dispatchId={dispatchDetails?.id || null}
                                tourId={tour?.id || null}
                            />
                        )}
                    </>
                }
                className={className}
                hasHeaderBottomBorder
                footerNode={getCardFooterNode()}
                keyboardShortcut={keyboardShortcut}
            >
                <div className={cx('content')}>
                    <InfoTable shouldRenderIcons rows={idDetailRows} className={cx('table', 'table--id')} />
                    <InfoTable
                        shouldRenderIcons
                        isCollapsable
                        rows={carrierDetails}
                        className={cx('table', 'table--carrier')}
                    />
                    <InfoTable shouldRenderIcons rows={spotDetails} className={cx('table', 'table--spot')} />
                    {waypoints.map((waypoint, index) => {
                        return (
                            <InfoTable
                                key={`${index}-${waypoint.id}`}
                                shouldRenderIcons
                                isCollapsable
                                rows={getWaypointDetailRows(waypoint)}
                                className={cx('table', 'table--waypoint')}
                            />
                        );
                    })}
                </div>
            </Card>
        </>
    );
});

export default TourDetailsCard;

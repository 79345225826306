import React from 'react';
import classNames from 'classnames/bind';

import styles from './ContractDetails.scss';
import { useTranslation } from 'react-i18next';
import Divider from 'design-system/components/Divider/Divider';
import FieldValue from 'common/components/FieldValue/FieldValue';
import CalendarIcon from 'common/icons/CalendarIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UnitTypeEnum } from 'common/constants';
import { formatDate } from 'common/utils/time';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import { useSelector } from 'react-redux';
import { selectCarrierContractStateById } from 'common/store/carrier-contract-details/selectors';
import isEmpty from 'lodash/isEmpty';
import { PartnerContractT } from 'common/store/carrier-contracts/models';

const cx = classNames.bind(styles);

type PropsT = {
    contract: PartnerContractT | null;
};

const ContractDetails: React.FC<PropsT> = (props) => {
    const { contract } = props;

    const { t } = useTranslation();

    const { data: contractDetails, fetchRequest: fetchContractDetailsRequest } = useSelector(
        selectCarrierContractStateById(contract?.id),
    );

    if (fetchContractDetailsRequest.loading && isEmpty(contractDetails)) {
        return <SideBarLoader isRelative />;
    }

    if (isEmpty(contractDetails)) {
        return null;
    }

    return (
        <>
            <FieldValue
                label={t('common:carrier-contracts.details.fields.expiration.label')}
                value={formatDate('DD.MM.YYYY', contract?.validTill)}
                placeholder={t('common:fields.placeholder.empty')}
                icon={
                    <CalendarIcon
                        size={DEFAULT_ICON_SIZE}
                        fillColor={StyleGuideColorsEnum.brandAccent}
                        strokeColor={StyleGuideColorsEnum.brandDark}
                    />
                }
            />
            <Divider className={cx('divider')} />
            <FieldValue
                label={t('common:carrier-contracts.details.fields.name.label')}
                value={contractDetails?.name}
                placeholder={t('common:fields.placeholder.empty')}
            />
            <FieldValue
                label={t('common:carrier-contracts.details.fields.number.label')}
                value={contractDetails?.number}
                placeholder={t('common:fields.placeholder.empty')}
            />
            <FieldValue
                label={t('common:carrier-contracts.details.fields.truck-rate.label')}
                value={contractDetails?.truckRateKm}
                placeholder={t('common:fields.placeholder.empty')}
                unitType={UnitTypeEnum.euroAbbreviation}
            />
            <FieldValue
                label={t('common:carrier-contracts.details.fields.tilt-rate.label')}
                value={contractDetails?.tiltRateKm}
                placeholder={t('common:fields.placeholder.empty')}
                unitType={UnitTypeEnum.euroAbbreviation}
            />
            <FieldValue
                label={t('common:carrier-contracts.details.fields.box-rate.label')}
                value={contractDetails?.boxRateKm}
                placeholder={t('common:fields.placeholder.empty')}
                unitType={UnitTypeEnum.euroAbbreviation}
            />
            <FieldValue
                label={t('common:carrier-contracts.details.fields.truck-expected-mileage.label')}
                value={contractDetails?.truckExpectedKm}
                placeholder={t('common:fields.placeholder.empty')}
                unitType={UnitTypeEnum.kilometersAbbreviation}
            />
            <FieldValue
                label={t('common:carrier-contracts.details.fields.box-expected-mileage.label')}
                value={contractDetails?.boxExpectedKm}
                placeholder={t('common:fields.placeholder.empty')}
                unitType={UnitTypeEnum.kilometersAbbreviation}
            />
            <FieldValue
                label={t('common:carrier-contracts.details.fields.tilt-expected-mileage.label')}
                value={contractDetails?.tiltExpectedKm}
                placeholder={t('common:fields.placeholder.empty')}
                unitType={UnitTypeEnum.kilometersAbbreviation}
            />
        </>
    );
};

export default ContractDetails;

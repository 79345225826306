import React from 'react';

import Avatar, { AvatarSizeEnum } from '../Avatar/Avatar';
import { DATE_INTERVAL_SPECIAL_SYMBOLS, formatDateInterval } from 'common/utils/formatters';

export const pillIconRendererMap = {
    avatar: (hash: string | null | undefined, isCompact: boolean | undefined): React.ReactNode => {
        return <Avatar size={isCompact ? AvatarSizeEnum.small : AvatarSizeEnum.medium} hash={hash || ''} />;
    },
};

export const formatDateRangeFilterValue = (
    from: string | null | undefined,
    to: string | null | undefined,
    format = 'DD MMM YYYY',
) => {
    return formatDateInterval(from, to || DATE_INTERVAL_SPECIAL_SYMBOLS.FUTURE, format);
};

import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';

import styles from './DropdownSearchInput.scss';
import SearchIcon from 'common/icons/SearchIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { DropdownOverlayPositionEnum } from '../constants';
import DropdownBaseLayout from '../base/DropdownBaseLayout/DropdownBaseLayout';
import DropdownBaseButtonTrigger from '../base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import cs from 'classnames';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import useDebouncedEffect from 'common/utils/hooks/useDebouncedEffect';

const cx = classNames.bind(styles);

export type IconMetaT = {
    isDisabled: boolean;
    isLoading: boolean;
    hasChanges: boolean;
    hasWarning: boolean;
    hasSuccess: boolean;
    hasError: boolean;
    hasValue: boolean;
};

export type PropsT<OptionT, OptionValueT> = {
    className?: string;
    selectedValue: OptionValueT;
    placeholder?: string;
    inputPlaceholder?: string;
    testSelector?: string;
    isInline?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    hasChanges?: boolean;
    hasWarning?: boolean;
    hasSuccess?: boolean;
    hasError?: boolean;
    renderLeftIcon?: (meta: IconMetaT) => React.ReactNode;
    renderRightIcon?: (meta: IconMetaT) => React.ReactNode;
    renderRightInputIcon?: (meta: IconMetaT) => React.ReactNode;
    options: Array<OptionT>;
    triggerClassName?: string;
    onSelect: (value: OptionValueT) => void;
    overlayPosition: DropdownOverlayPositionEnum;
    renderOption: (option: OptionT) => React.ReactNode;
    renderTrigger: (option: OptionT | undefined, placeholder?: string) => React.ReactNode;
    getOptionValue: (option: OptionT) => OptionValueT;
    onChangeQuery: (query: string) => void;
    hasOptionsSeparator?: boolean;
    overlayClassName?: string;
    onBlur?: () => void;
    onFocus?: () => void;
    onReset?: () => void;
};

const COLD_TIME = 300;

const FLOATING_INPUT_POSITIONS = new Set([
    DropdownOverlayPositionEnum.bottomLeft,
    DropdownOverlayPositionEnum.bottomRight,
]);

const DropdownSearchInput = <OptionT, OptionValueT>(props: PropsT<OptionT, OptionValueT>) => {
    const {
        className,
        selectedValue,
        testSelector,
        placeholder,
        inputPlaceholder,
        onSelect,
        options,
        renderOption,
        renderTrigger,
        getOptionValue,
        onChangeQuery,
        isInline,
        isDisabled,
        isLoading,
        renderLeftIcon,
        hasWarning,
        hasSuccess,
        hasChanges,
        hasError,
        overlayPosition,
        renderRightIcon,
        renderRightInputIcon,
        triggerClassName,
        hasOptionsSeparator,
        overlayClassName,
        onBlur,
        onFocus,
        onReset,
    } = props;

    const inputRef = React.useRef<HTMLInputElement>();
    const [inputValue, setInputValue] = React.useState<string>('');
    const [isOpen, toggleOpen] = React.useState<boolean>(false);

    useEffect(() => {
        if (isDisabled) {
            toggleOpen(false);
        }
    }, [isDisabled]);

    const handleOpen = (): void => {
        if (isDisabled) {
            return;
        }

        if (onFocus) {
            onFocus();
        }

        // already opened
        if (isOpen) {
            inputRef.current?.focus();
        }

        toggleOpen(true);
    };

    const handleClose = (): void => {
        if (onBlur) {
            onBlur();
        }

        toggleOpen(false);
    };

    const handleOuterEvent = (): void => {
        if (onBlur) {
            onBlur();
        }

        handleClose();
    };

    const selectedOptionRef = React.useRef<OptionT | undefined>();
    if (selectedValue) {
        const findedSelectedOption = find(options, (option) => isEqual(getOptionValue(option), selectedValue)) || null;
        if (findedSelectedOption) {
            selectedOptionRef.current = findedSelectedOption;
        }
    } else {
        selectedOptionRef.current = undefined;
    }

    const selectedOption = selectedOptionRef.current;

    const inputProps = {
        onChange: (event: TODO) => setInputValue(event.target.value),
        value: inputValue || '',
        type: 'text',
        className: cx('search-input', {
            'search-input--isEmpty': !inputValue,
            'search-input--isFocus': isOpen,
        }),
        placeholder: inputPlaceholder,
        disabled: isDisabled,
    };

    useDebouncedEffect(
        (): void => {
            onChangeQuery(inputValue);
        },
        COLD_TIME,
        [inputValue],
    );

    const hasValue = !!selectedOption;

    const searchInputNode = (
        <div className={cx('search-input__wrap')}>
            <input
                ref={(node) => {
                    node?.focus();

                    // @ts-ignore
                    inputRef.current = node;
                }}
                {...inputProps}
            />
            {renderRightInputIcon && (
                <div className={cx('search-input__icon', 'search-input__icon--right-icon')}>
                    {renderRightInputIcon({
                        isDisabled: !!isDisabled,
                        hasChanges: !!hasChanges,
                        hasWarning: !!hasWarning,
                        hasSuccess: !!hasSuccess,
                        hasError: !!hasError,
                        isLoading: !!isLoading,
                        hasValue,
                    })}
                </div>
            )}
            <div className={cx('search-input__icon', 'search-input__icon--search')}>
                {isLoading ? (
                    <ControlLoaderIcon fillColor={StyleGuideColorsEnum.brandDark} size={DEFAULT_ICON_SIZE} />
                ) : (
                    <SearchIcon fillColor={StyleGuideColorsEnum.brandDark} />
                )}
            </div>
        </div>
    );

    const isFloatingInput = FLOATING_INPUT_POSITIONS.has(overlayPosition) || !overlayPosition;

    return (
        <DropdownBaseLayout
            isInline={isInline}
            isOpen={isOpen}
            className={className}
            onClose={handleOuterEvent}
            triggerNode={
                <DropdownBaseButtonTrigger
                    isEmpty={!selectedOption}
                    isPressed={isOpen}
                    renderLeftIcon={
                        renderLeftIcon
                            ? (iconMeta) =>
                                  renderLeftIcon({
                                      ...iconMeta,
                                      isLoading: !!isLoading,
                                      hasValue,
                                  })
                            : undefined
                    }
                    isDisabled={isDisabled}
                    hasWarning={hasWarning}
                    hasSuccess={hasSuccess}
                    hasChanges={hasChanges}
                    hasError={hasError}
                    className={triggerClassName}
                    testSelector={testSelector}
                    onClick={handleOpen}
                    renderRightIcon={
                        renderRightIcon
                            ? (iconMeta) =>
                                  renderRightIcon({
                                      ...iconMeta,
                                      isLoading: !!isLoading,
                                      hasValue,
                                  })
                            : undefined
                    }
                    isShowClearControl={!!onReset && !!selectedOption}
                    onReset={() => {
                        if (onReset) {
                            onReset();
                        }
                    }}
                >
                    {renderTrigger(selectedOption, placeholder)}
                </DropdownBaseButtonTrigger>
            }
            overlayPosition={overlayPosition}
            overlayClassName={cs(cx('overlay'), overlayClassName)}
            overlayNode={
                <>
                    {isFloatingInput && searchInputNode}
                    <div className={cx('options')}>
                        {options.map((option, index): React.ReactElement | null => {
                            const value = getOptionValue(option);

                            const isSelected = selectedValue === value;

                            return (
                                <div
                                    key={index}
                                    className={cx('option', {
                                        'option--isSelected': isSelected,
                                        'option--hasSeparator': hasOptionsSeparator,
                                    })}
                                    onClick={(): void => {
                                        if (isDisabled) {
                                            return;
                                        }

                                        onSelect(value);
                                        handleClose();
                                        setInputValue('');
                                    }}
                                >
                                    {renderOption(option)}
                                </div>
                            );
                        })}
                    </div>
                    {!isFloatingInput && searchInputNode}
                </>
            }
        />
    );
};

export default DropdownSearchInput;

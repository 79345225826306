import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AdditionalServices.scss';
import { StyleGuideColorsEnum, TrailerTypeEnum } from 'common/constants';
import { ApiAdditionalServiceEnum, ApiTrailerDictTypeT } from 'common/utils/api/models';
import { useSelector } from 'react-redux';
import { PriceOfferT } from 'common/store/order-creation/models';
import { checkAvailableService } from 'common/layouts/NewOrderPage/additional-services';
import AdditionalServicesGroup from 'common/layouts/NewOrderPage/OffersForm/AdditionalServicesGroup/AdditionalServicesGroup';
import {
    selectAdditionalServicesByType,
    selectAdditionalServicesDictStatus,
} from 'common/store/additional-services-dict/selectors';
import ControlLoaderWithShadow from 'common/components/ControlLoaderWithShadow/ControlLoaderWithShadow';

const cx = classNames.bind(styles);

const TYPES_BY_TRAILER: Record<TrailerTypeEnum, ApiAdditionalServiceEnum[]> = {
    [TrailerTypeEnum.box]: [
        ApiAdditionalServiceEnum.box,
        ApiAdditionalServiceEnum.accessorials,
        ApiAdditionalServiceEnum.carrier,
    ],
    [TrailerTypeEnum.tilt]: [
        ApiAdditionalServiceEnum.tilt,
        ApiAdditionalServiceEnum.accessorials,
        ApiAdditionalServiceEnum.carrier,
    ],
};

type PropsT = {
    trailerType: ApiTrailerDictTypeT['trailerType'];
    selectedServicesIds: number[];
    setSelectedServices: (selectedServicesIds: number[]) => void;
    selectedOffer?: PriceOfferT;
    serviceGroupRefs: Partial<Record<ApiAdditionalServiceEnum, React.RefObject<HTMLDivElement>>> | null;
};

const AdditionalServices: React.FC<PropsT> = React.memo((props) => {
    const { trailerType, selectedServicesIds, setSelectedServices, selectedOffer, serviceGroupRefs } = props;
    const selectedServicesSet = new Set(selectedServicesIds || []);

    const additionalServicesByType = useSelector(selectAdditionalServicesByType);
    const additionalServicesRequestStatus = useSelector(selectAdditionalServicesDictStatus);

    const additionalServicesTypes = TYPES_BY_TRAILER[trailerType as TrailerTypeEnum] || [];

    const removeServices = (selectedServiceId: AdditionalServiceIdT): void => {
        setSelectedServices(selectedServicesIds.filter((serviceId) => serviceId !== selectedServiceId));
    };

    const addServices = (selectedServiceId: AdditionalServiceIdT): void => {
        setSelectedServices([...selectedServicesIds, selectedServiceId]);
    };

    const isLoading = additionalServicesRequestStatus.loading || !selectedOffer || !trailerType;

    return (
        <div className={cx('additional-services')}>
            {isLoading && (
                <div className={cx('loader')}>
                    <ControlLoaderWithShadow fillColor={StyleGuideColorsEnum.light} />
                </div>
            )}
            {additionalServicesRequestStatus.error && (
                <div>{JSON.stringify(additionalServicesRequestStatus.error)}</div>
            )}
            {additionalServicesRequestStatus.ok &&
                additionalServicesTypes.map((additionalServicesType) => {
                    const additionalServices = additionalServicesByType?.[additionalServicesType] || [];

                    const selectedServices = additionalServices?.filter((service) => {
                        const isAvailableForOffer = checkAvailableService(service, selectedOffer);

                        return selectedServicesSet.has(service?.id) && isAvailableForOffer;
                    });

                    return (
                        <AdditionalServicesGroup
                            serviceGroupRef={serviceGroupRefs?.[additionalServicesType]}
                            key={additionalServicesType}
                            additionalServicesType={additionalServicesType}
                            additionalServices={additionalServices}
                            selectedServicesCount={selectedServices.length}
                            selectedServicesSet={selectedServicesSet}
                            selectedOffer={selectedOffer}
                            onAddServices={addServices}
                            onRemoveServices={removeServices}
                        />
                    );
                })}
        </div>
    );
});

export default AdditionalServices;

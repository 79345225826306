import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './BaseDocumentsLayout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { downloadDocument, revokeDocument, uploadDocument } from 'common/store/documents/actions';
import { DocumentRequiredEnum, DocumentStatusEnum, ApiDocumentTypeT } from 'common/utils/api/models';
import { selectDocumentsDictById } from 'common/store/documents-dict/selectors';
import {
    selectUpdateCompanyDocumentsRequest,
    selectUploadCompanyDocumentsRequest,
} from 'common/store/documents/selectors';
import { logWarning } from 'common/utils/logger';
import UploadDocumentConfirmation, {
    ConfirmUploadDocumentModalDataT,
} from 'common/layouts/BaseDocumentsLayout/dialogs/UploadDocumentConfirmation/UploadDocumentConfirmation';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import BaseDocumentsTable, {
    DocumentRowT,
} from 'common/layouts/BaseDocumentsLayout/BaseDocumentsTable/BaseDocumentsTable';
import { useOpenLeftSidebar } from 'common/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { pickFile } from 'common/utils/pick-file';
import RevokeDocumentsConfirmation, {
    ConfirmRevokeDocumentModalDataT,
} from 'common/layouts/BaseDocumentsLayout/dialogs/RevokeDocumentsConfirmation/RevokeDocumentsConfirmation';

import { selectPermissions } from 'common/store/auth/selectors';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

const cx = classNames.bind(styles);

type PropsT = {
    isAllDocumentsVerified: boolean;

    isShowReviewUser: boolean;

    tableName: string;
    isLoading: boolean;

    rows: Array<DocumentRowT>;
    testSelector?: string;
    documentStatusesAttentionSet?: Set<DocumentStatusEnum | null>;

    navigationTabs: React.ReactNode;
};

const BaseDocumentsLayout: React.FC<PropsT> = React.memo((props) => {
    const {
        isAllDocumentsVerified,
        isLoading,
        isShowReviewUser,
        rows,
        tableName,
        testSelector,
        documentStatusesAttentionSet,
        navigationTabs,
    } = props;

    const { partnerId, partnerType } = usePartnerContext();

    const permissions = useSelector(selectPermissions);

    const dispatch = useDispatch();

    const confirmUploadDocumentModalDialog = useModalDialog<ConfirmUploadDocumentModalDataT>();
    const confirmRevokeDocumentModalDialog = useModalDialog<ConfirmRevokeDocumentModalDataT>();

    const uploadRequest = useSelector(selectUploadCompanyDocumentsRequest(partnerId));
    const updateRequest = useSelector(selectUpdateCompanyDocumentsRequest(partnerId));

    const isWaitAction = uploadRequest.loading || updateRequest.loading;

    const documentsDictById = useSelector(selectDocumentsDictById(partnerType));

    const handleDownloadDocument = (documentId: DocumentIdT) => {
        dispatch(downloadDocument(documentId, partnerId));
    };

    const onConfirmRevokeDocument = (id: DocumentIdT) => {
        dispatch(revokeDocument(id, partnerId, partnerType));
    };

    const onConfirmUploadDocument = (data: ConfirmUploadDocumentModalDataT): void => {
        pickFile('application/pdf', (file) => {
            dispatch(uploadDocument(data.dictDocumentId, file, partnerId, partnerType));
        });
    };

    const handleUploadDocument = (dictDocumentId: DictDocumentIdT) => {
        const dictDocument = documentsDictById[dictDocumentId];

        const shouldConfirm = dictDocument?.required === DocumentRequiredEnum.must && isAllDocumentsVerified;
        if (shouldConfirm) {
            confirmUploadDocumentModalDialog.setData({
                dictDocumentId,
            });
        } else {
            onConfirmUploadDocument({ dictDocumentId });
        }
    };

    const handleRevokeDocument = (dictDocumentId: DictDocumentIdT, id: DocumentIdT) => {
        confirmRevokeDocumentModalDialog.setData({
            id,
            dictDocumentId,
            partnerType,
        });
    };

    const handleEditDocumentInfo = (documentType: ApiDocumentTypeT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.document,
            partnerId,
            partnerType,
            documentType,
            initIsEditing: true,
        });
    };

    useCloseModalDialogAfterRequest(uploadRequest, [confirmUploadDocumentModalDialog]);
    useCloseModalDialogAfterRequest(updateRequest, [confirmRevokeDocumentModalDialog]);

    const openLeftSidebar = useOpenLeftSidebar();
    const handleOpenUserDetails = (userId: UserIdT | null) => {
        if (!userId) {
            logWarning('failed to open user details in dispatch details, empty user id');
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    const handleSelectTableRow = (row: DocumentRowT) => {
        openLeftSidebar({
            type: CommonSidebarsTypeEnum.document,
            documentType: row.dictDocument.type,
            partnerId,
            partnerType,
            initIsEditing:
                permissions.canReviewDocuments && row?.actualVersion?.status === DocumentStatusEnum.waitingForApprove,
        });
    };

    return (
        <>
            <div className={cx('navigation-tabs')}>{navigationTabs}</div>
            {isWaitAction && <LoaderOverlay />}
            <BaseDocumentsTable
                tableName={tableName}
                testSelector={testSelector}
                isLoading={isLoading}
                rows={rows}
                isShowReviewUser={isShowReviewUser}
                documentStatusesAttentionSet={documentStatusesAttentionSet}
                onSelectRow={handleSelectTableRow}
                onOpenUserDetails={handleOpenUserDetails}
                onRevokeDocument={handleRevokeDocument}
                onEditDocumentInfo={handleEditDocumentInfo}
                onDownloadDocument={handleDownloadDocument}
                onUploadDocument={handleUploadDocument}
            />
            <RevokeDocumentsConfirmation
                data={confirmRevokeDocumentModalDialog.data}
                onCancel={confirmRevokeDocumentModalDialog.onCancel}
                onClose={confirmRevokeDocumentModalDialog.onClose}
                onConfirm={onConfirmRevokeDocument}
                requestStatus={updateRequest}
            />
            <UploadDocumentConfirmation
                data={confirmUploadDocumentModalDialog.data}
                onCancel={confirmUploadDocumentModalDialog.onCancel}
                onClose={confirmUploadDocumentModalDialog.onClose}
                requestStatus={uploadRequest}
                documentsDictById={documentsDictById}
                onConfirm={onConfirmUploadDocument}
            />
        </>
    );
});

export default BaseDocumentsLayout;

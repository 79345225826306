import * as React from 'react';
import classNames from 'classnames/bind';
import cs from 'classnames';

import styles from './TimeLeft.scss';

const cx = classNames.bind(styles);

type TimeLeftPropsT = {
    className?: string;
    isFailed: boolean;
    failedMessage: React.ReactNode;
    time: string | null | undefined;
    timeDescription: React.ReactNode;
    isWarning?: boolean;
};

const TimeLeft: React.FC<TimeLeftPropsT> = (props) => {
    const { className, isWarning, isFailed, failedMessage, time, timeDescription } = props;

    if (!time) {
        return null;
    }

    if (isFailed) {
        return <div className={cx('failed-message')}>{failedMessage}</div>;
    }

    return (
        <div
            className={cs(
                cx('status', {
                    'status--is-warning': isWarning,
                }),
                className,
            )}
        >
            <div className={cx('time')}>{time}</div>
            <div className={cx('message')}>{timeDescription}</div>
        </div>
    );
};

export default TimeLeft;

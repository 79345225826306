import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './OrderListPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectBrokerOrdersByIds,
    selectBrokerOrdersPages,
    selectBrokerOrdersTotal,
} from 'broker-admin/store/orders/selectors';
import { OMSRoutesEnum, PartnersRoutesEnum } from 'broker-admin/constants';
import { CompanyTypeEnum, EmissionStandartEnum, QueryFiltersKeysEnum, QueryKeysEnum } from 'common/constants';
import OrdersTable from './OrdersTable/OrdersTable';
import { Trans, useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import history from 'common/utils/history';
import { generatePath } from 'react-router';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import { BrokerOrderSortEnum, BrokerOrdersQuickFilterEnum } from 'common/utils/api/models';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { QueryFiltersT } from 'common/models';
import Pagination from 'common/components/Table/Pagination/Pagination';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import { fetchBrokerOrdersPage } from 'broker-admin/store/orders/actions';
import { BrokerOrderT } from 'broker-admin/store/orders/models';
import { selectPermissions } from 'common/store/auth/selectors';
import FiltersSidebarContent from './FiltersSidebarContent/FiltersSidebarContent';
import SideBar from 'common/layouts/LeftMenuLayout/SideBar/SideBar';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    formatQuery,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import QuickFilters, { QuickFiltersOptionT } from 'common/components/Table/QuickFilters/QuickFilters';
import { NotificationLabelThemeEnum } from 'common/components/notifications/NotificationLabel/NotificationLabel';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TrailerDictIdFilterPill from 'common/components/filter-pills/TrailerDictIdFilterPill/TrailerDictIdFilterPill';
import TableError from 'common/components/Table/TableError/TableError';
import { prepareFetchPageQuery } from 'broker-admin/layouts/OrdersPage/OrderListPage/prepare-fetch-page-query';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ListPageHeaderTabsLayout from 'common/layouts/ListPage/ListPageHeaderTabsLayout/ListPageHeaderTabsLayout';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { EMISSION_STANDART_MAP } from 'common/components/EmissionStandartLabel/EmissionStandartLabel';
import { useHistoryPush } from 'common/utils/hooks/useHistoryPush';
import { useQueryParams } from 'use-query-params';
import { formatDateRangeFilterValue } from 'common/components/FilterPills/pill-renderers';

const cx = classNames.bind(styles);

type PropsT = {};

const OrderListPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    const { historyPush } = useHistoryPush();
    const dispatch = useDispatch();
    const total = useSelector(selectBrokerOrdersTotal);
    const ordersPages = useSelector(selectBrokerOrdersPages);
    const orderById = useSelector(selectBrokerOrdersByIds);

    const [isShowFilters, triggerFilters] = React.useState<boolean>(false);
    const showFilters = (): void => {
        triggerFilters(true);
    };
    const hideFilters = (): void => {
        triggerFilters(false);
    };

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.ordersFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.ordersPage]: createPageNumberParam(),
        [QueryKeysEnum.ordersSort]: createSortParams<BrokerOrderSortEnum>({
            value: BrokerOrderSortEnum.createdDate,
            direction: SortDirectionEnum.DESC,
        }),
    });

    const pageNumber = query[QueryKeysEnum.ordersPage];
    const selectedSort = query[QueryKeysEnum.ordersSort];
    const queryFilters = query[QueryKeysEnum.ordersFilters];

    const page = ordersPages[pageNumber];
    const { ids, requestStatus } = page || {};

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchBrokerOrdersPage(pageNumber, query));

        return (): void => {
            // TODO reset
        };
    }, [page, pageNumber, selectedSort, queryFilters]);

    const orders = React.useMemo(() => {
        return (ids || []).map((id): BrokerOrderT => orderById[id as string]);
    }, [ids, orderById]);

    const goToCreateOrderForm = (): void => {
        history.push({
            pathname: OMSRoutesEnum.newOrder,
        });
    };

    const goToPage = (pageNumber: PageNumberT) => {
        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.ordersPage]: pageNumber,
            }),
        });
    };

    const goToOrderDetails = (event: React.MouseEvent, orderId: OrderIdT) => {
        const pathname = generatePath(OMSRoutesEnum.orderDetails, {
            orderId,
        });

        historyPush(event, pathname + history.location.search);
    };

    const goToPartnerDetails = (companyId: PartnerIdT, companyType: CompanyTypeEnum) => {
        const pathname = generatePath(PartnersRoutesEnum.partnerDetails, {
            partnerType: companyType,
            partnerId: companyId,
        });

        history.push({
            pathname,

            search: formatQuery({
                ...query,
            }),
        });
    };

    const orderQuickFilterOptions: Array<QuickFiltersOptionT<BrokerOrdersQuickFilterEnum>> = [
        {
            id: undefined,
            showPriority: 0,
            hidePriority: 0,
            label: t('orders.list.status-filter.all-dispatches'),
            testSelector: 'all',
        },
        {
            id: BrokerOrdersQuickFilterEnum.unassigned,
            showPriority: 1,
            hidePriority: 2,
            notificationProps: {
                isShowPlusSign: true,
                count: 2,
                theme: NotificationLabelThemeEnum.red,
            },
            label: t('orders.list.status-filter.unassigned'),
            testSelector: BrokerOrdersQuickFilterEnum.unassigned,
        },
        {
            id: BrokerOrdersQuickFilterEnum.runningOutOfTime,
            showPriority: 2,
            hidePriority: 1,
            label: t('orders.list.status-filter.running-out-of-time'),
            testSelector: BrokerOrdersQuickFilterEnum.runningOutOfTime,
        },
        {
            id: BrokerOrdersQuickFilterEnum.ranOutOfTime,
            showPriority: 3,
            hidePriority: 4,
            label: t('orders.list.status-filter.ran-out-of-time'),
            testSelector: BrokerOrdersQuickFilterEnum.ranOutOfTime,
        },
        {
            id: BrokerOrdersQuickFilterEnum.shipmentExpired,
            showPriority: 4,
            hidePriority: 5,
            label: t('orders.list.status-filter.shipment-expired'),
            notificationProps: {
                isShowPlusSign: true,
                count: 2,
                theme: NotificationLabelThemeEnum.red,
            },
            testSelector: BrokerOrdersQuickFilterEnum.shipmentExpired,
        },
        {
            id: BrokerOrdersQuickFilterEnum.assigned,
            showPriority: 5,
            hidePriority: 12,
            label: t('orders.list.status-filter.assigned'),
            testSelector: BrokerOrdersQuickFilterEnum.assigned,
        },
        {
            id: BrokerOrdersQuickFilterEnum.inTransit,
            showPriority: 6,
            hidePriority: 11,
            label: t('orders.list.status-filter.in-transit'),
            testSelector: BrokerOrdersQuickFilterEnum.inTransit,
        },
        {
            id: BrokerOrdersQuickFilterEnum.delaying,
            showPriority: 7,
            hidePriority: 3,
            label: t('orders.list.status-filter.delaying'),
            notificationProps: {
                count: 2,
                theme: NotificationLabelThemeEnum.orange,
            },
            testSelector: BrokerOrdersQuickFilterEnum.delaying,
        },
        {
            id: BrokerOrdersQuickFilterEnum.tourCanceled,
            showPriority: 8,
            hidePriority: 10,
            label: t('orders.list.status-filter.tour-canceled'),
            testSelector: BrokerOrdersQuickFilterEnum.tourCanceled,
        },
        {
            id: BrokerOrdersQuickFilterEnum.shipmentMissed,
            showPriority: 9,
            hidePriority: 6,
            label: t('orders.list.status-filter.shipment-missed'),
            testSelector: BrokerOrdersQuickFilterEnum.shipmentMissed,
        },
        {
            id: BrokerOrdersQuickFilterEnum.reloading,
            showPriority: 10,
            hidePriority: 8,
            label: t('orders.list.status-filter.reloading'),
            testSelector: BrokerOrdersQuickFilterEnum.reloading,
        },
        {
            id: BrokerOrdersQuickFilterEnum.shipmentCanceled,
            showPriority: 11,
            hidePriority: 7,
            label: t('orders.list.status-filter.shipment-canceled'),
            testSelector: BrokerOrdersQuickFilterEnum.shipmentCanceled,
        },
    ];

    const sortOptions: Array<SortDropdownOptionT<BrokerOrderSortEnum>> = React.useMemo(
        () => [
            {
                label: t('orders.list.table.sorts.triggers.pickupDate'),
                value: BrokerOrderSortEnum.pickupDate,
            },
            {
                label: t('orders.list.table.sorts.triggers.dropOffDate'),
                value: BrokerOrderSortEnum.dropOffDate,
            },
            {
                label: t('orders.list.table.sorts.triggers.createdDate'),
                value: BrokerOrderSortEnum.createdDate,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<BrokerOrderSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.ordersPage]: 0,
            [QueryKeysEnum.ordersSort]: sort,
        });
    };

    const handleSetStatusFilter = (quickFilterId: BrokerOrdersQuickFilterEnum | undefined) => {
        const prevQueryFilters = query[QueryKeysEnum.ordersFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            [QueryFiltersKeysEnum.quickFilter]: quickFilterId,
        };

        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.ordersPage]: 0,
                [QueryKeysEnum.ordersFilters]: queryFilters,
            }),
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.ordersFilters] || {};

        const queryFilters = {
            [QueryFiltersKeysEnum.includeStatuses]: prevQueryFilters[QueryFiltersKeysEnum.includeStatuses],
            ...selectedQueryFilters,
        };

        history.push({
            search: formatQuery({
                ...query,
                [QueryKeysEnum.ordersPage]: 0,
                [QueryKeysEnum.ordersFilters]: queryFilters,
            }),
        });

        hideFilters();
    };

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                render: (queryFilters) => {
                    return t('orders-table.filters.pills.key-account-manager', {
                        value: queryFilters[QueryFiltersKeysEnum.keyAccountManagerName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.keyAccountManagerId, QueryFiltersKeysEnum.keyAccountManagerName],
            },
            {
                render: (queryFilters) => {
                    return t('orders-table.filters.pills.dispatcher', {
                        value: queryFilters[QueryFiltersKeysEnum.dispatcherName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dispatcherId, QueryFiltersKeysEnum.dispatcherName],
            },
            {
                render: (queryFilters) => {
                    return t('orders-table.filters.pills.shipper', {
                        value: queryFilters[QueryFiltersKeysEnum.shipperName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.shipperId, QueryFiltersKeysEnum.shipperName],
            },
            {
                render: (queryFilters) => {
                    return t('orders-table.filters.pills.carrier', {
                        value: queryFilters[QueryFiltersKeysEnum.carrierName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.carrierId, QueryFiltersKeysEnum.carrierName],
            },
            {
                render: (queryFilters) => {
                    const dictTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

                    return (
                        <Trans
                            i18nKey="orders-table.filters.pills.trailer"
                            components={{
                                value: <TrailerDictIdFilterPill trailerDictId={dictTrailerId} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.dictTrailerId],
            },
            {
                render: (queryFilters) => {
                    const emissionStandard = queryFilters[QueryFiltersKeysEnum.emissionStandard];

                    return t('orders-table.filters.pills.emission-standard', {
                        value: EMISSION_STANDART_MAP[emissionStandard as EmissionStandartEnum],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.emissionStandard],
            },
            {
                render: (queryFilters) => {
                    return t('orders-table.filters.pills.pickup-address', {
                        value: queryFilters[QueryFiltersKeysEnum.pickupAddress],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.pickupAddress],
            },
            {
                render: (queryFilters) => {
                    return t('orders-table.filters.pills.dropoff-address', {
                        value: queryFilters[QueryFiltersKeysEnum.dropoffAddress],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dropoffAddress],
            },
            {
                render: (queryFilters) => {
                    const pickupDateFrom = queryFilters[QueryFiltersKeysEnum.pickupDateFrom];
                    const pickupDateTo = queryFilters[QueryFiltersKeysEnum.pickupDateTo];

                    return t('orders-table.filters.pills.pickup', {
                        value: formatDateRangeFilterValue(pickupDateFrom, pickupDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.pickupDateFrom, QueryFiltersKeysEnum.pickupDateTo],
            },
            {
                render: (queryFilters) => {
                    const dropOffDateFrom = queryFilters[QueryFiltersKeysEnum.dropOffDateFrom];
                    const dropOffDateTo = queryFilters[QueryFiltersKeysEnum.dropOffDateTo];

                    return t('orders-table.filters.pills.drop-off', {
                        value: formatDateRangeFilterValue(dropOffDateFrom, dropOffDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dropOffDateFrom, QueryFiltersKeysEnum.dropOffDateTo],
            },
            {
                render: (queryFilters) => {
                    const createdDateFrom = queryFilters[QueryFiltersKeysEnum.createdDateFrom];
                    const createdDateTo = queryFilters[QueryFiltersKeysEnum.createdDateTo];

                    return t('orders-table.filters.pills.created', {
                        value: formatDateRangeFilterValue(createdDateFrom, createdDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdDateFrom, QueryFiltersKeysEnum.createdDateTo],
            },
        ],
        [],
    );

    const hasQueryFilters = !!Object.keys(queryFilters).length;

    const permissions = useSelector(selectPermissions);

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.orders')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('orders.list.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
                <ListPageLayout>
                    <ListPageHeaderTabsLayout>
                        <QuickFilters
                            options={orderQuickFilterOptions}
                            selectedId={queryFilters[QueryFiltersKeysEnum.quickFilter]}
                            onSelect={handleSetStatusFilter}
                            moreStatusesLabel={t('orders.list.status-filter.more-statuses')}
                        />
                    </ListPageHeaderTabsLayout>
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                <FiltersTrigger
                                    className={cx('filters-trigger')}
                                    title={t('orders.list.filters.trigger')}
                                    isActive={isShowFilters}
                                    onClick={showFilters}
                                />
                                <SortDropdown
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                />
                            </>
                        }
                        rightToolsNode={
                            <>
                                {permissions.canCreateOrder && (
                                    <Button theme={ButtonThemeEnum.primary} type="submit" onClick={goToCreateOrderForm}>
                                        {t('orders.list.create-new-order')}
                                    </Button>
                                )}
                            </>
                        }
                        filterTagsNode={
                            <FilterPills<QueryFiltersT>
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                                config={filterPillsConfig}
                            />
                        }
                    />
                    {!orders.length && requestStatus?.ok && hasQueryFilters && (
                        <TableMessage
                            iconType={TableMessageIconsEnum.notFound}
                            title={t('orders.list.table.messages.not-found.title')}
                            description={t('orders.list.table.messages.not-found.description')}
                            isShowAction
                            actionTitle={t('orders.list.table.messages.not-found.action')}
                            actionTheme={ButtonThemeEnum.secondary}
                            onActionClick={() => handleSetQueryFilters({})}
                        />
                    )}
                    {!orders.length && requestStatus?.ok && !hasQueryFilters && (
                        <TableMessage
                            iconType={TableMessageIconsEnum.empty}
                            title={t('orders.list.table.messages.empty.action')}
                            description={t('orders.list.table.messages.empty.description')}
                            isShowAction={permissions.canCreateOrder}
                            actionTitle={t('orders.list.table.messages.empty.action')}
                            onActionClick={goToCreateOrderForm}
                        />
                    )}
                    {!orders.length && requestStatus?.error && <TableError />}
                    <OrdersTable
                        orders={orders}
                        goToOrderDetails={goToOrderDetails}
                        goToPartnerDetails={goToPartnerDetails}
                        isLoading={requestStatus?.loading}
                    />
                </ListPageLayout>
            </ContentMargins>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
            <SideBar isShow={isShowFilters} onClose={hideFilters}>
                {(onClose) => (
                    <FiltersSidebarContent
                        onClose={onClose}
                        queryFilters={queryFilters}
                        setQueryFilters={handleSetQueryFilters}
                    />
                )}
            </SideBar>
        </ScrollableContent>
    );
});

export default OrderListPage;

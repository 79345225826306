import React from 'react';
import UiContext from 'common/contexts/ui-context';
import NavBurger from 'common/components/NavBurger/NavBurger';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './TopBar.scss';
import ContentLimiter from '../ContentLimiter/ContentLimiter';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    isSticky?: boolean;
    isWhite?: boolean;
    scrollBarWidth?: number;
};

const TopBar: React.FC<PropsT> = (props) => {
    const { children, isSticky, isWhite, scrollBarWidth, className } = props;

    const uiContext = React.useContext(UiContext);

    const contentNode = (
        <>
            <div className={cx('menu-trigger')} onClick={uiContext.menu?.handleToggle}>
                <NavBurger isOpen={!!uiContext.menu?.isVisible} />
            </div>
            {children}
        </>
    );

    return (
        <div
            className={cs(
                cx('top-bar', {
                    'top-bar--isSticky': isSticky,
                }),
                className,
            )}
            style={{
                left: `${0}px`,
                right: `${scrollBarWidth || 0}px`,
            }}
        >
            {isSticky ? (
                <ContentLimiter>
                    <div
                        className={cs(
                            cx('inner', {
                                'inner--isWhite': isWhite,
                                'inner--isSticky': isSticky,
                            }),
                        )}
                    >
                        {contentNode}
                    </div>
                </ContentLimiter>
            ) : (
                <div className={cx('inner')}>{contentNode}</div>
            )}
        </div>
    );
};

export default TopBar;

import * as React from 'react';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import { Trans, useTranslation } from 'react-i18next';
import TrailerDictIdFilterPill from 'common/components/filter-pills/TrailerDictIdFilterPill/TrailerDictIdFilterPill';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { useSelector } from 'react-redux';
import { selectTrailersDictById } from 'common/store/trailers-dict/selectors';
import { formatDateRangeFilterValue, pillIconRendererMap } from 'common/components/FilterPills/pill-renderers';

type PropsT = {
    isCompact?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, setQueryFilters } = props;

    const { t } = useTranslation();

    const dictTrailerById = useSelector(selectTrailersDictById);

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                theme: FilterPillThemeEnum.charcoal,
                renderIcon: (queryFilters, isCompact) => {
                    return pillIconRendererMap.avatar(
                        queryFilters[QueryFiltersKeysEnum.keyAccountManagerName],
                        isCompact,
                    );
                },
                render: (queryFilters) => {
                    return t('dispatches.list.filters.pills.key-account-manager', {
                        value: queryFilters[QueryFiltersKeysEnum.keyAccountManagerName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.keyAccountManagerId, QueryFiltersKeysEnum.keyAccountManagerName],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                renderIcon: (queryFilters, isCompact) => {
                    return pillIconRendererMap.avatar(queryFilters[QueryFiltersKeysEnum.dispatcherName], isCompact);
                },
                render: (queryFilters) => {
                    return t('dispatches.list.filters.pills.dispatcher', {
                        value: queryFilters[QueryFiltersKeysEnum.dispatcherName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dispatcherId, QueryFiltersKeysEnum.dispatcherName],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('dispatches.list.filters.pills.search', {
                        value: queryFilters[QueryFiltersKeysEnum.searchText],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.searchText],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    return t('dispatches.list.filters.pills.shipper', {
                        value: queryFilters[QueryFiltersKeysEnum.shipperName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.shipperId, QueryFiltersKeysEnum.shipperName],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    return t('dispatches.list.filters.pills.carrier', {
                        value: queryFilters[QueryFiltersKeysEnum.carrierName],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.carrierId, QueryFiltersKeysEnum.carrierName],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: (queryFilters) => {
                    const selectedTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];

                    return (
                        <Trans
                            i18nKey="dispatches.list.filters.pills.trailer"
                            components={{
                                value: <TrailerDictIdFilterPill trailerDictId={selectedTrailerId} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.dictTrailerId],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('dispatches.list.filters.pills.origin-address', {
                        value: queryFilters[QueryFiltersKeysEnum.originAddress],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.originAddress],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    return t('dispatches.list.filters.pills.destination-address', {
                        value: queryFilters[QueryFiltersKeysEnum.dropoffAddress],
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dropoffAddress],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('dispatches.list.filters.pills.has-unpaid-orders');
                },
                clearKeys: [QueryFiltersKeysEnum.hasUnpaidOrders],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: () => {
                    return t('dispatches.list.filters.pills.spot-transport-orders');
                },
                clearKeys: [QueryFiltersKeysEnum.spotTransportOrder],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: () => {
                    return t('dispatches.list.filters.pills.contract-transport-orders');
                },
                clearKeys: [QueryFiltersKeysEnum.contractedTransportOrder],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: () => {
                    return t('dispatches.list.filters.pills.spot-orders');
                },
                clearKeys: [QueryFiltersKeysEnum.spotOrder],
            },
            {
                theme: FilterPillThemeEnum.blazeOrange,
                render: () => {
                    return t('dispatches.list.filters.pills.contract-orders');
                },
                clearKeys: [QueryFiltersKeysEnum.contractedOrder],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const pickupDateFrom = queryFilters[QueryFiltersKeysEnum.pickupDateFrom];
                    const pickupDateTo = queryFilters[QueryFiltersKeysEnum.pickupDateTo];

                    return t('dispatches.list.filters.pills.pickup', {
                        value: formatDateRangeFilterValue(pickupDateFrom, pickupDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.pickupDateFrom, QueryFiltersKeysEnum.pickupDateTo],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const dropOffDateFrom = queryFilters[QueryFiltersKeysEnum.dropOffDateFrom];
                    const dropOffDateTo = queryFilters[QueryFiltersKeysEnum.dropOffDateTo];

                    return t('dispatches.list.filters.pills.drop-off', {
                        value: formatDateRangeFilterValue(dropOffDateFrom, dropOffDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.dropOffDateFrom, QueryFiltersKeysEnum.dropOffDateTo],
            },
            {
                theme: FilterPillThemeEnum.charcoal,
                render: (queryFilters) => {
                    const createdDateFrom = queryFilters[QueryFiltersKeysEnum.createdDateFrom];
                    const createdDateTo = queryFilters[QueryFiltersKeysEnum.createdDateTo];

                    return t('dispatches.list.filters.pills.created', {
                        value: formatDateRangeFilterValue(createdDateFrom, createdDateTo),
                    });
                },
                clearKeys: [QueryFiltersKeysEnum.createdDateFrom, QueryFiltersKeysEnum.createdDateTo],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('dispatches.list.warning-filters.running-out-of-time-to-assign-carrier');
                },
                clearKeys: [QueryFiltersKeysEnum.runningOutOfTimeToAssignCarrier],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('dispatches.list.warning-filters.run-out-of-time-to-assign-carrier');
                },
                clearKeys: [QueryFiltersKeysEnum.runOutOfTimeToAssignCarrier],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('dispatches.list.warning-filters.running-out-of-time-to-assign-assets');
                },
                clearKeys: [QueryFiltersKeysEnum.runningOutOfTimeToAssignAssets],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('dispatches.list.warning-filters.run-out-of-time-to-assign-assets');
                },
                clearKeys: [QueryFiltersKeysEnum.runOutOfTimeToAssignAssets],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('dispatches.list.warning-filters.running-out-of-time-to-assign-driver');
                },
                clearKeys: [QueryFiltersKeysEnum.runningOutOfTimeToAssignDriver],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('dispatches.list.warning-filters.run-out-of-time-to-assign-driver');
                },
                clearKeys: [QueryFiltersKeysEnum.runOutOfTimeToAssignDriver],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('dispatches.list.warning-filters.tour-delaying');
                },
                clearKeys: [QueryFiltersKeysEnum.tourDelaying],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('dispatches.list.warning-filters.no-show');
                },
                clearKeys: [QueryFiltersKeysEnum.noShow],
            },
            {
                theme: FilterPillThemeEnum.tomatoRed,
                render: () => {
                    return t('dispatches.list.warning-filters.shipment-missed');
                },
                clearKeys: [QueryFiltersKeysEnum.shipmentMissed],
            },
        ],
        [t, dictTrailerById],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
        />
    );
});

export default SelectedFilters;

import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    strokeColor: StyleGuideColorsEnum;
};

const ShipperIcon: React.FC<PropsT> = (props) => {
    const { size, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 21">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1.517h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M4 10.517v1.5h12v-1.5H4zm9-3.75L11.943 5.71 10.75 6.895V4.517h-1.5V6.91L8.043 5.725 7 6.767l3 3 3-3zm-9 7.5h12v-1.5H4v1.5zm0 2.25h12v-1.5H4v1.5z"
                />
            </g>
        </svg>
    );
};

ShipperIcon.displayName = 'ShipperIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.gray,
};

export { storyProps };
export default ShipperIcon;

export const ShipperIconProps = {
    getControlProps: ({
        isDisabled,
        isFocused,
        isPressed,
        hasError,
        hasWarning,
        hasSuccess,
        hasValue,
    }: {
        isDisabled?: boolean;
        isFocused?: boolean;
        isPressed?: boolean;
        hasError?: boolean;
        hasWarning?: boolean;
        hasSuccess?: boolean;
        hasValue?: boolean;
    }): PropsT => {
        if (hasError) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.tomatoRed,
            };
        }

        if (hasWarning) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.orange,
            };
        }

        if (hasSuccess) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }

        if (isDisabled) {
            return {
                size: DEFAULT_ICON_SIZE,
                strokeColor: StyleGuideColorsEnum.slate,
            };
        }

        return {
            size: DEFAULT_ICON_SIZE,
            strokeColor:
                hasValue || isFocused || isPressed ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.gray,
        };
    },
};

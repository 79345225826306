import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './DocumentsTable.scss';
import TableLoader from 'common/components/Table/TableLoader/TableLoader';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import LineLoader from 'common/components/LineLoader/LineLoader';
import { isNonNil } from 'common/utils';
import { ReactNode, useRef } from 'react';

const cx = classNames.bind(styles);

const CLICK_SHIFT_LIMIT = 10;

export type TableRowMetaT = {
    isSelected: boolean;
    isHovered: boolean;
};

export enum TableColumnModsEnum {
    center = 'center',
}

export type TableColumnT<RowT, ColumnsExtraDataT> = {
    // headers
    renderHeader: (columnsExtraData?: ColumnsExtraDataT) => React.ReactElement | null;
    headerClassName?: string;

    headerMods?: Partial<Record<TableColumnModsEnum, boolean>>;

    // cells
    render: (row: RowT, meta: TableRowMetaT, rowIndex: number) => React.ReactElement | null;
    className?: string;

    testSelector?: string;
};

export enum TableRowModsEnum {
    hasLeftGreenBorder = 'hasLeftGreenBorder',
    hasLeftOrangeBorder = 'hasLeftOrangeBorder',
    hasLeftRedBorder = 'hasLeftRedBorder',
    hasLeftGreyBorder = 'hasLeftGreyBorder',
}

export enum TableRowThemeEnum {
    empty = 'empty',
    normal = 'normal',
}

export type PropsT<RowT, ColumnsExtraDataT> = {
    className?: string;
    columns: Array<TableColumnT<RowT, ColumnsExtraDataT> | null>;
    columnsExtraData?: ColumnsExtraDataT;
    selectedRowsSet?: Set<RowT>;
    rows: Array<RowT>;
    getRowMods?: (meta: TableRowMetaT, row: RowT) => Partial<Record<TableRowModsEnum, Boolean>>;
    getRowTheme?: (meta: TableRowMetaT, row: RowT) => TableRowThemeEnum;
    onSelectRow?: (row: RowT) => void;
    isLoading: boolean;
    testSelector?: string;
    isUsedPrevRows: boolean;
    footerNode?: ReactNode;
    headerNode?: ReactNode;
};

const DEFAULT_MODS = {
    hasLeftGreenBorder: false,
    hasLeftOrangeBorder: false,
    hasLeftRedBorder: false,
    hasLeftGreyBorder: false,
    isHighlighted: false,
    isSelected: false,
};

const DocumentsTable = <RowT, ColumnsExtraDataT>(props: PropsT<RowT, ColumnsExtraDataT>) => {
    const {
        columns,
        rows,
        className,
        onSelectRow,
        selectedRowsSet,
        getRowMods,
        getRowTheme,
        columnsExtraData,
        isLoading,
        testSelector,
        isUsedPrevRows,
        headerNode,
        footerNode,
    } = props;

    const mouseDownPositionRef = useRef<number>(0);

    const [hoveredRow, onHoverRow] = React.useState<RowT | null>(null);

    const filteredColumns = React.useMemo(() => {
        return columns.filter(isNonNil);
    }, [columns]);

    const fullTestSelector = `${testSelector}_table`;

    const isShowTable = !!rows.length || !!headerNode || !!footerNode;
    const isShowTableLoader = isLoading && !isShowTable;
    const isShowLineLoader = isLoading && isShowTable && !isUsedPrevRows;
    const isShowOverlayLoader = isUsedPrevRows;

    return (
        <div className={cs(cx('table'), className)}>
            {isShowTable && (
                <table data-test-selector={fullTestSelector}>
                    <thead>
                        <tr>
                            {filteredColumns.map((column, columnIndex) => (
                                <th
                                    key={columnIndex}
                                    className={cs(
                                        cx('header', {
                                            'header--mod-center': !!column.headerMods?.center,
                                        }),
                                        column.headerClassName,
                                    )}
                                    data-test-selector={`${fullTestSelector}_header_${column.testSelector}`}
                                >
                                    {columnIndex === 0 && (
                                        <div className={cx('line-loader-container')}>
                                            <LineLoader isShow={isShowLineLoader} />
                                        </div>
                                    )}
                                    {column.renderHeader(columnsExtraData)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {!!headerNode && (
                            <tr>
                                <td colSpan={filteredColumns.length}>{headerNode}</td>
                            </tr>
                        )}
                        {rows.map((row, rowIndex) => {
                            const meta: TableRowMetaT = {
                                isSelected: !!selectedRowsSet?.has(row),
                                isHovered: hoveredRow === row,
                            };

                            const rowMods = getRowMods ? getRowMods(meta, row) : DEFAULT_MODS;
                            const rowTheme = getRowTheme ? getRowTheme(meta, row) : TableRowThemeEnum.normal;

                            return (
                                <tr
                                    key={rowIndex}
                                    className={cx('row', {
                                        [`row--theme-${rowTheme}`]: !!rowTheme,
                                        'row--mod-isSelected': meta.isSelected,
                                        'row--mod-isHovered': meta.isHovered,
                                        'row--hasLeftGreenBorder': rowMods.hasLeftGreenBorder,
                                        'row--hasLeftRedBorder': rowMods.hasLeftRedBorder,
                                        'row--hasLeftGreyBorder': rowMods.hasLeftGreyBorder,
                                        'row--hasLeftOrangeBorder': rowMods.hasLeftOrangeBorder,
                                    })}
                                    onClick={(event): void => {
                                        const isClick =
                                            Math.abs(mouseDownPositionRef.current - event.clientX) < CLICK_SHIFT_LIMIT;
                                        if (!isClick) {
                                            return;
                                        }

                                        if (onSelectRow) {
                                            onSelectRow(row);
                                        }
                                    }}
                                    onMouseDown={(event) => {
                                        mouseDownPositionRef.current = event.clientX;
                                    }}
                                    onMouseMove={() => {
                                        if (row !== hoveredRow) {
                                            onHoverRow(row);
                                        }
                                    }}
                                    data-test-selector={`${fullTestSelector}_row_${rowIndex}`}
                                >
                                    {filteredColumns.map((column, columnIndex) => (
                                        <td
                                            key={columnIndex}
                                            className={column.className}
                                            data-test-selector={`${fullTestSelector}_cell_${column.testSelector}`}
                                        >
                                            {column.render(row, meta, rowIndex)}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            )}
            {isShowTableLoader && <TableLoader />}
            {isShowOverlayLoader && <LoaderOverlay />}
            {footerNode}
        </div>
    );
};

export default React.memo(DocumentsTable) as typeof DocumentsTable;
